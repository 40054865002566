import { Components } from '@mui/material'

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    root: { borderBottomWidth: 0, fontSize: '0.875rem' },
    head: {
      fontWeight: 500,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      color: 'rgba(0,0,0,0.8)',
      paddingBottom: '8px',
    },
    body: { padding: '16px', minWidth: 15 },
    sizeSmall: {
      maxHeight: 10,
      padding: '8px 16px',
      '&.MuiTableCell-body': { borderBottomWidth: 1 },
    },
    // In MUIv5, when padding="none" prop is added to the component, there's a specificity conflict with paddings applied in the overrides above and padding will still be added.
    // This ensures that there's no padding when the prop is provided:
    paddingNone: {
      padding: 0,
    },
  },
}

export default MuiTableCell
