import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { LoadingStatus } from '../../types'
import { mapDaysToShifts } from '../../utils'

export const getSchedulerDates = (state: RootState) => ({
  dateFrom: state.common.schedules.schedulerDates?.dateFrom,
  dateTo: state.common.schedules.schedulerDates?.dateTo,
})

export const getSelectedOpenShiftAssignment = (state: RootState) =>
  state.common.schedules.selectedOpenShiftAssignment

export const getPickerAssignmentsResource = (state: RootState) =>
  state.common.schedules.getPickerAssignments

export const getAssignmentModalData = (state: RootState) =>
  state.common.schedules.assignmentModalData

export const getIsRemoveAssignmentsLoading = (state: RootState) =>
  state.common.schedules.removeAssignments.loading === LoadingStatus.Pending

export const getRemoveAssignmentsLoadingStatus = (state: RootState) =>
  state.common.schedules.removeAssignments.loading

export const getDeleteNeedSideEffects = (state: RootState) => ({
  loading: state.common.schedules.deleteNeed.loading,
  error: state.common.schedules.deleteNeed.error,
})

export const getUnitNeedsResource = (state: RootState) =>
  state.common.schedules.getUnitNeeds

export const getSchedulesDaysData = (state: RootState) =>
  state.common.schedules.getSchedulesDays.data

export const getSchedulesDaysSideEffects = (state: RootState) => ({
  loading: state.common.schedules.getSchedulesDays.loading,
  isInitialized: state.common.schedules.getSchedulesDays.isInitialized,
})

export const getUnitResource = (state: RootState) =>
  state.common.schedules.getUnit

export const getSchedulesShiftsResource = (state: RootState) =>
  state.common.schedules.getSchedulesShifts

export const getSchedulesShiftsData = (state: RootState) =>
  state.common.schedules.getSchedulesShifts.data

export const getSchedulesShiftName = (shiftId?: number) =>
  createSelector(getSchedulesShiftsData, shifts => {
    if (!shiftId) return ''

    return shifts?.find(({ id }) => id === shiftId)?.name || ''
  })

export const getSchedulesDays = createSelector(
  getSchedulesDaysData,
  getSchedulesShiftsData,
  (days, shifts) => mapDaysToShifts({ data: days, shifts })
)

export const createOpenShiftAssignmentLoading = (state: RootState) =>
  state.common.schedules.createOpenShiftAssignment.loading

export const editOpenShiftAssignmentLoading = (state: RootState) =>
  state.common.schedules.editOpenShiftAssignment.loading

export const getNeedAssignmentDataResource = (state: RootState) =>
  state.common.schedules.getNeedAssignmentData

export const getListingAssignmentDataResource = (state: RootState) =>
  state.common.schedules.getListingAssignmentData

export const getSelectedAssignment = createSelector(
  getNeedAssignmentDataResource,
  getListingAssignmentDataResource,
  (needAssignment, listingAssignment) => {
    return {
      data: needAssignment.data || listingAssignment.data,
      loading: [needAssignment.loading, listingAssignment.loading].includes(
        LoadingStatus.Pending
      ),
    }
  }
)

export const getPickerAssignmentsData = createSelector(
  getPickerAssignmentsResource,
  getSchedulesShiftsData,
  (pickerAssignmentsResource, shifts) => {
    const shiftsRecords = shifts.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
      {} as Record<number, string>
    )

    return pickerAssignmentsResource.data.map(assignment => ({
      ...assignment,
      shiftName: shiftsRecords[assignment.shiftId] || '',
    }))
  }
)
