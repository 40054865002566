import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { RadioFieldItem } from '../../../RadioField'
import { ResourcesSectionFields } from '../CPResourcesSection'
import BookingCheckbox from './BookingCheckbox'
import { CheckboxContainer } from '../../AssignmentModal.style'
import { MessageText } from '../RequestAssignmentContent.style'
import { BookingLabel } from './BookingSection.style'

interface BookableResourceCheckboxData extends RadioFieldItem {
  isOpenForOvertime: boolean
}
interface BookingSectionProps {
  disabledBooking?: boolean
  bookableResources: BookableResourceCheckboxData[]
}

const BookingSection = ({
  bookableResources,
  disabledBooking = false,
}: BookingSectionProps) => {
  const { t } = useTranslation()
  return (
    <>
      <BookingLabel>{t('assignmentModal.bookInResources')}</BookingLabel>
      <CheckboxContainer opaque container item>
        {bookableResources.length === 0 ? (
          <Grid>
            <MessageText variant="body2">
              {t('assignmentModal.noDirectResources')}
            </MessageText>
          </Grid>
        ) : (
          bookableResources.map((resource, i) => (
            <Grid item xs={12} key={`booking-checkbox-${i}`}>
              <BookingCheckbox
                name={ResourcesSectionFields.BookedResource}
                disabled={disabledBooking}
                openForOvertime={resource.isOpenForOvertime}
                {...resource}
              />
            </Grid>
          ))
        )}
      </CheckboxContainer>
    </>
  )
}

export default BookingSection
