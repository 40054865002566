import { GetUnitNeedsSummariesResponse } from '.'
import {
  AssignmentSummary,
  NeedAssignmentStatus,
  ScheduleSummaries,
} from '../../types'
import Api from '../api'
import {
  GetSchedulesDaysParams,
  GetSchedulesSummariesParams,
  GetSchedulesSummariesResponse,
  GetSchedulesDaysResponse,
  GetNeedsSummariesParams,
  GetNeedsSummariesResponse,
  GetResourcesSummariesParams,
  GetResourcesSummariesResponse,
  GetShiftsSummariesParams,
  GetShiftsSummariesResponse,
  ExportSchedulesParams,
  ExportSchedulesResponse,
} from './Schedules.types'

class Schedules extends Api {
  private mapAssignmentStatistics = (
    data: ScheduleSummaries[]
  ): AssignmentSummary[] =>
    data.map(({ date, assignmentsStatistics }) => {
      const {
        canceledAssignmentsCount,
        emptyAssignmentsCount,
        externallyBookedAssignmentsCount,
        internallyBookedAssignmentsCount,
        noResourcesAssignmentsCount,
        otherAssignmentsCount,
        requestedExternallyAssignmentsCount,
        requestedInternallyAssignmentsCount,
        totalAssignmentsCount,
      } = assignmentsStatistics

      return {
        date,
        total: totalAssignmentsCount,
        counts: {
          [NeedAssignmentStatus.BookedInternally]:
            internallyBookedAssignmentsCount,
          [NeedAssignmentStatus.BookedExternally]:
            externallyBookedAssignmentsCount,
          [NeedAssignmentStatus.Canceled]: canceledAssignmentsCount,
          [NeedAssignmentStatus.RequestedInternally]:
            requestedInternallyAssignmentsCount,
          [NeedAssignmentStatus.RequestedExternally]:
            requestedExternallyAssignmentsCount,
          [NeedAssignmentStatus.Empty]: emptyAssignmentsCount,
          [NeedAssignmentStatus.NoResources]: noResourcesAssignmentsCount,
          [NeedAssignmentStatus.OtherAssignments]: otherAssignmentsCount,
        },
      }
    })

  public getSchedulesSummaries = async ({
    careProviderId,
    ...params
  }: GetSchedulesSummariesParams) => {
    const { data } = await this.api.get<GetSchedulesSummariesResponse>(
      `/care-providers/${careProviderId}/schedules/summaries`,
      { params }
    )
    return this.mapAssignmentStatistics(data.data)
  }

  public getSchedulesDays = async ({
    careProviderId,
    ...params
  }: GetSchedulesDaysParams) => {
    const { data } = await this.api.get<GetSchedulesDaysResponse>(
      `/care-providers/${careProviderId}/schedules/-v2`,
      { params }
    )
    return data.data
  }

  public getNeedsSummaries = async ({
    careProviderId,
    ...params
  }: GetNeedsSummariesParams) => {
    const { data } = await this.api.get<GetNeedsSummariesResponse>(
      `/care-providers/${careProviderId}/schedules/units`,
      { params }
    )
    return data.data
  }

  public getUnitNeedsSummaries = async ({
    careProviderId,
    unitId,
    ...params
  }: GetNeedsSummariesParams) => {
    const { data } = await this.api.get<GetUnitNeedsSummariesResponse>(
      `/care-providers/${careProviderId}/schedules/units/${unitId}`,
      { params }
    )
    return data.data
  }

  public getResourcesSummaries = async ({
    careProviderId,
    ...params
  }: GetResourcesSummariesParams) => {
    const { data } = await this.api.get<GetResourcesSummariesResponse>(
      `/care-providers/${careProviderId}/schedules/resources`,
      { params }
    )
    return data.data
  }

  public getShiftsSummaries = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetShiftsSummariesParams) => {
    const { data } = await this.api.get<GetShiftsSummariesResponse>(
      `/care-providers/${careProviderId}/schedules/resources/${resourceId}`,
      { params }
    )
    return data.data
  }

  public exportSchedules = async ({
    careProviderId,
    unitId,
    resourceId,
    roleId,
    assignmentStatuses,
    ...params
  }: ExportSchedulesParams) => {
    const { data } = await this.api.post<ExportSchedulesResponse>(
      `/care-providers/${careProviderId}/schedules/export`,
      { unitId, resourceId, roleId, assignmentStatuses },
      { params, responseType: 'arraybuffer' }
    )
    return data
  }
}

export default Schedules
