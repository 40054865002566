import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'app'
import { isSameDay, parseISO } from 'date-fns'
import { NotificationType, SchedulesNotification } from '../../types'

export const getNotifications = (state: RootState) =>
  state.common.notifications.notifications

export const getUnreadCount = (state: RootState) =>
  state.common.notifications.unreadCount

export const getUnreadCreatedRequestsCount = (state: RootState) =>
  state.common.notifications.unreadCount[
    NotificationType.NeedAssignmentRequestCreated
  ]

export const getSchedulesUnreadCount = createSelector(
  getUnreadCount,
  unreadCount =>
    unreadCount[NotificationType.NeedAssignmentNoteAdded] +
    unreadCount[NotificationType.NeedAssignmentResourceNoteAdded] +
    unreadCount[NotificationType.NeedAssignmentCanceled] +
    unreadCount[NotificationType.NeedAssignmentBooked]
)

export const getSchedulesUnreadNotificationsData = (state: RootState) =>
  state.common.notifications.getSchedulesUnreadNotifications.data

export const getSchedulesUnreadNotifications = createSelector(
  getSchedulesUnreadNotificationsData,
  data => data.filter(({ notifications }) => notifications.length > 0)
)

export const getSchedulesUnreadNotificationsIds = createSelector(
  getSchedulesUnreadNotifications,
  unreadNotifications =>
    unreadNotifications.flatMap(({ notifications }) =>
      notifications.map(({ id }) => id)
    )
)

export const getResourceNotificationIdsByDate = (date: Date) =>
  createSelector(getSchedulesUnreadNotifications, notifications =>
    notifications
      .find(notification => isSameDay(parseISO(notification.date), date))
      ?.notifications.flatMap(({ id }) => id)
  )

export const getResourceNotificationIdByAssignment = (id?: number) =>
  createSelector(
    getSchedulesUnreadNotifications,
    notifications =>
      (id &&
        notifications
          .reduce(
            (acc, { notifications }) => [...acc, ...notifications],
            [] as SchedulesNotification['notifications']
          )
          .find(({ data }) => data.assignmentId === id)?.id) ||
      null
  )

export const markAsReadLoading = (state: RootState) =>
  state.common.notifications.markAsRead.loading

export const getRequestsNotifications = (state: RootState) =>
  state.common.notifications.resourceRequestsNotifications

export const getBookingsNotifications = (state: RootState) =>
  state.common.notifications.resourceBookingsNotifications

export const getNeedsCreatedNotifications = (state: RootState) =>
  state.common.notifications.getNeedsCreatedNotifications

export const getResourceRequestsCount = (state: RootState) =>
  state.common.notifications.requestsCount.data

export const getLatestAdminInfoMessage = (state: RootState) =>
  state.common.notifications.latestAdminInfoMessage

export const getLatestAdminUpdateMessage = (state: RootState) =>
  state.common.notifications.latestAdminUpdateMessage

export const getAdminUpdateMessage = (state: RootState) =>
  state.common.notifications.adminUpdateMessage

export const getUnreadChatNotificationsCount = (state: RootState) => {
  const notifications =
    state.common.notifications.getUnreadChatNotifications.data

  return notifications ? Object.entries(notifications).length : 0
}
