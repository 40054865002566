import { PayloadAction } from '@reduxjs/toolkit'
import resource from 'shared/resource'
import { ShiftTimeType, ShiftType } from 'shared/types'
import { State } from './reducer'
import { CreateHourRatePayload, DeleteShiftPayload } from './actions.types'

export const DEFAULT_SHIFT_ID = 0

export const defaultShiftBreakProps = {
  hasBreak: false,
  breakMinutes: 0,
  hasMealBreak: false,
  mealBreakMinutes: 0,
  timeType: ShiftTimeType.Day,
  startTime: '',
  endTime: '',
}

export const getDefinedShift = (state: State, shiftId: number) =>
  state.definedShifts.find(({ data }) => data.id === shiftId)

export const getOpenShift = (state: State, shiftId: number) =>
  state.openShifts.find(({ data }) => data.id === shiftId)

export const getRate = (state: State, id: number) =>
  state.hourRates.data.find(({ data }) => data.id === id)

export const deleteHourRateFromState = (state: State, id: number) => {
  const rate = getRate(state, id)
  if (rate) {
    resource.setSucceeded(rate)
    state.hourRates.data = state.hourRates.data.filter(
      data => data.data.id !== id
    )
  }
}

export const addHourRateToState = (
  state: State,
  action: PayloadAction<
    string,
    string,
    {
      arg: CreateHourRatePayload
      requestId: string
      requestStatus: 'fulfilled'
    },
    never
  >
) => {
  const rate = getRate(state, 0)
  if (rate && action.payload) {
    const id = parseInt(action.payload, 10)
    resource.setSucceeded(rate, {
      ...action.meta.arg.params,
      id,
    })
  }
}

export const activateUnitState = (state: State) => {
  if (state.getAdministrationUnit.data) {
    state.getAdministrationUnit.data.isActive = true
  }
}

export const inactivateUnitState = (state: State) => {
  if (state.getAdministrationUnit.data) {
    state.getAdministrationUnit.data.isActive = false
  }
}

export const activateUserState = (state: State) => {
  if (state.getUser.data) {
    state.getUser.data.isActive = true
    state.getUser.data.isBlocked = false
  }
}

export const deactivateUserState = (state: State) => {
  if (state.getUser.data) {
    state.getUser.data.isActive = false
  }
}

export const getShiftToDelete = (
  state: State,
  { shiftId, shiftType }: DeleteShiftPayload
) => {
  switch (shiftType) {
    case ShiftType.Defined:
      return getDefinedShift(state, shiftId)
    case ShiftType.Open:
      return getOpenShift(state, shiftId)
    default:
      return null
  }
}
