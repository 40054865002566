import { createAsyncThunk } from '@reduxjs/toolkit'
import CareProviders, {
  GetCareProvidersParams as GetOrganizationsParams,
} from 'shared/services/care-providers'
import { MODULE_NAME } from '../strings'

const careProviders = new CareProviders()

export const getOrganizations = createAsyncThunk(
  `${MODULE_NAME}/getOrganizations`,
  (payload: GetOrganizationsParams = {}) =>
    careProviders.getCareProviders(payload)
)
