import Api from '../api'
import {
  CreateDefinedShiftParams,
  CreateDefinedShiftResponse,
  CreateOpenShiftParams,
  CreateOpenShiftResponse,
  DeleteShiftParams,
  DeleteShiftResponse,
  UpdateDefinedShiftParams,
  UpdateDefinedShiftResponse,
  UpdateOpenShiftParams,
  UpdateOpenShiftResponse,
} from './Shifts.types'

class Shifts extends Api {
  public createDefinedShift = async ({
    careProviderId,
    ...payload
  }: CreateDefinedShiftParams) => {
    const { data } = await this.api.post<CreateDefinedShiftResponse>(
      `/care-providers/${careProviderId}/shifts/create-defined`,
      payload
    )
    return data
  }

  public updateDefinedShift = async ({
    careProviderId,
    shiftId,
    ...payload
  }: UpdateDefinedShiftParams) => {
    const { data } = await this.api.put<UpdateDefinedShiftResponse>(
      `/care-providers/${careProviderId}/shifts/${shiftId}/update-defined`,
      payload
    )
    return data
  }

  public createOpenShift = async ({
    careProviderId,
    ...payload
  }: CreateOpenShiftParams) => {
    const { data } = await this.api.post<CreateOpenShiftResponse>(
      `/care-providers/${careProviderId}/shifts/create-open`,
      payload
    )
    return data
  }

  public updateOpenShift = async ({
    careProviderId,
    shiftId,
    ...payload
  }: UpdateOpenShiftParams) => {
    const { data } = await this.api.put<UpdateOpenShiftResponse>(
      `/care-providers/${careProviderId}/shifts/${shiftId}/update-open`,
      payload
    )
    return data
  }

  public deleteShift = async ({
    careProviderId,
    shiftId,
  }: DeleteShiftParams) => {
    const { data } = await this.api.delete<DeleteShiftResponse>(
      `/care-providers/${careProviderId}/shifts/${shiftId}`
    )
    return data
  }
}

export default Shifts
