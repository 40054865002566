import { Suspense, SuspenseProps, lazy } from 'react'
import Loader from '../Loader'
import ErrorBoundary, { ErrorBoundaryProps } from './ErrorBoundary'

export interface LoadableProps extends ErrorBoundaryProps {
  component(): Promise<{ default: React.ComponentType<any> }>
  componentProps?: Record<string, any>
  loader?: SuspenseProps['fallback']
}

const Loadable = ({
  component,
  componentProps,
  loader,
  ...errorBoundaryProps
}: LoadableProps) => {
  const Component = lazy(component)

  return (
    <Suspense fallback={loader || <Loader />}>
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...componentProps} />
      </ErrorBoundary>
    </Suspense>
  )
}

export default Loadable
