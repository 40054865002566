import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import Resources, {
  GetResourceBookingsParams,
  GetResourceRequestsParams,
} from 'shared/services/resources'
import { ResourceActions } from 'shared/types'
import { MODULE_NAME } from '../strings'
import { State } from './reducer'
import { RequestActionPayload } from './actions.types'

const DEFAULT_TAKE = 10

const resources = new Resources()

export const getBookings = createAsyncThunk(
  `${MODULE_NAME}/getBookings`,
  (payload: GetResourceBookingsParams) => resources.getBookings(payload)
)

export const getMoreBookings = createAsyncThunk(
  `${MODULE_NAME}/getMoreBookings`,
  (payload: GetResourceBookingsParams, { getState }) => {
    const { resourceNotifications } = getState() as {
      resourceNotifications: State
    }
    const length = resourceNotifications.getBookings.data.length
    return resources.getBookings({
      ...payload,
      take: DEFAULT_TAKE,
      skip: length,
    })
  }
)

export const getRequests = createAsyncThunk(
  `${MODULE_NAME}/getRequests`,
  (payload: GetResourceRequestsParams) => resources.getRequests(payload)
)

export const getMoreRequests = createAsyncThunk(
  `${MODULE_NAME}/getMoreRequests`,
  (payload: GetResourceRequestsParams, { getState }) => {
    const { resourceNotifications } = getState() as {
      resourceNotifications: State
    }
    const length = resourceNotifications.getRequests.data.length
    return resources.getRequests({
      ...payload,
      take: DEFAULT_TAKE,
      skip: length,
    })
  }
)

export const acceptRequest = createAsyncThunk(
  `${MODULE_NAME}/acceptRequest`,
  async ({ onSuccess, onError, ...payload }: RequestActionPayload) => {
    try {
      const res = await resources.requestAction({
        ...payload,
        action: ResourceActions.Accept,
      })
      onSuccess()
      return res
    } catch (err) {
      const error = err as AxiosError
      onError(error?.response?.data?.message || '')
    }
  }
)

export const rejectRequest = createAsyncThunk(
  `${MODULE_NAME}/rejectRequest`,
  async ({ onSuccess, onError, ...payload }: RequestActionPayload) => {
    try {
      const res = await resources.requestAction({
        ...payload,
        action: ResourceActions.Reject,
      })
      onSuccess()
      return res
    } catch (err) {
      const error = err as AxiosError
      onError(error?.response?.data?.message || '')
    }
  }
)

export const resetBookings = createAction(`${MODULE_NAME}/resetBookings`)

export const resetRequests = createAction(`${MODULE_NAME}/resetRequests`)
