import { useTranslation } from 'react-i18next'
import { Dialog, Button, Grid } from '@mui/material'
import { useSelector } from '../../hooks'
import { useModal } from '../../hooks'
import { selectors } from '../../store'
import { LoadingStatus } from '../../types'
import Form from '../Form'
import LoadingButton from '../LoadingButton'
import TextField from '../TextField'
import { ModalWrapper } from './CreateTag.styles'
import { useFormProps, OnSuccess } from './CreateTag.utils'

interface CreateTagProps {
  buttonLabel: string
  onSucces?: OnSuccess
}

const CreateTag = ({ buttonLabel, onSucces }: CreateTagProps) => {
  const { t } = useTranslation()
  const { openModal, closeModal, isModalOpen } = useModal()
  const formProps = useFormProps(onSucces)
  const loading = useSelector(selectors.tags.getCreateTagLoading)

  const isCreating = loading === LoadingStatus.Pending

  const handleClose = () => {
    closeModal()
    formProps.reset()
  }

  return (
    <>
      <Button
        fullWidth={false}
        color="primary"
        variant="outlined"
        onClick={openModal}
      >
        {buttonLabel}
      </Button>
      <Dialog open={isModalOpen} onClose={handleClose}>
        <ModalWrapper>
          <Form {...formProps}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <TextField name="name" label={t('createTagModal.tagName')} />
              </Grid>
              <Grid item>
                <LoadingButton
                  fullWidth={false}
                  color="primary"
                  variant="outlined"
                  loading={isCreating}
                  onClick={() => formProps.handleSubmit(formProps.onSubmit)()}
                >
                  {t('form.add')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </ModalWrapper>
      </Dialog>
    </>
  )
}

export default CreateTag
