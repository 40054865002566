import {
  isSameDay,
  parseISO,
  addMonths,
  endOfMonth,
  startOfMonth,
  subMonths,
  differenceInDays,
} from 'date-fns'
import {
  ChangeAssignmentStateParams,
  ModifyBookedAssignmentParams,
} from 'shared/services/need-assignments'
import {
  Assignment,
  AssignmentStatus,
  ResourcePeriodType,
  ScheduleDays,
  ScheduleDaysAssignment,
} from 'shared/types'
import {
  RequestWithActions,
  ResourceScheduleWithActions,
  State,
} from './reducer'

const DAYS_IN_WEEK = 7

interface FilterResourceScheduleOptions {
  id: number
  type: ResourcePeriodType
  date: Date
}

export const filterResourceSchedules = <
  T extends ResourceScheduleWithActions[]
>(
  data: T,
  { id, type, date }: FilterResourceScheduleOptions
) => {
  const filterPeriods = <T extends { id: number }>(period: T) =>
    period.id !== id

  return data.map(day =>
    isSameDay(parseISO(day.date), date)
      ? {
          ...day,
          ...(type === ResourcePeriodType.Absence && {
            absences: day.absences.filter(filterPeriods),
          }),
          ...(type === ResourcePeriodType.Availability && {
            availabilities: day.availabilities.filter(filterPeriods),
          }),
        }
      : day
  )
}

export const removeResourcePeriod = (
  state: State,
  period: FilterResourceScheduleOptions
) => {
  state.getResourceSchedules.data = filterResourceSchedules(
    state.getResourceSchedules.data,
    period
  )
}

export const mapResourceSchedulesAfterBookedAssignmentChange = (
  resourceSchedule: ResourceScheduleWithActions[],
  {
    isActive,
    assignmentIds,
    hasAbsence,
    hasOvertime,
    note,
  }: ModifyBookedAssignmentParams
): ResourceScheduleWithActions[] =>
  isActive
    ? resourceSchedule.map(day => ({
        ...day,
        assignments: day.assignments.filter(
          assignment => !assignmentIds.includes(assignment.id)
        ),
      }))
    : resourceSchedule.map(day => ({
        ...day,
        assignments: day.assignments.map(assignment =>
          assignmentIds.includes(assignment.id)
            ? { ...assignment, hasAbsence, hasOvertime, note }
            : assignment
        ),
      }))

export const mapResourceSchedulesAfterAssignmentStateChange = (
  resourceSchedule: ResourceScheduleWithActions[],
  { modifiedAssignment }: ChangeAssignmentStateParams
) =>
  resourceSchedule.map(day => ({
    ...day,
    requests: day.requests.filter(
      request => request.assignment.id !== modifiedAssignment.id
    ),
    assignments: day.assignments.filter(
      assignment => assignment.id !== modifiedAssignment.id
    ),
  }))

interface SummaryDates {
  startDate: Date
  endDate: Date
}

const getDatesForSummary = (startDate: Date, endDate: Date): SummaryDates => {
  if (startDate.getDay() !== 0) {
    startDate = startOfMonth(addMonths(startDate, 1))
  }

  if (endDate !== endOfMonth(endDate)) {
    endDate = endOfMonth(subMonths(endDate, 1))
  }
  return {
    startDate,
    endDate,
  }
}

export const filterSummaries = <T extends { date: string }>(
  summaries: T[],
  dateFrom?: string,
  dateTo?: string
): T[] => {
  if (!dateFrom || !dateTo || isWeekSchedulerView(dateFrom, dateTo))
    return summaries

  const dates = getDatesForSummary(parseISO(dateFrom), parseISO(dateTo))
  return summaries.filter(
    summary =>
      new Date(summary.date) >= dates.startDate &&
      new Date(summary.date) <= dates.endDate
  )
}

export const isWeekSchedulerView = (dateFrom: string, dateTo: string) => {
  const isWeekSchedulerView = differenceInDays(
    parseISO(dateTo),
    parseISO(dateFrom)
  )
  return isWeekSchedulerView <= DAYS_IN_WEEK
}

const mapAssignmentsToSchedules = (
  assignments: Assignment[]
): ScheduleDaysAssignment[] =>
  assignments.map(assignment => ({
    unit: assignment.unit,
    endDate: assignment.endDate,
    id: assignment.id,
    shiftId: assignment.shift.id,
    startDate: assignment.startDate,
    status: AssignmentStatus.BookedInternally,
    type: assignment.shift.type,
    resource: assignment.resource || null,
    roleShortCode: assignment.roleShortCode,
    hasNote: Boolean(assignment.note || assignment.resourceNote),
  }))

const mapRequestsToSchedules = (
  requests: RequestWithActions[]
): ScheduleDaysAssignment[] =>
  requests.map(({ assignment }) => ({
    unit: assignment.unit,
    endDate: assignment.endDate,
    id: assignment.id,
    shiftId: assignment.shift.id,
    startDate: assignment.startDate,
    status: AssignmentStatus.RequestedInternally,
    type: assignment.shift.type,
    resource: null,
  }))

export const mapResourceScheduleToDays = (
  data: ResourceScheduleWithActions[]
): ScheduleDays[] =>
  data.map(({ assignments, absences, availabilities, date, requests }) => ({
    availabilities,
    absences,
    date,
    assignments: [
      ...mapAssignmentsToSchedules(assignments),
      ...mapRequestsToSchedules(requests),
    ],
  }))
