import { RootState } from 'app'
import { ApplicationType } from '../../types'

export const getNeedSettingsResource = (state: RootState) =>
  state.common.settings.getNeedSettings

export const getUpdateNeedSettingsLoading = (state: RootState) =>
  state.common.settings.patchNeedSettings.loading

export const isViviumXAccessableApplication = (state: RootState) =>
  state.common.settings.getNeedSettings.data?.accessableApplications.some(
    accessableApplication => accessableApplication === ApplicationType.ViviumX
  )

export const getLanguage = (state: RootState) => state.common.settings.language

export const isSupportWidgetVisible = (state: RootState) =>
  state.common.settings.isSupportWidgetVisible
