import { Components } from '@mui/material'
import LinkBehavior from './utils/LinkBehavior'

const MuiButtonBase: Components['MuiButtonBase'] = {
  defaultProps: {
    LinkComponent: LinkBehavior,
  },
}

export default MuiButtonBase
