import { useTranslation } from 'react-i18next'
import { Divider, Grid } from '@mui/material'
import { useEnumOptions } from '../../../../hooks'
import { ResourceUnassignmentReason } from '../../../../types'
import Form from '../../../Form'
import TextAreaField from '../../../TextAreaField'
import {
  StyledRadioField,
  Section,
  StyledCheckboxField,
} from '../../AssignmentModal.style'
import {
  ListingAssignmentFormFields,
  useListingAssignmentForm,
} from './AssignmentFields.utils'

const AssignmentFields = () => {
  const { t } = useTranslation()
  const reasonOptions = useEnumOptions(ResourceUnassignmentReason, 'ReasonType')
  const formProps = useListingAssignmentForm()
  return (
    <Form {...formProps}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextAreaField
            disabled
            name={ListingAssignmentFormFields.Notes}
            multiline
            rows={3}
            label={t('assignmentModal.notes')}
          />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Section item>
            <StyledCheckboxField
              disabled
              name={ListingAssignmentFormFields.Overtime}
              label={t('assignmentModal.markOvertime')}
            />
          </Section>
        </Grid>
        <Grid item>
          <Section item>
            <StyledCheckboxField
              disabled
              name={ListingAssignmentFormFields.Absence}
              label={t('assignmentModal.markAbsence')}
            />
          </Section>
        </Grid>

        <Grid item>
          <Section
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <StyledCheckboxField
                disabled
                name={ListingAssignmentFormFields.Active}
                label={t('assignmentModal.cancelResource')}
              />
            </Grid>
            <Grid item>
              <StyledRadioField
                disabled
                name={ListingAssignmentFormFields.Reason}
                options={reasonOptions}
              />
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </Form>
  )
}

export default AssignmentFields
