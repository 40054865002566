import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useMatch } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { ButtonProps, Theme, useMediaQuery } from '@mui/material'
import { paths } from 'config'
import { useOrganizationId } from '../../../../hooks'
import { StyledButton, StyledFab, Container } from './TopbarAction.style'

interface TopbarActionProps extends Partial<ButtonProps> {
  label: string
}

const TopbarAction = ({ label, ...props }: TopbarActionProps) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  const Wrapper = isMobile ? Fragment : Container
  return (
    <Wrapper>
      {isMobile ? (
        <StyledFab size="large" color="primary" {...props}>
          <Add fontSize="small" />
        </StyledFab>
      ) : (
        <StyledButton
          variant="contained"
          size="medium"
          endIcon={<Add fontSize="small" />}
          {...props}
        >
          {label}
        </StyledButton>
      )}
    </Wrapper>
  )
}

export default TopbarAction

export const AddAvailabilityButton = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  const organizationId = useOrganizationId()
  const match = useMatch({
    path: paths.organizationSchedules,
    end: true,
  })

  if (isMobile && !match) return null
  return (
    <TopbarAction
      label={t('topbar.enterAvailability')}
      aria-label={t('topbar.enterAvailability')}
      data-test-id="add-availability-button"
      href={generatePath(paths.organizationSchedulesAvailability, {
        organizationId: organizationId.toString(),
      })}
    />
  )
}

export const CreateNeedButton = () => {
  const { t } = useTranslation()
  const organizationId = useOrganizationId()
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  const match = useMatch({
    path: paths.organizationUnits,
    end: true,
  })

  if (!organizationId || (isMobile && !match)) return null
  return (
    <TopbarAction
      label={t('topbar.newNeed')}
      aria-label={t('topbar.newNeed')}
      data-test-id="new-need-button"
      href={generatePath(paths.newNeed, {
        organizationId: organizationId.toString(),
      })}
    />
  )
}
