import { ListItem, ListItemProps, Skeleton, styled } from '@mui/material'

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: 0,
})) as React.ComponentType<ListItemProps>

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))
