import { useCallback, useEffect, useMemo } from 'react'
import {
  DeepPartial,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { debounce } from '@mui/material'
import { AnyObjectSchema } from 'yup'
import { FormProps } from '../components'
import { identity } from '../utils'
import { useUpdateQuery } from './useQuery'

const DEBOUNCE_TIMEOUT = 500

export interface FiltersFormOptions<FilterValues, Payload> {
  defaultValues?: DeepPartial<FilterValues>
  validationSchema?: AnyObjectSchema
  enableReinitialize?: boolean
  onSubmit?: (values: Payload) => void
  mapQuery?: (values: FilterValues) => any
  mapPayload?: (values: FilterValues) => any
}

export const useFilters = <FilterValues extends FieldValues, Payload>({
  validationSchema,
  defaultValues,
  enableReinitialize = false,
  onSubmit = () => {},
  mapQuery = identity,
  mapPayload = identity,
}: FiltersFormOptions<FilterValues, Payload>): FormProps<FilterValues> => {
  const updateQuery = useUpdateQuery()

  const formProps = useForm<FilterValues, object>({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  })

  const { handleSubmit, watch, reset } = formProps
  const values = watch()
  const valuesString = JSON.stringify(values)

  const submitHandler: SubmitHandler<FilterValues> = useCallback(
    values => {
      updateQuery(mapQuery(values as FilterValues))
      onSubmit(mapPayload(values as FilterValues))
    },
    [mapQuery, mapPayload, updateQuery, onSubmit]
  )

  const submit = useMemo(
    () => debounce(handleSubmit(submitHandler), DEBOUNCE_TIMEOUT),
    [handleSubmit, submitHandler]
  )

  useEffect(() => {
    if (enableReinitialize) reset(defaultValues)
  }, [reset, enableReinitialize, defaultValues])

  useEffect(() => {
    submit()
    return () => {
      submit.clear()
    }
  }, [valuesString]) // eslint-disable-line

  return { ...formProps, onSubmit: submitHandler }
}
