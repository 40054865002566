import { Grid } from '@mui/material'
import { AdminMessageFeedbackRateType } from '../../../../types'
import { StyledDialogActions } from '../UpdateMessageModal.style'
import { FeedbackButton, TextContent } from './FeedbackSection.components'
import { useFeedbackSection } from './FeedbackSection.utils'

const FeedbackSection = () => {
  const { shouldShowForm } = useFeedbackSection()

  return (
    <StyledDialogActions disableSpacing>
      <Grid container spacing={1}>
        <Grid container item direction="column" alignItems="center">
          <TextContent />
        </Grid>
        {shouldShowForm && (
          <Grid container item justifyContent="center">
            {Object.values(AdminMessageFeedbackRateType).map(rate => (
              <FeedbackButton rate={rate} />
            ))}
          </Grid>
        )}
      </Grid>
    </StyledDialogActions>
  )
}

export default FeedbackSection
