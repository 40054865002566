import { styled, Typography } from '@mui/material'

interface TextProps {
  size?: 'small' | 'large'
}

export const Text = styled(Typography)<TextProps>(
  ({ theme, size = 'small' }) => ({
    fontSize: size === 'small' ? '0.75rem' : '0.875rem',
    color: theme.palette.secondary.dark,
  })
)

export const DateText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '500',
}))
