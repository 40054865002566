import { ChartData } from '../../types'
import { PieChartSize } from './PieChart.types'

export const chartSizes: Record<PieChartSize, number> = {
  small: 45,
  medium: 60,
  large: 80,
}

export const fontSizes: Record<PieChartSize, number> = {
  small: 10,
  medium: 14,
  large: 16,
}

export const getDimensions = (size?: PieChartSize) => {
  const calculatedFontSize = fontSizes[size || 'large'] || fontSizes.large
  const chartSize = chartSizes[size || 'large'] || chartSizes.large
  return { chartSize, calculatedFontSize }
}

export const isZeroValues = (data?: ChartData[]) =>
  data?.every(({ value }) => !Number(value))
