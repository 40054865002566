import {
  Accordion as BaseAccordion,
  AccordionDetails as BaseAccordionDetails,
  AccordionSummary as BaseAccordionSummary,
  Badge,
  Chip,
  styled,
  Theme,
  Typography,
} from '@mui/material'
import BaseConfirmationModal from '../ConfirmationModal'

export const AccordionSummary = styled(BaseAccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.pill.border.light}`,
  padding: theme.spacing(0, 0.25),

  '& .MuiTypography-root': {
    fontSize: '0.85rem',
  },
  '& .MuiAccordionSummary-content.MuiAccordionSummary-expanded': {
    margin: 0,
  },
  '&.MuiAccordionSummary-root.MuiAccordionSummary-expanded': {
    minHeight: 0,
  },
}))

export const Accordion = styled(BaseAccordion)({
  boxShadow: 'none',
  background: 'transparent',
})

export const AccordionDetails = styled(BaseAccordionDetails)(({ theme }) => ({
  overflow: 'auto',
  maxHeight: 120,
  padding: theme.spacing(1, 0.25),
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(2),
}))

export const SkeletonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    marginRight: theme.spacing(2),
  },
}))

interface GetNeedPillColorParams
  extends Omit<IDChipProps, 'isSelected' | 'hasNotification' | 'isDeletable'> {
  theme: Theme
}

const getNeedPillColor = ({
  theme,
  isFullyProcessed,
  isEmpty,
}: GetNeedPillColorParams) => {
  if (isEmpty) return theme.palette.pill.gray.main
  if (isFullyProcessed) return theme.palette.pill.green.dark
  return theme.palette.primary.main
}

interface IDChipProps {
  isSelected: boolean
  isFullyProcessed: boolean
  isDeletable: boolean
  hasNotification: boolean
  isEmpty: boolean
}

export const IDChip = styled(Chip)<IDChipProps>(
  ({
    theme,
    isSelected,
    isFullyProcessed,
    isDeletable,
    hasNotification,
    isEmpty,
  }) => {
    const pillColor = getNeedPillColor({ theme, isFullyProcessed, isEmpty })

    return {
      height: 16,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      position: 'relative',
      top: theme.spacing(0.4),
      ...(hasNotification && { paddingRight: theme.spacing(1) }),
      '& .MuiChip-label': {
        color: isSelected ? theme.palette.common.white : pillColor,
        margin: 0,
      },
      '& , &:hover, &:active, &:focus': {
        background: isSelected ? pillColor : theme.palette.common.white,
        borderColor: pillColor,
      },
      '& .MuiChip-deleteIcon': {
        marginRight: hasNotification
          ? theme.spacing(1.5)
          : theme.spacing(0.625),
        display: isSelected && isDeletable ? 'block' : 'none',
        color: theme.palette.common.white,
      },
      '& .MuiChip-deleteIcon:hover': {
        color: theme.palette.error.main,
      },
    }
  }
)

export const ConfirmationModal = styled(BaseConfirmationModal)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '1rem',
    fontWeight: 500,
  },
  '& .MuiButton-outlined': {
    borderWidth: 0,
    padding: theme.spacing(0.75, 0),
  },
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: theme.spacing(3.5),
    top: theme.spacing(1.4),
  },
}))

export const DeselectNeedsButton = styled(Chip)(({ theme }) => ({
  width: 140,
  color: theme.palette.text.primary,
  fontWeight: 500,
  borderWidth: 2,
  justifyContent: 'space-between',

  '& .MuiChip-deleteIcon': {
    width: 20,
    height: 20,
    color: theme.palette.text.secondary,
  },
}))
