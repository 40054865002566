import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { CareProviderResourceDocumentType } from 'shared/types'

export const getResourcesTableInfoResource = (state: RootState) =>
  state.resources.getResources

export const getResourcesTableInfoTotalCount = (state: RootState) =>
  state.resources.getResources.meta?.count || 0

export const getCreateResource = (state: RootState) =>
  state.resources.createResource

export const getUpdateResource = (state: RootState) =>
  state.resources.updateResource

export const getResourceDetailsResource = (state: RootState) =>
  state.resources.getResourceDetails

export const getResourceDetailsData = (state: RootState) =>
  state.resources.getResourceDetails.data

export const getResourceDetailsLoading = (state: RootState) =>
  state.resources.getResourceDetails.loading

export const getResourceDocuments = (state: RootState) =>
  state.resources.resourceDocuments

export const getResourceDocumentsTypes = createSelector(
  getResourceDocuments,
  documents => documents.map(document => document.type)
)

export const getResourceDocumentsCount = createSelector(
  getResourceDocuments,
  documents =>
    documents.filter(
      document => document.type !== CareProviderResourceDocumentType.Other
    ).length
)

export const getUploadResourceDocumentLoading = (state: RootState) =>
  state.resources.uploadResourceDocument.loading

export const deleteResourceDocumentLoading = (state: RootState) =>
  state.resources.deleteResourceDocument.loading

export const getMatchingUnitsResource = (state: RootState) =>
  state.resources.getMatchingUnits

export const getDeleteResource = (state: RootState) =>
  state.resources.deleteResource

export const getDeactivateResource = (state: RootState) =>
  state.resources.deactivateResource

export const getActivateResource = (state: RootState) =>
  state.resources.activateResource

export const getIsResourceActive = (state: RootState) =>
  state.resources.getResourceDetails.data?.account.isActive

export const getResourcePhoneNumber = (state: RootState) =>
  state.resources.resourcePhoneNumber

export const getStaffingTableInfoResource = (state: RootState) =>
  state.resources.getStaffingTableInfo

export const getStaffingTableInfoData = (state: RootState) =>
  state.resources.getStaffingTableInfo.data

export const getAvailabilityTableInfoResource = (state: RootState) =>
  state.resources.getAvailabilityTableInfo

export const getAvailabilityTableInfoData = (state: RootState) =>
  state.resources.getAvailabilityTableInfo.data

export const getExportStaffingStatisticsSideEffects = (state: RootState) => ({
  loading: state.resources.exportStaffingStatistics.loading,
  error: state.resources.exportStaffingStatistics.error,
})

export const getExportAvailabilityStatisticsSideEffects = (
  state: RootState
) => ({
  loading: state.resources.exportAvailabilityStatistics.loading,
  error: state.resources.exportAvailabilityStatistics.error,
})

export const getIsAvailabilityTableEditingMode = (state: RootState) =>
  state.resources.isAvailabilityTableEditingMode

export const getResourceMatchingAssignmentsResource = (state: RootState) =>
  state.resources.getResourceMatchingAssignments

export const bookMatchingAssignmentResource = (state: RootState) =>
  state.resources.bookMatchingAssignment

export const requestMatchingAssignmentResource = (state: RootState) =>
  state.resources.requestMatchingAssignment
