import * as Sentry from '@sentry/react'
import { BrowserOptions } from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import env from './env'

const sentryOptions: BrowserOptions = {
  dsn: env.sentryDsn,
  integrations: [new BrowserTracing()],
  enabled: !!env.sentryDsn,
}

const sentry = {
  init: () => Sentry.init(sentryOptions),
}

export default sentry
