import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material'
import { Collapse, IconButton, TableCell, Tooltip, Grid } from '@mui/material'
import { Row } from '../../../types'
import { identity } from '../../../utils'
import {
  StyledTableRow,
  ExpandTableRow,
  Filler,
  StyledTableRowProps,
  ADDITIONAL_BUTTON_CLASS,
  DeleteButton,
} from './DataTableRow.style'
import { Column } from './DataTableRow.types'
import { useExpandRow } from './DataTableRow.utils'

export interface DataTableRowProps extends StyledTableRowProps {
  columns: Column[]
  row: Row
  nested?: boolean
  disableClick?: boolean
  spanningRow?: JSX.Element
  onDelete?: (() => void) | null
  onEdit?: (() => void) | null
  ExpandComponent?: (props: { row: Row }) => JSX.Element
  shouldCollapseExpandComponents?: boolean
}

const EDIT_BUTTON_CELL_WIDTH = 40

const DataTableRow = ({
  columns,
  row,
  spanningRow,
  onDelete,
  onEdit,
  ExpandComponent,
  shouldCollapseExpandComponents = false,
  ...props
}: DataTableRowProps) => {
  const { t } = useTranslation()
  const {
    isOpen,
    handleExpandRow,
    isExpandComponentVisible,
    hideExpandComponent,
  } = useExpandRow(row, shouldCollapseExpandComponents)

  const tooltipText = t('dataTable.delete')

  const handleDelete = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onDelete && onDelete()
  }

  const handleEdit = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onEdit && onEdit()
  }

  const handleExpand = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    handleExpandRow()
  }

  return (
    <>
      <StyledTableRow {...props} expand={isOpen}>
        {spanningRow}
        {columns
          .filter(({ hidden }) => !hidden)
          .map(({ field, renderValue = identity, ...cellProps }, i) => (
            <TableCell key={`row-${field}-${i}`} {...cellProps}>
              {renderValue(row[field], row)}
            </TableCell>
          ))}
        {onDelete && (
          <TableCell padding="none">
            <Tooltip title={tooltipText} placement="top">
              <DeleteButton
                data-test-id="delete-button"
                className={ADDITIONAL_BUTTON_CLASS}
                aria-label={tooltipText}
                size="small"
                onClick={handleDelete}
              >
                <Delete color="error" />
              </DeleteButton>
            </Tooltip>
          </TableCell>
        )}
        {onEdit && (
          <TableCell padding="none" width={EDIT_BUTTON_CELL_WIDTH}>
            <IconButton
              className={ADDITIONAL_BUTTON_CLASS}
              size="small"
              onClick={handleEdit}
            >
              <Edit />
            </IconButton>
          </TableCell>
        )}
        {ExpandComponent && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              color="primary"
              onClick={handleExpand}
            >
              {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
      </StyledTableRow>
      {ExpandComponent && isExpandComponentVisible && (
        <ExpandTableRow>
          <TableCell colSpan={11} padding="none">
            <Collapse
              in={isOpen}
              timeout="auto"
              unmountOnExit
              onExited={hideExpandComponent}
            >
              <Filler />
              <Grid container justifyContent="center">
                <ExpandComponent row={row} />
              </Grid>
            </Collapse>
          </TableCell>
        </ExpandTableRow>
      )}
    </>
  )
}

export default DataTableRow
