import { Entity, AutocompleteFieldOption } from '../types'

export const getAutocompleteValue = <T extends Entity>({
  id,
  name,
  ...data
}: T): AutocompleteFieldOption => ({
  data,
  value: id,
  label: name,
})

export const getAutocompleteValues = <T extends Entity>(data: T[]) =>
  data.map(getAutocompleteValue)

export const createGetAutocompleteOptions =
  <T extends Entity>(fetchData: (query?: string) => Promise<T[]>) =>
  (query?: string) =>
    fetchData(query).then(getAutocompleteValues)
