import { useMemo } from 'react'
import {
  AssignmentDetailedInfo,
  AssignmentRequestStatus,
} from '../../../../types'

export const useRequestedResources = (
  assignment: AssignmentDetailedInfo | null
) => {
  const pendingRequests = useMemo(
    () =>
      assignment
        ? assignment?.requests.filter(
            request => request.status === AssignmentRequestStatus.Pending
          )
        : [],
    [assignment]
  )

  const rejectedRequests = useMemo(
    () =>
      assignment
        ? assignment?.requests.filter(
            request => request.status === AssignmentRequestStatus.Rejected
          )
        : [],
    [assignment]
  )

  return { pendingRequests, rejectedRequests }
}
