import { useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import { useDialog, useAuth } from '../../../../hooks'
import { AssignmentStatus, LoadingStatus, UserRole } from '../../../../types'
import ConfirmationModal from '../../../ConfirmationModal'
import Divider from '../../../Divider'
import Form, { FormProps } from '../../../Form'
import LoadingButton from '../../../LoadingButton'
import NotesSection from '../../../NotesSection'
import ShiftsSection from '../../ShiftsSection'
import Checkbox from '../Checkbox'
import {
  Section,
  StyledCheckboxField,
  StyledTextAreaField,
} from '../../AssignmentModal.style'
import { useHasCPPermissions, useModalProps } from '../../AssignmentModal.utils'
import {
  ExternalResourceFieldsContainer,
  ResourceNoteWrapper,
  StyledTextField,
} from './AssignmentStateSection.styles'
import {
  AssignmentStateSectionFields,
  AssignmentStateSectionValues,
  useRemoveAssignments,
  useSelectedOption,
  useAssignmentStateSectionDefaultValues,
} from './AssignmentStateSection.utils'

interface AssignmentStateSectionProps
  extends FormProps<AssignmentStateSectionValues> {
  disableSection: boolean
  onRemoveAssignmentsSuccess: () => void
}

const AssignmentStateSection = ({
  disableSection,
  onRemoveAssignmentsSuccess,
  ...formProps
}: AssignmentStateSectionProps) => {
  const { t } = useTranslation()
  const { hasAllowedRole } = useAuth()
  const [hasExternalResource, assignmentIds] = useWatch({
    control: formProps.control,
    name: [
      AssignmentStateSectionFields.HasExternalResource,
      AssignmentStateSectionFields.AssignmentsIds,
    ],
  }) as [
    AssignmentStateSectionValues[AssignmentStateSectionFields.HasExternalResource],
    AssignmentStateSectionValues[AssignmentStateSectionFields.AssignmentsIds]
  ]
  const { needAssignment, handleClose, stateSectionLoading, data } =
    useModalProps()
  const { selectedOption, setSelectedOption } =
    useSelectedOption(needAssignment)
  const defaultValues = useAssignmentStateSectionDefaultValues()
  const { removeAssignments, isRemoving } = useRemoveAssignments({
    assignmentIds,
    onSuccess: () => {
      onRemoveAssignmentsSuccess()
      handleClose()
    },
  })
  const [isRemoveModalOpen, , openRemoveModal, closeRemoveModal] = useDialog()
  const hasCareProviderPermissions = useHasCPPermissions()

  const isVisible = hasAllowedRole([
    UserRole.SuperCareProvider,
    UserRole.CareProvider,
  ])

  useEffect(() => {
    formProps.reset(defaultValues)
  }, []) //eslint-disable-line

  const resourceNote = useMemo(
    () => needAssignment?.resourceNote || data?.resourceNote,
    [needAssignment?.resourceNote, data?.resourceNote]
  )

  return (
    <Form {...formProps}>
      <Grid container direction="column">
        <Grid item>
          <Divider>
            <Typography variant="overline" color="secondary">
              {t('assignmentModal.notes')}
            </Typography>
          </Divider>
        </Grid>
        <Grid item>
          {!!needAssignment && (
            <NotesSection historyEntries={needAssignment.history} />
          )}
        </Grid>
        <Grid item>
          <StyledTextAreaField
            name={AssignmentStateSectionFields.Notes}
            multiline
            rows={3}
            disabled={disableSection}
          />
        </Grid>
        {Boolean(resourceNote) && (
          <ResourceNoteWrapper item>
            <StyledTextAreaField
              name={AssignmentStateSectionFields.ResourceNote}
              value={resourceNote}
              multiline
              InputProps={{
                readOnly: true,
              }}
              rows={3}
              label={t('assignmentModal.resourceNote')}
            />
          </ResourceNoteWrapper>
        )}
      </Grid>
      <Divider>
        <Typography variant="overline" color="secondary">
          {t('assignmentModal.manage')}
        </Typography>
      </Divider>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ShiftsSection
            name={AssignmentStateSectionFields.AssignmentsIds}
            assignmentId={needAssignment?.id}
            assignmentStatus={needAssignment?.status}
            preliminary={needAssignment?.isPreliminary}
          />
        </Grid>
        <Grid item>
          <Section item>
            <Checkbox
              onFieldChange={setSelectedOption}
              disabled={disableSection}
              name={AssignmentStateSectionFields.IsClosed}
              label={t('assignmentModal.closeNeed')}
              selectedField={selectedOption}
            />
          </Section>
        </Grid>
        <Grid item>
          <Section item>
            <Checkbox
              onFieldChange={setSelectedOption}
              disabled={disableSection}
              name={AssignmentStateSectionFields.IsResolvedInternally}
              label={t('assignmentModal.resolvedInternally')}
              selectedField={selectedOption}
            />
          </Section>
        </Grid>
        {needAssignment.status !== AssignmentStatus.Canceled && (
          <Grid item>
            <Section item>
              <Checkbox
                onFieldChange={setSelectedOption}
                disabled={disableSection}
                name={AssignmentStateSectionFields.HasNoResource}
                label={t('assignmentModal.noResource')}
                selectedField={selectedOption}
              />
            </Section>
          </Grid>
        )}
        {hasCareProviderPermissions && (
          <Grid item>
            <Section>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Checkbox
                    data-test-id="external-resource-button"
                    selectedField={selectedOption}
                    onFieldChange={setSelectedOption}
                    disabled={disableSection}
                    name={AssignmentStateSectionFields.HasExternalResource}
                    label={t('assignmentModal.externalResourceSelected')}
                  />
                </Grid>
                {hasExternalResource && (
                  <Grid item>
                    <StyledCheckboxField
                      name={AssignmentStateSectionFields.IsPreliminary}
                      label={t('assignmentModal.preliminary')}
                    />
                  </Grid>
                )}
              </Grid>
              {hasExternalResource && (
                <ExternalResourceFieldsContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <StyledTextField
                        data-test-id="external-resource-name-text-fields"
                        disabled={disableSection}
                        name={AssignmentStateSectionFields.ExternalResourceName}
                        placeholder={t('assignmentModal.externalResourceName')}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <StyledTextField
                        data-test-id="external-resource-hsaid-text-fields"
                        disabled={disableSection}
                        name={
                          AssignmentStateSectionFields.ExternalResourceHSAID
                        }
                        placeholder={t('assignmentModal.externalResourceHSAID')}
                      />
                    </Grid>
                  </Grid>
                </ExternalResourceFieldsContainer>
              )}
            </Section>
          </Grid>
        )}
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <Button color="primary" onClick={handleClose}>
              {t('assignmentModal.cancel')}
            </Button>
          </Grid>
          <Grid item xs={10} container spacing={2} justifyContent="flex-end">
            {isVisible && (
              <Grid item xs={5}>
                <LoadingButton
                  data-test-id="remove-shift-button"
                  color="primary"
                  variant="contained"
                  onClick={openRemoveModal}
                  loading={isRemoving}
                  disabled={!assignmentIds?.length}
                >
                  {t('assignmentModal.removeShift', {
                    count: assignmentIds.length,
                  })}
                </LoadingButton>
              </Grid>
            )}

            <Grid item xs={5}>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                disabled={disableSection}
                loading={stateSectionLoading === LoadingStatus.Pending}
              >
                {t('assignmentModal.save', {
                  count: assignmentIds.length,
                })}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={isRemoveModalOpen}
        mainContent={t('assignmentModal.removeShiftConfirmation')}
        confirmationButtonLabel={t('assignmentModal.removeShift')}
        onConfirmation={removeAssignments}
        onCancel={closeRemoveModal}
      />
    </Form>
  )
}

export default AssignmentStateSection
