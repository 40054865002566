import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { Notifications } from '../../services'
import ListingAssignments from '../../services/listing-assignments'
import NeedAssignments, {
  AssignAssignmentToResourcesParams,
  ChangeAssignmentStateParams,
  ModifyBookedAssignmentParams,
} from '../../services/need-assignments'
import Needs, { DeleteNeedParams } from '../../services/needs'
import Schedules, { GetSchedulesDaysParams } from '../../services/schedules'
import Units, { GetShiftsParams, GetUnitParams } from '../../services/units'
import { NAMESPACE } from '../../strings'
import { ScheduleDaysAssignment, OpenShiftAssignmentDraft } from '../../types'
import {
  createAsyncThunkWithErrorHandling,
  SetSchedulerDatesPayload,
} from '../../utils'
import {
  AssignmentModalPayload,
  CreateOpenShiftAssignentPayload,
  EditOpenShiftAssignentPayload,
  GetAssignmentDataPayload,
  GetSchedulesDaysPayload,
  GetUnitNeedsPayload,
  MarkNeedsAsReadPayload,
  RemoveNeedAssignmentsPayload,
} from './actions.types'

const needAssignments = new NeedAssignments()
const schedules = new Schedules()
const needs = new Needs()
const notifications = new Notifications()
const units = new Units()
const listingAssignments = new ListingAssignments()

export const getSchedulesDays = createAsyncThunk(
  `${NAMESPACE}/getSchedulesDays`,
  ({ refreshOnPending, ...payload }: GetSchedulesDaysPayload) =>
    schedules.getSchedulesDays(payload)
)

export const resetSchedulesDays = createAction(
  `${NAMESPACE}/resetSchedulesDays`
)

export const setSchedulerDates = createAction(
  `${NAMESPACE}/setSchedulerDates`,
  (payload: SetSchedulerDatesPayload) => ({ payload })
)

export const setSelectedAssignment = createAction<ScheduleDaysAssignment>(
  `${NAMESPACE}/setSelectedAssignment`
)

export const setSelectedOpenShiftAssignment =
  createAction<OpenShiftAssignmentDraft>(
    `${NAMESPACE}/setSelectedOpenShiftAssignment`
  )

export const resetSelectedAssignment = createAction(
  `${NAMESPACE}/resetSelectedAssignment`
)

export const resetSelectedOpenShiftAssignment = createAction(
  `${NAMESPACE}/resetSelectedOpenShiftAssignment`
)

export const getPickerAssignments = createAsyncThunk(
  `${NAMESPACE}/getPickerAssignments`,
  (payload: GetSchedulesDaysParams) => schedules.getSchedulesDays(payload)
)

export const resetGetPickerAssignments = createAction(
  `${NAMESPACE}/resetGetPickerAssignments`
)

export const openAssignmentModal = createAction<AssignmentModalPayload>(
  `${NAMESPACE}/openAssignmentModal`
)

export const setAssignmentModalRole = createAction<string | undefined>(
  `${NAMESPACE}/setAssignmentModalRole`
)

export const closeAssignmentModal = createAction(
  `${NAMESPACE}/closeAssignmentModal`
)

export const removeAssignments = createAsyncThunk(
  `${NAMESPACE}/removeUnbookedAssignment`,
  async (
    { onSuccess, onFailure, ...payload }: RemoveNeedAssignmentsPayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await needAssignments.removeAssignments(payload)
      if (onSuccess) onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      if (onFailure) onFailure(message)
      return rejectWithValue(message)
    }
  }
)

export const resetRemoveAssignments = createAction(
  `${NAMESPACE}/resetRemoveUnbookedAssignment`
)

export const getUnitNeeds = createAsyncThunk(
  `${NAMESPACE}/getUnitNeeds`,
  async ({ onSuccess, ...payload }: GetUnitNeedsPayload) => {
    const data = await needs.getUnitNeeds(payload)
    if (onSuccess) onSuccess(data)
    return data
  }
)

export const deleteNeed = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/deleteNeed`,
  (params: DeleteNeedParams) => needs.deleteNeed(params)
)

export const markNeedsAsRead = createAsyncThunk(
  `${NAMESPACE}/markNeedsAsRead`,
  async ({ notificationIds, onSuccess }: MarkNeedsAsReadPayload) => {
    const data = await notifications.markAsRead(notificationIds)
    if (onSuccess) onSuccess()
    return data
  }
)

export const assignAssignmentToResources = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/assignAssignmentToResources`,
  (payload: AssignAssignmentToResourcesParams) =>
    needAssignments.assignAssignmentToResources(payload)
)

export const changeAssignmentState = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/changeAssignmentState`,
  (payload: ChangeAssignmentStateParams) =>
    needAssignments.changeAssignmentState(payload)
)

export const modifyBookedAssignment = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/modifyBookedAssignment`,
  (payload: ModifyBookedAssignmentParams) =>
    needAssignments.modifyBookedAssignment(payload)
)

export const resetChangeAssignmentState = createAction(
  `${NAMESPACE}/resetChangeAssignmentState`
)

export const resetModifyBookedAssignment = createAction(
  `${NAMESPACE}/resetModifyBookedAssignment`
)

export const getUnit = createAsyncThunk(
  `${NAMESPACE}/getUnit`,
  (payload: GetUnitParams) => units.getUnit(payload)
)

export const resetUnit = createAction(`${NAMESPACE}/getUnit`)

export const getSchedulesShifts = createAsyncThunk(
  `${NAMESPACE}/getShifts`,
  (payload: GetShiftsParams) => units.getShifts(payload)
)

export const createOpenShiftAssignment = createAsyncThunk(
  `${NAMESPACE}/createOpenShiftAssignment`,
  async ({
    onSuccess,
    onFailure,
    ...payload
  }: CreateOpenShiftAssignentPayload) => {
    try {
      const data = await needAssignments.createOpenShiftAssignment(payload)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
    }
  }
)

export const editOpenShiftAssignment = createAsyncThunk(
  `${NAMESPACE}/editOpenShiftAssignment`,
  async ({
    onSuccess,
    onFailure,
    ...payload
  }: EditOpenShiftAssignentPayload) => {
    try {
      const data = await needAssignments.editOpenShiftAssignment(payload)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
    }
  }
)

export const getNeedAssignmentData = createAsyncThunk(
  `${NAMESPACE}/getNeedAssignmentData`,
  async ({ onFailure, ...payload }: GetAssignmentDataPayload) => {
    try {
      const data = await needAssignments.getAssignmentData(payload)
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
    }
  }
)

export const getListingAssignmentData = createAsyncThunk(
  `${NAMESPACE}/getListingAssignmentData`,
  async ({ onFailure, ...payload }: GetAssignmentDataPayload) => {
    try {
      const data = await listingAssignments.getListingAssignment(payload)
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
    }
  }
)

export const resetAssignmentData = createAction(
  `${NAMESPACE}/resetAssignmentData`
)
