import { Grid, InputLabel, styled, Typography } from '@mui/material'

export const RequestContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

export const RequestLabel = styled(Typography)({
  fontWeight: 500,
  fontSize: '0.75rem',
})

export const RejectedRequestLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.75rem',
  fontStyle: 'italic',
}))

export const RequestsContainerLabel = styled(InputLabel)(({ theme }) => ({
  paddingBottom: theme.spacing(2.5),
}))
