import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { SyncIcon } from './ModifiedByIcon.style'

interface ModifiedByIconProps {
  name?: string
  tooltip?: boolean
}

const ModifiedByIcon = ({ name, tooltip = true }: ModifiedByIconProps) => {
  const { t } = useTranslation()

  if (!tooltip || !name) return <SyncIcon />

  return (
    <Tooltip title={`${t('resourceAccessibility.modifiedBy')}: ${name}`}>
      <SyncIcon />
    </Tooltip>
  )
}

export default ModifiedByIcon
