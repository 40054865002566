import { Components } from '@mui/material'
import { LinkProps } from '@mui/material/Link'
import LinkBehavior from './utils/LinkBehavior'

const MuiLink: Components['MuiLink'] = {
  defaultProps: {
    component: LinkBehavior,
  } as LinkProps,
  styleOverrides: { root: { textTransform: 'uppercase' } },
}

export default MuiLink
