import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { Log } from 'shared/types'
import { getActivityLogs, resetGetActivityLogs } from './actions'

interface State {
  getActivityLogs: Resource<Log[]>
}

const initialState: State = {
  getActivityLogs: resource.getInitial<Log[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getActivityLogs.pending, state => {
      resource.setPending(state.getActivityLogs)
    })
    .addCase(getActivityLogs.rejected, (state, action) => {
      resource.setFailed(state.getActivityLogs, action.error.message)
    })
    .addCase(getActivityLogs.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getActivityLogs,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(resetGetActivityLogs, state => {
      resource.reset(state.getActivityLogs, initialState.getActivityLogs.data)
    })
)
