import { Theme } from '@mui/material'
import breakpoints from './breakpoints'
import components from './components'
import palette from './palette'
import spacing from './spacing'
import typography from './typography'
import zIndex from './zIndex'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const themeOptions = {
  typography,
  zIndex,
  breakpoints,
  spacing,
  components,
  mixins: {},
  palette,
}

export default themeOptions
