import { FormControlLabel, styled } from '@mui/material'

interface SyledFormControlLabelProps {
  disableClickOnLabel?: boolean
}

export const StyledFormControlLabel = styled(
  FormControlLabel
)<SyledFormControlLabelProps>(({ disableClickOnLabel }) => ({
  ...(disableClickOnLabel && { pointerEvents: 'none' }),

  '& .MuiCheckbox-root': {
    ...(disableClickOnLabel && { pointerEvents: 'auto' }),
  },
}))
