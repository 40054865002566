import { useLocation, Navigate } from 'react-router-dom'
import { paths } from 'config'
import { useAuth, useOrganizationId } from '../hooks'
import { LoadingStatus, ModuleRoute } from '../types'
import { generatePathWithQuery } from '../utils'

const PrivateRoute = ({
  allowedRoles,
  path,
  public: isPublic,
  element,
}: ModuleRoute) => {
  const { hasAllowedRole, loading } = useAuth()
  const organizationId = useOrganizationId()
  const { pathname, search } = useLocation()

  const redirectUrl = generatePathWithQuery({
    path: paths.forbidden,
    params: {},
    qs: { redirectUrl: `${pathname}${search}` },
  })

  const isLoading = loading === LoadingStatus.Pending
  if (isLoading || isPublic) {
    return <>{element}</>
  }

  const hasNoOrganization = path?.includes(':organizationId') && !organizationId
  if (!hasAllowedRole(allowedRoles) || hasNoOrganization) {
    return <Navigate replace to={redirectUrl} />
  }

  return <>{element}</>
}

export default PrivateRoute
