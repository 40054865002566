import { Chip, styled } from '@mui/material'
import { AssignmentStatus } from '../../types'
import { getAssignmentPillStyle } from '../../utils'

interface PillProps {
  status: AssignmentStatus
  viviumXAffiliation: boolean
}

export const Pill = styled(Chip)<PillProps>(
  ({ theme, status, viviumXAffiliation }) => {
    const style = getAssignmentPillStyle({ theme, status })

    return {
      backgroundColor: theme.palette.pill.yellow.main,
      color: theme.palette.common.black,
      height: theme.spacing(2),
      width: theme.spacing(12),

      '&, &:active, &:focus, &:hover': style,

      '& .MuiChip-label': {
        margin: theme.spacing(0.25, 0, 0, 0),
        ...(viviumXAffiliation && {
          paddingLeft: theme.spacing(0.5),
        }),
        '& svg': {
          marginBottom: theme.spacing(0.125),
        },
      },
    }
  }
)
