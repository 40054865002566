import { createReducer } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import config from 'config'
import resource, { Resource } from '../../resource'
import { LoadingStatus, User } from '../../types'
import { expireReducer, getErrorMessage } from '../../utils'
import {
  getCurrentUser,
  signIn,
  signOut,
  resetPassword,
  setNewPassword,
  confirmForgotPassword,
  resolveChallenge,
  resetSignIn,
  resetSignOut,
  resetResetPassword,
  resetSetNewPassword,
  resetConfirmForgotPassword,
  resetResolveChallenge,
  changeLoginInfo,
  resetChangeLoginInfo,
  setCurrentUserLanguage,
} from './actions'

export interface State {
  /**
   * Loading status and user object are required to authenticate user in the app,
   * to maintain their session and to prevent sign-out when the current user is
   * being fetched. See `../../components/PrivateRoute.tsx:22-23`.
   *
   */
  user: User | null
  loading: LoadingStatus
  error?: string | null
  getCurrentUser: Resource
  signIn: Resource
  signOut: Resource
  resetPassword: Resource
  setNewPassword: Resource
  confirmForgotPassword: Resource
  resolveChallenge: Resource
  changeLoginInfo: Resource
}

const initialState: State = {
  user: null,
  loading: LoadingStatus.Idle,
  error: null,
  getCurrentUser: resource.getInitial(),
  signIn: resource.getInitial(),
  signOut: resource.getInitial(),
  resetPassword: resource.getInitial(),
  setNewPassword: resource.getInitial(),
  confirmForgotPassword: resource.getInitial(),
  resolveChallenge: resource.getInitial(),
  changeLoginInfo: resource.getInitial(),
}

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(getCurrentUser.pending, state => {
      state.loading = LoadingStatus.Pending
      resource.setPending(state.getCurrentUser)
    })
    .addCase(getCurrentUser.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.user = action.payload
      resource.setPending(state.getCurrentUser)
    })
    .addCase(getCurrentUser.rejected, (state, action) => {
      const error = getErrorMessage(action)
      state.loading = LoadingStatus.Failed
      state.error = error
      state.user = null
      resource.setFailed(state.getCurrentUser, error)
    })
    .addCase(signIn.pending, state => {
      resource.setPending(state.signIn)
    })
    .addCase(signIn.fulfilled, (state, action) => {
      resource.setSucceeded(state.signIn)
      state.user = action.payload
    })
    .addCase(signIn.rejected, (state, action) => {
      const error = (action.payload as AxiosError<any>).code
      resource.setFailed(state.signIn, error)
      state.user = null
    })
    .addCase(resetSignIn, state => {
      resource.reset(state.signIn, initialState.signIn)
    })
    .addCase(signOut.pending, state => {
      resource.setPending(state.signOut)
      state.user = null
    })
    .addCase(signOut.fulfilled, state => {
      resource.setSucceeded(state.signOut)
    })
    .addCase(signOut.rejected, (state, action) => {
      resource.setFailed(state.signOut, getErrorMessage(action))
    })
    .addCase(resetSignOut, state => {
      resource.reset(state.signOut, initialState.signOut)
    })
    .addCase(resetPassword.pending, state => {
      resource.setPending(state.resetPassword)
    })
    .addCase(resetPassword.fulfilled, state => {
      resource.setSucceeded(state.resetPassword)
    })
    .addCase(resetPassword.rejected, (state, action) => {
      resource.setFailed(state.resetPassword, getErrorMessage(action))
    })
    .addCase(resetResetPassword, state => {
      resource.reset(state.resetPassword, initialState.resetPassword)
    })
    .addCase(setNewPassword.pending, state => {
      resource.setPending(state.setNewPassword)
    })
    .addCase(setNewPassword.fulfilled, state => {
      resource.setSucceeded(state.setNewPassword)
    })
    .addCase(setNewPassword.rejected, (state, action) => {
      resource.setFailed(state.setNewPassword, getErrorMessage(action))
    })
    .addCase(resetSetNewPassword, state => {
      resource.reset(state.setNewPassword, initialState.setNewPassword)
    })
    .addCase(confirmForgotPassword.pending, state => {
      resource.setPending(state.confirmForgotPassword)
    })
    .addCase(confirmForgotPassword.fulfilled, state => {
      resource.setSucceeded(state.confirmForgotPassword)
    })
    .addCase(confirmForgotPassword.rejected, (state, action) => {
      resource.setFailed(state.confirmForgotPassword, getErrorMessage(action))
    })
    .addCase(resetConfirmForgotPassword, state => {
      resource.reset(
        state.confirmForgotPassword,
        initialState.confirmForgotPassword
      )
    })
    .addCase(resolveChallenge.pending, state => {
      resource.setPending(state.resolveChallenge)
    })
    .addCase(resolveChallenge.fulfilled, (state, action) => {
      resource.setSucceeded(state.resolveChallenge)
      state.user = action.payload
    })
    .addCase(resolveChallenge.rejected, (state, action) => {
      resource.setFailed(state.resolveChallenge, getErrorMessage(action))
      state.user = null
    })
    .addCase(resetResolveChallenge, state => {
      resource.reset(state.resolveChallenge, initialState.resolveChallenge)
    })
    .addCase(changeLoginInfo.pending, state => {
      resource.setPending(state.changeLoginInfo)
    })
    .addCase(changeLoginInfo.fulfilled, (state, action) => {
      resource.setSucceeded(state.changeLoginInfo)
    })
    .addCase(changeLoginInfo.rejected, (state, action) => {
      resource.setFailed(state.changeLoginInfo, action.error.message)
    })
    .addCase(resetChangeLoginInfo, state => {
      resource.reset(state.changeLoginInfo)
    })
    .addCase(setCurrentUserLanguage.fulfilled, (state, action) => {
      if (state.user) state.user.language = action.payload
    })
)

export default persistReducer(
  {
    storage,
    key: 'auth',
    whitelist: ['user'],
    transforms: [
      expireReducer<State>({
        reducerKey: 'user',
        expireSeconds: config.sessionLength,
        expiredState: initialState.user,
      }),
    ],
  },
  reducer
)
