import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { RequestNotification, NotificationType } from '../../../../../types'
import { Badge } from '../../../../Badge'
import NotificationItem, { NotificationItemProps } from './NotificationItem'
import {
  AssignmentRequestChip,
  NotificationCell,
  NotificationRow,
} from './NotificationItem.style'
import {
  useRedirectionParams,
  useBookingSchedulePath,
} from './NotificationItem.utils'

const useChipText = (type: NotificationType) => {
  const { t } = useTranslation()
  switch (type) {
    case NotificationType.NeedAssignmentRequestAccepted:
      return t('notification.acceptedChip')
    case NotificationType.NeedAssignmentRequestCreated:
      return t('notification.ongoingChip')
    case NotificationType.NeedAssignmentRequestRejected:
      return t('notification.deniedChip')
    default:
      return ''
  }
}

export interface RequestNotificationItemProps
  extends Partial<NotificationItemProps> {
  notification: RequestNotification
}

const RequestNotificationItem = ({
  notification: { id, data, isRead, type },
  ...props
}: RequestNotificationItemProps) => {
  const {
    requestResourceId,
    requestResourceName,
    unitId,
    unitName,
    shiftName,
    assignmentStartDate,
    roleShortCode,
  } = data

  const { resource, unit } = useRedirectionParams({
    unitId,
    unitName,
    resourceId: requestResourceId,
    resourceName: requestResourceName,
  })

  const chipText = useChipText(type)
  const path = useBookingSchedulePath({
    unit,
    resource,
    date: assignmentStartDate,
  })

  const date = assignmentStartDate
    ? format(parseISO(assignmentStartDate), 'dd/MM')
    : ''

  return (
    <NotificationItem notificationId={id} path={path} {...props}>
      <NotificationRow container>
        <NotificationCell item xs={1} container justifyContent="center">
          {!isRead && <Badge notification />}
        </NotificationCell>
        <NotificationCell item xs={3}>
          <Typography component="p" variant="caption" noWrap>
            <strong>{unitName}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={1}>
          <Typography component="p" variant="caption" noWrap>
            <strong>{roleShortCode}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={3}>
          <Typography component="p" variant="caption" noWrap>
            {requestResourceName}
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={2}>
          <Typography component="p" variant="caption" noWrap>
            {date}&nbsp;<strong>-&nbsp;{shiftName}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={2}>
          <AssignmentRequestChip label={chipText} notificationType={type} />
        </NotificationCell>
      </NotificationRow>
    </NotificationItem>
  )
}

export default RequestNotificationItem
