import { ChangeEvent, useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControlLabelProps,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { DailyAvailabilityField } from './DailyAvailability'

interface OvertimeCheckboxFieldProps extends Partial<FormControlLabelProps> {
  name: string
  selectAllFor: string
  targetFieldName: keyof DailyAvailabilityField
}

const OvertimeCheckboxField = ({
  name,
  selectAllFor,
  targetFieldName,
  ...props
}: OvertimeCheckboxFieldProps) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()
  const fields: DailyAvailabilityField[] = useWatch({
    control,
    name: selectAllFor,
  })

  const isChecked = useMemo(
    () => fields.every(field => field[targetFieldName]),
    [targetFieldName, fields]
  )

  const isIndeterminate = useMemo(
    () => !isChecked && fields.some(field => field[targetFieldName]),
    [isChecked, fields, targetFieldName]
  )

  const handleChange = useCallback(
    (_: ChangeEvent<{}>, checked: boolean) =>
      setValue(
        selectAllFor,
        fields.map(field => ({ ...field, [targetFieldName]: checked }))
      ),
    [setValue, targetFieldName, selectAllFor, fields]
  )

  return (
    <FormControlLabel
      onChange={handleChange}
      checked={isChecked}
      label={
        <Typography variant="caption" color="textSecondary">
          {t('availabilityModal.isOpenForOvertime')}
        </Typography>
      }
      control={
        <Checkbox name={name} color="primary" indeterminate={isIndeterminate} />
      }
      {...props}
    />
  )
}

export default OvertimeCheckboxField
