//DO NOT REMOVE THIS FILE OR HOOKS IMPORT BECAUSE THEY ARE CRUCIAL FOR PROJECT'S INTEGRITY
import { useCurrentUser } from '../hooks'

//TODO: investigate circular dependencies in shared directory because it might be a reason for problems with project's integrity
const AuthProvider = () => {
  useCurrentUser()
  return <></>
}

export default AuthProvider
