export enum SortingOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface FilteringParams {
  search?: string
  searchBy?: string
}

export interface SortingParams {
  sortBy?: string
  sortOrder?: SortingOrder
}

export interface PaginationData {
  count: number
  take: number
  skip: number
}

export interface PaginationParams {
  take?: number
  skip?: number
}

export enum ApiErrorType {
  Validation = 'VALIDATION',
}

export interface ApiError {
  message: string
  path: string
  statusCode: number
  type: ApiErrorType
}
