import { isBefore, parseISO } from 'date-fns'
import { ChatListEntry, ChatMessage } from 'shared/types'

export const getMessagesObject = (messages: ChatMessage[]) =>
  messages.reduce(
    (acc, { id, ...message }) => ({
      ...acc,
      [id]: message,
    }),
    {}
  )

export const sortChats = (chat1: ChatListEntry, chat2: ChatListEntry) => {
  const chat1Date = chat1.lastMessage?.createdAt || chat1.createdAt
  const chat2Date = chat2.lastMessage?.createdAt || chat2.createdAt

  return isBefore(parseISO(chat1Date), parseISO(chat2Date)) ? 1 : -1
}
