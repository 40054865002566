import { styled, Typography } from '@mui/material'
import DataTable from 'shared/components/DataTable'

export const AbsenceText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  whiteSpace: 'pre',
}))

export const AvailabilityText = styled(Typography)({
  whiteSpace: 'pre',
})

interface StyledDataTableProps {
  isLoading: boolean
}

export const StyledDataTable = styled(DataTable)<StyledDataTableProps>(
  ({ theme, isLoading }) => ({
    padding: 0,
    maxHeight: 250,
    marginBottom: theme.spacing(2),

    '& .MuiTable-root': {
      borderCollapse: 'collapse',
      ...(!isLoading && { marginTop: 0 }),
    },

    '& .MuiTableBody-root': {
      border: '1px solid black',

      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-root': {
          border: 'none',
        },
      },
    },
  })
)
