import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import {
  Notification,
  SchedulerType,
  NotificationType,
} from '../../../../../types'
import { Badge } from '../../../../Badge'
import NotificationItem, { NotificationItemProps } from './NotificationItem'
import {
  BookingChip,
  MessageIcon,
  NotificationCell,
  NotificationRow,
} from './NotificationItem.style'
import {
  useRedirectionParams,
  useBookingSchedulePath,
} from './NotificationItem.utils'

export interface ResourceBookedNotificationProps
  extends Partial<NotificationItemProps> {
  notification: Notification
}

const ResourceNotificationItem = ({
  notification: { id, data, isRead, type },
  ...props
}: ResourceBookedNotificationProps) => {
  const { t } = useTranslation()

  const {
    unitId,
    unitName,
    shiftName,
    assignmentExternalResourceName,
    assignmentStartDate,
    requestResourceId,
    assignmentResourceId,
    requestResourceName,
    assignmentResourceName,
  } = data

  const { resource, unit } = useRedirectionParams({
    unitId,
    unitName,
    resourceId: requestResourceId || assignmentResourceId,
    resourceName: requestResourceName || assignmentResourceName,
  })

  const path = useBookingSchedulePath({
    resource,
    unit,
    date: assignmentStartDate,
    schedulerType: SchedulerType.Scheme,
  })

  const date = assignmentStartDate
    ? format(parseISO(assignmentStartDate), 'dd/MM')
    : ''

  return (
    <NotificationItem notificationId={id} path={path} {...props}>
      <NotificationRow container>
        <NotificationCell item xs={1} container justifyContent="center">
          {!isRead && <Badge notification />}
        </NotificationCell>
        <NotificationCell item xs={3}>
          <Typography component="p" variant="caption" noWrap>
            {data.requestResourceName ||
              data.assignmentResourceName ||
              data.assignmentExternalResourceName}
            {type === NotificationType.NeedAssignmentNoteAdded && (
              <MessageIcon fontSize="inherit" />
            )}
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={1}>
          <Typography component="p" variant="caption" noWrap>
            <strong>{date}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={2}>
          <Typography component="p" variant="caption" noWrap>
            {shiftName}
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={3}>
          <Typography component="p" variant="caption" noWrap>
            {unitName}
          </Typography>
        </NotificationCell>
        <NotificationCell
          item
          xs={2}
          container
          justifyContent="center"
          alignItems="center"
        >
          <BookingChip
            externalResource={!!assignmentExternalResourceName}
            label={
              assignmentExternalResourceName
                ? t('notification.external')
                : t('notification.internal')
            }
          />
        </NotificationCell>
      </NotificationRow>
    </NotificationItem>
  )
}

export default ResourceNotificationItem
