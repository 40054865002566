import { Components } from '@mui/material'

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      minWidth: '72px',
      '@media (min-width:600px)': { minWidth: '160px' },
    },
  },
}

export default MuiTab
