import { PropsWithChildren } from 'react'
import {
  Scheduler,
  SchedulerProps,
} from '@devexpress/dx-react-scheduler-material-ui'
import { useDateFns, useSelector } from '../hooks'
import { selectors } from '../store'

export type LocalizedSchedulerProps = PropsWithChildren<SchedulerProps>

const LocalizedScheduler = (props: LocalizedSchedulerProps) => {
  const selectedLanguage = useSelector(selectors.settings.getLanguage)
  const dateOptions = useDateFns()

  return (
    <Scheduler
      firstDayOfWeek={dateOptions?.weekStartsOn}
      locale={selectedLanguage}
      {...props}
    />
  )
}

export default LocalizedScheduler
