import { HTMLProps } from 'react'
import { Breadcrumbs } from 'shared/components'
import { Container, Icon } from './BackButton.styles'

interface BackButtonProps extends Omit<HTMLProps<HTMLSpanElement>, 'as'> {
  label?: string
  link?: string
}

const BackButton = ({ label, link, ...props }: BackButtonProps) => (
  <Breadcrumbs
    items={[
      {
        link,
        label: (
          <Container {...props}>
            <Icon color="inherit" />
            <>{label}</>
          </Container>
        ),
      },
    ]}
  />
)

export default BackButton
