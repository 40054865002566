import { Menu } from '@mui/icons-material'
import { AppBarProps, Divider, IconButton, Toolbar } from '@mui/material'
import {
  useAdminInfoMessage,
  useAdminUpdateMessage,
  useAuth,
  useIsInOrganizationList,
} from '../../../hooks'
import { UserRole } from '../../../types'
import AdminMessage from '../../AdminMessage'
import Hidden from '../../Hidden'
import LanguagePicker from '../../LanguagePicker'
import Notifications from './Notifications'
import { AddAvailabilityButton, CreateNeedButton } from './TopbarAction'
import UserMenu from './UserMenu'
import { ReactComponent as Logo } from '../../../assets/logo.svg'
import {
  StyledTopbar,
  ToolbarActions,
  LogoContainer,
  LanguagePickerWrapper,
} from './Topbar.style'
import { useIsOrganizationSelected } from './Topbar.utils'

export interface TopbarProps extends Partial<AppBarProps> {
  hidden?: boolean
  onDrawerToggle?: () => void
}

const Topbar = ({ hidden, onDrawerToggle, ...props }: TopbarProps) => {
  const { hasAllowedRole } = useAuth()
  const isInOrganizationList = useIsInOrganizationList()
  const isOrganizationSelected = useIsOrganizationSelected()
  const isAvailabilityButtonVisible = hasAllowedRole([UserRole.Resource])
  const isCreateNeedButtonVisible =
    isOrganizationSelected &&
    hasAllowedRole([
      UserRole.SuperCareProvider,
      UserRole.CareProvider,
      UserRole.UnitAdmin,
    ])

  const {
    close: closeUpdateMessage,
    isOpen: isUpdateMessageOpen,
    message: updateMessage,
    id: updateMessageId,
  } = useAdminUpdateMessage()

  const {
    close: closeInfoMessage,
    isOpen: isInfoMessageOpen,
    message: infoMessage,
  } = useAdminInfoMessage()

  if (hidden) return null
  return (
    <StyledTopbar position="fixed" color="primary" {...props}>
      <Toolbar disableGutters>
        <LogoContainer>
          <Hidden mdUp>
            <IconButton edge="start" color="inherit" onClick={onDrawerToggle}>
              <Menu />
            </IconButton>
          </Hidden>
          <Logo />
        </LogoContainer>
        <ToolbarActions>
          {!isInOrganizationList && <Notifications />}
          {isAvailabilityButtonVisible && <AddAvailabilityButton />}
          {isCreateNeedButtonVisible && <CreateNeedButton />}
          <Divider variant="middle" orientation="vertical" flexItem />
          <LanguagePickerWrapper>
            <LanguagePicker />
          </LanguagePickerWrapper>
          <UserMenu />
        </ToolbarActions>
      </Toolbar>
      <AdminMessage
        bar
        close={closeInfoMessage}
        open={isInfoMessageOpen}
        message={infoMessage}
      />
      <AdminMessage
        bar
        update
        close={closeUpdateMessage}
        open={isUpdateMessageOpen}
        message={updateMessage}
        messageId={updateMessageId}
      />
    </StyledTopbar>
  )
}

export default Topbar
