import { useTranslation } from 'react-i18next'
import { Grid, InputLabel } from '@mui/material'
import { RadioFieldItem } from '../../../RadioField'
import {
  RequestableOptions,
  ResourcesSectionFields,
} from '../CPResourcesSection'
import RequestCheckbox from './RequestCheckbox'
import {
  CheckboxContainer,
  StyledSelectAllCheckboxField,
} from '../../AssignmentModal.style'
import { MessageText } from '../RequestAssignmentContent.style'
import { RequestedResourceStatus } from '../RequestAssignmentContent.utils'

interface RequestSectionProps {
  requestableResources: RequestableOptions[]
  pendingResources?: RadioFieldItem[]
  disabledBooking?: boolean
}

const RequestSection = ({
  requestableResources,
  disabledBooking = false,
  pendingResources = [],
}: RequestSectionProps) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid item>
          <InputLabel>{t('assignmentModal.sendRequest')}</InputLabel>
        </Grid>
        <Grid item>
          <StyledSelectAllCheckboxField
            disabled={disabledBooking || requestableResources.length === 0}
            selectAllFor={ResourcesSectionFields.RequestedResources}
            label={t('assignmentModal.selectAllResources', {
              count: requestableResources.length,
            })}
            options={requestableResources}
            checked
            disableClickOnLabel
          />
        </Grid>
      </Grid>
      <CheckboxContainer opaque item container direction="column">
        {requestableResources.length === 0 && pendingResources.length === 0 ? (
          <Grid item>
            <MessageText variant="body2">
              {t('assignmentModal.noDirectResources')}
            </MessageText>
          </Grid>
        ) : (
          <>
            {requestableResources.map(({ value, label, status }, index) => {
              return (
                <RequestCheckbox
                  disabledBooking={disabledBooking}
                  name={ResourcesSectionFields.RequestedResources}
                  value={value}
                  label={label}
                  status={status}
                  key={`request-checkbox-${index}`}
                />
              )
            })}
            {pendingResources.map(({ value, label }, index) => {
              return (
                <RequestCheckbox
                  name={ResourcesSectionFields.AlreadyRequestedResources}
                  value={value}
                  label={label}
                  status={RequestedResourceStatus.Requested}
                  key={`pending-request-checkbox-${index}`}
                />
              )
            })}
          </>
        )}
      </CheckboxContainer>
    </Grid>
  )
}

export default RequestSection
