import { useSelector } from 'react-redux'
import { selectors } from '../store'

const DEFAULT_MAX_ASSIGNMENTS_COUNT = 5

export const useMaxDisplayedAssignmentsCount = () => {
  const { data: needsSettingsData } = useSelector(
    selectors.settings.getNeedSettingsResource
  )

  return (
    needsSettingsData?.maxDisplayedAssignmentsCount ||
    DEFAULT_MAX_ASSIGNMENTS_COUNT
  )
}
