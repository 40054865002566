import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute, UserRole } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.organizationUnits,
    allowedRoles: [UserRole.CareProvider, UserRole.SuperCareProvider],
    element: <Loadable component={() => import('./pages/Units')} />,
  },
  {
    path: paths.newNeed,
    allowedRoles: [
      UserRole.CareProvider,
      UserRole.SuperCareProvider,
      UserRole.UnitAdmin,
    ],
    element: <Loadable component={() => import('./pages/NewNeed')} />,
  },
  {
    path: paths.organizationNeeds,
    allowedRoles: [
      UserRole.CareProvider,
      UserRole.SuperCareProvider,
      UserRole.UnitAdmin,
    ],
    element: <Loadable component={() => import('./pages/Needs')} />,
  },
  {
    path: paths.organizationUnitBookings,
    allowedRoles: [UserRole.CareProvider, UserRole.SuperCareProvider],
    element: <Loadable component={() => import('./pages/UnitBookings')} />,
  },
]

export default routes
