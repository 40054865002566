import { useEffect } from 'react'
import { isAfter, isBefore } from 'date-fns'
import { parseDate } from '../../utils'

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_MASK = '____-__-__'

export type DateFieldValue = Date | string | null

export interface RangeOptions {
  minRangeDate?: DateFieldValue
  maxRangeDate?: DateFieldValue
}

interface UseRangeOptions extends RangeOptions {
  value: DateFieldValue
  handleChange: (date: Date | string | null) => void
  adjustToRange: boolean
}

export const useRange = ({
  value,
  handleChange,
  minRangeDate = null,
  maxRangeDate = null,
  adjustToRange,
}: UseRangeOptions) => {
  useEffect(() => {
    const fieldValue = parseDate(value)

    const startDate = parseDate(minRangeDate)
    // TODO: `|| new Date()` part can be removed once this pickers issue is solved - https://github.com/mui/mui-x/issues/5091
    if (startDate && isBefore(fieldValue || new Date(), startDate)) {
      return handleChange(adjustToRange ? startDate : null)
    }

    const endDate = parseDate(maxRangeDate)
    // TODO: `|| new Date()` part can be removed once this pickers issue is solved - https://github.com/mui/mui-x/issues/5091
    if (endDate && isAfter(fieldValue || new Date(), endDate)) {
      return handleChange(adjustToRange ? endDate : null)
    }
  }, [minRangeDate, maxRangeDate, value, handleChange, adjustToRange])

  return {
    ...(minRangeDate && { minDate: minRangeDate }),
    ...(maxRangeDate && { maxDate: maxRangeDate }),
  }
}
