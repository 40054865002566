import { createAsyncThunk } from '@reduxjs/toolkit'
import { Resources } from '../../services'
import {
  CreateResourceAbsencesParams,
  CreateResourceAvailabilitiesParams,
  GetAvailabilityTableInfoParams,
} from '../../services/resources'
import { AbsenceReason, ResourcePeriodType } from '../../types'
import { createAsyncThunkWithErrorHandling } from '../../utils'
import {
  CreateResourceAccessibilitiesPayload,
  DeleteResourceAccessibilityPayload,
  UpdateResourceAccessibilityDataPayload,
  UpdateResourceAccessibilityPayload,
} from './actions.types'

const resources = new Resources()

export const createResourceAccessibilities = createAsyncThunkWithErrorHandling(
  `createResourceAccessibilities`,
  async ({ type, ...payload }: CreateResourceAccessibilitiesPayload) => {
    if (type === ResourcePeriodType.Absence) {
      await resources.createResourceAbsences(
        payload as CreateResourceAbsencesParams
      )
    } else if (type === ResourcePeriodType.Availability) {
      await resources.createResourceAvailabilities(
        payload as CreateResourceAvailabilitiesParams
      )
    }
  }
)

const updateResourceAccessibilityData = async (
  type: ResourcePeriodType,
  {
    isOpenForOvertime: openForOvertime = false,
    reason = AbsenceReason.Vacation,
    note = '',
    ...payload
  }: UpdateResourceAccessibilityDataPayload
) => {
  switch (type) {
    case ResourcePeriodType.Availability:
      return resources.updateResourceAvailability({
        ...payload,
        openForOvertime,
        note,
      })
    case ResourcePeriodType.Absence:
      return resources.updateResourceAbsence({ ...payload, reason, note })
    default:
      return
  }
}

const changeResourceAccessibilityType = async (
  type: ResourcePeriodType,
  {
    id,
    startDate,
    endDate,
    note = '',
    isOpenForOvertime: openForOvertime = false,
    reason = AbsenceReason.Vacation,
    ...payload
  }: UpdateResourceAccessibilityDataPayload
) => {
  switch (type) {
    case ResourcePeriodType.Availability: {
      await resources.deleteResourceAbsence({ ...payload, id })
      return resources.createResourceAvailability({
        ...payload,
        startDate,
        endDate,
        note,
        openForOvertime,
      })
    }
    case ResourcePeriodType.Absence: {
      await resources.deleteResourceAvailability({ ...payload, id })
      return resources.createResourceAbsence({
        ...payload,
        startDate,
        endDate,
        note,
        reason,
      })
    }
    default:
      return
  }
}

export const updateResourceAccessibility = createAsyncThunkWithErrorHandling(
  `updateResourceAccessibility`,
  async ({
    originalType,
    type,
    ...payload
  }: UpdateResourceAccessibilityPayload) => {
    return originalType === type
      ? await updateResourceAccessibilityData(type, payload)
      : await changeResourceAccessibilityType(type, payload)
  }
)

export const deleteResourceAccessibility = createAsyncThunkWithErrorHandling(
  `deleteResourceAccessibility`,
  async ({ type, ...payload }: DeleteResourceAccessibilityPayload) => {
    if (type === ResourcePeriodType.Absence) {
      return await resources.deleteResourceAbsence(payload)
    } else if (type === ResourcePeriodType.Availability) {
      return await resources.deleteResourceAvailability(payload)
    }
  }
)

export const getSingleResourceAccessibilityTableInfo = createAsyncThunk(
  `getSingleResourceAccessibilityTableInfo`,
  (payload: GetAvailabilityTableInfoParams) =>
    resources.getAvailabilityTableInfo(payload)
)
