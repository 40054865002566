import { Components } from '@mui/material'

const MuiAvatar: Components['MuiAvatar'] = {
  styleOverrides: {
    root: { fontSize: '0.75rem' },
    colorDefault: { color: 'rgba(0,0,0,0.8)', backgroundColor: '#EBEBEB' },
  },
}

export default MuiAvatar
