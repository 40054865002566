import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import {
  AssignmentNotification,
  AssignmentStatus,
  NotificationType,
  SchedulerType,
} from '../../../../../types'
import { Badge } from '../../../../Badge'
import NotificationItem, { NotificationItemProps } from './NotificationItem'
import {
  AssignmentChip,
  BookingChip,
  MessageIcon,
  NotificationCell,
  NotificationRow,
} from './NotificationItem.style'
import {
  useRedirectionParams,
  useBookingSchedulePath,
} from './NotificationItem.utils'

const bookedStatuses = [
  NotificationType.NeedAssignmentBooked,
  NotificationType.NeedAssignmentRequestAccepted,
]

const useChipText = (
  type: NotificationType,
  assignmentExternalResourceName?: string
) => {
  const { t } = useTranslation()

  switch (type) {
    case NotificationType.NeedAssignmentCanceled:
      return t('notification.canceled')
    case NotificationType.NeedAssignmentResourceNoteAdded:
    case NotificationType.NeedAssignmentNoteAdded:
      return assignmentExternalResourceName
        ? t('notification.external')
        : t('notification.internal')
    default:
      return ''
  }
}

export interface AssignmentNotificationItemProps
  extends Partial<NotificationItemProps> {
  notification: AssignmentNotification
}

const AssignmentNotificationItem = ({
  notification: { id, data, isRead, type },
  ...props
}: AssignmentNotificationItemProps) => {
  const { t } = useTranslation()
  const {
    unitId,
    unitName,
    shiftName,
    assignmentStartDate,
    assignmentExternalResourceName,
    assignmentResourceId,
    assignmentResourceName,
    requestResourceId,
    requestResourceName,
    roleShortCode,
    assignmentStatus,
  } = data

  const { resource, unit } = useRedirectionParams({
    unitId,
    unitName,
    resourceId: assignmentResourceId || requestResourceId,
    resourceName: assignmentResourceName || requestResourceName,
  })

  const isAssignmentExternal =
    assignmentStatus === AssignmentStatus.BookedExternally

  const chipText = useChipText(type, assignmentExternalResourceName)
  const path = useBookingSchedulePath({
    unit,
    resource,
    date: assignmentStartDate,
    schedulerType: SchedulerType.Scheme,
  })

  const date = assignmentStartDate
    ? format(parseISO(assignmentStartDate), 'dd/MM')
    : ''

  return (
    <NotificationItem notificationId={id} path={path} {...props}>
      <NotificationRow container>
        <NotificationCell item xs={1} container justifyContent="center">
          {!isRead && <Badge notification />}
        </NotificationCell>
        <NotificationCell item xs={3}>
          <Typography component="p" variant="caption" noWrap>
            <strong>{unitName}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={1}>
          <Typography component="p" variant="caption" noWrap>
            <strong>{roleShortCode}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={3}>
          <Typography component="p" variant="caption" noWrap>
            {assignmentResourceName ||
              requestResourceName ||
              assignmentExternalResourceName}
            {[
              NotificationType.NeedAssignmentNoteAdded,
              NotificationType.NeedAssignmentResourceNoteAdded,
            ].includes(type) && <MessageIcon />}
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={2}>
          <Typography component="p" variant="caption" noWrap>
            {date}&nbsp;<strong>-&nbsp;{shiftName}</strong>
          </Typography>
        </NotificationCell>
        <NotificationCell item xs={2}>
          {bookedStatuses.includes(type) ? (
            <BookingChip
              externalResource={!!isAssignmentExternal}
              label={
                isAssignmentExternal
                  ? t('notification.external')
                  : t('notification.internal')
              }
            />
          ) : (
            <AssignmentChip
              label={chipText}
              assignmentStatus={assignmentStatus}
              notificationType={type}
            />
          )}
        </NotificationCell>
      </NotificationRow>
    </NotificationItem>
  )
}

export default AssignmentNotificationItem
