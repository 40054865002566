import { PropsWithChildren } from 'react'
import { useFilters, FiltersFormOptions } from '../hooks'
import Form from './Form'

export interface FiltersProps<FiltersValues = any, Payload = any>
  extends FiltersFormOptions<FiltersValues, Payload> {
  onSubmit?: (payload: Payload) => void
}

const Filters = <FiltersValues, Payload>({
  children,
  ...props
}: PropsWithChildren<FiltersProps<FiltersValues, Payload>>) => {
  const formProps = useFilters(props)
  return <Form {...formProps}>{children}</Form>
}

export default Filters
