import { styled, TableCell, TableCellProps } from '@mui/material'
import { TableHeadVariant } from '../DataTable.types'

export interface StyledTableCellProps extends Partial<TableCellProps> {
  nested: boolean
  tableHeadVariant?: TableHeadVariant
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({ theme, nested, tableHeadVariant }) => ({
    ...((nested || tableHeadVariant === 'light') && {
      backgroundColor: theme.palette.common.white,
      borderBottomWidth: 1,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    }),
  })
)
