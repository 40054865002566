import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface UseBreakDurationLabelProps {
  hasBreak?: boolean
  breakMinutes?: number
  hasMealBreak?: boolean
  mealBreakMinutes?: number
}

export const useBreakDurationLabel = ({
  hasBreak,
  breakMinutes,
  hasMealBreak,
  mealBreakMinutes,
}: UseBreakDurationLabelProps) => {
  const { t } = useTranslation()
  const label = useMemo(() => {
    const isBreakValueValid = hasBreak && typeof breakMinutes === 'number'
    const isMealBreakValueValid =
      hasMealBreak && typeof mealBreakMinutes === 'number'
    const minutesShortLabel = t('time.minutesShort')

    if (isBreakValueValid && isMealBreakValueValid) {
      return `${breakMinutes} + ${mealBreakMinutes} ${minutesShortLabel}`
    }
    if (isBreakValueValid) {
      return `${breakMinutes} ${minutesShortLabel}`
    }
    if (isMealBreakValueValid) {
      return `${mealBreakMinutes} ${minutesShortLabel}`
    }
    return ''
  }, [breakMinutes, hasBreak, hasMealBreak, mealBreakMinutes, t])

  return label
}
