import { useCallback, useMemo } from 'react'
import { Typography } from '@mui/material'
import { Shift } from '../../types'
import { trimSeconds } from '../../utils'
import Control from './Control'
import EventsTile from './EventsTile'
import {
  RowWrapper,
  Wrapper,
  ControlWrapper,
  ShiftName,
} from './sharedSchedulerStyles'
import {
  addRow,
  deleteRow,
  BulkActionTypes,
  Event,
  isSameWeekDay,
  isBeforeOrAfterRange,
  SelectedId,
} from './Scheduler.utils'

interface RowProps {
  id: number
  shift: Shift
  events: Event[]
  days: Date[]
  onChange: (events: Event[]) => void
  selectedDay: Date | null
  onHoverTypeChange: (type: BulkActionTypes | null) => void
  hoverType: BulkActionTypes | null
  showOnly?: boolean
  monthlyView?: boolean
  firstWeekInRow?: boolean
  startDate: Date
  endDate: Date
  rowStartDate?: Date
  rowEndDate?: Date
  defaultPillName?: string
  selectedId: SelectedId | null
  onShiftSelect: (props: SelectedId | null) => void
}

const Row = ({
  id,
  shift,
  events,
  days,
  onChange,
  selectedDay,
  onHoverTypeChange,
  hoverType,
  showOnly,
  monthlyView,
  firstWeekInRow,
  startDate,
  endDate,
  rowStartDate,
  rowEndDate,
  defaultPillName,
  selectedId,
  onShiftSelect,
}: RowProps) => {
  const shiftStart = useMemo(
    () => trimSeconds(shift.startTime),
    [shift.startTime]
  )
  const shiftEnd = useMemo(() => trimSeconds(shift.endTime), [shift.endTime])

  const onHover = useCallback(
    () => onShiftSelect({ shiftId: shift.id, rowId: id }),
    [id, onShiftSelect, shift.id]
  )

  const onLeave = useCallback(() => onShiftSelect(null), [onShiftSelect])

  const onControlAddHover = useCallback(
    () => onHoverTypeChange('add'),
    [onHoverTypeChange]
  )

  const onControlRemoveHover = useCallback(
    () => onHoverTypeChange('remove'),
    [onHoverTypeChange]
  )

  const onControlLeave = useCallback(
    () => onHoverTypeChange(null),
    [onHoverTypeChange]
  )

  const addEventsRow = useCallback(() => {
    onChange(
      addRow({
        events,
        days,
        shift,
        startDate,
        endDate,
        rowStartDate,
        rowEndDate,
        monthlyView,
        defaultPillName,
      })
    )
  }, [
    onChange,
    events,
    days,
    shift,
    startDate,
    endDate,
    rowStartDate,
    rowEndDate,
    monthlyView,
    defaultPillName,
  ])

  const deleteEventsRow = useCallback(
    () =>
      onChange(
        deleteRow({
          events,
          days,
          shift,
          startDate,
          endDate,
          rowStartDate,
          rowEndDate,
          isMonthlyView: monthlyView,
        })
      ),
    [
      onChange,
      events,
      days,
      shift,
      startDate,
      endDate,
      rowStartDate,
      rowEndDate,
      monthlyView,
    ]
  )

  const isSelected = useMemo(
    () => selectedId?.shiftId === shift.id && selectedId?.rowId === id,
    [selectedId?.shiftId, selectedId?.rowId, shift.id, id]
  )

  const shouldShowControls = useMemo(
    () => !showOnly && isSelected,
    [showOnly, isSelected]
  )

  return (
    <RowWrapper>
      {firstWeekInRow && (
        <Wrapper onMouseEnter={onHover} onMouseLeave={onLeave}>
          <ShiftName>{shift.name}</ShiftName>
          <div>
            <Typography variant="caption">
              {shiftStart} - {shiftEnd}
            </Typography>
          </div>
          {shouldShowControls && (
            <ControlWrapper>
              <Control
                type="remove"
                onMouseEnter={onControlRemoveHover}
                onMouseLeave={onControlLeave}
                onClick={deleteEventsRow}
              />
              <Control
                type="add"
                onMouseEnter={onControlAddHover}
                onMouseLeave={onControlLeave}
                onClick={addEventsRow}
              />
            </ControlWrapper>
          )}
        </Wrapper>
      )}
      {days.map(day => (
        <EventsTile
          key={`events-${shift.name}-${String(day)}`}
          events={events}
          shift={shift}
          day={day}
          onChange={onChange}
          highlight={isSelected || isSameWeekDay(selectedDay, day)}
          preview={hoverType}
          showOnly={showOnly}
          disabled={isBeforeOrAfterRange(day, startDate, endDate)}
          monthlyView={monthlyView}
          defaultPillName={defaultPillName}
        />
      ))}
    </RowWrapper>
  )
}

export default Row
