import { Suspense, useEffect, useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  createTheme,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SnackbarProvider } from 'notistack'
import config, { themeOptions } from 'config'
import {
  Layout,
  Loader,
  AuthProvider,
  SupportWidgetButton,
} from 'shared/components'
import { useDispatch, useSelector } from 'shared/hooks'
import { actions, selectors } from 'shared/store'
import {
  NotificationsProvider,
  NewVersionListener,
  routes,
  SettingsProvider,
  AdminMessagesProvider,
} from './App.setup'
import { dateFnsLocaleMap, muiLocaleMap } from './App.utils'

const [directRoutes, modalRoutes] = routes
const notificationsElement = document.getElementById('notifications')

const Shell = () => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(selectors.settings.getLanguage)
  const themeWithLocale = useMemo(
    () => createTheme(themeOptions, muiLocaleMap[selectedLanguage]),
    [selectedLanguage]
  )

  useEffect(() => {
    dispatch(actions.settings.setupLanguage())
  }, [dispatch])

  return (
    <SnackbarProvider
      maxSnack={config.maxSnackbarsCount}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      {...(notificationsElement && { domRoot: notificationsElement })}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeWithLocale}>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={dateFnsLocaleMap[selectedLanguage]}
            >
              <NewVersionListener />
              <SupportWidgetButton />
              <BrowserRouter>
                <AuthProvider />
                <SettingsProvider />
                <NotificationsProvider />
                <AdminMessagesProvider />
                <Layout routes={directRoutes} modalRoutes={modalRoutes} />
              </BrowserRouter>
            </LocalizationProvider>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  )
}

export default Shell
