import { ReactNode, useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Checkbox, FormControlLabelProps } from '@mui/material'
import { identity } from '../../utils'
import { StyledFormControlLabel } from './SelectAllCheckboxField.styles'

interface Option {
  label: string
  value: any
}

export type SelectAllCheckboxFieldProps = Partial<FormControlLabelProps> & {
  label: ReactNode
  selectAllFor: string
  options?: Option[]
  indeterminate?: boolean
  disableClickOnLabel?: boolean
  getOptionId?: (value: any) => any
}

const SelectAllCheckboxField = ({
  label,
  selectAllFor,
  indeterminate = false,
  checked = false,
  options = [],
  disableClickOnLabel = false,
  getOptionId = identity,
  ...props
}: SelectAllCheckboxFieldProps) => {
  const { control, setValue } = useFormContext()
  const [isChecked, setIsChecked] = useState(checked)
  const [isIndeterminate, setIsIndeterminate] = useState(indeterminate)
  const fieldValue: Option[] = useWatch({ control, name: selectAllFor })

  useEffect(() => {
    const isEveryChecked =
      fieldValue.length > 0 &&
      options.every(option =>
        fieldValue.some(val => getOptionId(option.value) === getOptionId(val))
      )
    setIsChecked(isEveryChecked)
    setIsIndeterminate(
      !isEveryChecked &&
        (fieldValue.some(val =>
          options.some(option => getOptionId(option.value) === getOptionId(val))
        ) ||
          indeterminate)
    )
  }, [fieldValue, options, setValue, getOptionId, indeterminate])

  const handleSelectAll = () => {
    setIsChecked(checked => !checked)
    const filteredValues = fieldValue.filter(
      val =>
        !options.some(option => getOptionId(option.value) === getOptionId(val))
    )
    setValue(
      selectAllFor,
      isChecked
        ? filteredValues
        : [...filteredValues, ...options.map(v => v.value)],
      { shouldDirty: true }
    )
  }

  return (
    <StyledFormControlLabel
      label={label}
      onChange={handleSelectAll}
      checked={isChecked}
      disableClickOnLabel={disableClickOnLabel}
      control={
        <Checkbox
          color="primary"
          indeterminate={isIndeterminate}
          size="small"
        />
      }
      {...props}
    />
  )
}

export default SelectAllCheckboxField
