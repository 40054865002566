import {
  Tooltip as BaseTooltip,
  TooltipProps as BaseTooltipProps,
} from '@mui/material'

interface TooltipProps extends BaseTooltipProps {
  disabled?: boolean
}

const Tooltip = ({ children, disabled, ...props }: TooltipProps) => {
  return (
    <BaseTooltip
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      disableFocusListener={disabled}
      {...props}
    >
      <div>{children}</div>
    </BaseTooltip>
  )
}

export default Tooltip
