import { alpha, Grid, styled, Typography, IconButton } from '@mui/material'
import CheckboxField from '../CheckboxField'
import RadioField from '../RadioField'
import SelectAllCheckboxField from '../SelectAllCheckboxField'
import TextAreaField from '../TextAreaField'

export const PopoverContentWrapper = styled('div')(({ theme }) => ({
  width: 470,
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2.5, 3),
  overflowY: 'auto',
  maxHeight: 600,
  [theme.breakpoints.down('maxLaptop')]: {
    maxHeight: 520,
  },
  boxShadow: `0px 15px 12px ${alpha(
    theme.palette.common.black,
    0.3
  )}, 0px 19px 38px ${alpha(theme.palette.common.black, 0.3)}`,
}))

export const Section = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  padding: theme.spacing(0.25, 0),
  borderRadius: theme.spacing(0.5),
}))

interface StyledCheckboxFieldProps {
  labelColorInherited?: boolean
  overtimeLabel?: string
}

export const StyledCheckboxField = styled(
  CheckboxField
)<StyledCheckboxFieldProps>(
  ({ theme, labelColorInherited, overtimeLabel }) => ({
    '& .MuiFormControlLabel-label': {
      fontWeight: 500,
      fontSize: '0.75rem',
      marginLeft: theme.spacing(0.5),
      color: labelColorInherited ? 'inherit' : 'default',
    },
    '& .MuiButtonBase-root': {
      padding: theme.spacing(0.5, 0.25, 0.5, 3),
    },
    '& .MuiTouchRipple-root': {
      marginLeft: theme.spacing(3.5),
    },
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 300,
    },
    ...(!!overtimeLabel && {
      '&:after': {
        marginLeft: theme.spacing(2),
        content: `'${overtimeLabel}'`,
        color: theme.palette.grey[700],
        fontWeight: 500,
      },
    }),
  })
)

interface CheckboxContainerProps {
  error?: boolean
  opaque?: boolean
}

export const CheckboxContainer = styled(Grid)<CheckboxContainerProps>(
  ({ theme, error, opaque }) => ({
    padding: theme.spacing(0.25, 0),
    borderRadius: theme.spacing(0.5),
    ...(!opaque && {
      backgroundColor: theme.palette.neutral.main,
    }),
    ...(opaque && {
      border: `1px solid ${alpha(theme.palette.common.black, 0.4)}`,
    }),
    ...(error && {
      border: `1px solid ${theme.palette.error.main}`,
    }),
    maxHeight: 200,
    overflowY: 'scroll',
  })
)

export const StyledSelectAllCheckboxField = styled(SelectAllCheckboxField)({
  '&.MuiFormControlLabel-root': {
    margin: 0,
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '0.75rem',
  },
})

export const ErrorWrapper = styled(Typography)(({ theme }) => ({
  width: '100%',
  color: theme.palette.error.main,
  fontSize: '0.75rem',
}))

export const StyledRadioField = styled(RadioField)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
  },
  '& .MuiButtonBase-root': {
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
}))

export const StyledTextAreaField = styled(TextAreaField)(({ theme }) => ({
  '& .MuiInput-multiline': {
    borderRadius: theme.spacing(0.5),
    boxShadow: 'none',
    backgroundColor: theme.palette.neutral.main,
    padding: theme.spacing(0.5, 1),
  },
  '& .MuiInputBase-focused': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: 0,
}))
