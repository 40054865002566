import { useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useAuth, useAssignmentsDatesText } from '../../../hooks'
import { AssignmentStatus, ShiftType, UserRole } from '../../../types'
import {
  AssignmentStateSectionFields,
  AssignmentStateSectionValues,
} from './AssignmentStateSection'
import {
  ResourcesSectionFields,
  ResourcesSectionValues,
} from './CPResourcesSection'
import { useModalProps } from '../AssignmentModal.utils'

export const TITLELESS_STATUSES = [
  AssignmentStatus.Available,
  AssignmentStatus.Bookable,
  AssignmentStatus.Requestable,
]

export enum RequestedResourceStatus {
  Requested = 'requested',
  Rejected = 'rejected',
  Requestable = 'requestable',
}

export const WATCHABLE_STATE_FIELDS = [
  AssignmentStateSectionFields.IsClosed,
  AssignmentStateSectionFields.IsResolvedInternally,
  AssignmentStateSectionFields.HasExternalResource,
  AssignmentStateSectionFields.HasNoResource,
]

export const useFieldsDisabling = (
  resourcesSectionControl: Control<ResourcesSectionValues, object>,
  stateSectionControl: Control<AssignmentStateSectionValues, object>
) => {
  const { hasAllowedRole } = useAuth()
  const { needAssignment } = useModalProps()
  const isUnitAdmin = hasAllowedRole([UserRole.UnitAdmin])
  const [bookedResource, requestedResources] = useWatch({
    control: resourcesSectionControl,
    name: [
      ResourcesSectionFields.BookedResource,
      ResourcesSectionFields.RequestedResources,
    ],
  })
  const stateFields = useWatch({
    control: stateSectionControl,
    name: WATCHABLE_STATE_FIELDS,
  })
  const { assignmentIds } = useWatch<AssignmentStateSectionValues>({
    control: stateSectionControl,
  })
  const enableResourcesSection =
    assignmentIds?.length === 1 &&
    assignmentIds[0] === needAssignment?.id &&
    !isUnitAdmin

  return {
    enableResourcesSection,
    disabledBooking: stateFields.some(field => field === true) || isUnitAdmin,
    disabledState:
      (requestedResources as string[]).length > 0 || bookedResource !== '',
  }
}

export const useModalTitle = () => {
  const { data, needAssignment } = useModalProps()
  const { t } = useTranslation()

  const date = useMemo(
    () => (data && data?.shiftDate ? format(data.shiftDate, 'dd/MM') : ''),
    [data]
  )
  const name = useMemo(
    () => (!!needAssignment ? needAssignment.shift.name : ''),
    [needAssignment]
  )

  const titleStatus = useMemo(
    () =>
      needAssignment?.status &&
      TITLELESS_STATUSES.includes(needAssignment?.status)
        ? AssignmentStatus.Empty
        : needAssignment?.status,
    [needAssignment?.status]
  )

  const isOnOpenShift = useMemo(
    () => needAssignment?.type && needAssignment?.type === ShiftType.Open,
    [needAssignment?.type]
  )

  const assignmentsDatesText = useAssignmentsDatesText(
    needAssignment?.startDate,
    needAssignment?.endDate
  )

  const title = useMemo(
    () =>
      isOnOpenShift
        ? `${date}: ${assignmentsDatesText}`
        : `${date} - ${name}: ${t(`scheduler.status.${titleStatus}`)}`,
    [assignmentsDatesText, date, isOnOpenShift, name, t, titleStatus]
  )

  return { title, isOnOpenShift }
}
