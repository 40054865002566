import { useMemo } from 'react'
import { useQuery } from './useQuery'

export const useNeedIds = () => {
  const { needIds } = useQuery()
  return useMemo(
    () => (Array.isArray(needIds) ? needIds.map(Number) : []),
    [needIds]
  )
}
