import { Chip, styled } from '@mui/material'

const ACCOUNT_STATUS_CHIP_HEIGHT = 20

interface AccountStatusChipProps {
  blocked?: boolean
}

export const AccountStatusChip = styled(Chip)<AccountStatusChipProps>(
  ({ theme, blocked }) => ({
    height: ACCOUNT_STATUS_CHIP_HEIGHT,
    ...(blocked && {
      background: theme.palette.pill.red.dark,
      color: theme.palette.common.white,
    }),
    fontSize: '0.75rem',
    '& .MuiChip-label': {
      margin: theme.spacing(0, 1),
    },
  })
)
