import {
  eachWeekOfInterval,
  eachDayOfInterval,
  getISOWeek,
  subSeconds,
  format,
  getDay,
  isSameDay,
  isAfter,
  isBefore,
  addWeeks,
} from 'date-fns'
import { DateFnsOptions, Shift } from '../../types'

export enum SchedulerView {
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export type BulkActionTypes = 'add' | 'remove'

export interface Event {
  name?: string
  shift: Shift
  date: Date
  color?: string
}

export interface Week {
  number: number
  start: Date
  end: Date
}

export interface SelectedId {
  rowId: number
  shiftId: number
}

export const WEEKS_IN_ROW = 5

interface GetWeekOptions {
  isViewMonthly?: boolean
  dateOptions?: DateFnsOptions
}

export const getWeeks = (
  start: Date,
  end: Date,
  { dateOptions, isViewMonthly }: GetWeekOptions
): Week[] => {
  const numberOfWeeks = eachWeekOfInterval({ start, end }, dateOptions).length
  const weeksToAdd = WEEKS_IN_ROW - (numberOfWeeks % WEEKS_IN_ROW)
  const endDate = isViewMonthly ? addWeeks(end, weeksToAdd) : end

  return eachWeekOfInterval({ start, end: endDate }, dateOptions).map(date => ({
    number: getISOWeek(date),
    start: date,
    end: subSeconds(addWeeks(date, 1), 1),
  }))
}

export const getWeekDays = (start: Date, end: Date) =>
  eachDayOfInterval({ start, end })

export const getMonthNameByWeek = (
  weekStart: Date,
  weekEnd: Date,
  dateOptions?: DateFnsOptions
) => {
  const weekStartMonth = format(weekStart, 'LLLL', dateOptions)
  const weekEndMonth = format(weekEnd, 'LLLL', dateOptions)
  return weekStartMonth === weekEndMonth
    ? weekStartMonth
    : `${weekStartMonth}/${weekEndMonth}`
}

export const getEventsForSpecificShift = (
  events: Event[],
  shift: Shift,
  day: Date
): Event[] =>
  events.filter(
    event => event.shift.id === shift.id && isSameDay(event.date, day)
  )

export const removeOneEvent = (events: Event[], event: Event) => {
  const index = events.findIndex(
    e => e.date === event.date && e.shift.id === event.shift.id
  )
  return [...events.slice(0, index), ...events.slice(index + 1)]
}

interface AddRowOptions {
  events: Event[]
  days: Date[]
  shift: Shift
  startDate: Date
  endDate: Date
  rowStartDate?: Date
  rowEndDate?: Date
  monthlyView?: boolean
  defaultPillName?: string
}

export const addRow = ({
  events,
  days,
  shift,
  startDate,
  endDate,
  rowStartDate,
  rowEndDate,
  monthlyView,
  defaultPillName,
}: AddRowOptions) => [
  ...events,
  ...(monthlyView
    ? eachDayOfInterval({
        start: rowStartDate
          ? isBefore(rowStartDate, startDate)
            ? startDate
            : rowStartDate
          : startDate,
        end: rowEndDate
          ? isAfter(rowEndDate, endDate)
            ? endDate
            : rowEndDate
          : endDate,
      })
    : days.filter(day => !isBeforeOrAfterRange(day, startDate, endDate))
  ).map(day => ({
    shift,
    name: events[0]?.name || defaultPillName,
    date: day,
  })),
]

interface AddColOptions {
  events: Event[]
  day: Date
  shifts: Shift[]
  defaultPillName?: string
}

export const addCol = ({
  events,
  day,
  shifts,
  defaultPillName,
}: AddColOptions) => [
  ...events,
  ...shifts.map(shift => ({
    shift,
    date: day,
    name: events[0]?.name || defaultPillName,
  })),
]

interface DeleteRowOptions {
  events: Event[]
  days: Date[]
  shift: Shift
  isMonthlyView?: boolean
  startDate: Date
  endDate: Date
  rowStartDate?: Date
  rowEndDate?: Date
}

export const deleteRow = ({
  events,
  days,
  shift,
  isMonthlyView,
  startDate,
  endDate,
  rowStartDate,
  rowEndDate,
}: DeleteRowOptions) => {
  const indexes = (
    isMonthlyView
      ? eachDayOfInterval({
          start: rowStartDate
            ? isAfter(startDate, rowStartDate)
              ? startDate
              : rowStartDate
            : startDate,
          end: rowEndDate
            ? isBefore(endDate, rowEndDate)
              ? endDate
              : rowEndDate
            : endDate,
        })
      : days
  )
    .map(day =>
      events.findIndex(
        event => isSameDay(event.date, day) && event.shift.name === shift.name
      )
    )
    .filter(index => index > -1)

  return [
    ...indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .reduce((acc, index) => {
        acc.splice(index, 1)
        return acc
      }, events),
  ]
}

export const deleteCol = (
  events: Event[],
  day: Date,
  shifts: Shift[]
): Event[] => {
  const indexes = shifts
    .map(shift =>
      events.findIndex(
        event => isSameDay(event.date, day) && event.shift.name === shift.name
      )
    )
    .filter(index => index > -1)

  return [
    ...indexes
      .sort((a, b) => (a > b ? -1 : 1))
      .reduce((acc, index) => {
        acc.splice(index, 1)
        return acc
      }, events),
  ]
}

export const isSameWeekDay = (selectedDay: Date | null, day: Date) => {
  if (!selectedDay) return false
  const selectedDayNumber = getDay(selectedDay)
  const dayNumber = getDay(day)
  return selectedDayNumber === dayNumber
}

export const isBeforeOrAfterRange = (
  currentDay: Date,
  start: Date,
  end: Date
): boolean => isBefore(currentDay, start) || isAfter(currentDay, end)
