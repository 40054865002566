import { useEffect } from 'react'
import config from 'config'
import { actions } from '../store'
import {
  AutocompleteValue,
  CalendarView,
  GetStartEndDatesParams,
} from '../types'
import { useAppDispatch as useDispatch } from './redux'
import { useNeedIds } from './useNeedIds'
import { useOrganizationId } from './useOrganization'
import { useQuery } from './useQuery'
import { useQueryDate } from './useQueryDate'
import { useUnitId } from './useUnitId'

type QueryParams = {
  resource: AutocompleteValue
  viewName?: CalendarView
}

type GetSchedulesDatesArgs = (params: GetStartEndDatesParams) => {
  dateFrom: Date
  dateTo: Date
}

export const useSchedulesNotificationsListener = (
  getSchedulesDates: GetSchedulesDatesArgs
) => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const unitId = useUnitId()
  const needIds = useNeedIds()

  const { resource, viewName } = useQuery<QueryParams>()
  const date = useQueryDate()

  const { dateFrom, dateTo } = getSchedulesDates({ date, viewName })
  const getSchedulesUnreadNotifications = () => {
    if (!dateFrom || !dateTo) return
    dispatch(
      actions.notifications.getSchedulesUnreadNotifications({
        careProviderId,
        dateFrom,
        dateTo,
        unitId,
        needIds,
        resourceId: resource?.value,
      })
    )
  }

  useEffect(() => {
    getSchedulesUnreadNotifications()
  }, [date, viewName, unitId, needIds?.length, resource?.value]) // eslint-disable-line

  useEffect(() => {
    const interval =
      careProviderId && dateFrom && dateTo
        ? setInterval(
            getSchedulesUnreadNotifications,
            config.notificationsListenerInterval
          )
        : null

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    careProviderId,
    dateFrom,
    dateTo,
    unitId,
    needIds?.length,
    resource?.value,
  ])
}
