import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { GetResourceSchedulesParams } from 'shared/services/resources'
import { GetSchedulesSummariesParams } from 'shared/services/schedules'
import {
  ResourceSchedule,
  ResourceStatistics,
  NeedAssignmentsStatistics,
  AssignmentRequest,
  AssignmentSummary,
  NeedSummary,
  ResourceSummary,
  ShiftSummary,
  UnitNeedsSummary,
  NeedAssignmentNote,
} from 'shared/types'
import {
  getSchedulesSummaries,
  getResourceSchedules,
  resetGetResourceSchedules,
  changeAssignmentState,
  getResourceStatistics,
  getNeedAssignmentsStatistics,
  acceptRequest,
  rejectRequest,
  modifyBookedAssignment,
  deleteAvailabilities,
  resetDeleteAvailabilities,
  resetGetSchedulesSummaries,
  assignAssignmentToResources,
  resetModifyBookedAssignment,
  resetChangeAssignmentState,
  addResourceNoteToAssignment,
  getNeedsSummaries,
  resetGetNeedsSummaries,
  getResourceSummaries,
  resetGetResourceSummaries,
  getShiftsSummaries,
  resetGetShiftsSummaries,
  getUnitNeedsSummaries,
  exportSchedules,
  resetExportSchedules,
  getAssignmentNotesHistory,
  resetAssignmentNotesHistory,
  refreshGetResourceSchedules,
} from './actions'
import {
  mapResourceSchedulesAfterAssignmentStateChange,
  mapResourceSchedulesAfterBookedAssignmentChange,
} from './utils'
import {
  bookAssignment,
  getRequest,
  getResourceScheduleWithActions,
  rejectAssignmentRequest,
} from './reducer.utils'

export type RequestWithActions = AssignmentRequest & {
  acceptRequest: Resource
  rejectRequest: Resource
}
export interface ResourceScheduleWithActions extends ResourceSchedule {
  requests: RequestWithActions[]
}

export interface State {
  getSchedulesSummaries: Resource<
    AssignmentSummary[],
    GetSchedulesSummariesParams
  >
  getResourceSchedules: Resource<
    ResourceScheduleWithActions[],
    GetResourceSchedulesParams
  >
  refreshGetResourceSchedules: Resource<
    ResourceScheduleWithActions[],
    GetResourceSchedulesParams
  >
  modifyBookedAssignment: Resource<string>
  changeAssignmentState: Resource<string>
  getResourceStatistics: Resource<ResourceStatistics>
  getNeedAssignmentsStatistics: Resource<NeedAssignmentsStatistics>
  deleteAvailabilities: Resource
  assignAssignmentToResources: Resource<string>
  addResourceNoteToAssignment: Resource<number>
  getNeedsSummaries: Resource<NeedSummary[]>
  getUnitNeedsSummaries: Resource<UnitNeedsSummary[]>
  getResourceSummaries: Resource<ResourceSummary[]>
  getShiftsSummaries: Resource<ShiftSummary[]>
  exportSchedules: Resource<ArrayBuffer>
  getAssignmentNotesHistory: Resource<NeedAssignmentNote[]>
}

const initialState: State = {
  getSchedulesSummaries: resource.getInitial<AssignmentSummary[]>([]),
  getResourceSchedules: resource.getInitial<ResourceScheduleWithActions[]>([]),
  refreshGetResourceSchedules: resource.getInitial<
    ResourceScheduleWithActions[]
  >([]),
  modifyBookedAssignment: resource.getInitial(''),
  changeAssignmentState: resource.getInitial(''),
  getResourceStatistics: resource.getInitial<ResourceStatistics>(),
  getNeedAssignmentsStatistics:
    resource.getInitial<NeedAssignmentsStatistics>(),
  deleteAvailabilities: resource.getInitial(),
  assignAssignmentToResources: resource.getInitial<string>(''),
  addResourceNoteToAssignment: resource.getInitial<number>(),
  getNeedsSummaries: resource.getInitial<NeedSummary[]>([]),
  getUnitNeedsSummaries: resource.getInitial<UnitNeedsSummary[]>([]),
  getResourceSummaries: resource.getInitial<ResourceSummary[]>([]),
  getShiftsSummaries: resource.getInitial<ShiftSummary[]>([]),
  exportSchedules: resource.getInitial<ArrayBuffer>(),
  getAssignmentNotesHistory: resource.getInitial<NeedAssignmentNote[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getSchedulesSummaries.pending, (state, action) => {
      resource.setPending(state.getSchedulesSummaries, action.meta.arg)
    })
    .addCase(getSchedulesSummaries.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSchedulesSummaries, action.payload)
    })
    .addCase(getSchedulesSummaries.rejected, (state, action) => {
      resource.setFailed(state.getSchedulesSummaries, action.error.message)
    })
    .addCase(getResourceSchedules.pending, (state, action) => {
      resource.setPending(state.getResourceSchedules, action.meta.arg)
    })
    .addCase(getResourceSchedules.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getResourceSchedules,
        getResourceScheduleWithActions(action.payload)
      )
    })
    .addCase(getResourceSchedules.rejected, (state, action) => {
      resource.setFailed(state.getResourceSchedules, action.error.message)
    })
    .addCase(refreshGetResourceSchedules.pending, (state, action) => {
      resource.setPending(state.refreshGetResourceSchedules, action.meta.arg)
    })
    .addCase(refreshGetResourceSchedules.fulfilled, (state, action) => {
      resource.setSucceeded(state.refreshGetResourceSchedules)
      resource.setSucceeded(
        state.getResourceSchedules,
        getResourceScheduleWithActions(action.payload)
      )
    })
    .addCase(refreshGetResourceSchedules.rejected, (state, action) => {
      resource.setFailed(
        state.refreshGetResourceSchedules,
        action.error.message
      )
    })
    .addCase(resetGetResourceSchedules, state => {
      resource.reset(
        state.getResourceSchedules,
        initialState.getResourceSchedules.data
      )
    })
    .addCase(changeAssignmentState.pending, state => {
      resource.setPending(state.changeAssignmentState)
    })
    .addCase(changeAssignmentState.fulfilled, (state, action) => {
      resource.setSucceeded(state.changeAssignmentState, action.payload)
      state.getResourceSchedules.data =
        mapResourceSchedulesAfterAssignmentStateChange(
          state.getResourceSchedules.data,
          action.meta.arg.params
        )
    })
    .addCase(changeAssignmentState.rejected, (state, action) => {
      resource.setFailed(state.changeAssignmentState, action.error.message)
    })
    .addCase(resetChangeAssignmentState, state => {
      resource.reset(
        state.changeAssignmentState,
        initialState.changeAssignmentState.data
      )
    })
    .addCase(modifyBookedAssignment.pending, state => {
      resource.setPending(state.modifyBookedAssignment)
    })
    .addCase(modifyBookedAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.modifyBookedAssignment, action.payload)
      state.getResourceSchedules.data =
        mapResourceSchedulesAfterBookedAssignmentChange(
          state.getResourceSchedules.data,
          action.meta.arg.params
        )
    })
    .addCase(modifyBookedAssignment.rejected, (state, action) => {
      resource.setFailed(state.modifyBookedAssignment, action.error.message)
    })
    .addCase(resetModifyBookedAssignment, state => {
      resource.reset(state.modifyBookedAssignment)
    })

    .addCase(getResourceStatistics.pending, state => {
      resource.setPending(state.getResourceStatistics)
    })
    .addCase(getResourceStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.getResourceStatistics, action.payload)
    })
    .addCase(getResourceStatistics.rejected, (state, action) => {
      resource.setFailed(state.getResourceStatistics, action.error.message)
    })
    .addCase(getNeedAssignmentsStatistics.pending, state => {
      resource.setPending(state.getNeedAssignmentsStatistics)
    })
    .addCase(getNeedAssignmentsStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.getNeedAssignmentsStatistics, action.payload)
    })
    .addCase(getNeedAssignmentsStatistics.rejected, (state, action) => {
      resource.setFailed(
        state.getNeedAssignmentsStatistics,
        action.error.message
      )
    })
    .addCase(acceptRequest.pending, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) resource.setPending(request.acceptRequest)
    })
    .addCase(acceptRequest.fulfilled, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) {
        resource.setSucceeded(request.acceptRequest)
        bookAssignment(requestId, state)
      }
    })
    .addCase(acceptRequest.rejected, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request)
        resource.setFailed(request.acceptRequest, action.error.message)
    })
    .addCase(rejectRequest.pending, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) resource.setPending(request.rejectRequest)
    })
    .addCase(rejectRequest.fulfilled, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) {
        resource.setSucceeded(request.rejectRequest)
        rejectAssignmentRequest(requestId, state)
      }
    })
    .addCase(rejectRequest.rejected, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) {
        resource.setFailed(request.rejectRequest, action.error.message)
      }
    })
    .addCase(deleteAvailabilities.pending, state => {
      resource.setPending(state.deleteAvailabilities)
    })
    .addCase(deleteAvailabilities.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteAvailabilities, action.payload)
    })
    .addCase(deleteAvailabilities.rejected, (state, action) => {
      resource.setFailed(state.deleteAvailabilities, action.error.message)
    })
    .addCase(resetDeleteAvailabilities, state => {
      resource.reset(state.deleteAvailabilities)
    })
    .addCase(resetGetSchedulesSummaries, state => {
      resource.reset(
        state.getSchedulesSummaries,
        initialState.getSchedulesSummaries.data
      )
    })
    .addCase(assignAssignmentToResources.pending, state => {
      resource.setPending(state.assignAssignmentToResources)
    })
    .addCase(assignAssignmentToResources.fulfilled, (state, action) => {
      resource.setSucceeded(state.assignAssignmentToResources, action.payload)
    })
    .addCase(assignAssignmentToResources.rejected, (state, action) => {
      resource.setFailed(
        state.assignAssignmentToResources,
        action.error.message
      )
    })
    .addCase(addResourceNoteToAssignment.pending, (state, action) => {
      resource.setPending(state.addResourceNoteToAssignment, action.meta.arg)
    })
    .addCase(addResourceNoteToAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.addResourceNoteToAssignment, action.payload)
      const scheduleAssignment = state.getResourceSchedules.data
        .flatMap(({ assignments }) => assignments)
        .find(({ id }) => action.meta.arg.params.assignmentId === id)

      if (!scheduleAssignment) return

      scheduleAssignment.resourceNote = action.meta.arg.params.resourceNote
    })

    .addCase(addResourceNoteToAssignment.rejected, (state, action) => {
      resource.setFailed(
        state.addResourceNoteToAssignment,
        action.error.message
      )
    })
    .addCase(getNeedsSummaries.pending, (state, action) => {
      resource.setPending(state.getNeedsSummaries, action.meta.arg)
    })
    .addCase(getNeedsSummaries.fulfilled, (state, action) => {
      resource.setSucceeded(state.getNeedsSummaries, action.payload)
    })
    .addCase(getNeedsSummaries.rejected, (state, action) => {
      resource.setFailed(state.getNeedsSummaries, action.error.message)
    })
    .addCase(getUnitNeedsSummaries.pending, (state, action) => {
      resource.setPending(state.getUnitNeedsSummaries, action.meta.arg)
    })
    .addCase(getUnitNeedsSummaries.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUnitNeedsSummaries, action.payload)
    })
    .addCase(getUnitNeedsSummaries.rejected, (state, action) => {
      resource.setFailed(state.getUnitNeedsSummaries, action.error.message)
    })
    .addCase(resetGetNeedsSummaries, state => {
      resource.reset(
        state.getNeedsSummaries,
        initialState.getNeedsSummaries.data
      )
    })
    .addCase(getResourceSummaries.pending, (state, action) => {
      resource.setPending(state.getResourceSummaries, action.meta.arg)
    })
    .addCase(getResourceSummaries.fulfilled, (state, action) => {
      resource.setSucceeded(state.getResourceSummaries, action.payload)
    })
    .addCase(getResourceSummaries.rejected, (state, action) => {
      resource.setFailed(state.getResourceSummaries, action.error.message)
    })
    .addCase(resetGetResourceSummaries, state => {
      resource.reset(
        state.getResourceSummaries,
        initialState.getResourceSummaries.data
      )
    })
    .addCase(getShiftsSummaries.pending, (state, action) => {
      resource.setPending(state.getShiftsSummaries, action.meta.arg)
    })
    .addCase(getShiftsSummaries.fulfilled, (state, action) => {
      resource.setSucceeded(state.getShiftsSummaries, action.payload)
    })
    .addCase(getShiftsSummaries.rejected, (state, action) => {
      resource.setFailed(state.getShiftsSummaries, action.error.message)
    })
    .addCase(resetGetShiftsSummaries, state => {
      resource.reset(
        state.getShiftsSummaries,
        initialState.getShiftsSummaries.data
      )
    })
    .addCase(exportSchedules.pending, state => {
      resource.setPending(state.exportSchedules)
    })
    .addCase(exportSchedules.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportSchedules, action.payload)
    })
    .addCase(exportSchedules.rejected, (state, action) => {
      resource.setFailed(state.exportSchedules, action.error.message)
    })
    .addCase(resetExportSchedules, state => {
      resource.reset(state.exportSchedules, initialState.exportSchedules.data)
    })
    .addCase(getAssignmentNotesHistory.pending, state => {
      resource.setPending(state.getAssignmentNotesHistory)
    })
    .addCase(getAssignmentNotesHistory.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getAssignmentNotesHistory,
        action.payload.data
      )
    })
    .addCase(getAssignmentNotesHistory.rejected, (state, action) => {
      resource.setFailed(state.getAssignmentNotesHistory, action.error.message)
    })
    .addCase(resetAssignmentNotesHistory, state => {
      resource.reset(state.getAssignmentNotesHistory)
    })
)
