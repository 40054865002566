import { useEffect, useMemo } from 'react'
import {
  Control,
  FieldValues,
  Path,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form'
import { AutocompleteValue } from '../types'
import {
  useGetRoleOptions,
  useGetSpecialtyOptions,
} from './useAutocompleteOptions'
import { usePrevious } from './usePrevious'

interface UseRoleAndSpecialtiesFieldsParams<T> {
  roleFieldName: Path<T>
  specialtyFieldName: Path<T>
  setValue: UseFormSetValue<T>
  control: Control<T, object>
  isSpecialtiesFieldMultiple?: boolean
  unitId?: number
}

export const useRoleAndSpecialtiesFields = <T extends FieldValues>({
  roleFieldName,
  specialtyFieldName,
  setValue,
  control,
  isSpecialtiesFieldMultiple = false,
  unitId,
}: UseRoleAndSpecialtiesFieldsParams<T>) => {
  const role = useWatch<T>({
    control,
    name: roleFieldName,
  }) as unknown as AutocompleteValue
  const prevRole = usePrevious(role)
  useEffect(() => {
    const specialtiesDefaultValue = isSpecialtiesFieldMultiple ? [] : null
    if (prevRole?.value !== role?.value) {
      //@ts-ignore
      setValue(specialtyFieldName, specialtiesDefaultValue)
    }
  }, [
    setValue,
    role,
    isSpecialtiesFieldMultiple,
    prevRole?.value,
    specialtyFieldName,
  ])

  const roleIds = useMemo(
    () => (role?.value ? [role.value] : []),
    [role?.value]
  )

  const getSpecialtyOptions = useGetSpecialtyOptions({ roleIds, unitId })
  const getRoleOptions = useGetRoleOptions({ unitId })

  const isRoleEmpty = useMemo(() => !role, [role])

  return {
    getSpecialtyOptions,
    getRoleOptions,
    isRoleEmpty,
  }
}
