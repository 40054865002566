import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from '../../hooks'
import {
  useUpdateQuery,
  useNeedIds,
  useOrganizationId,
  useQuery,
} from '../../hooks'
import { actions, selectors } from '../../store'
import {
  BaseNotification,
  LoadingStatus,
  UnitNeed,
  NeedStatus,
  AutocompleteValue,
} from '../../types'
import { getEarliestNeedDate, useOnSubmitSnackbars } from '../../utils'

export const useMarkAsRead = () => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()

  const markAsRead = useCallback(
    (notifications: BaseNotification[]) => {
      const notificationIds = notifications.map(({ id }) => id)

      const onSuccess = () =>
        dispatch(
          actions.notifications.getUnreadNotifications({ careProviderId })
        )

      dispatch(
        actions.schedules.markNeedsAsRead({
          notificationIds,
          onSuccess,
        })
      )
    },
    [careProviderId, dispatch]
  )

  return markAsRead
}

export const useIDsSection = (unitId?: number) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const updateQuery = useUpdateQuery()
  const selectedIds = useNeedIds()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const markAsRead = useMarkAsRead()
  const { role, includeAssignmentsExportedToViviumX } = useQuery<{
    role?: AutocompleteValue
    includeAssignmentsExportedToViviumX: boolean
  }>()

  const {
    data: unitNeeds,
    loading,
    isInitialized: areNeedsInitialized,
  } = useSelector(selectors.schedules.getUnitNeedsResource)

  const { data: unitInfo } = useSelector(selectors.schedules.getUnitResource)

  const onSuccess = useCallback(
    (needs: UnitNeed[]) => {
      const needIds = selectedIds.filter(needId =>
        needs.some(({ id }) => id === needId)
      )
      if (needIds.length === selectedIds.length) return
      updateQuery({
        needIds,
        date: getEarliestNeedDate({ needs, selectedIds: needIds }),
      })
    },
    [selectedIds, updateQuery]
  )

  useEffect(() => {
    if (!unitId) return

    dispatch(
      actions.schedules.getUnitNeeds({
        careProviderId,
        unitId,
        includeAssignmentsExportedToViviumX,
        roleId: role?.value,
        needStatus: NeedStatus.Published,
        onSuccess,
      })
    )
  }, [unitId, role?.value, includeAssignmentsExportedToViviumX]) //eslint-disable-line

  useEffect(() => {
    if (loading === LoadingStatus.Failed) {
      enqueueSnackbar(t('idSection.error'), { variant: 'error' })
    }
  }, [loading]) // eslint-disable-line

  useEffect(() => {
    if (unitId) dispatch(actions.schedules.getUnit({ careProviderId, unitId }))
  }, [careProviderId, dispatch, unitId])

  const areNeedsLoading = loading === LoadingStatus.Pending
  const isEmpty = unitNeeds.length === 0

  const handleNeedClick = useCallback(
    (id: number, notifications: BaseNotification[]) => {
      const newSelectedNeedIds = selectedIds.includes(id)
        ? selectedIds.filter(needId => id !== needId)
        : [...selectedIds, id]
      const earliestDate = getEarliestNeedDate({
        needs: unitNeeds,
        selectedIds: newSelectedNeedIds,
      })
      if (notifications.length > 0) markAsRead(notifications)

      return updateQuery({ needIds: newSelectedNeedIds, date: earliestDate })
    },
    [markAsRead, selectedIds, unitNeeds, updateQuery]
  )

  const deselectAllNeeds = useCallback(() => {
    return updateQuery({ needIds: [], date: new Date() })
  }, [updateQuery])

  return {
    areNeedsLoading,
    areNeedsInitialized,
    isEmpty,
    selectedIds,
    unitNeeds,
    handleNeedClick,
    publishedNeedsCount: unitInfo?.needsSummary?.publishedCount,
    deselectAllNeeds,
  }
}

export const useRemoveDialog = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { data: unitNeeds } = useSelector(
    selectors.schedules.getUnitNeedsResource
  )
  const { loading: deleteLoading } = useSelector(
    selectors.schedules.getDeleteNeedSideEffects
  )
  const careProviderId = useOrganizationId()
  const [selectedNeedId, setSelectedNeedId] = useState<number | null>(null)
  const selectedIds = useNeedIds()
  const updateQuery = useUpdateQuery()

  const open = useMemo(() => !!selectedNeedId, [selectedNeedId])

  const openModal = useCallback(
    (id: number) => setSelectedNeedId(id),
    [setSelectedNeedId]
  )

  const closeModal = useCallback(
    () => setSelectedNeedId(null),
    [setSelectedNeedId]
  )

  const { onSuccess: onDeleteSuccess, onFailure } =
    useOnSubmitSnackbars(closeModal)

  const onSuccess = useCallback(
    () => {
      const updatedNeedIds = selectedIds.filter(id => id !== selectedNeedId)
      updateQuery({
        needIds: updatedNeedIds,
        date: getEarliestNeedDate({
          needs: unitNeeds,
          selectedIds: updatedNeedIds,
        }),
      })
      onDeleteSuccess(t('idSection.removeNeedSuccess'))
    },
    [onDeleteSuccess, selectedIds, t, unitNeeds, selectedNeedId] //eslint-disable-line
  )

  const onConfirmation = useCallback(() => {
    if (selectedNeedId)
      dispatch(
        actions.schedules.deleteNeed({
          params: {
            careProviderId,
            needId: selectedNeedId,
          },
          onSuccess,
          onFailure,
        })
      )
  }, [selectedNeedId, dispatch, onSuccess, onFailure]) //eslint-disable-line

  return {
    open,
    selectedNeedId,
    openModal,
    closeModal,
    onConfirmation,
    deleting: deleteLoading === LoadingStatus.Pending,
  }
}
