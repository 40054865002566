import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../resource'
import { Tag } from '../../types'
import { createTag, resetCreateTag, getTags, resetGetTags } from './actions'

export interface State {
  createTag: Resource<string>
  getTags: Resource<Tag[]>
}

const initialState: State = {
  getTags: resource.getInitial<Tag[]>([]),
  createTag: resource.getInitial(''),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(createTag.pending, state => {
      resource.setPending(state.createTag)
    })
    .addCase(createTag.fulfilled, (state, action) => {
      resource.setSucceeded(state.createTag, action.payload)
    })
    .addCase(createTag.rejected, (state, action) => {
      resource.setFailed(state.createTag, action.error.message)
    })
    .addCase(resetCreateTag, state => {
      resource.reset(state.createTag)
    })
    .addCase(getTags.pending, state => {
      resource.setPending(state.getTags)
    })
    .addCase(getTags.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getTags,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getTags.rejected, (state, action) => {
      resource.setFailed(state.getTags, action.error.message)
    })
    .addCase(resetGetTags, state => {
      resource.reset(state.getTags)
    })
)
