import { SvgIcon, SvgIconProps } from '@mui/material'

const Organizations = (props: SvgIconProps) => (
  <SvgIcon viewBox="-1 0 22 24" {...props}>
    <path
      d="M2 6V20H16V22H2C0.9 22 0 21.1 0 20V6H2ZM18 2C19.1 2 20 2.9 20 4V16C20 17.1 19.1 18 18 18H6C4.9 18 4 17.1 4 16V4C4 2.9 4.9 2 6 2H9.18C9.6 0.84 10.7 0 12 0C13.3 0 14.4 0.84 14.82 2H18ZM12 2C11.45 2 11 2.45 11 3C11 3.55 11.45 4 12 4C12.55 4 13 3.55 13 3C13 2.45 12.55 2 12 2ZM8 6V4H6V16H18V4H16V6H8ZM13 14H8V12H13V14ZM16 10H8V8H16V10Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Organizations
