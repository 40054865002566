import TextField, { TextFieldProps } from '../TextField'
import { InputLabel, useStyles } from './TextAreaField.style'

type TextAreaFieldProps = TextFieldProps & {
  rows?: number
}

const TextAreaField = ({
  name,
  label,
  defaultValue,
  rows = 4,
  variant = 'standard',
  ...props
}: TextAreaFieldProps) => {
  const classes = useStyles()
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <TextField
        name={name}
        multiline
        rows={rows}
        {...props}
        InputProps={{
          className: classes.root,
          ...(variant === 'standard' && {
            disableUnderline: true,
          }),
        }}
      />
    </>
  )
}

export default TextAreaField
