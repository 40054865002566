import {
  useLocation,
  Route,
  Routes as RouterRoutes,
  Navigate,
} from 'react-router-dom'
import { useAuth } from '../hooks'
import { ModuleRoute, Location } from '../types'
import PrivateRoute from './PrivateRoute'

export interface RoutesProps {
  routes: ModuleRoute[]
  modalRoutes: ModuleRoute[]
}

const Routes = ({ routes, modalRoutes }: RoutesProps) => {
  const { initialPath } = useAuth()
  const location = useLocation()
  const pathname = location.pathname
  const state = location.state as { background?: Location } | null
  const { background } = state || {}

  const ModalRoutes = modalRoutes.map((route, i) => (
    <Route key={`modalRoute-${i}`} {...route} />
  ))

  return (
    <>
      <RouterRoutes location={background || location}>
        <Route
          path="/:url*(/+)"
          element={<Navigate replace={true} to={pathname.slice(0, -1)} />}
        />
        {routes.map((route, i) =>
          route.public ? (
            <Route key={`publicRoute-${i}`} {...route} />
          ) : (
            <Route
              key={`privateRoute-${i}`}
              {...route}
              element={<PrivateRoute {...route} />}
            />
          )
        )}
        {!background && ModalRoutes}
        <Route
          path="/*"
          element={<Navigate replace={true} to={initialPath} />}
        />
      </RouterRoutes>
      {background && <RouterRoutes>{ModalRoutes}</RouterRoutes>}
    </>
  )
}

export default Routes
