import { Badge, Chip, styled, Tooltip, TooltipProps } from '@mui/material'
import { AssignmentStatus } from '../../types'
import { getAssignmentPillStyle } from '../../utils'

const CIRCLE_DIAMETER = 12

export const Pill = styled(Chip)<{ monthly: boolean }>(
  ({ theme, monthly }) => ({
    marginTop: theme.spacing(0.5),
    height: 16,
    width: '100%',
    cursor: 'pointer',

    '&, &:active, &:focus, &:hover': {
      backgroundColor: theme.palette.pill.yellow.main,
      color: theme.palette.common.black,
    },

    '& .MuiChip-label': {
      margin: theme.spacing(0.25, 0, 0, 0),
    },

    ...(monthly && {
      fontSize: '0.725rem',
    }),
  })
)

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingTop: theme.spacing(0.5),
  pointerEvents: 'none',

  '& > *': {
    pointerEvents: 'auto',
  },
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 4,

  '& .MuiBadge-dot': {
    height: '0.563rem',
    width: '0.563rem',
    border: `0.5px solid ${theme.palette.primary.contrastText}`,
  },
}))

interface ShowMoreButtonProps {
  showAssignmentsSummary?: boolean
  shorthand?: boolean
}

const getButtonWidth = ({
  shorthand,
  showAssignmentsSummary,
}: ShowMoreButtonProps) => {
  if (shorthand) return '100%'
  if (showAssignmentsSummary) return '40%'
  return '70%'
}

export const MoreAssignmentsButton = styled('div')<ShowMoreButtonProps>(
  ({ theme, ...props }) => ({
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      cursor: 'pointer',
    },
    height: 16,
    textTransform: 'none',
    fontSize: '0.7rem',
    fontWeight: 500,
    padding: theme.spacing(0, 0.25),
    textAlign: 'center',
    borderRadius: 4,
    width: getButtonWidth(props),
  })
)

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[24],
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}))

export const CirclesWrapper = styled('div')({
  display: 'flex',
})

interface AssignmentCircleProps {
  status: AssignmentStatus
}
export const AssignmentCircle = styled('div')<AssignmentCircleProps>(
  ({ theme, status }) => {
    const style = getAssignmentPillStyle({ theme, status })
    return {
      ...style,
      height: CIRCLE_DIAMETER,
      width: CIRCLE_DIAMETER,
      borderRadius: '50%',
      marginRight: theme.spacing(0.5),
      cursor: 'pointer',
    }
  }
)
