import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import {
  AdministrationUnit,
  Shift,
  HourRate,
  CareProviderInformation,
  ResourceTableInfo,
  DetailedDepartment,
  AdministrationDepartment,
  AdministrationUser,
  Admin,
  ShiftType,
  Unit,
  FAQEntry,
} from 'shared/types'
import { SystemUpdateMessage } from 'shared/types/systemMessages'
import { getSplitedShifts } from 'shared/utils'
import {
  getUnits,
  getAdministrationUnit,
  resetAdministrationUnit,
  createUnit,
  resetCreateUnit,
  updateUnit,
  resetUpdateUnit,
  getConnectedResources,
  updateDefinedShift,
  createDefinedShift,
  deleteShift,
  getHourRates,
  updateHourRate,
  createHourRate,
  deleteHourRate,
  getMyInformation,
  updateMyInformation,
  inactivateUnit,
  resetInactivateUnit,
  activateUnit,
  resetActivateUnit,
  deleteUnit,
  getDetailedDepartments,
  resetGetDetailedDepartments,
  getDepartment,
  resetGetDepartment,
  createDepartment,
  resetCreateDepartment,
  updateDepartment,
  resetUpdateDepartment,
  createUser,
  resetCreateUser,
  updateUser,
  resetUpdateUser,
  deleteUser,
  resetDeleteUser,
  getUser,
  resetGetUser,
  resetUpdateMyInformation,
  getSuperCareProviders,
  getCareProviders,
  getUnitAdmins,
  deactivateUser,
  resetDeactivateUser,
  activateUser,
  resetActivateUser,
  createOpenShift,
  updateOpenShift,
  getSystemUpdateMessages,
  getFAQs,
} from './actions'
import {
  addHourRateToState,
  deleteHourRateFromState,
  getRate,
  getDefinedShift,
  getOpenShift,
  activateUnitState,
  inactivateUnitState,
  deactivateUserState,
  activateUserState,
  defaultShiftBreakProps,
  DEFAULT_SHIFT_ID,
  getShiftToDelete,
} from './reducer.utils'

export interface State {
  getUnits: Resource<Unit[]>
  getAdministrationUnit: Resource<AdministrationUnit | null>
  createUnit: Resource<string>
  updateUnit: Resource<string>
  deleteUnit: Resource<string>
  getConnectedResources: Resource<ResourceTableInfo[]>
  definedShifts: Resource<Shift>[]
  openShifts: Resource<Shift>[]
  hourRates: Resource<Resource<HourRate>[]>
  getMyInformation: Resource<CareProviderInformation | null>
  updateMyInformation: Resource<string>
  inactivateUnit: Resource<string>
  activateUnit: Resource<string>
  getDetailedDepartments: Resource<DetailedDepartment[]>
  getDepartment: Resource<AdministrationDepartment | null>
  createDepartment: Resource<string>
  updateDepartment: Resource<string>
  createUser: Resource<string>
  updateUser: Resource<string>
  deleteUser: Resource<string>
  deactivateUser: Resource<string>
  activateUser: Resource<string>
  getUser: Resource<AdministrationUser | null>
  getSuperCareProviders: Resource<Admin[]>
  getCareProviders: Resource<Admin[]>
  getUnitAdmins: Resource<Admin[]>
  userPhoneNumber: string
  getSystemUpdateMessages: Resource<SystemUpdateMessage[]>
  getFAQs: Resource<FAQEntry[]>
}

const initialState: State = {
  getUnits: resource.getInitial<Unit[]>([]),
  getAdministrationUnit: resource.getInitial<AdministrationUnit | null>(null),
  createUnit: resource.getInitial(''),
  updateUnit: resource.getInitial(''),
  deleteUnit: resource.getInitial(''),
  deactivateUser: resource.getInitial(''),
  activateUser: resource.getInitial(''),
  getConnectedResources: resource.getInitial<ResourceTableInfo[]>([]),
  definedShifts: [],
  openShifts: [],
  hourRates: resource.getInitial<Resource<HourRate>[]>([]),
  getMyInformation: resource.getInitial<CareProviderInformation | null>(null),
  updateMyInformation: resource.getInitial(''),
  inactivateUnit: resource.getInitial(''),
  activateUnit: resource.getInitial(''),
  getDetailedDepartments: resource.getInitial<DetailedDepartment[]>([]),
  getDepartment: resource.getInitial<AdministrationDepartment | null>(null),
  createDepartment: resource.getInitial(''),
  updateDepartment: resource.getInitial(''),
  createUser: resource.getInitial(''),
  updateUser: resource.getInitial(''),
  deleteUser: resource.getInitial(''),
  getUser: resource.getInitial<AdministrationUser | null>(null),
  getSuperCareProviders: resource.getInitial<Admin[]>([]),
  getCareProviders: resource.getInitial<Admin[]>([]),
  getUnitAdmins: resource.getInitial<Admin[]>([]),
  userPhoneNumber: '',
  getSystemUpdateMessages: resource.getInitial<SystemUpdateMessage[]>([]),
  getFAQs: resource.getInitial<FAQEntry[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getUnits.pending, state => {
      resource.setPending(state.getUnits)
    })
    .addCase(getUnits.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getUnits,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getUnits.rejected, (state, action) => {
      resource.setFailed(state.getUnits, action.error.message)
    })
    .addCase(getAdministrationUnit.pending, state => {
      resource.setPending(state.getAdministrationUnit)
    })
    .addCase(getAdministrationUnit.rejected, (state, action) => {
      resource.setFailed(state.getAdministrationUnit, action.error.message)
    })
    .addCase(getAdministrationUnit.fulfilled, (state, action) => {
      const { definedShifts, openShifts } = getSplitedShifts(
        action.payload.shifts
      )
      state.openShifts = openShifts.map(shift => resource.getInitial(shift))
      state.definedShifts = definedShifts.map(shift =>
        resource.getInitial(shift)
      )

      resource.setSucceeded(state.getAdministrationUnit, action.payload)
    })
    .addCase(resetAdministrationUnit, state => {
      resource.reset(state.getAdministrationUnit)
    })
    .addCase(createUnit.pending, state => {
      resource.setPending(state.createUnit)
    })
    .addCase(createUnit.rejected, (state, action) => {
      resource.setFailed(state.createUnit, action.error.message)
    })
    .addCase(createUnit.fulfilled, (state, action) => {
      resource.setSucceeded(state.createUnit, action.payload)
      if (state.getAdministrationUnit.data) {
        state.getAdministrationUnit.data.name = action.meta.arg.name
      }
    })
    .addCase(resetCreateUnit, state => {
      resource.reset(state.createUnit)
    })
    .addCase(updateUnit.pending, state => {
      resource.setPending(state.updateUnit)
    })
    .addCase(updateUnit.rejected, (state, action) => {
      resource.setFailed(state.updateUnit, action.error.message)
    })
    .addCase(updateUnit.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateUnit, action.payload)
      if (state.getAdministrationUnit.data) {
        state.getAdministrationUnit.data.name = action.meta.arg.name
      }
    })
    .addCase(resetUpdateUnit, state => {
      resource.reset(state.updateUnit)
    })
    .addCase(deleteUnit.pending, state => {
      resource.setPending(state.deleteUnit)
    })
    .addCase(deleteUnit.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteUnit, action.payload)
    })
    .addCase(deleteUnit.rejected, (state, action) => {
      resource.setFailed(state.deleteUnit, action.error.message)
    })
    .addCase(getConnectedResources.pending, state => {
      resource.setPending(state.getConnectedResources)
    })
    .addCase(getConnectedResources.rejected, (state, action) => {
      resource.setFailed(state.getConnectedResources, action.error.message)
    })
    .addCase(getConnectedResources.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getConnectedResources,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(updateDefinedShift.pending, (state, { meta: { arg } }) => {
      const shift = getDefinedShift(state, arg.params.shiftId)
      if (shift) resource.setPending(shift)
    })
    .addCase(updateDefinedShift.rejected, (state, action) => {
      const shift = getDefinedShift(state, action.meta.arg.params.shiftId)
      if (shift) resource.setFailed(shift, action.error.message)
    })
    .addCase(updateDefinedShift.fulfilled, (state, { meta: { arg } }) => {
      const shift = getDefinedShift(state, arg.params.shiftId)
      if (shift)
        resource.setSucceeded(shift, {
          ...arg.params,
          id: arg.params.shiftId,
          type: ShiftType.Defined,
        })
    })
    .addCase(createDefinedShift.pending, (state, { meta: { arg } }) => {
      const i = state.definedShifts.push(
        resource.getInitial({ ...arg.params, id: 0, type: ShiftType.Defined })
      )
      resource.setPending(state.definedShifts[i - 1])
    })
    .addCase(createDefinedShift.rejected, (state, action) => {
      const shift = getDefinedShift(state, DEFAULT_SHIFT_ID)
      if (shift) resource.setFailed(shift, action.error.message)
    })
    .addCase(createDefinedShift.fulfilled, (state, action) => {
      const shift = getDefinedShift(state, DEFAULT_SHIFT_ID)
      const id = action.payload ? parseInt(action.payload, 10) : 0
      if (shift)
        resource.setSucceeded(shift, {
          ...action.meta.arg.params,
          id,
          type: ShiftType.Defined,
        })
    })
    .addCase(updateOpenShift.pending, (state, { meta: { arg } }) => {
      const shift = getOpenShift(state, arg.params.shiftId)
      if (shift) resource.setPending(shift)
    })
    .addCase(updateOpenShift.rejected, (state, action) => {
      const shift = getOpenShift(state, action.meta.arg.params.shiftId)
      if (shift) resource.setFailed(shift, action.error.message)
    })
    .addCase(updateOpenShift.fulfilled, (state, { meta: { arg } }) => {
      const shift = getOpenShift(state, arg.params.shiftId)
      if (shift)
        resource.setSucceeded(shift, {
          ...arg.params,
          ...defaultShiftBreakProps,
          id: arg.params.shiftId,
          type: ShiftType.Open,
        })
    })
    .addCase(createOpenShift.pending, (state, { meta: { arg } }) => {
      const i = state.openShifts.push(
        resource.getInitial({
          ...arg.params,
          ...defaultShiftBreakProps,
          id: DEFAULT_SHIFT_ID,
          type: ShiftType.Open,
        })
      )
      resource.setPending(state.openShifts[i - 1])
    })
    .addCase(createOpenShift.rejected, (state, action) => {
      const shift = getOpenShift(state, DEFAULT_SHIFT_ID)
      if (shift) resource.setFailed(shift, action.error.message)
    })
    .addCase(createOpenShift.fulfilled, (state, action) => {
      const shift = getOpenShift(state, DEFAULT_SHIFT_ID)
      const id = action.payload
        ? parseInt(action.payload, 10)
        : DEFAULT_SHIFT_ID
      if (shift)
        resource.setSucceeded(shift, {
          ...action.meta.arg.params,
          ...defaultShiftBreakProps,
          id,
          type: ShiftType.Open,
        })
    })
    .addCase(deleteShift.pending, (state, { meta: { arg } }) => {
      const shift = getShiftToDelete(state, arg.params)
      if (shift) resource.setPending(shift)
    })
    .addCase(deleteShift.rejected, (state, action) => {
      const shift = getShiftToDelete(state, action.meta.arg.params)
      if (shift) resource.setFailed(shift, action.error.message)
    })
    .addCase(deleteShift.fulfilled, (state, { meta: { arg } }) => {
      const shift = getShiftToDelete(state, arg.params)
      if (!shift) return

      resource.setSucceeded(shift)
      switch (arg.params.shiftType) {
        case ShiftType.Defined:
          state.definedShifts = state.definedShifts.filter(
            shift => shift.data.id !== arg.params.shiftId
          )
          return
        case ShiftType.Open:
          state.openShifts = state.openShifts.filter(
            shift => shift.data.id !== arg.params.shiftId
          )
          return

        default:
          break
      }
    })
    .addCase(getHourRates.pending, state => {
      resource.setPending(state.hourRates)
    })
    .addCase(getHourRates.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.hourRates,
        action.payload.data.map(data => resource.getInitial(data)),
        action.payload.meta
      )
    })
    .addCase(getHourRates.rejected, (state, action) => {
      resource.setFailed(state.hourRates, action.error.message)
    })
    .addCase(createHourRate.pending, (state, action) => {
      const { params } = action.meta.arg
      const initialRate = resource.getInitial({ ...params, id: 0 })
      const i = state.hourRates.data.push(initialRate)
      resource.setPending(state.hourRates.data[i - 1])
    })
    .addCase(createHourRate.rejected, (state, action) => {
      const rate = getRate(state, 0)
      if (rate) resource.setFailed(rate, action.error.message)
    })
    .addCase(createHourRate.fulfilled, (state, action) => {
      addHourRateToState(state, action)
    })
    .addCase(updateHourRate.pending, (state, action) => {
      const { hourRateId } = action.meta.arg.params
      const rate = getRate(state, hourRateId)
      if (rate) resource.setPending(rate)
    })
    .addCase(updateHourRate.rejected, (state, action) => {
      const { hourRateId } = action.meta.arg.params
      const rate = getRate(state, hourRateId)
      if (rate) resource.setFailed(rate, action.error.message)
    })
    .addCase(updateHourRate.fulfilled, (state, action) => {
      const { hourRateId } = action.meta.arg.params
      const rate = getRate(state, hourRateId)
      if (rate) resource.setSucceeded(rate)
    })
    .addCase(deleteHourRate.pending, (state, action) => {
      const { hourRateId } = action.meta.arg.params
      const rate = getRate(state, hourRateId)
      if (rate) resource.setPending(rate)
    })
    .addCase(deleteHourRate.rejected, (state, action) => {
      const { hourRateId } = action.meta.arg.params
      const rate = getRate(state, hourRateId)
      if (rate) resource.setFailed(rate, action.error.message)
    })
    .addCase(deleteHourRate.fulfilled, (state, action) => {
      const { hourRateId } = action.meta.arg.params
      deleteHourRateFromState(state, hourRateId)
    })
    .addCase(getMyInformation.pending, state => {
      resource.setPending(state.getMyInformation)
    })
    .addCase(getMyInformation.rejected, (state, action) => {
      resource.setFailed(state.getMyInformation, action.error.message)
    })
    .addCase(getMyInformation.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMyInformation, action.payload)
    })
    .addCase(updateMyInformation.pending, state => {
      resource.setPending(state.updateMyInformation)
    })
    .addCase(updateMyInformation.rejected, (state, action) => {
      resource.setFailed(state.updateMyInformation, action.error.message)
    })
    .addCase(updateMyInformation.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateMyInformation, action.payload)
    })
    .addCase(resetUpdateMyInformation, state => {
      resource.reset(state.updateMyInformation)
    })
    .addCase(inactivateUnit.pending, state => {
      resource.setPending(state.inactivateUnit)
    })
    .addCase(inactivateUnit.rejected, (state, action) => {
      resource.setFailed(state.inactivateUnit, action.error.message)
    })
    .addCase(inactivateUnit.fulfilled, (state, action) => {
      resource.setSucceeded(state.inactivateUnit, action.payload)
      inactivateUnitState(state)
    })
    .addCase(resetInactivateUnit, state => {
      resource.reset(state.inactivateUnit)
    })
    .addCase(activateUnit.pending, state => {
      resource.setPending(state.activateUnit)
    })
    .addCase(activateUnit.rejected, (state, action) => {
      resource.setFailed(state.activateUnit, action.error.message)
    })
    .addCase(activateUnit.fulfilled, (state, action) => {
      resource.setSucceeded(state.activateUnit, action.payload)
      activateUnitState(state)
    })
    .addCase(resetActivateUnit, state => {
      resource.reset(state.activateUnit)
    })
    .addCase(getDetailedDepartments.pending, state => {
      resource.setPending(state.getDetailedDepartments)
    })
    .addCase(getDetailedDepartments.rejected, (state, action) => {
      resource.setFailed(state.getDetailedDepartments, action.error.message)
    })
    .addCase(getDetailedDepartments.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getDetailedDepartments,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(resetGetDetailedDepartments, state => {
      resource.reset(state.getDetailedDepartments)
    })
    .addCase(getDepartment.pending, state => {
      resource.setPending(state.getDepartment)
    })
    .addCase(getDepartment.rejected, (state, action) => {
      resource.setFailed(state.getDepartment, action.error.message)
    })
    .addCase(getDepartment.fulfilled, (state, action) => {
      resource.setSucceeded(state.getDepartment, action.payload)
    })
    .addCase(resetGetDepartment, state => {
      resource.reset(state.getDepartment)
    })
    .addCase(createDepartment.pending, state => {
      resource.setPending(state.createDepartment)
    })
    .addCase(createDepartment.rejected, (state, action) => {
      resource.setFailed(state.createDepartment, action.error.message)
    })
    .addCase(createDepartment.fulfilled, (state, action) => {
      resource.setSucceeded(state.createDepartment, action.payload)
    })
    .addCase(resetCreateDepartment, state => {
      resource.reset(state.createDepartment)
    })
    .addCase(updateDepartment.pending, state => {
      resource.setPending(state.updateDepartment)
    })
    .addCase(updateDepartment.rejected, (state, action) => {
      resource.setFailed(state.updateDepartment, action.error.message)
    })
    .addCase(updateDepartment.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateDepartment, action.payload)
    })
    .addCase(resetUpdateDepartment, state => {
      resource.reset(state.updateDepartment)
    })
    .addCase(createUser.pending, state => {
      resource.setPending(state.createUser)
    })
    .addCase(createUser.rejected, (state, action) => {
      resource.setFailed(state.createUser, action.error.message)
    })
    .addCase(createUser.fulfilled, (state, action) => {
      resource.setSucceeded(state.createUser, action.payload)
    })
    .addCase(resetCreateUser, state => {
      resource.reset(state.createUser)
    })
    .addCase(updateUser.pending, state => {
      resource.setPending(state.updateUser)
    })
    .addCase(updateUser.rejected, (state, action) => {
      resource.setFailed(state.updateUser, action.error.message)
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateUser, action.payload)
      state.userPhoneNumber = action.meta.arg.phoneNumber
    })
    .addCase(resetUpdateUser, state => {
      resource.reset(state.updateUser)
    })
    .addCase(deleteUser.pending, state => {
      resource.setPending(state.deleteUser)
    })
    .addCase(deleteUser.rejected, (state, action) => {
      resource.setFailed(state.deleteUser, action.error.message)
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteUser, action.payload)
    })
    .addCase(resetDeleteUser, state => {
      resource.reset(state.deleteUser)
    })
    .addCase(deactivateUser.pending, state => {
      resource.setPending(state.deactivateUser)
    })
    .addCase(deactivateUser.fulfilled, (state, action) => {
      resource.setSucceeded(state.deactivateUser, action.payload)
      deactivateUserState(state)
    })
    .addCase(deactivateUser.rejected, (state, action) => {
      resource.setFailed(state.deactivateUser, action.error.message)
    })
    .addCase(resetDeactivateUser, state => {
      resource.reset(state.deactivateUser)
    })
    .addCase(activateUser.pending, state => {
      resource.setPending(state.activateUser)
    })
    .addCase(activateUser.fulfilled, (state, action) => {
      resource.setSucceeded(state.activateUser, action.payload)
      activateUserState(state)
    })
    .addCase(activateUser.rejected, (state, action) => {
      resource.setFailed(state.activateUser, action.error.message)
    })
    .addCase(resetActivateUser, state => {
      resource.reset(state.activateUser)
    })
    .addCase(getUser.pending, state => {
      resource.setPending(state.getUser)
    })
    .addCase(getUser.rejected, (state, action) => {
      resource.setFailed(state.getUser, action.error.message)
    })
    .addCase(getUser.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUser, action.payload)
      state.userPhoneNumber = action.payload.phoneNumber
    })
    .addCase(resetGetUser, state => {
      resource.reset(state.getUser)
    })
    .addCase(getSuperCareProviders.pending, state => {
      resource.setPending(state.getSuperCareProviders)
    })
    .addCase(getSuperCareProviders.rejected, (state, action) => {
      resource.setFailed(state.getSuperCareProviders, action.error.message)
    })
    .addCase(getSuperCareProviders.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSuperCareProviders, action.payload.data)
    })
    .addCase(getCareProviders.pending, state => {
      resource.setPending(state.getCareProviders)
    })
    .addCase(getCareProviders.rejected, (state, action) => {
      resource.setFailed(state.getCareProviders, action.error.message)
    })
    .addCase(getCareProviders.fulfilled, (state, action) => {
      resource.setSucceeded(state.getCareProviders, action.payload.data)
    })
    .addCase(getUnitAdmins.pending, state => {
      resource.setPending(state.getUnitAdmins)
    })
    .addCase(getUnitAdmins.rejected, (state, action) => {
      resource.setFailed(state.getUnitAdmins, action.error.message)
    })
    .addCase(getUnitAdmins.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUnitAdmins, action.payload.data)
    })
    .addCase(getSystemUpdateMessages.pending, state => {
      resource.setPending(state.getSystemUpdateMessages)
    })
    .addCase(getSystemUpdateMessages.rejected, (state, action) => {
      resource.setFailed(state.getSystemUpdateMessages, action.error.message)
    })
    .addCase(getSystemUpdateMessages.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getSystemUpdateMessages,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getFAQs.pending, state => {
      resource.setPending(state.getFAQs)
    })
    .addCase(getFAQs.rejected, (state, action) => {
      resource.setFailed(state.getFAQs, action.error.message)
    })
    .addCase(getFAQs.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getFAQs,
        action.payload.data,
        action.payload.meta
      )
    })
)
