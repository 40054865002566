import Api from '../api'
import {
  AddUsersToChatParams,
  AddUsersToChatResponse,
  CreateChatParams,
  CreateChatResponse,
  EditChatParams,
  EditChatResponse,
  GetChatAccountsParams,
  GetChatAccountsResponse,
  GetChatMembersParams,
  GetChatMembersResponse,
  GetChatMessagesParams,
  GetChatMessagesResponse,
  GetChatNotificationsParams,
  GetChatNotificationsResponse,
  GetChatParams,
  GetChatResponse,
  GetChatsParams,
  GetChatsResponse,
  GetChatUserParams,
  GetChatUserResponse,
  ReadChatNotificationsParams,
  SendChatMessageParams,
  SendChatMessageResponse,
} from './Chat.types'

class Chat extends Api {
  public createChat = async (payload: CreateChatParams) => {
    const { data } = await this.api.post<CreateChatResponse>('/chats', payload)

    return data
  }

  public editChat = async ({
    chatId,
    careProviderId,
    ...payload
  }: EditChatParams) => {
    const { data } = await this.api.patch<EditChatResponse>(
      `/chats/${chatId}`,
      payload,
      { params: { careProviderId } }
    )

    return data
  }

  public sendChatMessage = async ({
    chatId,
    careProviderId,
    ...payload
  }: SendChatMessageParams) => {
    const { data } = await this.api.post<SendChatMessageResponse>(
      `/chats/${chatId}/messages`,
      payload,
      { params: { careProviderId } }
    )

    return data
  }

  public addUsersToChat = async ({
    chatId,
    careProviderId,
    ...payload
  }: AddUsersToChatParams) => {
    const { data } = await this.api.post<AddUsersToChatResponse>(
      `/chats/${chatId}/add-users`,
      payload,
      { params: { careProviderId } }
    )

    return data
  }

  public getChats = async (params: GetChatsParams) => {
    const response = await this.api.get<GetChatsResponse>('/chats', { params })

    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getChat = async ({ chatId, ...params }: GetChatParams) => {
    const { data } = await this.api.get<GetChatResponse>(`/chats/${chatId}`, {
      params,
    })

    return data
  }

  public getChatMessages = async ({
    chatId,
    ...params
  }: GetChatMessagesParams) => {
    const response = await this.api.get<GetChatMessagesResponse>(
      `/chats/${chatId}/messages`,
      {
        params,
      }
    )

    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getChatUser = async ({ chatId, ...params }: GetChatUserParams) => {
    const { data } = await this.api.get<GetChatUserResponse>(
      `/chats/${chatId}/me`,
      { params }
    )

    return data
  }

  public getChatMembers = async ({
    chatId,
    ...params
  }: GetChatMembersParams) => {
    const { data } = await this.api.get<GetChatMembersResponse>(
      `/chats/${chatId}/users`,
      { params }
    )

    return data
  }

  public getChatAccounts = async (params: GetChatAccountsParams) => {
    const { data } = await this.api.get<GetChatAccountsResponse>(
      `/chats/accounts`,
      { params }
    )

    return data.data
  }

  public getChatNotifications = async (params: GetChatNotificationsParams) => {
    const { data } = await this.api.get<GetChatNotificationsResponse>(
      `/chats/unread`,
      { params }
    )

    return data.data
  }

  public readChatNotifications = async ({
    chatId,
    careProviderId,
    ...payload
  }: ReadChatNotificationsParams) => {
    const { data } = await this.api.post<number>(
      `/chats/${chatId}/messages/read`,
      payload,
      { params: { careProviderId } }
    )

    return data
  }
}

export default Chat
