import { useEffect, useCallback, useState } from 'react'
import config from 'config'
import { actions, selectors } from '../store'
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from './redux'
import { useAuth } from './useAuth'

export const useAdminMessagesListener = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()

  const getLatestAdminInfoMessage = () => {
    dispatch(actions.notifications.getLatestAdminInfoMessage())
  }

  const getLatestAdminUpdateMessage = () => {
    dispatch(actions.notifications.getLatestAdminUpdateMessage())
  }

  const getAdminMessages = useCallback(() => {
    getLatestAdminInfoMessage()
    if (isAuthenticated) {
      getLatestAdminUpdateMessage()
    }
  }, [isAuthenticated]) //eslint-disable-line

  useEffect(() => {
    getAdminMessages()
  }, [getAdminMessages, isAuthenticated])

  useEffect(() => {
    const interval = setInterval(
      getAdminMessages,
      config.adminInfoMessageListenerInterval
    )

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [getAdminMessages])
}

export const useAdminInfoMessage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const { data } = useSelector(
    selectors.notifications.getLatestAdminInfoMessage
  )

  const close = () => {
    setIsOpen(false)
    if (isAuthenticated) {
      dispatch(
        actions.notifications.markAdminMessageAsRead({ messageId: data?.id })
      )
    }
  }

  useEffect(() => {
    setIsOpen(!data?.isReadByCurrentAccount && !!data?.message)
  }, [data?.isReadByCurrentAccount, data?.message])

  return {
    close,
    isOpen,
    message: data?.message,
  }
}

export const useAdminUpdateMessage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const { data } = useSelector(
    selectors.notifications.getLatestAdminUpdateMessage
  )

  const close = () => {
    setIsOpen(false)
    dispatch(
      actions.notifications.markAdminMessageAsRead({ messageId: data?.id })
    )
  }

  useEffect(() => {
    if (isAuthenticated) {
      setIsOpen(!data?.isReadByCurrentAccount && !!data?.message)
    }
  }, [data?.isReadByCurrentAccount, data?.message, isAuthenticated])

  return {
    close,
    isOpen,
    message: data?.message,
    id: data?.id,
  }
}
