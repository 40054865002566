import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { routes as baseRoutes } from 'modules'
import config from 'config'
import {
  useAdminMessagesListener,
  useNotificationsListener,
  useSettings,
} from 'shared/hooks'
import { partition } from 'shared/utils'

export const routes = partition(baseRoutes, route => !route.modal)

export const NotificationsProvider = () => {
  useNotificationsListener()
  return <></>
}

export const SettingsProvider = () => {
  useSettings()
  return <></>
}

export const AdminMessagesProvider = () => {
  useAdminMessagesListener()
  return <></>
}

export const NewVersionListener = () => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false)
  const { t } = useTranslation()

  const reloadApp = useCallback(() => window.location.reload(), [])

  const cancel = () => setIsNewVersionAvailable(false)

  const checkNewVersion = useCallback(async () => {
    const response = await fetch(`${config.versionFileName}?_=${Date.now()}`)
    const latestVersion = await response.text()
    setIsNewVersionAvailable(
      latestVersion.trim() !== config.currentVersion.trim()
    )
  }, [])

  useEffect(() => {
    checkNewVersion()
    const interval = setInterval(
      checkNewVersion,
      config.newVersionListenerInterval
    )
    return () => {
      clearInterval(interval)
    }
  }, [checkNewVersion])

  return (
    <Dialog open={isNewVersionAvailable} fullWidth maxWidth="xs">
      <DialogTitle>{t('newVersionListener.title')}</DialogTitle>
      <DialogContent>{t('newVersionListener.message')}</DialogContent>
      <DialogActions>
        <Button variant="text" fullWidth={false} onClick={cancel}>
          {t('modal.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={reloadApp}
          autoFocus
        >
          {t('newVersionListener.update')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
