import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { NavigateNext } from '@mui/icons-material'
import {
  BreadcrumbsProps as BaseBreadcrumbsProps,
  Typography,
} from '@mui/material'
import { StyledBreadcrumbs } from './Breadcrumbs.style'

export type BreadcrumbItem = string | { link?: string; label: ReactNode }

interface BreadcrumbsProps extends BaseBreadcrumbsProps {
  items?: BreadcrumbItem[]
}

const Breadcrumbs = ({ items = [], ...props }: BreadcrumbsProps) => (
  <>
    <StyledBreadcrumbs
      separator={<NavigateNext fontSize="small" />}
      aria-label="breadcrumb"
      {...props}
    >
      {items.map((item, i) => (
        <Typography
          key={`breadcrumb-${i}`}
          color="textSecondary"
          variant="body2"
          {...(typeof item === 'object' &&
            item.link && { component: Link, to: item.link })}
        >
          {typeof item === 'string' ? item : item.label}
        </Typography>
      ))}
    </StyledBreadcrumbs>
  </>
)

export default Breadcrumbs
