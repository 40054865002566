import { DataPrepareSettings } from 'devextreme-react/chart'
import BasePieChart, {
  Series,
  Size as BasePieChartSize,
} from 'devextreme-react/pie-chart'
import { alpha, useTheme } from '@mui/material'
import { Skeleton } from '@mui/material'
import { ChartData } from '../../types'
import { CenterTemplate, Legend } from './components'
import { Container, Column, Title, StyledSkeleton } from './PieChart.style'
import { PieChartSize } from './PieChart.types'
import { getDimensions, isZeroValues } from './PieChart.utils'

const CHART_TYPE = 'doughnut'
const ARGUMENT_FIELD = 'status'
const VALUE_FIELD = 'value'
const INNER_RADIUS = 0.8
const BORDER_WIDTH = 1

interface ChartProps {
  data?: ChartData[]
  size?: PieChartSize
  centerText?: string
  centerTextFontSize?: number
  centerSubText?: string
  titleText?: string
  titleFontSize?: number
  innerRadius?: number
  legend?: boolean
  loading?: boolean
  noData?: boolean
  sortingOrder: any[]
}

const PieChart = ({
  data,
  size,
  centerText,
  centerTextFontSize,
  centerSubText,
  titleText,
  titleFontSize = 12,
  innerRadius = INNER_RADIUS,
  legend = false,
  loading = false,
  noData = false,
  sortingOrder,
}: ChartProps) => {
  const theme = useTheme()
  const { chartSize, calculatedFontSize } = getDimensions(size)
  const fontSize = centerTextFontSize || calculatedFontSize

  const empty = [
    { status: '', value: 0, color: theme.palette.pill.gray.contrastText },
  ]

  const dataSource = noData || isZeroValues(data) ? empty : data

  const sortByStatus = <T extends { status: string }>(a: T, b: T) =>
    dataSource
      ? sortingOrder.indexOf(a.status) - sortingOrder.indexOf(b.status)
      : 0

  const legendData = data?.sort(sortByStatus)

  return (
    <Container>
      {legend && <Legend data={legendData} loading={loading} />}
      <Column>
        {loading ? (
          <>
            <Skeleton variant="circular" width={chartSize} height={chartSize} />
            {titleText && (
              <StyledSkeleton variant="rectangular" width={chartSize} />
            )}
          </>
        ) : (
          <>
            <BasePieChart
              startAngle={90}
              segmentsDirection="clockwise"
              id="pie"
              adaptiveLayout={{ height: chartSize }}
              dataSource={dataSource}
              innerRadius={innerRadius}
              type={CHART_TYPE}
              animation={false}
              legend={{ visible: false }}
              disabled={isZeroValues(data)}
              customizePoint={({ data }) => ({
                color: data.lightenChartColor
                  ? alpha(data.color, 0.25)
                  : data.color,
                border: {
                  color: data.color,
                  visible: data.hasBorder,
                  width: BORDER_WIDTH,
                  radius: 2,
                },
              })}
              centerRender={() => (
                <CenterTemplate
                  fontSize={fontSize}
                  label={centerText}
                  subLabel={centerSubText}
                />
              )}
            >
              <DataPrepareSettings sortingMethod={sortByStatus} />
              <Series
                argumentField={ARGUMENT_FIELD}
                valueField={VALUE_FIELD}
                hoverMode="none"
              />
              <BasePieChartSize height={chartSize} width={chartSize} />
            </BasePieChart>
            {titleText && <Title fontSize={titleFontSize}>{titleText}</Title>}
          </>
        )}
      </Column>
    </Container>
  )
}

export default PieChart
