import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteFieldOption } from '../types'

export const useEnumOptions = (
  enumObj: object,
  enumName: string
): AutocompleteFieldOption[] => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      Object.values(enumObj).map(value => ({
        value,
        label: t(`enums.${enumName}.${value}`),
      })),
    [t, enumObj, enumName]
  )
}
