import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.signIn,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/Login')} />,
  },
  {
    path: paths.activateAccount,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/ActivateAccount')} />,
  },
  {
    path: paths.newPassword,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/NewPassword')} />,
  },
  {
    path: paths.forgotPassword,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/ForgotPassword')} />,
  },
  {
    path: paths.multifactorAuthentication,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/Multifactor')} />,
  },
  {
    path: paths.unauthorized,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/Unauthorized')} />,
  },
  {
    path: paths.forbidden,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
    },
    element: <Loadable component={() => import('./pages/Forbidden')} />,
  },
]

export default routes
