import {
  Toolbar,
  List,
  DrawerProps as BaseDrawerProps,
  useMediaQuery,
  Theme,
} from '@mui/material'
import DrawerItem, { DrawerItemProps } from './DrawerItem'
import { StyledDrawer, Divider, ListContainer } from './Drawer.style'

export interface DrawerProps extends BaseDrawerProps {
  hidden?: boolean
  navItems?: DrawerItemProps[]
  footerNavItems?: DrawerItemProps[]
  onClose?: () => void
}

const Drawer = ({
  hidden,
  navItems = [],
  footerNavItems = [],
  onClose = () => {},
  ...props
}: DrawerProps) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  if (hidden) return null
  return (
    <StyledDrawer
      anchor="left"
      onClose={onClose}
      {...props}
      {...(!isMobile && { variant: 'permanent', open: true })}
    >
      {!isMobile && (
        <>
          <Toolbar />
          <Divider />
        </>
      )}
      <ListContainer>
        <List disablePadding>
          {navItems.map((itemProps, i) => (
            <DrawerItem
              key={`item-${i}`}
              onClick={onClose}
              data-test-id={`${itemProps.label}-tab`}
              {...itemProps}
            />
          ))}
        </List>
        {footerNavItems.length > 0 && (
          <List disablePadding>
            {footerNavItems.map((itemProps, i) => (
              <DrawerItem
                key={`footer-item-${i}`}
                onClick={onClose}
                data-test-id={`${itemProps.label}-tab`}
                {...itemProps}
              />
            ))}
          </List>
        )}
      </ListContainer>
    </StyledDrawer>
  )
}

export default Drawer
