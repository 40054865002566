import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import Archives, { GetArchivesParams } from 'shared/services/archives'
import { MODULE_NAME } from '../strings'
import { ExportArchivesPayload } from './actions.types'

const archives = new Archives()

export const getArchives = createAsyncThunk(
  `${MODULE_NAME}/getArchives`,
  (payload: GetArchivesParams) => archives.getArchives(payload)
)

export const exportArchives = createAsyncThunk(
  `${MODULE_NAME}/exportArchives`,
  async ({ params, onFailure, onSuccess }: ExportArchivesPayload) => {
    try {
      const file = await archives.exportArchives(params)
      onSuccess(file)
      return file
    } catch {
      onFailure()
    }
  }
)

export const resetExportArchives = createAction(`${MODULE_NAME}/exportArchives`)
