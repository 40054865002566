import { ReactNode } from 'react'
import { RouteProps } from 'react-router-dom'
import { Locale } from 'date-fns'
import { DrawerProps, TopbarProps } from '../components'
import { UserRole } from './enums'

export type ValueOf<T> = T[keyof T]

export interface LayoutSettings {
  drawerProps?: Partial<DrawerProps>
  topbarProps?: Partial<TopbarProps>
}

export interface ModuleRoute extends RouteProps {
  layoutSettings?: LayoutSettings
  public?: boolean
  allowedRoles?: UserRole[]
  modal?: boolean
}

export interface Module<Name, Reducer> {
  name: Name
  routes: ModuleRoute[]
  reducer: Reducer
}

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

export type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6

export interface DateFnsOptions {
  locale?: Locale
  weekStartsOn?: WeekDay
}

export interface ChartData {
  status: string
  value: number
  filledLegend?: boolean
  color?: string
  borderColor?: string
  legendItem?: ReactNode | string
  lightenChartColor?: boolean
  hasBorder?: boolean
}

export type Location<T = {}> = typeof window.location & {
  state: T
  key: string
}

export enum LanguageCode {
  EN = 'en',
  SV = 'sv',
}
