import { useMemo } from 'react'
import { format } from 'date-fns'
import { paths } from 'config'
import { useOrganizationId } from '../../../../../../hooks'
import { generatePathWithQuery } from '../../../../../../utils'

const DATE_FORMAT = 'dd/MM'

export const getDate = (date?: string) =>
  date ? format(new Date(date), DATE_FORMAT) : null

export const useHref = (date?: string) => {
  const organizationId = useOrganizationId()
  return useMemo(
    () =>
      date
        ? generatePathWithQuery({
            path: paths.organizationSchedules,
            params: { organizationId: organizationId.toString() },
            qs: { date },
          })
        : '',
    [organizationId, date]
  )
}
