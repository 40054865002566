import { useTranslation } from 'react-i18next'
import { Grid, Switch, SwitchProps } from '@mui/material'
import { SwitchLabel, TopLabel } from './LabeledSwitch.style'

interface LabeledSwitchProps extends SwitchProps {
  onChange: () => void
  labelTop?: string
  labelUnchecked?: string
  labelChecked?: string
}

const LabeledSwitch = ({
  onChange,
  labelTop,
  labelUnchecked,
  labelChecked,
  ...props
}: LabeledSwitchProps) => {
  const { t } = useTranslation()

  return (
    <Grid container direction={'column'} spacing={1}>
      <Grid item>{labelTop && <TopLabel>{labelTop}</TopLabel>}</Grid>
      <Grid item container>
        <SwitchLabel>{labelUnchecked || t('form.off')}</SwitchLabel>
        <Switch size={props.size} onChange={() => onChange()} />
        <SwitchLabel>{labelChecked || t('form.on')}</SwitchLabel>
      </Grid>
    </Grid>
  )
}

export default LabeledSwitch
