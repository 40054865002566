import { SvgIcon, SvgIconProps } from '@mui/material'

const AddFile = (props: SvgIconProps) => (
  <SvgIcon width="20" height="23" viewBox="-3.5 0 27 23" {...props}>
    <path
      d="M3.33333 0V2.22222H2.22222C1.63285 2.22222 1.06762 2.45635 0.650874 2.8731C0.234126 3.28984 0 3.85507 0 4.44444V20C0 20.5894 0.234126 21.1546 0.650874 21.5713C1.06762 21.9881 1.63285 22.2222 2.22222 22.2222H17.7778C18.3671 22.2222 18.9324 21.9881 19.3491 21.5713C19.7659 21.1546 20 20.5894 20 20V4.44444C20 3.85507 19.7659 3.28984 19.3491 2.8731C18.9324 2.45635 18.3671 2.22222 17.7778 2.22222H16.6667V0H14.4444V2.22222H5.55556V0H3.33333ZM2.22222 7.77778H17.7778V20H2.22222V7.77778ZM10.2111 8.88889C9.24444 8.88889 8.46667 9.11111 7.86667 9.54444C7.28889 10 7 10.6333 7.01111 11.5111L7.02222 11.5444H9.16667C9.17778 11.2111 9.27778 10.9556 9.47778 10.7778C9.68353 10.6072 9.94389 10.5164 10.2111 10.5222C10.5556 10.5222 10.8444 10.6333 11.0444 10.8333C11.2444 11.0444 11.3333 11.3333 11.3333 11.6667C11.3333 12.0222 11.2556 12.3222 11.0778 12.5778C10.9222 12.8333 10.6889 13.0556 10.4 13.2333C9.83333 13.6111 9.44445 13.9444 9.23333 14.2444C9.01111 14.5333 8.88889 15 8.88889 15.5556H11.1111C11.1111 15.2111 11.1556 14.9333 11.2556 14.7333C11.3556 14.5333 11.5444 14.3333 11.8222 14.1556C12.3222 13.8889 12.7333 13.5667 13.0556 13.1222C13.3778 12.6778 13.5444 12.2222 13.5444 11.6667C13.5444 10.8222 13.2444 10.1444 12.6444 9.64444C12.0556 9.14444 11.2444 8.88889 10.2111 8.88889ZM8.88889 16.6667V18.8889H11.1111V16.6667H8.88889Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default AddFile
