import { DeleteNeedParams } from '../services/needs'
import { AssignmentSummaryCounts, Entity, ScheduleDaysAssignment } from './data'
import {
  CalendarView,
  LisitingAssignmentStatus,
  ListingAssignmentCancelReason,
} from './enums'
import { Absence, Assignment, Availability } from './resourceSchedule'

export type CloseModalType = () => void

export interface DeleteNeedPayload {
  params: DeleteNeedParams
  onFailure: () => void
  onSuccess: () => void
}

export interface UsePillTextsOptions {
  assignment?: Partial<ScheduleDaysAssignment>
  isShorthand?: boolean
  hasUnitName?: boolean
  shouldShowDeleteState?: boolean
  shouldShowDatesText?: boolean
}

export interface AssignmentToAdd {
  shiftId: number
  startDate: Date
  endDate: Date
}

export interface AssignmentToRemove {
  id?: number
  shiftId: number
  startDate: Date
  endDate: Date
}

export interface EditingModeDialogData {
  mainContent: string
  confirmationButtonLabel: string
  cancelButtonLabel: string
  onConfirmation: () => void
  onCancel: () => void
  onClose?: () => void
}

export interface AssignmentHistoryEntry {
  id: number
  createdBy: Entity | null
  createdAt: string
  changes: AssignmentHistoryChange[]
  note: string
}

export enum AssignmentHistoryChange {
  MarkedOvertime = 'MARKED_OVERTIME',
  MarkedAbsent = 'MARKED_ABSENT',
  SetCanceled = 'SET_CANCELED',
  SetEmpty = 'SET_EMPTY',
  ResolvedInternally = 'RESOLVED_INTERNALLY',
}

export interface MonthlyViewAppointment {
  startDate: Date
  endDate: Date
  assignments: Assignment[]
  availabilities: Availability[]
  absences: Absence[]
  total: number
  counts: AssignmentSummaryCounts
}

export interface GetStartEndDatesParams {
  date: Date
  viewName?: CalendarView
  showAllWeeks?: boolean
}

export interface ScheduleListingAssignment {
  id: number
  hasAbsece: boolean
  hasOvertime: boolean
  hasNote: boolean
  status: LisitingAssignmentStatus
}

export interface ListingAssignment {
  id: number
  startDate: string
  endDate: string
  status: LisitingAssignmentStatus
  shiftId: number
  resource: Entity | null
  hasAbsence: boolean
  isAbsenceFined: boolean
  hasOvertime: boolean
  cancelReason: ListingAssignmentCancelReason
  note: string
}
