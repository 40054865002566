import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import Matching, { GetMatchingParams } from 'shared/services/matching'
import NeedAssignments, {
  AssignAssignmentToResourcesParams,
  AssignResourceToAssignmentsParams,
  ChangeAssignmentStateParams,
  GetBookedAssignmentsParams,
  ModifyBookedAssignmentParams,
  RequestAssignmentsParams,
  UnbookAssignmentsParams,
} from 'shared/services/need-assignments'
import Needs, {
  GetPublishedNeedsParams,
  GetUnitNeedsSummariesParams,
  PublishNeedParams,
} from 'shared/services/needs'
import Suggestions, {
  GetRolesSuggestionsParams,
} from 'shared/services/suggestions'
import Units, {
  GetShiftsParams,
  GetMatchingResourcesParams,
  GetNeedAssignmentsStatisticsParams,
  GetBookedResourcesParams,
  GetDetailedUnitsParams,
  GetUnitNeedAssignmentsParams,
} from 'shared/services/units'
import {
  AssignmentToAdd,
  AssignmentToRemove,
  MatchingAssignment,
} from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME } from '../strings'
import {
  SetSelectedResourcePayload,
  SetBookedResourcePayload,
  ExportNeedAssignmentsPayload,
  BookAssignmentsPayload,
  FilterUnitNotifications,
  UpdateNeedPayload,
  OpenUpdateNeedDialogPayload,
  ExportNeedAssignmentsToViviumXPayload,
} from './actions.types'

const needs = new Needs()
const units = new Units()
const needAssignments = new NeedAssignments()
const suggestions = new Suggestions()
const matching = new Matching()

export const getPublishedNeeds = createAsyncThunk(
  `${MODULE_NAME}/getPublishedNeeds`,
  (payload: GetPublishedNeedsParams) => needs.getPublishedNeeds(payload)
)

export const publishNeed = createAsyncThunk(
  `${MODULE_NAME}/publishNeed`,
  (payload: PublishNeedParams) => needs.publishNeed(payload)
)

export const resetPublishNeed = createAction(`${MODULE_NAME}/resetPublishNeed`)

export const getShifts = createAsyncThunk(
  `${MODULE_NAME}/getShifts`,
  (payload: GetShiftsParams) => units.getShifts(payload)
)

export const getDetailedUnits = createAsyncThunk(
  `${MODULE_NAME}/getDetailedUnits`,
  (payload: GetDetailedUnitsParams) => units.getDetailedUnits(payload)
)

export const resetDetailedUnits = createAction(
  `${MODULE_NAME}/resetDetailedUnits`
)

export const getMatchingResources = createAsyncThunk(
  `${MODULE_NAME}/getMatchingResources`,
  (payload: GetMatchingParams) => matching.getMatching(payload)
)

export const resetGetMatchingResources = createAction(
  `${MODULE_NAME}/resetGetMatchingResources`
)

export const bookAssignments = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/bookAssignments`,
  ({
    matchingResource,
    externalResource,
    ...payload
  }: BookAssignmentsPayload) =>
    externalResource
      ? needAssignments.bookAssignmentsExternally({
          ...payload,
          externalResourceHSAID: externalResource.hsaId,
          externalResourceName: externalResource.name,
        })
      : needAssignments.bookAssignmentsInternally({
          ...payload,
          resourceId: matchingResource.id,
        })
)

export const resetShifts = createAction(`${MODULE_NAME}/resetShifts`)

export const resetBookAssignments = createAction(
  `${MODULE_NAME}/resetBookAssignments`
)
export const resetMatchingAssignments = createAction(
  `${MODULE_NAME}/resetMatchingAssignments`
)

export const assignAssignmentToResources = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/assignAssignmentToResources`,
  (payload: AssignAssignmentToResourcesParams) =>
    needAssignments.assignAssignmentToResources(payload)
)

export const changeAssignmentState = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/changeAssignmentState`,
  (payload: ChangeAssignmentStateParams) =>
    needAssignments.changeAssignmentState(payload)
)

export const resetChangeAssignmentState = createAction(
  `${MODULE_NAME}/resetChangeAssignmentState`
)

export const modifyBookedAssignment = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/modifyBookedAssignment`,
  (payload: ModifyBookedAssignmentParams) =>
    needAssignments.modifyBookedAssignment(payload)
)

export const resetModifyBookedAssignment = createAction(
  `${MODULE_NAME}/resetModifyBookedAssignment`
)

export const setSelectedResource = createAction<SetSelectedResourcePayload>(
  `${MODULE_NAME}/setSelectedResource`
)

export const getUpdatedSelectedResource = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateSelectedResource`,
  (payload: GetMatchingResourcesParams) => matching.getMatching(payload)
)

export const resetSelectedResource = createAction(
  `${MODULE_NAME}/resetSelectedResource`
)

export const getNeedAssignmentsStatistics = createAsyncThunk(
  `${MODULE_NAME}/getNeedAssignmentsStatistics`,
  (payload: GetNeedAssignmentsStatisticsParams) =>
    units.getNeedAssignmentsStatistics(payload)
)

export const getBookedResources = createAsyncThunk(
  `${MODULE_NAME}/getBookedResources`,
  (payload: GetBookedResourcesParams) => units.getBookedResources(payload)
)

export const resetGetBookedResources = createAction(
  `${MODULE_NAME}/resetGetBookedResources`
)

export const setSelectedBookedResource = createAction<SetBookedResourcePayload>(
  `${MODULE_NAME}/setSelectedBookedResource`
)

export const requestAssignments = createAsyncThunk(
  `${MODULE_NAME}/requestAssignments`,
  (payload: RequestAssignmentsParams) =>
    needAssignments.requestAssignments(payload)
)

export const assignResourceToAssignments = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/assignResourceToAssignments`,
  (payload: AssignResourceToAssignmentsParams) =>
    needAssignments.assignResourceToAssignments(payload)
)

export const getBookedAssignments = createAsyncThunk(
  `${MODULE_NAME}/getBookedAssignments`,
  (payload: GetBookedAssignmentsParams) =>
    needAssignments.getBookedAssignments(payload)
)

export const unbookAssignments = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/unbookAssignments`,
  (payload: UnbookAssignmentsParams) =>
    needAssignments.unbookAssignment(payload)
)

export const setSelectedAssignments = createAction(
  `${MODULE_NAME}/setSelectedAssignments`,
  (payload: MatchingAssignment[]) => ({ payload })
)

export const resetSelectedAssignments = createAction(
  `${MODULE_NAME}/resetSelectedAssignments`
)

export const resetBookingScheduler = createAction(
  `${MODULE_NAME}/resetBookingScheduler`
)

export const getUnitNeedAssignments = createAsyncThunk(
  `${MODULE_NAME}/getUnitNeedAssignments`,
  (payload: GetUnitNeedAssignmentsParams) =>
    units.getUnitNeedAssignments(payload)
)

export const getUnitNeedsSummaries = createAsyncThunk(
  `${MODULE_NAME}/getUnitNeedsSummaries`,
  (payload: GetUnitNeedsSummariesParams) => needs.getUnitNeedsSummaries(payload)
)

export const exportNeedAssignments = createAsyncThunk(
  `${MODULE_NAME}/exportNeedAssignments`,
  async ({ params, onSuccess, onFailure }: ExportNeedAssignmentsPayload) => {
    try {
      const file = await needAssignments.exportNeedAssignments(params)
      onSuccess(file)
      return file
    } catch {
      onFailure()
    }
  }
)

export const exportNeedAssignmentsToViviumX = createAsyncThunk(
  `${MODULE_NAME}/exportNeedAssignmentsToViviumX`,
  async ({
    onSuccess,
    onFailure,
    ...payload
  }: ExportNeedAssignmentsToViviumXPayload) => {
    try {
      const data = await needAssignments.exportNeedAssignmentsToViviumX(payload)
      onSuccess(data.createdListingDraftsCount)
      return data
    } catch {
      onFailure()
    }
  }
)

export const resetExportNeedAssignments = createAction(
  `${MODULE_NAME}/resetExportNeedAssignments`
)

export const getRoles = createAsyncThunk(
  `${MODULE_NAME}/getRoles`,
  (payload: GetRolesSuggestionsParams) => suggestions.getRoles(payload)
)

export const filterUnitNotifications = createAction(
  `${MODULE_NAME}/getNeedsNotifications`,
  (payload: FilterUnitNotifications) => ({ payload })
)

export const updateNeed = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateNeed`,
  (payload: UpdateNeedPayload) => needs.updateNeed(payload)
)

export const resetUpdateNeed = createAction(`${MODULE_NAME}/resetUpdateNeed`)

export const enableSchedulerEditingMode = createAction(
  `${MODULE_NAME}/enableSchedulerEditingMode`
)

export const disableSchedulerEditingMode = createAction(
  `${MODULE_NAME}/disableSchedulerEditingMode`
)

export const addNeedAssignment = createAction(
  `${MODULE_NAME}/addNeedAssignment`,
  (payload: AssignmentToAdd) => ({ payload })
)

export const removeNeedAssignment = createAction(
  `${MODULE_NAME}/removeNeedAssignment`,
  (payload: AssignmentToRemove) => ({ payload })
)

export const openUpdateNeedDialog = createAction(
  `${MODULE_NAME}/openUpdateNeedDialog`,
  (payload: OpenUpdateNeedDialogPayload) => ({ payload })
)

export const closeUpdateNeedDialog = createAction(
  `${MODULE_NAME}/closeUpdateNeedDialog`
)
