import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { parseISO, isSameDay } from 'date-fns'
import resource from 'shared/resource'
import {
  getUnitNeedsResource,
  getSchedulesDaysData,
  getSchedulesShiftsData,
} from 'shared/store/schedules/selectors'
import { LoadingStatus, UnitNeedAssignment } from 'shared/types'
import { mapDaysToShifts } from 'shared/utils'
import { mapAvailabilitiesToDays } from './utils'

export const getPublishedNeedsResource = (state: RootState) =>
  state.needs.getPublishedNeeds

export const getPublishNeedResource = (state: RootState) =>
  state.needs.publishNeed

export const getShiftsResource = (state: RootState) => state.needs.getShifts

export const getShiftsData = (state: RootState) => state.needs.getShifts.data

export const getDetailedUnitsResourceData = (state: RootState) =>
  state.needs.getDetailedUnits

export const getMatchingResources = (state: RootState) =>
  state.needs.getMatchingResources

export const getBookAssignmentsResource = (state: RootState) =>
  state.needs.bookAssignments

export const getSelectedResource = (state: RootState) =>
  state.needs.selectedResource

export const getSelectedResourceBookedAssignments = createSelector(
  getSelectedResource,
  selectedResource => {
    if (!selectedResource) return []

    const {
      matchingAssignments: { bookableAssignments, requestableAssignments },
    } = selectedResource

    if (!!bookableAssignments?.length) return bookableAssignments
    if (!!requestableAssignments?.length) return requestableAssignments
    return []
  }
)

export const getSelectedBookedResource = (state: RootState) =>
  state.needs.selectedBookedResource

export const getSelectedAssignments = (state: RootState) =>
  state.needs.selectedAssignments

export const getSchedulerEditingModeIsEnabled = (state: RootState) =>
  state.needs.schedulerEditingMode.isEnabled

export const getAssigmentsToAdd = (state: RootState) =>
  state.needs.schedulerEditingMode.assignmentsToAdd

export const getAssignmentIdsToDelete = (state: RootState) =>
  state.needs.schedulerEditingMode.assignmentIdsToDelete

export const getSchedulerEditingModeDialogProps = (state: RootState) =>
  state.needs.schedulerEditingMode.dialog

export const getUpdateNeed = (state: RootState) => state.needs.updateNeed

export const getSchedulerDays = createSelector(
  getSchedulesDaysData,
  getSelectedResource,
  getSelectedBookedResource,
  getSelectedAssignments,
  getAssignmentIdsToDelete,
  getSchedulerEditingModeIsEnabled,
  getSchedulesShiftsData,
  (
    daysData,
    selectedResource,
    selectedBookedResource,
    selectedAssignments,
    assignmentIdsToDelete,
    isSchedulerEditingModeEnabled,
    shiftsData
  ) =>
    mapDaysToShifts({
      data: mapAvailabilitiesToDays({
        daysData,
        selectedResource,
        selectedBookedResource,
        selectedAssignments,
        shifts: shiftsData,
      }),
      assignmentIdsToDelete,
      isSchedulerEditingModeEnabled,
      shifts: shiftsData,
    })
)

export const getModifyBookedAssignmentResource = (state: RootState) =>
  state.needs.modifyBookedAssignment

export const getChangeAssignmentStateResource = (state: RootState) =>
  state.needs.changeAssignmentState

export const getModifyBookedAssignmentLoading = (state: RootState) =>
  state.needs.modifyBookedAssignment.loading

export const getChangeAssignmentStateLoading = (state: RootState) =>
  state.needs.changeAssignmentState.loading

export const getNeedAssignmentsStatistics = (state: RootState) =>
  state.needs.getNeedAssignmentsStatistics

export const getBookedResourcesResource = (state: RootState) =>
  state.needs.getBookedResources

export const getMatchingAssignments = createSelector(
  getSelectedResourceBookedAssignments,
  bookableAssignments =>
    bookableAssignments
      .filter(
        (assignment, i, self) =>
          i ===
          self.findIndex(
            a =>
              a.shiftId === assignment.shiftId &&
              isSameDay(parseISO(a.startDate), parseISO(assignment.startDate))
          )
      )
      .map(({ shiftId, shiftName, ...props }) => ({
        ...props,
        shift: {
          id: shiftId,
          name: shiftName,
        },
      }))
)

export const getRequestAssignmentsLoading = (state: RootState) =>
  state.needs.requestAssignments.loading

export const getIsAssignResourceToAssignmentsLoading = (state: RootState) =>
  state.needs.assignResourceToAssignments.loading === LoadingStatus.Pending

export const getBookedAssignmentsResource = (state: RootState) =>
  state.needs.getBookedAssignments

export const getBookedAssignmentsLoading = (state: RootState) =>
  state.needs.getBookedAssignments.loading === LoadingStatus.Pending

export const getUnbookAssignmentsLoading = (state: RootState) =>
  state.needs.unbookAssignments.loading === LoadingStatus.Pending

export const getUpdateSelectedResourceLoading = (state: RootState) =>
  state.needs.getUpdatedSelectedResource.loading === LoadingStatus.Pending

export const getUnitNeedAssignmentsResource =
  (rowId: number) => (state: RootState) =>
    state.needs.getUnitNeedAssignments[rowId] ||
    resource.getInitial<UnitNeedAssignment[]>([])

export const exportNeedAssignmentsSideEffects = (state: RootState) => ({
  loading: state.needs.exportNeedAssignments.loading,
  error: state.needs.exportNeedAssignments.error,
})

export const exportNeedAssignmentsToViviumXSideEffects = (
  state: RootState
) => ({
  loading: state.needs.exportNeedAssignmentsToViviumX.loading,
  error: state.needs.exportNeedAssignmentsToViviumX.error,
})

export const getRoles = (state: RootState) => state.needs.roles

export const getSelectedNeeds = (selectedNeedIds: number[]) =>
  createSelector(getUnitNeedsResource, unitNeeds =>
    unitNeeds.data.filter(need => selectedNeedIds.some(id => id === need.id))
  )

export const getUnitNeedsSummariesResource =
  (unitId: number) => (state: RootState) =>
    state.needs.getUnitNeedsSummaries[unitId] || resource.getInitial([])
