import { useMemo } from 'react'
import config from 'config'
import Suggestions from '../services/suggestions'
import { Country, NeedStatus, UserRole } from '../types'
import { createGetAutocompleteOptions } from '../utils'
import { useOrganizationId } from './useOrganization'

const { defaultSearchBy } = config
const suggestions = new Suggestions()

///// Common /////

interface UseGetRolesOptionsParams {
  hasCareProviderUsage?: boolean
  unitId?: number
}

export const useGetRoleOptions = ({
  hasCareProviderUsage = true,
  unitId,
}: UseGetRolesOptionsParams = {}) => {
  const careProviderId = useOrganizationId()

  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getRoles({
          search,
          unitId,
          searchBy: defaultSearchBy,
          ...(hasCareProviderUsage && { careProviderId }),
        })
      ),
    [careProviderId, hasCareProviderUsage, unitId]
  )
}

export const useGetCompetenceOptions = () =>
  useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCompetences({ search, searchBy: defaultSearchBy })
      ),
    []
  )

export const useGetRegionOptions = (country: Country) =>
  useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getRegions({ search, country, searchBy: defaultSearchBy })
      ),
    [country]
  )

interface UseGetSpecialtiesOptionsParams extends UseGetRolesOptionsParams {
  roleIds?: number[]
}

export const useGetSpecialtyOptions = ({
  hasCareProviderUsage = true,
  roleIds = [],
  unitId,
}: UseGetSpecialtiesOptionsParams = {}) => {
  const roles = JSON.stringify(roleIds)
  const careProviderId = useOrganizationId()

  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getSpecialties({
          roleIds,
          search,
          searchBy: defaultSearchBy,
          unitId,
          ...(hasCareProviderUsage && { careProviderId }),
        })
      ),
    [roles, careProviderId, hasCareProviderUsage, unitId] // eslint-disable-line react-hooks/exhaustive-deps
  )
}

///// Care Provider /////

interface UseGetCareProviderUnitOptions {
  resourceId?: number
  departmentId?: number
  tagIds?: number[]
  roleId?: number
}

export const useGetCareProviderUnitOptions = ({
  resourceId,
  departmentId,
  tagIds,
  roleId,
}: UseGetCareProviderUnitOptions = {}) => {
  const careProviderId = useOrganizationId()
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderUnits({
          careProviderId,
          resourceId,
          departmentId,
          tagIds,
          roleId,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [careProviderId, departmentId, resourceId, roleId, tagIds]
  )
}

export const useGetCareProviderDepartmentOptions = () => {
  const careProviderId = useOrganizationId()
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderDepartments({
          careProviderId,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [careProviderId]
  )
}

export const useGetCareProviderTagOptions = () => {
  const careProviderId = useOrganizationId()
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderTags({
          careProviderId,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [careProviderId]
  )
}

interface UseGetCareProviderResourceOptions {
  unitId?: number
  roleId?: number
}

export const useGetCareProviderResourceOptions = ({
  roleId,
  unitId,
}: UseGetCareProviderResourceOptions = {}) => {
  const careProviderId = useOrganizationId()
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderResources({
          roleId,
          unitId,
          careProviderId,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [careProviderId, roleId, unitId]
  )
}

export const useGetCareProviderAccountOptions = (authRole?: UserRole) => {
  const careProviderId = useOrganizationId()
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderAccounts({
          authRole,
          careProviderId,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [authRole, careProviderId]
  )
}

export const useGetCareProviderChatAccountOptions = (authRole?: UserRole) => {
  const careProviderId = useOrganizationId()
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderChatAccounts({
          authRole,
          careProviderId,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [authRole, careProviderId]
  )
}

interface UseGetCareProviderNeedsOptions {
  unitId?: number
  roleId?: number
  specialtyIds?: number[]
  needStatus?: NeedStatus
}

export const useGetCareProviderNeedsOptions = ({
  unitId,
  roleId,
  specialtyIds,
  needStatus,
}: UseGetCareProviderNeedsOptions) => {
  const careProviderId = useOrganizationId()
  const specialties = JSON.stringify(specialtyIds)
  return useMemo(
    () =>
      createGetAutocompleteOptions((search?: string) =>
        suggestions.getCareProviderNeeds({
          careProviderId,
          unitId,
          roleId,
          specialtyIds,
          needStatus,
          search,
          searchBy: defaultSearchBy,
        })
      ),
    [careProviderId, roleId, specialties, unitId] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
