import { RootState } from 'app'

export const getCreateResourceAccessibilities = (state: RootState) =>
  state.common.accessibilities.createResourceAccessibilities

export const getSingleResourceAccessibilityTableInfo = (state: RootState) =>
  state.common.accessibilities.getSingleResourceAccessibilityTableInfo

export const getUpdateResourceAccessibilities = (state: RootState) =>
  state.common.accessibilities.updateResourceAccessibility

export const getDeleteResourceAccessibilities = (state: RootState) =>
  state.common.accessibilities.deleteResourceAccessibility
