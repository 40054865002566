import { generatePath, Params } from 'react-router-dom'
import { format, format as formatDate, isValid, parse } from 'date-fns'
import { stringify } from 'qs'
import {
  AutocompleteValue,
  Entity,
  ScheduleDays,
  ScheduleDaysAssignment,
  ValueOf,
} from '../../types'

export const identity = <T>(value: T) => value

export const addIf = <T>(
  predicate: boolean | undefined | null,
  ...value: T[]
) => (predicate ? value : [])

export const partition = <T>(array: T[], predicate: (element: T) => boolean) =>
  array.reduce<T[][]>(
    (acc, el) => {
      acc[predicate(el) ? 0 : 1].push(el)
      return acc
    },
    [[], []]
  )

export const updateItemByKey = <T, U>(
  items: T[],
  itemKey: U,
  mapFn: (item: T) => T,
  getKey: (item: T) => U
) => items.map(item => (getKey(item) === itemKey ? mapFn(item) : item))

export const mergeWith = <T extends {}>(
  a: T,
  b: T,
  merge: (a: ValueOf<T>, b: ValueOf<T>) => ValueOf<T>,
  defaultValue: T = {} as T
) =>
  Object.entries(a).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: merge(value as ValueOf<T>, b[key as keyof T]),
    }),
    defaultValue
  )

export const getEntity = (data: AutocompleteValue): Entity | null =>
  !!data ? { id: parseInt(data.value, 10), name: data.label } : null

export const chunks = <T>(arr: Array<T>, size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size)
  )

export const trimString = (value: string, maxLength: number) =>
  value.length > maxLength ? `${value.substring(0, maxLength - 3)}...` : value

export const trimSeconds = (time: string) => time.slice(0, -3)

export const joinNames = <T extends Entity>(value: T[] = []) =>
  value.map(({ name }) => name).join(', ')

export const generateTimes = (step = 1, offset = 0, format = 'HH.mm') =>
  Array.from({ length: (24 * 60) / (step || 1) }, (_, i) =>
    formatDate(new Date().setHours(0, i * step + offset), format)
  )

export const getInitialLetters = (name: string = '', separator = '. ') =>
  name
    ? name
        .split(' ')
        .map(word => word.charAt(0))
        .join(separator)
    : ''

export const capitalize = (s: string) => s.replace(/^\w/, c => c.toUpperCase())

export const hhmmTimeToHHmmss = (time: string) =>
  formatDate(parse(time, 'HH.mm', new Date()), 'HH:mm:ss')

export const hhmmssTimetoHHmm = (time: string) =>
  formatDate(parse(time, 'HH:mm:ss', new Date()), 'HH.mm')

export const appendArray = <T>(data: FormData, name: string, arr: T[]) =>
  arr.forEach(item => data.append(`${name}[]`, item as any))

export const removeByIndex = <T>(arr: T[], index: number) =>
  arr.filter((_, i) => i !== index)

export const isEmpty = (obj: Object) => Object.keys(obj).length === 0

export const isNonNullObject = <T>(obj: T): boolean =>
  !!obj && typeof obj === 'object'

export const renderArrayOfEntity = (data: Entity[]) =>
  data.map(data => data.name).join(', ')

export const mapDaysAfterAssignmentChange = (
  days: ScheduleDays[],
  modifyAssignment: (
    assignment: ScheduleDaysAssignment
  ) => ScheduleDaysAssignment
) =>
  days.map(day => ({
    ...day,
    assignments: day.assignments.map(assignment =>
      modifyAssignment(assignment)
    ),
  }))

interface GeneratePathWithQueryParams {
  path: string
  params?: Params
  qs?: object
}

export const generatePathWithQuery = ({
  path,
  params,
  qs,
}: GeneratePathWithQueryParams) => {
  const url = generatePath(path, params)
  return qs ? `${url}?${stringify(qs)}` : url
}

export const toggleArrayItem = <T>(
  arr: T[],
  element: T,
  getValue: (item: T) => any = identity
) =>
  arr.some(el => getValue(el) === getValue(element))
    ? arr.filter(el => getValue(el) !== getValue(element))
    : [...arr, element]

export const getFileUrl = (file: ArrayBuffer) => {
  const blob = new Blob([file])
  return URL.createObjectURL(blob)
}

export const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link?.parentNode?.removeChild(link)
}

export const formatFileName = (s: string) =>
  s
    .replace(/[\\._-]/g, '')
    .replace(/\s+/g, ' ')
    .replace(/\s/g, '-')
    .toUpperCase()

export interface GenerateFileNameOptions {
  prefix?: string
  suffix?: string
  dateTo?: Date | null
  dateFrom?: Date | null
  extension?: string
  defaultName?: string
  dateFormat?: string
}

export const generateFileName = ({
  dateTo,
  dateFrom,
  prefix = '',
  suffix = '',
  extension = 'xlsx',
  dateFormat = 'ddMMyyyy',
}: GenerateFileNameOptions) => {
  const minDate =
    dateFrom && isValid(dateFrom) ? format(dateFrom, dateFormat) : ''
  const maxDate = dateTo && isValid(dateTo) ? format(dateTo, dateFormat) : ''

  return `${[formatFileName(prefix), minDate, maxDate, formatFileName(suffix)]
    .filter(Boolean)
    .join('-')}.${extension}`
}

export const joinStrings = (...strings: (string | null | undefined)[]) =>
  strings.filter(Boolean).join(' - ')

export const pick = <T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> => {
  const ret: any = {}
  keys.forEach(key => {
    ret[key] = obj[key]
  })
  return ret
}

export const validateEmptyStringToNull = (
  value: string,
  originalValue: string
) => (originalValue === '' ? null : value)

export const getShiftId = (id?: number | string, defaultValue: number = 0) => {
  if (typeof id === 'number') return id
  if (typeof id === 'string') return parseInt(id, 10)
  return defaultValue
}
