import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDispatch,
  useSelector,
  useOrganizationId,
  useOrganizationResourceId,
} from '../../../../../hooks'
import { actions, selectors } from '../../../../../store'
import { LoadingStatus } from '../../../../../types'
import { NOTIFICATIONS_PAGE_SIZE } from '../Notifications.utils'

export const useRequestsNotifications = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const careProviderId = useOrganizationId()
  const resourceId = useOrganizationResourceId()
  const { data, loading, isInitialized, meta } = useSelector(
    selectors.notifications.getRequestsNotifications
  )

  const getRequestsNotifications = useCallback(
    (skip: number = 0) =>
      dispatch(
        actions.notifications.getResourceRequestsNotifications({
          careProviderId,
          skip,
          take: NOTIFICATIONS_PAGE_SIZE,
          ...(resourceId && { resourceId }),
        })
      ),
    [careProviderId, dispatch, resourceId]
  )

  const resetRequestsNotifications = useCallback(
    () => dispatch(actions.notifications.resetRequestsNotifications()),
    [dispatch]
  )

  return {
    getRequestsNotifications,
    resetRequestsNotifications,
    onLoadMore: () => getRequestsNotifications(data.length),
    notifications: data,
    loading: loading === LoadingStatus.Pending,
    initialized: isInitialized,
    totalCount: meta?.count || 0,
    notificationTitle: t('notification.requests'),
  }
}

export const useBookingsNotifications = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const careProviderId = useOrganizationId()
  const resourceId = useOrganizationResourceId()
  const { data, loading, isInitialized, meta } = useSelector(
    selectors.notifications.getBookingsNotifications
  )

  const getBookingsNotifications = useCallback(
    (skip: number = 0) =>
      dispatch(
        actions.notifications.getResourceBookingsNotifications({
          careProviderId,
          skip,
          take: NOTIFICATIONS_PAGE_SIZE,
          ...(resourceId && { resourceId }),
        })
      ),
    [careProviderId, dispatch, resourceId]
  )

  const resetBookingsNotifications = useCallback(
    () => dispatch(actions.notifications.resetBookingsNotifications()),
    [dispatch]
  )

  return {
    getBookingsNotifications,
    resetBookingsNotifications,
    onLoadMore: () => getBookingsNotifications(data.length),
    notifications: data,
    loading: loading === LoadingStatus.Pending,
    initialized: isInitialized,
    totalCount: meta?.count || 0,
    notificationTitle: t('notification.bookings'),
  }
}
