import { matchPath, useLocation } from 'react-router-dom'

const DEFAULT_ID = 0

export const useId = <T extends string>(paramName: T, path: string) => {
  const location = useLocation()
  const pathname = location.pathname
  const state = location.state as { background?: Location } | null
  const match = matchPath(
    { path, end: false },
    state?.background?.pathname || pathname
  )
  const id: string = match?.params?.[paramName] || ''
  const parsedId = parseInt(id)
  return isNaN(parsedId) ? DEFAULT_ID : parsedId
}
