import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { i18n, consoleFilter, sentry } from 'config'
import App from './app'
import reportWebVitals from './reportWebVitals'

i18n.init()
consoleFilter.init()
sentry.init()

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
