import {
  DialogContent,
  DialogProps,
  styled,
  Dialog as BaseDialog,
} from '@mui/material'
import { Form } from '../../components'

export const Dialog = styled(BaseDialog)<DialogProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  backdropFilter: 'blur(18px)',
}))

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3, 3),
}))

export const StyledForm = styled(Form)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))
