import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { paths } from 'config'
import { useAuth } from './useAuth'

const DEFAULT_ORGANIZATION_ID = 0

/**
 * React hook that checks if there's an `organizationId` param
 * in the current path and:
 * - returns organization assigned to the current user based on the param
 * - returns user's first organization if the param is incorrect or missing
 * - returns null if user has no organizations
 * @returns Organization object or null.
 */
export const useOrganization = () => {
  const { user } = useAuth()
  const location = useLocation()
  const pathname = location.pathname
  const state = location.state as { background?: Location } | null
  const path = state?.background?.pathname || pathname

  const organizationId = useMemo(() => {
    const match = matchPath<'organizationId', string>(
      { path: paths.organization, end: false },
      path
    )
    const { organizationId = '' } = match?.params || {}
    const parsedId = parseInt(organizationId, 10)
    return isNaN(parsedId) ? user?.careProviders?.[0]?.id : parsedId
  }, [path, user?.careProviders])

  return useMemo(
    () => user?.careProviders?.find(({ id }) => id === organizationId) || null,
    [organizationId, user]
  )
}

/**
 * React hook that checks if there's an `organizationId` param
 * in the current path and returns ID of an organization assigned
 * to the current user or returns default organization id (0)
 * if a user has no organization or the organizationId parameter is incorrect.
 * @returns Organization id as number.
 */
export const useOrganizationId = () =>
  useOrganization()?.id || DEFAULT_ORGANIZATION_ID

export const useOrganizationResourceId = () => {
  const organization = useOrganization()
  const { resources } = organization || {}
  const [resource] = resources || []
  return resource?.id || null
}

export const useIsInOrganizationList = () => {
  const location = useLocation()
  const pathname = location.pathname
  const state = location.state as { background?: Location } | null
  const path = state?.background?.pathname || pathname
  const isInOrganizationsList = matchPath<'organizationId', string>(
    {
      path: paths.organizations,
      end: true,
    },
    path
  )

  return isInOrganizationsList
}
