import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { actions, selectors } from '../store'
import { AssignmentModalPayload } from '../store/schedules'
import { OpenShiftAssignmentDraft } from '../types'
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from './redux'

export const useAssignmentModal = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const data = useSelector(selectors.schedules.getAssignmentModalData)
  const { data: needAssignment } = useSelector(
    selectors.schedules.getNeedAssignmentDataResource
  )

  const { data: listingAssignment } = useSelector(
    selectors.schedules.getListingAssignmentDataResource
  )

  const selectedOpenShiftAssignment = useSelector(
    selectors.schedules.getSelectedOpenShiftAssignment
  ) as OpenShiftAssignmentDraft | null

  const setSelectedOpenShiftAssignment = useCallback(
    (assignment: OpenShiftAssignmentDraft) =>
      dispatch(actions.schedules.setSelectedOpenShiftAssignment(assignment)),
    [dispatch]
  )

  const closeModal = useCallback(
    () => dispatch(actions.schedules.closeAssignmentModal()),
    [dispatch]
  )

  const onGetAssignmentFailure = useCallback(
    (message?: string) => {
      enqueueSnackbar(message || t('error.defaultMessage'), {
        variant: 'error',
      })
      closeModal()
    },
    [closeModal, enqueueSnackbar, t]
  )

  const openModal = useCallback(
    ({
      needAssignmentId,
      listingAssignmentId,
      careProviderId,
      ...modalData
    }: AssignmentModalPayload) => {
      dispatch(
        actions.schedules.openAssignmentModal({ careProviderId, ...modalData })
      )
      if (listingAssignmentId) {
        return dispatch(
          actions.schedules.getListingAssignmentData({
            onFailure: onGetAssignmentFailure,
            careProviderId,
            assignmentId: listingAssignmentId,
          })
        )
      }

      if (needAssignmentId) {
        return dispatch(
          actions.schedules.getNeedAssignmentData({
            onFailure: onGetAssignmentFailure,
            careProviderId,
            assignmentId: needAssignmentId,
          })
        )
      }
    },
    [dispatch, onGetAssignmentFailure]
  )

  const onExit = useCallback(() => {
    dispatch(actions.matchingResources.resetMatchingResources())
    dispatch(actions.schedules.resetGetPickerAssignments())
    dispatch(actions.schedules.resetAssignmentData())
  }, [dispatch])

  const onBack = useCallback(() => {
    onExit()
    dispatch(actions.schedules.resetSelectedOpenShiftAssignment())
  }, [dispatch, onExit])

  return {
    data,
    needAssignment,
    listingAssignment,
    openModal,
    closeModal,
    onBack,
    onExit,
    setSelectedOpenShiftAssignment,
    openShiftAssignment: selectedOpenShiftAssignment,
  }
}
