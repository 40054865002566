import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns'
import { selectors } from '../../../store'
import { LisitingAssignmentStatus } from '../../../types'
import { useModalProps } from '../AssignmentModal.utils'

const ASSIGNMENT_WITH_RESOURCE_STATUSES = [
  LisitingAssignmentStatus.Accepted,
  LisitingAssignmentStatus.Booked,
]

export const useModalTitle = () => {
  const { listingAssignment, data } = useModalProps()
  const shiftName = useSelector(
    selectors.schedules.getSchedulesShiftName(data?.shiftId)
  )

  const { t } = useTranslation()

  const shiftText = `${format(
    parseISO(listingAssignment.startDate),
    'dd/MM'
  )} ${shiftName}`

  if (ASSIGNMENT_WITH_RESOURCE_STATUSES.includes(listingAssignment.status))
    return `${listingAssignment.resource?.name} - ${shiftText}`

  return `${t(`scheduler.status.${listingAssignment.status}`)} - ${shiftText}`
}
