import { alpha, styled } from '@mui/material'

export const Title = styled('span')(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6),
  fontSize: '1.3rem',
}))

export const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(0.75),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(0.5),
  '& svg': {
    marginRight: theme.spacing(1.5),
    fontSize: '1em',
    color: theme.palette.viviumX.main,
  },
}))

export const ButtonWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))
