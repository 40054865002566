import { svSE as MUIsvSE, enUS as MUIenUS } from '@mui/material/locale'
import { enGB, sv } from 'date-fns/locale'
import { LanguageCode } from 'shared/types'

export const dateFnsLocaleMap = {
  [LanguageCode.EN]: enGB,
  [LanguageCode.SV]: sv,
}

export const muiLocaleMap = {
  [LanguageCode.EN]: MUIenUS,
  [LanguageCode.SV]: MUIsvSE,
}
