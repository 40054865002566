import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, SchemaOf, string, boolean, array } from 'yup'

export const TIME_FORMAT = 'HH.mm'

export enum DailyAvailabilityItemFields {
  Date = 'date',
  TimeFrom = 'timeFrom',
  TimeTo = 'timeTo',
  IsOpenForOvertime = 'isOpenForOvertime',
  IsRemoved = 'isRemoved',
}

export interface DailyAvailabilityItemValues {
  [DailyAvailabilityItemFields.Date]: Date
  [DailyAvailabilityItemFields.TimeFrom]: string
  [DailyAvailabilityItemFields.TimeTo]: string
  [DailyAvailabilityItemFields.IsOpenForOvertime]?: boolean
  [DailyAvailabilityItemFields.IsRemoved]: boolean
}

export const useValidationSchema = (): SchemaOf<
  DailyAvailabilityItemValues[]
> => {
  const { t } = useTranslation()
  return array().of(
    object()
      .shape({
        [DailyAvailabilityItemFields.Date]: date().required(
          t('validation.required')
        ),
        [DailyAvailabilityItemFields.TimeFrom]: string().required(
          t('validation.required')
        ),
        [DailyAvailabilityItemFields.TimeTo]: string()
          .required(t('validation.required'))
          .test(
            'timeTo !== timeFrom',
            t('validation.differentThan', { value: t('time.from') }),
            function (timeTo) {
              return this.parent.timeFrom !== timeTo
            }
          ),
        [DailyAvailabilityItemFields.IsOpenForOvertime]: boolean(),
        [DailyAvailabilityItemFields.IsRemoved]: boolean(),
      })
      .required()
  )
}

export type DailyAvailabilityField = {
  id: string
} & DailyAvailabilityItemValues

interface UseInitialValueOptions {
  name: string
}

export const useFields = ({ name }: UseInitialValueOptions) => {
  const { control, setValue } = useFormContext()
  const [initialValues, setInitialValues] = useState<
    DailyAvailabilityItemValues[]
  >([])
  const { fields } = useFieldArray({ name, control })
  const fieldValues: DailyAvailabilityField[] = useWatch({ name, control })
  const controlledFields: DailyAvailabilityField[] = useMemo(
    () => fields.map((field, index) => ({ ...field, ...fieldValues[index] })),
    [fields, fieldValues]
  )

  useEffect(() => {
    setInitialValues(controlledFields.map(({ id, ...value }) => value))
  }, []) // eslint-disable-line

  const resetFields = useCallback(() => {
    setValue(name, initialValues)
  }, [name, initialValues, setValue])

  return { resetFields, fields: controlledFields }
}
