import { Grid, styled } from '@mui/material'
import { Skeleton } from '@mui/material'

const LOADER_ITEMS_NUMBER = 6

export const LoaderRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2),
  '& > *:first-child': {
    marginRight: theme.spacing(1),
  },
}))

interface LoaderProps {}

const Loader = (props: LoaderProps) => (
  <Grid item>
    {Array.from({ length: LOADER_ITEMS_NUMBER }, (_, i) => (
      <LoaderRow key={`loader-${i}`}>
        <Skeleton variant="rectangular" width={16} height={16} />
        <Skeleton variant="text" width={125} height={12} />
      </LoaderRow>
    ))}
  </Grid>
)

export default Loader
