import { Grid, Button, styled } from '@mui/material'

export const TitleContainer = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  background: theme.palette.background.default,
  padding: theme.spacing(0.5, 0),
  zIndex: 10,
}))

export const Title = styled('span')(({ theme }) => ({
  color: theme.palette.neutral.contrastText,
  fontSize: '1.25rem',
  textTransform: 'capitalize',
  paddingBottom: theme.spacing(1),
}))

export const CancelButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(8),
}))
