import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { LoadingStatus, ArchiveAssignment } from 'shared/types'
import { exportArchives, getArchives, resetExportArchives } from './actions'

interface State {
  loading: LoadingStatus
  error?: string | null
  getArchives: Resource<ArchiveAssignment[]>
  exportArchives: Resource<ArrayBuffer>
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  getArchives: resource.getInitial([]),
  exportArchives: resource.getInitial<ArrayBuffer>(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getArchives.pending, state => {
      resource.setPending(state.getArchives)
    })
    .addCase(getArchives.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getArchives,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getArchives.rejected, (state, action) => {
      resource.setFailed(state.getArchives, action.error.message)
    })
    .addCase(exportArchives.pending, state => {
      resource.setPending(state.exportArchives)
    })
    .addCase(exportArchives.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportArchives, action.payload)
    })
    .addCase(exportArchives.rejected, (state, action) => {
      resource.setFailed(state.exportArchives, action.error.message)
    })
    .addCase(resetExportArchives, state => {
      resource.reset(state.exportArchives, initialState.exportArchives.data)
    })
)
