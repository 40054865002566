import { memo } from 'react'
import { List } from '@mui/material'
import { ChartData } from '../../../../types'
import LegendItem from '../LegendItem'
import LegendItemLoadingIndicator from '../LegendItemLoadingIndicator'

const LOADER_ITEMS = 4
interface LegendProps {
  data?: ChartData[]
  loading: boolean
}

const Legend = memo(({ data, loading }: LegendProps) => {
  return (
    <List>
      {loading
        ? Array.from({ length: LOADER_ITEMS }, (_, i) => (
            <LegendItemLoadingIndicator key={`legend-item-loader-${i}`} />
          ))
        : data &&
          data.map((item, i) => (
            <LegendItem data={item} key={`legend-item-${i}`} />
          ))}
    </List>
  )
})

export default Legend
