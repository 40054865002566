//TODO: Remove this file once react-router v6 reimplements this component - https://github.com/remix-run/react-router/issues/8139
import { usePrompt } from '../hooks'

interface PromptProps {
  message: string
  when?: boolean
}

const Prompt = ({ message, when }: PromptProps) => {
  usePrompt(message, when)

  return null
}

export default Prompt
