import { Card as BaseCard, styled } from '@mui/material'

export const Card = styled(BaseCard)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  marginTop: theme.spacing(2),
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}))

export const FieldsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.75, 1.75),
}))
