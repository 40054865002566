import { PropsWithChildren } from 'react'
import { Grid, Typography } from '@mui/material'
import { Card, FieldsContainer } from './SectionCard.style'

interface SectionCardProps {
  label: string
  additionalText?: string
}

const SectionCard = ({
  label,
  children,
  additionalText,
}: PropsWithChildren<SectionCardProps>) => {
  return (
    <Card>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="overline" color="textSecondary">
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            {additionalText}
          </Typography>
        </Grid>
      </Grid>
      <FieldsContainer>{children}</FieldsContainer>
    </Card>
  )
}

export default SectionCard
