import { AssignmentStatus, Day } from '../types'

export const daysOfWeek: Day[] = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
]

export const potentialExternalAssignmentsStatuses = [
  AssignmentStatus.BookedExternally,
  AssignmentStatus.RequestedExternally,
]
