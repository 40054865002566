import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { useAssignmentsDatesText } from '../../hooks'
import { AssignmentStatus, UsePillTextsOptions } from '../../types'
import { getInitialLetters } from '../../utils'

export const usePillLabelText = ({
  assignment,
  isShorthand,
  shouldShowDatesText,
}: UsePillTextsOptions) => {
  const { t } = useTranslation()
  const assignmentsDatesText = useAssignmentsDatesText(
    assignment?.startDate,
    assignment?.endDate
  )

  return useMemo(() => {
    if (!assignment) return ''
    const { resource, externalResourceName, status } = assignment

    if (shouldShowDatesText) return assignmentsDatesText

    switch (status) {
      case AssignmentStatus.BookedInternally:
      case AssignmentStatus.Available:
      case AssignmentStatus.Bookable:
      case AssignmentStatus.Requestable:
        return isShorthand ? getInitialLetters(resource?.name) : resource?.name
      case AssignmentStatus.BookedExternally:
        return isShorthand
          ? getInitialLetters(externalResourceName)
          : externalResourceName
      case AssignmentStatus.RequestedExternally:
      case AssignmentStatus.RequestedInternally:
        return t(`scheduler.status.${status}`)
      case AssignmentStatus.NoResources:
      case AssignmentStatus.Empty:
      case AssignmentStatus.Canceled:
        return isShorthand ? '' : t(`scheduler.status.${status}`)
      default:
        return ''
    }
  }, [assignment, assignmentsDatesText, isShorthand, t, shouldShowDatesText])
}

export interface UseRoleFontColorOptions {
  assignmentStatus?: AssignmentStatus
  isPreliminary?: boolean
}

export const useRoleFontColor = ({
  assignmentStatus,
  isPreliminary,
}: UseRoleFontColorOptions) => {
  const theme = useTheme()
  return useMemo(() => {
    switch (assignmentStatus) {
      case AssignmentStatus.BookedInternally:
        return theme.palette.common.black
      case AssignmentStatus.BookedExternally:
        return isPreliminary
          ? theme.palette.common.black
          : theme.palette.common.white
      case AssignmentStatus.Canceled:
        return theme.palette.common.white
      default:
        return theme.palette.text.secondary
    }
  }, [assignmentStatus, isPreliminary, theme])
}

interface UseViviumXColorsParams {
  status?: AssignmentStatus
  hasAbsence?: boolean
}

export const useViviumXColors = ({
  hasAbsence = false,
  status,
}: UseViviumXColorsParams) => {
  const theme = useTheme()
  if (status === AssignmentStatus.BookedExternally)
    return {
      color: theme.palette.common.white,
      background: hasAbsence
        ? theme.palette.pill.red.main
        : theme.palette.pill.blue.main,
    }

  if (status === AssignmentStatus.Canceled)
    return {
      color: theme.palette.common.white,
      background: theme.palette.pill.gray.dark,
    }

  return {
    color: theme.palette.pill.blue.main,
    background: theme.palette.common.white,
  }
}
