import { useTranslation } from 'react-i18next'
import { LoadingStatus } from '../../types'
import { Button, Text } from './ReadAllNotificationsButton.style'
import { useReadAllNotifications } from './ReadAllNotificationsButton.utils'

interface ReadAllNotificationsButtonProps {}

const ReadAllNotificationsButton = (props: ReadAllNotificationsButtonProps) => {
  const { t } = useTranslation()
  const { markAsRead, loading } = useReadAllNotifications()

  return (
    <Button
      variant="outlined"
      onClick={markAsRead}
      loading={loading === LoadingStatus.Pending}
    >
      <Text>{t('notification.readAll')}</Text>
    </Button>
  )
}
export default ReadAllNotificationsButton
