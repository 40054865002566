import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom'
import {
  ListItem,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  Badge,
  SvgIconTypeMap,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Location } from '../../../types'
import { getCurrentPath } from '../../../utils'

export interface ListItemLinkProps extends Partial<ListItemProps> {
  label?: string
  icon?: OverridableComponent<SvgIconTypeMap>
  badgeCount?: number
  to: string
}

const ListItemLink = ({
  label,
  to,
  badgeCount,
  icon: Icon,
  ...props
}: ListItemLinkProps) => {
  const { t } = useTranslation()
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem
        // @ts-ignore
        button
        component={renderLink}
        {...props}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        {label && (
          <ListItemText
            primary={t(label)}
            primaryTypographyProps={{ color: 'inherit' }}
          />
        )}
        <Badge badgeContent={badgeCount} color="error" />
      </ListItem>
    </li>
  )
}

export interface DrawerItemProps extends ListItemLinkProps {
  end?: boolean
  match?: boolean
}

const DrawerItem = ({
  end = false,
  match = true,
  to,
  ...props
}: DrawerItemProps) => {
  const location = useLocation()

  const pathname = getCurrentPath(location as Location)
  const pathMatch = match ? matchPath({ end, path: to }, pathname) : null

  return (
    <ListItemLink
      className={pathMatch ? 'Mui-selected' : ''}
      to={`${to}${pathMatch ? location.search : ''}`}
      {...props}
    />
  )
}

export default DrawerItem
