import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { reducers } from 'modules'
import { persistStore } from 'redux-persist'
import { reducer as common } from 'shared/store'

const rootReducer = combineReducers({ ...reducers, common })

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Turned off due to redux-persist usage
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
