import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'app/App.store'
import i18next from 'i18next'
import { DEFAULT_LANGUAGE } from 'config/i18n'
import NeedSettings, {
  GetNeedSettingsParams,
  UpdateNeedSettingsParams,
} from '../../services/need-settings'
import { actions } from '../../store'
import { NAMESPACE } from '../../strings'
import { LanguageCode } from '../../types'
import { createAsyncThunkWithErrorHandling } from '../../utils'

const needSettings = new NeedSettings()

export const getNeedSettings = createAsyncThunk(
  `${NAMESPACE}/getNeedSettings`,
  (payload: GetNeedSettingsParams) => needSettings.getNeedSettings(payload)
)

export const patchNeedSettings = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/patchNeedSettings`,
  (payload: UpdateNeedSettingsParams) => needSettings.patchNeedSettings(payload)
)

export const setLanguage = createAction<LanguageCode>(
  `${NAMESPACE}/setLanguage`
)

export const setupLanguage = createAsyncThunk(
  `${NAMESPACE}/setLanguage`,
  (selectedLanguage: LanguageCode | undefined, { dispatch, getState }) => {
    const currentUser = (getState() as RootState).common.auth.user
    const supportedLanguageCodes = Object.values(LanguageCode) as string[]
    const handleSetLanguage = (language: LanguageCode) => {
      const supportedLanguage = supportedLanguageCodes.includes(language)
        ? language
        : DEFAULT_LANGUAGE

      localStorage.setItem('language', supportedLanguage)
      document.documentElement.lang = supportedLanguage
      i18next.changeLanguage(supportedLanguage)
      dispatch(setLanguage(supportedLanguage))
    }

    // on manual lang change + after log in:
    if (selectedLanguage) {
      handleSetLanguage(selectedLanguage)

      // update user account's language on manual lang change:
      if (currentUser) {
        dispatch(
          actions.auth.setCurrentUserLanguage({
            params: { language: selectedLanguage },
          })
        )
      }

      return
    }

    // on app init while logged in:
    if (currentUser?.language) {
      handleSetLanguage(currentUser.language)
      return
    }

    const matchBrowserPreferredLanguage = () => {
      const matchedLanguage = navigator.languages
        .find(lang => {
          const preferredLanguageShortCode = lang.substring(0, 2)

          return supportedLanguageCodes.includes(preferredLanguageShortCode)
        })
        ?.substring(0, 2)

      if (!!matchedLanguage) return matchedLanguage as LanguageCode
    }

    const initialLanguage =
      (localStorage.getItem('language') as LanguageCode) ||
      matchBrowserPreferredLanguage() ||
      DEFAULT_LANGUAGE

    // on app init while logged out (+ after logout too, because logging out reloads app):
    handleSetLanguage(initialLanguage)
  }
)

export const setSupportWidgetVisibility = createAction<boolean>(
  `${NAMESPACE}/setSupportWidgetVisibility`
)
