import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import Matching, { GetMatchingParams } from '../../services/matching'
import { NAMESPACE } from '../../strings'

const matching = new Matching()

export const getMatchingResources = createAsyncThunk(
  `${NAMESPACE}/getMatchingResources`,
  (payload: GetMatchingParams) => matching.getMatching(payload)
)

export const resetMatchingResources = createAction(
  `${NAMESPACE}/resetMatchingResources`
)
