import Api from '../api'
import { GetFAQsParams, GetFAQsResponse } from './Faqs.types'

class FAQs extends Api {
  public getFAQs = async (params: GetFAQsParams) => {
    const response = await this.api.get<GetFAQsResponse>('/FAQs', { params })

    const { data, ...meta } = response.data
    return { data, meta }
  }
}

export default FAQs
