import filterConsole from 'filter-console'

const wellKnownLogs: (string | RegExp)[] = [
  /Warning: Received .+ for a non-boolean attribute/,
  /Warning: React does not recognize the .+ prop on a DOM element/,
  /Warning: findDOMNode is deprecated in StrictMode./,
  /Warning: Failed prop type: Invalid prop `href` of type `function` supplied/,
  /Warning: Cannot update a component \(`ViewDatePlugin`\)/,
  /Material-UI: The `fade` color utility was renamed to `alpha`/,
  /TemplateConnectorBase/,
  // We're not using SSR, but Emotion maintainers are purposedly making it hard to disable this warning - https://github.com/emotion-js/emotion/issues/1105 , ignoring it here seems more sane:
  /The pseudo class ":first-child" is potentially unsafe when doing server-side rendering./,
]

const consoleFilter = {
  init: () => {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        '%c Some of the errors could be removed due to the development mode. You can find a list of them in config/consoleFilter.ts',
        'color: #FFCC00; font-size: 12px'
      )
      filterConsole(wellKnownLogs)
    }
  },
}

export default consoleFilter
