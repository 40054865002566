import activityLogs from './activityLogs'
import administration from './administration'
import archives from './archives'
import home from './home'
import messages from './messages'
import needs from './needs'
import organizations from './organizations'
import resourceNotifications from './resourceNotifications'
import resources from './resources'
import schedules from './schedules'
import statistics from './statistics'

export const routes = [
  // Add routes below
  ...home.routes,
  ...organizations.routes,
  ...needs.routes,
  ...schedules.routes,
  ...statistics.routes,
  ...resources.routes,
  ...archives.routes,
  ...messages.routes,
  ...activityLogs.routes,
  ...administration.routes,
  ...resourceNotifications.routes,
]

export const reducers = {
  // Add reducers below
  [home.name]: home.reducer,
  [organizations.name]: organizations.reducer,
  [needs.name]: needs.reducer,
  [schedules.name]: schedules.reducer,
  [statistics.name]: statistics.reducer,
  [resources.name]: resources.reducer,
  [archives.name]: archives.reducer,
  [messages.name]: messages.reducer,
  [activityLogs.name]: activityLogs.reducer,
  [administration.name]: administration.reducer,
  [resourceNotifications.name]: resourceNotifications.reducer,
}
