import {
  alpha,
  IconButton,
  styled,
  TableRow,
  TableRowProps,
} from '@mui/material'

export const ADDITIONAL_BUTTON_CLASS = 'additional-button'
const TABLE_ROW_HEIGHT = 80

export interface StyledTableRowProps extends Partial<TableRowProps> {
  flat?: boolean
  expand?: boolean
  nested?: boolean
  disableClick?: boolean
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
  ({ theme, flat, expand, nested, disableClick }) => ({
    ...(flat
      ? {
          ...(nested && {
            height: `calc(${TABLE_ROW_HEIGHT} - ${theme.spacing(2)})`,
          }),
          borderRadius: 0,
          boxShadow: 'none',
          cursor: disableClick ? 'default' : 'pointer',
          '& td': {
            backgroundColor: theme.palette.common.white,
          },
          '&:hover': {
            [`& .${ADDITIONAL_BUTTON_CLASS}`]: {
              visibility: 'visible',
            },
          },
        }
      : {
          height: TABLE_ROW_HEIGHT,
          boxShadow: `0px 2px 16px ${alpha(theme.palette.common.black, 0.2)}`,
          borderRadius: theme.spacing(0.5),
          '&:hover': {
            cursor: disableClick ? 'default' : 'pointer',
            '& td': {
              backgroundColor: theme.palette.neutral.main,
            },
            [`& .${ADDITIONAL_BUTTON_CLASS}`]: {
              visibility: 'visible',
            },
          },
          '& td': {
            backgroundColor: theme.palette.common.white,
          },
          '& td:first-child': {
            borderRadius: theme.spacing(0.5, 0, 0, 0.5),
          },
          '& td:last-child': {
            borderRadius: theme.spacing(0, 0.5, 0.5, 0),
          },
        }),
    [`& .${ADDITIONAL_BUTTON_CLASS}`]: {
      visibility: 'hidden',
    },
    ...(expand && {
      boxShadow: 'none',

      '& td': {
        backgroundColor: theme.palette.neutral.main,
      },
    }),
  })
)

export const ExpandTableRow = styled(TableRow)(({ theme }) => ({
  position: 'relative',
}))

export const Filler = styled('div')(({ theme }) => ({
  top: '100%',
  left: 0,
  right: 0,
  width: '100%',
  height: `calc(100% + ${TABLE_ROW_HEIGHT}px + ${theme.spacing(2)})`,
  zIndex: -1,
  position: 'absolute',
  transform: 'translateY(-100%)',
  background: theme.palette.neutral.main,
  boxShadow: `0px 2px 16px ${alpha(theme.palette.common.black, 0.2)}`,
  borderRadius: theme.spacing(0.5),
}))

export const DeleteButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}))
