import { useCallback, useMemo } from 'react'
import { format } from 'date-fns'
import { isSameDay } from 'date-fns'
import { useDateFns } from '../../hooks'
import { Shift } from '../../types'
import Control from './Control'
import { ControlWrapper } from './sharedSchedulerStyles'
import { DayWrapper } from './DayHeader.style'
import { addCol, deleteCol, BulkActionTypes, Event } from './Scheduler.utils'

interface DayProps {
  onChange: (events: Event[]) => void
  shifts: Shift[]
  events: Event[]
  day: Date
  selectedDay: Date | null
  setSelectedDay: (day: Date | null) => void
  onHoverTypeChange: (type: BulkActionTypes | null) => void
  showOnly?: boolean
  monthlyView?: boolean
  disabled: boolean
  defaultPillName?: string
}

const DayHeader = ({
  day,
  selectedDay,
  setSelectedDay,
  onHoverTypeChange,
  onChange,
  shifts,
  events,
  showOnly,
  monthlyView,
  disabled,
  defaultPillName,
}: DayProps) => {
  const dateOptions = useDateFns()
  const isHover = useMemo(
    () => selectedDay && isSameDay(day, selectedDay),
    [day, selectedDay]
  )
  const onControlAddHover = useCallback(
    () => onHoverTypeChange('add'),
    [onHoverTypeChange]
  )
  const onControlRemoveHover = useCallback(
    () => onHoverTypeChange('remove'),
    [onHoverTypeChange]
  )
  const onControlLeave = useCallback(
    () => onHoverTypeChange(null),
    [onHoverTypeChange]
  )
  const addEventsCol = useCallback(
    () => onChange(addCol({ events, day, shifts, defaultPillName })),
    [onChange, events, day, shifts, defaultPillName]
  )
  const deleteEventsCol = useCallback(
    () => onChange(deleteCol(events, day, shifts)),
    [onChange, events, day, shifts]
  )
  const shouldShowControls = useMemo(
    () => !disabled && !showOnly && isHover,
    [disabled, showOnly, isHover]
  )

  return (
    <DayWrapper
      onMouseEnter={() => setSelectedDay(day)}
      onMouseLeave={() => setSelectedDay(null)}
    >
      {!monthlyView && <strong>{format(day, 'EEE', dateOptions)}</strong>}
      <strong>{format(day, 'dd', dateOptions)}</strong>
      {shouldShowControls && (
        <ControlWrapper>
          <Control
            type="remove"
            onMouseEnter={onControlRemoveHover}
            onMouseLeave={onControlLeave}
            onClick={deleteEventsCol}
          />
          <Control
            type="add"
            onMouseEnter={onControlAddHover}
            onMouseLeave={onControlLeave}
            onClick={addEventsCol}
          />
        </ControlWrapper>
      )}
    </DayWrapper>
  )
}

export default DayHeader
