import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import Accounts, {
  CreateUserParams,
  DeleteUserParams,
  GetUserParams,
  UpdateUserParams,
} from 'shared/services/accounts'
import CareProviders, {
  GetCareProviderInformationParams,
  UpdateCareProviderInformationParams,
} from 'shared/services/care-providers'
import Departments, {
  CreateDepartmentParams,
  GetDepartmentParams,
  GetDetailedDepartmentsParams,
  UpdateDepartmentParams,
} from 'shared/services/departments'
import FAQs, { GetFAQsParams } from 'shared/services/faqs'
import HourRates, { GetHourRatesParams } from 'shared/services/hourRates'
import Resources, {
  GetResourcesTableInfoParams,
} from 'shared/services/resources'
import Shifts, {
  UpdateDefinedShiftParams,
  UpdateOpenShiftParams,
} from 'shared/services/shifts'
import SystemMessages, {
  GetSystemUpdateMessagesParams,
} from 'shared/services/system-messages'
import Units, {
  GetUnitParams,
  CreateUnitParams,
  UpdateUnitParams,
  GetAdminsParams,
  DeleteUnitParams,
  GetUnitsParams,
} from 'shared/services/units'
import { ActionType, UserRole } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME } from '../strings'
import {
  ChangeUnitStatusPayload,
  CreateHourRatePayload,
  CreateDefinedShiftPayload,
  DeleteHourRatePayload,
  UpdateHourRatePayload,
  ChangeUserStatusPayload,
  CreateOpenShiftPayload,
  DeleteShiftPayload,
} from './actions.types'

const units = new Units()
const shifts = new Shifts()
const hourRates = new HourRates()
const careProviders = new CareProviders()
const resources = new Resources()
const departments = new Departments()
const accounts = new Accounts()
const systemMessages = new SystemMessages()
const faqs = new FAQs()

export const getUnits = createAsyncThunk(
  `${MODULE_NAME}/getUnits`,
  (payload: GetUnitsParams) => units.getUnits(payload)
)

export const getAdministrationUnit = createAsyncThunk(
  `${MODULE_NAME}/getAdministrationUnit`,
  (payload: GetUnitParams) => units.getUnit(payload)
)

export const resetAdministrationUnit = createAction(
  `${MODULE_NAME}/resetAdministrationUnit`
)

export const createUnit = createAsyncThunk(
  `${MODULE_NAME}/addUnit`,
  (payload: CreateUnitParams) => units.createUnit(payload)
)

export const resetCreateUnit = createAction(`${MODULE_NAME}/resetAddUnit`)

export const updateUnit = createAsyncThunk(
  `${MODULE_NAME}/updateUnit`,
  (payload: UpdateUnitParams) => units.updateUnit(payload)
)

export const deleteUnit = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteUnit`,
  (payload: DeleteUnitParams) => units.deleteUnit(payload)
)

export const inactivateUnit = createAsyncThunk(
  `${MODULE_NAME}/inactivateUnitStatus`,
  (payload: ChangeUnitStatusPayload) =>
    units.changeUnitStatus({ ...payload, action: ActionType.Deactivate })
)

export const resetInactivateUnit = createAction(
  `${MODULE_NAME}/resetInactivateUnit`
)

export const activateUnit = createAsyncThunk(
  `${MODULE_NAME}/activateUnitStatus`,
  (payload: ChangeUnitStatusPayload) =>
    units.changeUnitStatus({ ...payload, action: ActionType.Activate })
)

export const resetActivateUnit = createAction(
  `${MODULE_NAME}/resetActivateUnit`
)

export const resetUpdateUnit = createAction(`${MODULE_NAME}/resetUpdateUnit`)

export const getConnectedResources = createAsyncThunk(
  `${MODULE_NAME}/getConnectedResources`,
  (payload: GetResourcesTableInfoParams) =>
    resources.getResourcesTableInfo(payload)
)

export const updateDefinedShift = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateDefinedShift`,
  (payload: UpdateDefinedShiftParams) => shifts.updateDefinedShift(payload)
)

export const createDefinedShift = createAsyncThunk(
  `${MODULE_NAME}/createDefinedShift`,
  async ({ params, onSuccess, onFailure }: CreateDefinedShiftPayload) => {
    try {
      const data = await shifts.createDefinedShift(params)
      onSuccess(parseInt(data, 10))
      return data
    } catch {
      onFailure()
    }
  }
)

export const updateOpenShift = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/updateOpenShift`,
  (payload: UpdateOpenShiftParams) => shifts.updateOpenShift(payload)
)

export const createOpenShift = createAsyncThunk(
  `${MODULE_NAME}/createOpenShift`,
  async ({ params, onSuccess, onFailure }: CreateOpenShiftPayload) => {
    try {
      const data = await shifts.createOpenShift(params)
      onSuccess(parseInt(data, 10))
      return data
    } catch {
      onFailure()
    }
  }
)

export const deleteShift = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteShift`,
  ({ shiftType, ...payload }: DeleteShiftPayload) => shifts.deleteShift(payload)
)

export const getHourRates = createAsyncThunk(
  `${MODULE_NAME}/getHourRates`,
  (payload: GetHourRatesParams) => hourRates.getRates(payload)
)

export const updateHourRate = createAsyncThunk(
  `${MODULE_NAME}/updateHourRate`,
  async (
    { params, onSuccess, onFailure }: UpdateHourRatePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await hourRates.updateRate(params)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
      return rejectWithValue(message)
    }
  }
)

export const createHourRate = createAsyncThunk(
  `${MODULE_NAME}/createHourRate`,
  async (
    { params, onSuccess, onFailure }: CreateHourRatePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = await hourRates.createRate(params)
      onSuccess(parseInt(data, 10))
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
      return rejectWithValue(message)
    }
  }
)

export const deleteHourRate = createAsyncThunk(
  `${MODULE_NAME}/deleteHourRate`,
  (
    { params, onSuccess, onFailure }: DeleteHourRatePayload,
    { rejectWithValue }
  ) => {
    try {
      const data = hourRates.deleteRate(params)
      onSuccess()
      return data
    } catch (err) {
      const error = err as AxiosError
      const message = error?.response?.data?.message
      onFailure(message)
      return rejectWithValue(message)
    }
  }
)

export const getMyInformation = createAsyncThunk(
  `${MODULE_NAME}/getMyInformation`,
  (payload: GetCareProviderInformationParams) =>
    careProviders.getCareProviderInformation(payload)
)

export const updateMyInformation = createAsyncThunk(
  `${MODULE_NAME}/updateMyInformation`,
  (payload: UpdateCareProviderInformationParams) =>
    careProviders.updateCareProviderInformation(payload)
)

export const resetUpdateMyInformation = createAction(
  `${MODULE_NAME}/resetUpdateMyInformation`
)

export const getDetailedDepartments = createAsyncThunk(
  `${MODULE_NAME}/getDetailedDepartments`,
  (payload: GetDetailedDepartmentsParams) =>
    departments.getDetailedDepartments(payload)
)

export const resetGetDetailedDepartments = createAction(
  `${MODULE_NAME}/resetGetDetailedDepartments`
)

export const getDepartment = createAsyncThunk(
  `${MODULE_NAME}/getDepartment`,
  (payload: GetDepartmentParams) => departments.getDepartment(payload)
)

export const resetGetDepartment = createAction(
  `${MODULE_NAME}/resetGetDepartment`
)

export const createDepartment = createAsyncThunk(
  `${MODULE_NAME}/createDepartment`,
  (payload: CreateDepartmentParams) => departments.createDepartment(payload)
)

export const resetCreateDepartment = createAction(
  `${MODULE_NAME}/resetCreateDepartment`
)

export const updateDepartment = createAsyncThunk(
  `${MODULE_NAME}/updateDepartment`,
  (payload: UpdateDepartmentParams) => departments.updateDepartment(payload)
)

export const resetUpdateDepartment = createAction(
  `${MODULE_NAME}/resetUpdateDepartment`
)
export const createUser = createAsyncThunk(
  `${MODULE_NAME}/createUser`,
  (payload: CreateUserParams) => accounts.createUser(payload)
)

export const resetCreateUser = createAction(`${MODULE_NAME}/resetCreateUser`)

export const updateUser = createAsyncThunk(
  `${MODULE_NAME}/updateUser`,
  (payload: UpdateUserParams) => accounts.updateUser(payload)
)

export const resetUpdateUser = createAction(`${MODULE_NAME}/resetUpdateUser`)

export const deleteUser = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteUser`,
  (payload: DeleteUserParams) => accounts.deleteUser(payload)
)

export const resetDeleteUser = createAction(`${MODULE_NAME}/resetDeleteUser`)

export const deactivateUser = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deactivateUser`,
  (payload: ChangeUserStatusPayload) =>
    accounts.changeUserStatus({
      ...payload,
      action: ActionType.Deactivate,
    })
)

export const resetDeactivateUser = createAction(
  `${MODULE_NAME}/resetDeactivateUser`
)

export const activateUser = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/activateUser`,
  (payload: ChangeUserStatusPayload) =>
    accounts.changeUserStatus({
      ...payload,
      action: ActionType.Activate,
    })
)

export const resetActivateUser = createAction(
  `${MODULE_NAME}/resetActivateUser`
)

export const getUser = createAsyncThunk(
  `${MODULE_NAME}/getUser`,
  (payload: GetUserParams) => accounts.getUser(payload)
)

export const resetGetUser = createAction(`${MODULE_NAME}/resetGetUser`)

export const getSuperCareProviders = createAsyncThunk(
  `${MODULE_NAME}/getSuperCareProviders`,
  (payload: GetAdminsParams) =>
    units.getAdmins({ ...payload, authRole: UserRole.SuperCareProvider })
)

export const getCareProviders = createAsyncThunk(
  `${MODULE_NAME}/getCareProviders`,
  (payload: GetAdminsParams) =>
    units.getAdmins({ ...payload, authRole: UserRole.CareProvider })
)

export const getUnitAdmins = createAsyncThunk(
  `${MODULE_NAME}/getUnitAdmins`,
  (payload: GetAdminsParams) =>
    units.getAdmins({ ...payload, authRole: UserRole.UnitAdmin })
)

export const getSystemUpdateMessages = createAsyncThunk(
  `${MODULE_NAME}/getSystemUpdateMessages`,
  (payload: GetSystemUpdateMessagesParams) =>
    systemMessages.getSystemUpdateMessages(payload)
)

export const getFAQs = createAsyncThunk(
  `${MODULE_NAME}/getFAQs`,
  (params: GetFAQsParams) => faqs.getFAQs(params)
)
