import { useCallback, useEffect, useState } from 'react'
import { ComputedFn, Getter, Plugin } from '@devexpress/dx-react-core'
import { endOfDay, startOfDay } from 'date-fns'
import { useUpdateQuery } from '../../hooks'
import { formatQueryDate } from '../../utils'
import { ViewDateGetter } from './ViewDatePlugin.types'

const NAME = 'ViewDatePlugin'

interface ViewDatePluginProps {}

const ViewDatePlugin = (props: ViewDatePluginProps) => {
  const updateQuery = useUpdateQuery()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (!startDate || !endDate) return
    updateQuery({
      dateFrom: formatQueryDate(startOfDay(startDate)),
      dateTo: formatQueryDate(endOfDay(endDate)),
    })
  }, [startDate, endDate, updateQuery])

  const handleStartDateChange: ComputedFn = useCallback(
    ({ startViewDate }) => setStartDate(startViewDate),
    []
  )
  const handleEndDateChange: ComputedFn = useCallback(
    ({ endViewDate }) => setEndDate(endViewDate),
    []
  )

  return (
    <Plugin name={NAME}>
      <Getter
        name={ViewDateGetter.StartViewDate}
        computed={handleStartDateChange}
      />
      <Getter
        name={ViewDateGetter.EndViewDate}
        computed={handleEndDateChange}
      />
    </Plugin>
  )
}

export default ViewDatePlugin
