const paths = {
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  activateAccount: '/activate-account',
  newPassword: '/new-password',
  multifactorAuthentication: '/mfa',
  organizations: '/organizations',
  organization: '/organizations/:organizationId',
  organizationNeeds: '/organizations/:organizationId/needs',
  organizationResources: '/organizations/:organizationId/resources',
  organizationResourceDetails:
    '/organizations/:organizationId/resources/:resourceId',
  organizationNewResource: '/organizations/:organizationId/resources/new',
  organizationSchedules: '/organizations/:organizationId/schedules',
  organizationSchedulesAvailability:
    '/organizations/:organizationId/schedules/availability',
  organizationArchives: '/organizations/:organizationId/archives',
  organizationMessages: '/organizations/:organizationId/messages',
  organizationActivityLogs: '/organizations/:organizationId/activity-logs',
  organizationStatistics: '/organizations/:organizationId/statistics',
  organizationAdministration: '/organizations/:organizationId/administration',
  organizationAdministrationNewUnit:
    '/organizations/:organizationId/administration/units/new',
  organizationAdministrationUnit:
    '/organizations/:organizationId/administration/units/:unitId',
  organizationAdministrationNewDepartment:
    '/organizations/:organizationId/administration/departments/new',
  organizationAdministrationDepartment:
    '/organizations/:organizationId/administration/departments/:departmentId',
  organizationAdministrationNewUser:
    '/organizations/:organizationId/administration/users/new',
  organizationAdministrationUser:
    '/organizations/:organizationId/administration/users/:userId',
  organizationUnit: '/organizations/:organizationId/units/:unitId',
  organizationUnits: '/organizations/:organizationId/needs/units',
  organizationUnitBookings:
    '/organizations/:organizationId/needs/units/:unitId',
  newNeed: '/organizations/:organizationId/needs/new',
  unauthorized: '/unauthorized',
  forbidden: '/forbidden',
  organizationBookings: '/organizations/:organizationId/bookings',
  organizationRequests: '/organizations/:organizationId/requests',
  organizationHelp: '/organizations/:organizationId/help',
}

export default paths
