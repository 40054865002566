import { alpha, Grid, IconButton, styled, Typography } from '@mui/material'

export const MessageText = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6),
  padding: theme.spacing(1, 0, 1, 1),
}))

export const HeaderGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))

export const TitleGrid = styled(Grid)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '85%',
})

export const Title = styled('span')(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6),
  fontSize: '1.3rem',
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: 0,
}))

export const StyledEditButton = styled(IconButton)(({ theme }) => ({
  paddingBottom: theme.spacing(0.75),
}))
