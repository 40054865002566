import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { StylesProvider } from '@mui/styles'
import { Loader } from 'shared/components'
import { generateClassName } from 'shared/utils'
import Shell from './Shell'
import { store, persistor } from './App.store'

const App = () => (
  <StylesProvider generateClassName={generateClassName}>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <Shell />
      </PersistGate>
    </Provider>
  </StylesProvider>
)

export default App
