import { ChangeEvent, useCallback, useMemo } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import {
  TextField,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material'
import { AutocompleteFieldOption, LoadingStatus } from '../../types'
import { Loader, StyledChip } from './AutocompleteField.style'
import { AutocompleteFieldProps } from './AutocompleteField.types'
import {
  defaultGetOptionLabel,
  getOptionSelected,
  useAutocomplete,
  useGetMultipleProps,
} from './AutocompleteField.utils'

const LOADER_SIZE = 16

const AutocompleteField = ({
  name,
  label,
  placeholder,
  textFieldProps,
  FieldIcon,
  defaultValue = null,
  multiple = false,
  withAddIcons = false,
  withChips = false,
  readOnly = false,
  disableClearable = false,
  getOptionLabel = defaultGetOptionLabel,
  ...props
}: AutocompleteFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })

  const {
    isOpen,
    loading,
    handleInputChange,
    handleOpen,
    handleClose,
    options,
  } = useAutocomplete(props)
  const getMultipleProps = useGetMultipleProps({
    withAddIcons,
    withChips,
    getOptionLabel,
  })

  const isEmpty = multiple && Array.isArray(value) ? value.length === 0 : !value
  const isLoading = loading === LoadingStatus.Pending

  const textFieldPlaceholder =
    multiple && !withChips ? (isEmpty ? placeholder : '') : placeholder

  const handleChange = useCallback(
    (e: ChangeEvent<{}>, val: any) => onChange(val),
    [onChange]
  )

  const getTextFieldProps = useCallback(
    (params: AutocompleteRenderInputParams) =>
      typeof textFieldProps === 'function'
        ? textFieldProps(params)
        : textFieldProps,
    [textFieldProps]
  )

  const inputLabel = useMemo(
    () =>
      withChips && Array.isArray(value) && value.length > 0
        ? `${label} (${value.length})`
        : label,
    [label, withChips, value]
  )

  const createDeleteHandler = useCallback(
    (valueToDelete: AutocompleteFieldOption) => () => {
      onChange(
        value.filter(
          (v: AutocompleteFieldOption) => v.value !== valueToDelete.value
        )
      )
    },
    [onChange, value]
  )

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const fieldProps = getTextFieldProps(params)
      return (
        <TextField
          {...params}
          error={!!error}
          helperText={error?.message}
          label={inputLabel}
          placeholder={textFieldPlaceholder}
          {...fieldProps}
          inputProps={{ readOnly, ...params.inputProps }}
          InputProps={{
            ...(!multiple && { startAdornment: FieldIcon }),
            ...params?.InputProps,
            ...fieldProps?.InputProps,
            endAdornment: (
              <>
                {isLoading && (
                  <Loader
                    color="secondary"
                    size={LOADER_SIZE}
                    shifted={!isEmpty}
                  />
                )}
                {params.InputProps.endAdornment}
                {fieldProps?.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )
    },
    [
      getTextFieldProps,
      error,
      inputLabel,
      textFieldPlaceholder,
      readOnly,
      isLoading,
      FieldIcon,
      isEmpty,
      multiple,
    ]
  )

  return (
    <>
      <Autocomplete
        {...inputProps}
        options={options}
        open={isOpen}
        onOpen={() => !readOnly && handleOpen()}
        onClose={handleClose}
        disableClearable={disableClearable || readOnly}
        openOnFocus
        onChange={handleChange}
        value={value}
        multiple={multiple}
        loading={isLoading}
        filterSelectedOptions={withAddIcons}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={getOptionLabel}
        onInputChange={handleInputChange}
        renderInput={renderInput}
        {...(multiple && getMultipleProps())}
        {...props}
      />
      {withChips &&
        multiple &&
        (value as AutocompleteFieldOption[]).map((value, index) => (
          <StyledChip
            key={`chip-${index}`}
            label={value.label}
            variant="outlined"
            color="primary"
            size="small"
            onDelete={createDeleteHandler(value)}
          />
        ))}
    </>
  )
}

export default AutocompleteField
