import { Components } from '@mui/material'

const MuiBadge: Components['MuiBadge'] = {
  styleOverrides: {
    root: { color: '#FFF' },
    standard: {
      height: '1.125rem',
      minWidth: '1.125rem',
      fontSize: '0.625rem',
    },
  },
}

export default MuiBadge
