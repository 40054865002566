import {
  Button,
  Chip,
  darken,
  Grid,
  IconButton,
  lighten,
  styled,
  Typography,
} from '@mui/material'

interface ContainerProps {
  bar?: boolean
}

export const Container = styled('div')<ContainerProps>(
  ({ theme, bar = false }) => ({
    ...(bar
      ? {
          padding: theme.spacing(1, 3),
          position: 'relative',
        }
      : {
          borderRadius: 3,
          position: 'absolute',
          bottom: theme.spacing(2),
          maxWidth: `calc(${theme.breakpoints.values.sm}px - ${theme.spacing(
            6
          )})`,
          padding: theme.spacing(2.5),
          [theme.breakpoints.down('sm')]: {
            maxWidth: `calc(100vw - ${theme.spacing(2.5)})`,
            left: 10,
          },
        }),
    zIndex: 1700,
    width: '100%',
    backgroundColor: lighten(theme.palette.common.black, 0.03),
    color: theme.palette.common.white,
    borderBottom: `1px ${theme.palette.grey[400]} solid`,
  })
)

export const CloseButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.25),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  '& svg': {
    fontSize: '0.875rem',
  },
  '&:hover': {
    backgroundColor: darken(theme.palette.common.white, 0.2),
  },
}))

export const UpdateMessageChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  color: theme.palette.common.white,
  '& .MuiChip-label': {
    marginRight: 0,
    padding: theme.spacing(0, 2),
    fontSize: '0.85rem',
  },
}))

export const ReadMoreButton = styled(Button)(({ theme }) => ({
  minHeight: theme.spacing(3),
  height: theme.spacing(3),
  borderColor: theme.palette.common.white,
  borderWidth: 1,
  '&:hover': { borderWidth: 1 },
}))

export const ButtonText = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  letterSpacing: 1,
  color: theme.palette.common.white,
}))

export const MessageText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

export const StyledGrid = styled(Grid)(() => ({
  overflow: 'hidden',
}))
