import { SvgIconProps, Badge } from '@mui/material'
import { Button, StyledLabel } from './NotificationButton.style'

export interface NotificationButtonProps {
  icon: (props: SvgIconProps) => JSX.Element
  label?: string
  unreadCount?: number
}

const NotificationButton = ({
  icon: Icon,
  label,
  unreadCount,
}: NotificationButtonProps) => (
  <Button>
    <Badge badgeContent={unreadCount} color="error">
      <Icon fontSize="medium" />
    </Badge>
    {label && <StyledLabel variant="body2">{label}</StyledLabel>}
  </Button>
)

export default NotificationButton
