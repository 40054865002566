import Api from '../api'
import { GetLogsParams, GetLogsResponse } from './Logs.types'

class Logs extends Api {
  public getLogs = async ({ careProviderId, ...params }: GetLogsParams) => {
    const response = await this.api.get<GetLogsResponse>(
      `/care-providers/${careProviderId}/activity-logs`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }
}

export default Logs
