import { initReactI18next } from 'react-i18next'
import {
  differenceInDays,
  parseISO,
  format as formatDate,
  isDate,
} from 'date-fns'
import { sv, enGB as en, nb as no } from 'date-fns/locale'
import i18next, { InitOptions } from 'i18next'
import Backend from 'i18next-http-backend'
import { DateFnsOptions, LanguageCode } from 'shared/types'
import constants from './constants'

export const DEFAULT_LANGUAGE = LanguageCode.EN
const DEFAULT_NAMESPACE = 'common'

const weekStartsOn = constants.scheduler.firstDayOfWeek

export const dateFnsOptions: Record<string, DateFnsOptions> = {
  sv: {
    weekStartsOn,
    locale: { ...sv, options: { ...sv.options, weekStartsOn } },
  },
  en: {
    weekStartsOn,
    locale: { ...en, options: { ...en.options, weekStartsOn } },
  },
  no: {
    weekStartsOn,
    locale: { ...no, options: { ...no.options, weekStartsOn } },
  },
}

const currentVersion = constants.currentVersion

const i18nOptions: InitOptions = {
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  defaultNS: DEFAULT_NAMESPACE,
  fallbackNS: DEFAULT_NAMESPACE,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    queryStringParams: { currentVersion: currentVersion },
    requestOptions: {
      cache: 'no-cache',
    },
  },
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng = DEFAULT_LANGUAGE) {
      if (format === 'daysLeft') {
        return differenceInDays(parseISO(value), new Date())
      }
      if (isDate(value)) {
        return formatDate(value, format || '', dateFnsOptions[lng])
      }
      return value
    },
  },
}

const i18n = {
  init: () => i18next.use(Backend).use(initReactI18next).init(i18nOptions),
}

export default i18n
