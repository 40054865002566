import { RootState } from 'app'
import { Resource } from 'shared/resource'
import {
  DepartmentStatistics,
  LoadingStatus,
  ShiftStatistics,
  UnitStatistics,
} from 'shared/types'

export const getDepartmentsStaticsSummaryData = (state: RootState) =>
  state.statistics.getDepartmentsSummary.data?.summary

export const getUnitsStaticsSummaryData = (state: RootState) =>
  state.statistics.getUnitsSummary.data?.summary

export const getShiftsStaticsSummaryData = (state: RootState) =>
  state.statistics.getShiftsSummary.data?.summary

export const getDepartmentsStatisticsResource = (
  state: RootState
): Resource<DepartmentStatistics[]> => {
  const { data, ...resource } = state.statistics.getDepartmentsSummary
  return {
    ...resource,
    data: data?.statistics || [],
  }
}

export const getUnitsStatisticsResource = (
  state: RootState
): Resource<UnitStatistics[]> => {
  const { data, ...resource } = state.statistics.getUnitsSummary
  return {
    ...resource,
    data: data?.statistics || [],
  }
}

export const getShiftsStatisticsResource = (
  state: RootState
): Resource<ShiftStatistics[]> => {
  const { data, ...resource } = state.statistics.getShiftsSummary
  return {
    ...resource,
    data: data?.statistics || [],
  }
}

export const getIsExportingUnitStatitstics = (state: RootState) =>
  state.statistics.exportUnitStatistics.loading === LoadingStatus.Pending

export const getIsExportingOrganizationStatitstics = (state: RootState) =>
  state.statistics.exportOrganizationStatistics.loading ===
  LoadingStatus.Pending
