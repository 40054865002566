import Api from '../api'
import {
  GetCompetencesSuggestionsParams,
  GetCompetencesSuggestionsResponse,
  GetRegionsSuggestionsParams,
  GetRegionsSuggestionsResponse,
  GetRolesSuggestionsParams,
  GetRolesSuggestionsResponse,
  GetSpecialtiesSuggestionsParams,
  GetSpecialtiesSuggestionsResponse,
  GetCareProviderUnitsSuggestionsParams,
  GetCareProviderUnitsSuggestionsResponse,
  GetCareProviderDepartmentsSuggestionsParams,
  GetCareProviderDepartmentsSuggestionsResponse,
  GetCareProviderTagsSuggestionsParams,
  GetCareProviderTagsSuggestionsResponse,
  GetCareProviderResourcesSuggestionsParams,
  GetCareProviderResourcesSuggestionsResponse,
  GetCareProviderAccountsSuggestionsParams,
  GetCareProviderAccountsSuggestionsResponse,
  GetCareProviderChatAccountsSuggestionsParams,
  GetCareProviderChatAccountsSuggestionsResponse,
  GetCareProviderNeedsSuggestionsParams,
  GetCareProviderNeedsSuggestionsResponse,
} from './Suggestions.types'

class Suggestions extends Api {
  public async getRoles(params?: GetRolesSuggestionsParams) {
    const { data } = await this.api.get<GetRolesSuggestionsResponse>(
      '/suggestions/roles',
      { params }
    )
    return data.data
  }

  public async getCompetences(params?: GetCompetencesSuggestionsParams) {
    const { data } = await this.api.get<GetCompetencesSuggestionsResponse>(
      '/suggestions/competences',
      { params }
    )
    return data.data
  }

  public async getRegions(params?: GetRegionsSuggestionsParams) {
    const { data } = await this.api.get<GetRegionsSuggestionsResponse>(
      '/suggestions/regions',
      { params }
    )
    return data.data
  }

  public async getSpecialties(params?: GetSpecialtiesSuggestionsParams) {
    const { data } = await this.api.get<GetSpecialtiesSuggestionsResponse>(
      '/suggestions/specialties',
      { params }
    )
    return data.data
  }

  public async getCareProviderUnits({
    careProviderId,
    ...params
  }: GetCareProviderUnitsSuggestionsParams) {
    const { data } =
      await this.api.get<GetCareProviderUnitsSuggestionsResponse>(
        `/care-providers/${careProviderId}/suggestions/units`,
        { params }
      )
    return data.data
  }

  public async getCareProviderDepartments({
    careProviderId,
    ...params
  }: GetCareProviderDepartmentsSuggestionsParams) {
    const { data } =
      await this.api.get<GetCareProviderDepartmentsSuggestionsResponse>(
        `/care-providers/${careProviderId}/suggestions/departments`,
        { params }
      )
    return data.data
  }

  public async getCareProviderTags({
    careProviderId,
    ...params
  }: GetCareProviderTagsSuggestionsParams) {
    const { data } = await this.api.get<GetCareProviderTagsSuggestionsResponse>(
      `/care-providers/${careProviderId}/suggestions/tags`,
      { params }
    )
    return data.data
  }

  public async getCareProviderResources({
    careProviderId,
    ...params
  }: GetCareProviderResourcesSuggestionsParams) {
    const { data } =
      await this.api.get<GetCareProviderResourcesSuggestionsResponse>(
        `/care-providers/${careProviderId}/suggestions/resources`,
        { params }
      )
    return data.data
  }

  public async getCareProviderAccounts({
    careProviderId,
    ...params
  }: GetCareProviderAccountsSuggestionsParams) {
    const { data } =
      await this.api.get<GetCareProviderAccountsSuggestionsResponse>(
        `/care-providers/${careProviderId}/suggestions/accounts`,
        { params }
      )
    return data.data
  }

  public async getCareProviderChatAccounts({
    careProviderId,
    ...params
  }: GetCareProviderChatAccountsSuggestionsParams) {
    const { data } =
      await this.api.get<GetCareProviderChatAccountsSuggestionsResponse>(
        `/care-providers/${careProviderId}/suggestions/chat-accounts`,
        { params }
      )
    return data.data
  }

  public async getCareProviderNeeds({
    careProviderId,
    ...params
  }: GetCareProviderNeedsSuggestionsParams) {
    const { data } =
      await this.api.get<GetCareProviderNeedsSuggestionsResponse>(
        `/care-providers/${careProviderId}/suggestions/needs`,
        { params }
      )
    return data.data
  }
}

export default Suggestions
