import { CSSProperties } from 'react'
import { Backdrop, Popover, styled } from '@mui/material'

const BACKDROP_TOP_OFFSET = 64
const LIST_HEIGHT_CORRECTION = 72

export const ListContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
  borderRadius: theme.spacing(1),
  maxHeight: `calc((100vh - ${LIST_HEIGHT_CORRECTION}px)/2)`,
  overflow: 'auto',

  '&:first-child': {
    marginTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: `grey 0px ${theme.spacing(2)} ${theme.spacing(
      1
    )} ${theme.spacing(-2)} inset`,
  },
}))

export const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    minWidth: '100vw',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
}))

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  position: 'absolute',
  top: BACKDROP_TOP_OFFSET,
  height: '100vh',
  zIndex: theme.zIndex.appBar,
}))

export const backdropStyles: CSSProperties = {
  opacity: 0.7,
}
