import { Components } from '@mui/material'

const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: {
    '*': {
      '&:focus': { outline: 'none' },
      WebkitTapHighlightColor: 'transparent',
    },
    '#root': {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    a: { color: 'inherit', textDecoration: 'none' },
  },
}

export default MuiCssBaseline
