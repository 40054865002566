import { useCallback, useRef, useState } from 'react'
import config from 'config'
import { ResourceActionCreator } from '../resource'
import { SortingParams, SortingOrder, PaginationParams } from '../types'

interface Pagination {
  pageSize: number
  page: number
}

export const useResourceTable = <
  Payload extends SortingParams & PaginationParams
>(
  getData: ResourceActionCreator<Payload>,
  pageSize: number = config.pagination.defaultPageSize
) => {
  const [pagination, setPagination] = useState<Pagination>({
    page: config.pagination.defaultPage / config.pagination.defaultPageSize,
    pageSize: pageSize,
  })

  const [sorting, setSorting] = useState<SortingParams>({})

  const prevPayload = useRef<Payload>({
    take: pageSize,
    skip: config.pagination.defaultPage * pageSize,
  } as Payload)

  const fetchData = useCallback(
    (payload: Payload) => {
      const { take, sortBy, sortOrder } = prevPayload.current
      const newPayload: Payload = {
        take,
        sortBy,
        sortOrder,
        skip: config.pagination.defaultPage * (take ?? pageSize),
        ...payload,
      }
      prevPayload.current = newPayload

      setPagination({
        pageSize: newPayload.take ?? pageSize,
        page:
          (newPayload.skip ?? config.pagination.defaultPage) /
          (newPayload.take ?? pageSize),
      })
      return getData(newPayload)
    },
    [getData, pageSize]
  )

  const changePage = useCallback(
    (page: number) =>
      fetchData({
        ...prevPayload.current,
        skip: page * (prevPayload.current.take ?? pageSize),
      }),
    [fetchData, pageSize]
  )

  const sort = useCallback(
    (sortBy: string, sortingOrder: SortingOrder = SortingOrder.Descending) => {
      const sortOrder =
        sortingOrder === SortingOrder.Descending
          ? SortingOrder.Ascending
          : SortingOrder.Descending

      setSorting({ sortBy, sortOrder })
      fetchData({
        ...prevPayload.current,
        sortBy,
        sortOrder,
        skip:
          config.pagination.defaultPage *
          (prevPayload.current.take ?? pageSize),
      })
    },
    [fetchData, pageSize]
  )

  return {
    fetchData,
    sort,
    changePage,
    sorting,
    pagination,
    /**
     * Value of a previous payload stored in ref.
     * When changed, it shouldn't trigger render.
     */
    prevPayload: prevPayload.current,
  }
}
