import { MouseEventHandler, useCallback } from 'react'
import { ListItemButton, ListItemButtonBaseProps } from '@mui/material'
import { useDispatch } from '../../../../../hooks'
import {
  useOrganizationId,
  useOrganizationResourceId,
} from '../../../../../hooks'
import { actions } from '../../../../../store'
import ButtonLink from '../../../../ButtonLink'

export interface NotificationItemProps extends ListItemButtonBaseProps {
  onClick?: MouseEventHandler<HTMLDivElement>
  notificationId?: number
  path?: string
}

const NotificationItem = ({
  path,
  notificationId,
  onClick,
  ...props
}: NotificationItemProps) => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const resourceId = useOrganizationResourceId()

  const markAsRead = useCallback(
    () =>
      notificationId &&
      dispatch(
        actions.notifications.markAsRead({
          notificationIds: [notificationId],
          careProviderId,
          ...(resourceId && { resourceId }),
        })
      ),
    [notificationId, dispatch, careProviderId, resourceId]
  )

  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    event => {
      markAsRead()
      if (onClick) onClick(event as any)
    },
    [onClick, markAsRead]
  )

  return (
    <ListItemButton
      {...props}
      onClick={handleClick}
      href={path ? path : ''}
      component={ButtonLink}
    />
  )
}

export default NotificationItem
