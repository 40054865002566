import { useTranslation } from 'react-i18next'
import { isValidNumber } from 'libphonenumber-js'
import { string } from 'yup'

interface UsePhoneNumberValidationSchemaParams {
  isOptional?: boolean
}

export const usePhoneNumberValidationSchema = ({
  isOptional = true,
}: UsePhoneNumberValidationSchemaParams = {}) => {
  const { t } = useTranslation()

  return string().test(
    'is-phone-number-valid',
    t('validation.phoneNumber'),
    function (value) {
      if (isOptional && !value) return true

      return value
        ? isValidNumber(value?.replaceAll('_', '').replaceAll(' ', ''))
        : false
    }
  )
}
