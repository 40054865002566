import { ListItemButtonProps } from '@mui/material'
import { Notification } from '../../../../types'
import NotificationItem from './NotificationItem'

export interface NotificationProps extends Partial<ListItemButtonProps> {
  notification: Notification
}

const DefaultNotificationComponent = ({
  notification: { data = {} },
  ...props
}: NotificationProps) => (
  <NotificationItem path={data.url} {...props}>
    {data.message}
  </NotificationItem>
)

export default DefaultNotificationComponent
