import { createReducer } from '@reduxjs/toolkit'
import { Row } from '../../types'
import { addExpandRow, removeExpandRow, resetExpandRows } from './actions'

export interface State {
  expandRows: Row[]
}

const initialState: State = {
  expandRows: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(addExpandRow, (state, action) => {
      state.expandRows = [...state.expandRows, action.payload]
    })
    .addCase(removeExpandRow, (state, action) => {
      state.expandRows = state.expandRows.filter(
        row => row.id !== action.payload.id
      )
    })
    .addCase(resetExpandRows, (state, action) => {
      state.expandRows = []
    })
)
