import {
  AssignmentStatus,
  CancelReason,
  ResourceUnassignmentReason,
} from '../../types'
import Api from '../api'
import {
  GetBookedAssignmentsParams,
  GetBookedAssignmentsResponse,
  BookAssignmentsInternallyParams,
  BookAssignmentsExternallyParams,
  CancelAssignmentsParams,
  RequestAssignmentsParams,
  UnbookAssignmentsParams,
  ModifyAssignmentParams,
  ModifyBookedAssignmentParams,
  AssignResourceToAssignmentsParams,
  AssignAssignmentToResourcesParams,
  ChangeAssignmentStateParams,
  UncancelAssignmentParams,
  ModifyCanceledAssignmentsParams,
  RemoveNeedAssignmentsParams,
  AddResourceNoteParams,
  AddResourceNoteResponse,
  RemoveNeedAssignmentsResponse,
  ExportNeedAssignmentsParams,
  ExportNeedAssignmentsResponse,
  SetAssignmentAsNoResourcesParams,
  ModifyExternalAssignmentParams,
  CloseAssignmentParams,
  ResolveAssignmentInternallyParams,
  ResetAssignmentParams,
  ChangeAssignmentStateToNoResourcesParams,
  CreateOpenShiftAssignmentParams,
  CreateOpenShiftAssignentResponse,
  GetNeedAssignmentDataParams,
  GetNeedAssignmentDataResponse,
  EditOpenShiftAssignmentParams,
  EditOpenShiftAssignentResponse,
  ExportNeedAssignmentsToViviumXResponse,
  ExportNeedAssignmentsToViviumXParams,
  GetNeedAssignmentNotesHistoryParams,
  GetNeedAssignmentNotesHistoryResponse,
} from './NeedAssignments.types'

class NeedAssignments extends Api {
  public getBookedAssignments = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetBookedAssignmentsParams) => {
    const { data } = await this.api.get<GetBookedAssignmentsResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/need-assignments`,
      { params }
    )
    return data.data
  }

  public bookAssignmentsInternally = async ({
    careProviderId,
    note,
    ...payload
  }: BookAssignmentsInternallyParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignments/book-internally`,
      { ...payload, note: note || '' }
    )
    return data
  }

  public bookAssignmentsExternally = async ({
    careProviderId,
    note,
    isPreliminary = false,
    ...payload
  }: BookAssignmentsExternallyParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignments/book-externally`,
      {
        ...payload,
        isPreliminary,
        note: note || '',
      }
    )
    return data
  }

  public modifyAssignment = async ({
    careProviderId,
    note,
    hasAbsence = false,
    hasOvertime = false,
    ...payload
  }: ModifyAssignmentParams) => {
    const { data } = await this.api.put<string>(
      `/care-providers/${careProviderId}/need-assignments`,
      {
        ...payload,
        hasAbsence,
        hasOvertime,
        note: note || '',
      }
    )
    return data
  }

  public cancelAssignment = async ({
    careProviderId,
    note = '',
    ...payload
  }: CancelAssignmentsParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignments/cancel`,
      { ...payload, note }
    )
    return data
  }

  public unbookAssignment = async ({
    careProviderId,
    note = '',
    ...payload
  }: UnbookAssignmentsParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignments/unbook`,
      { ...payload, note }
    )
    return data
  }

  public uncancelAssignment = async ({
    careProviderId,
    note = '',
    ...payload
  }: UncancelAssignmentParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignments/uncancel`,
      { ...payload, note }
    )

    return data
  }

  public modifyCanceledAssignment = async ({
    careProviderId,
    note = '',
    ...payload
  }: ModifyCanceledAssignmentsParams) => {
    const { data } = await this.api.put<string>(
      `/care-providers/${careProviderId}/need-assignments/cancel`,
      { ...payload, note }
    )
    return data
  }

  public requestAssignments = async ({
    careProviderId,
    ...payload
  }: RequestAssignmentsParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignment-requests`,
      payload
    )
    return data
  }

  public setAssignmentAsNoResources = async ({
    careProviderId,
    ...payload
  }: SetAssignmentAsNoResourcesParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/need-assignments/set-no-resources-status`,
      payload
    )
    return data
  }

  public assignResourceToAssignments = async ({
    matchingResource,
    ...payload
  }: AssignResourceToAssignmentsParams) =>
    !!matchingResource.matchingAssignments.bookableAssignments?.length
      ? this.bookAssignmentsInternally({
          resourceId: matchingResource.id,
          ...payload,
        })
      : this.requestAssignments({
          resourceIds: [matchingResource.id],
          careProviderId: payload.careProviderId,
          assignmentIds: payload.assignmentIds,
        })

  public assignAssignmentToResources = async ({
    modifiedAssignmentId,
    bookedResourceId,
    requestedResourceIds,
    ...payload
  }: AssignAssignmentToResourcesParams) => {
    if (bookedResourceId) {
      return this.bookAssignmentsInternally({
        resourceId: bookedResourceId,
        assignmentIds: [modifiedAssignmentId],
        ...payload,
      })
    }
    if (requestedResourceIds && requestedResourceIds?.length > 0) {
      return this.requestAssignments({
        resourceIds: requestedResourceIds,
        careProviderId: payload.careProviderId,
        assignmentIds: [modifiedAssignmentId],
      })
    }
    throw new Error('Incorrect request data')
  }

  public removeAssignments = async ({
    careProviderId,
    assignmentIds,
  }: RemoveNeedAssignmentsParams) => {
    const { data } = await this.api.delete<RemoveNeedAssignmentsResponse>(
      `/care-providers/${careProviderId}/need-assignments/`,
      { data: { assignmentIds } }
    )
    return data
  }

  public addResourceNote = async ({
    careProviderId,
    resourceId,
    assignmentId,
    ...payload
  }: AddResourceNoteParams) => {
    const { data } = await this.api.put<AddResourceNoteResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/need-assignments/${assignmentId}`,
      payload
    )
    return data
  }

  public exportNeedAssignments = async ({
    careProviderId,
    ...payload
  }: ExportNeedAssignmentsParams) => {
    const { data } = await this.api.post<ExportNeedAssignmentsResponse>(
      `/care-providers/${careProviderId}/need-assignments/export/file`,
      payload,
      { responseType: 'arraybuffer' }
    )
    return data
  }

  public exportNeedAssignmentsToViviumX = async ({
    careProviderId,
    ...payload
  }: ExportNeedAssignmentsToViviumXParams) => {
    const { data } =
      await this.api.post<ExportNeedAssignmentsToViviumXResponse>(
        `/care-providers/${careProviderId}/need-assignments/export/vivium-x`,
        payload
      )
    return data
  }

  public changeAssignmentState = async ({
    careProviderId,
    modifiedAssignment,
    assignmentIds,
    unitId,
    externalResource,
    note,
    isClosed,
    isResolvedInternally,
    isPreliminary,
    hasNoResource,
  }: ChangeAssignmentStateParams) => {
    if (externalResource) {
      return this.modifyExternalAssignment({
        careProviderId,
        modifiedAssignment,
        assignmentIds,
        note,
        unitId,
        externalResource,
        isPreliminary,
      })
    }
    if (isClosed) {
      return this.closeAssignment({
        careProviderId,
        modifiedAssignment,
        assignmentIds,
        note,
      })
    }
    if (isResolvedInternally) {
      return this.resolveAssignmentInternally({
        careProviderId,
        modifiedAssignment,
        assignmentIds,
        note,
      })
    }
    if (hasNoResource) {
      return this.changeAssignmentStateToNoResources({
        careProviderId,
        modifiedAssignment,
        assignmentIds,
        note,
        unitId,
      })
    }
    return this.resetAssignment({
      careProviderId,
      modifiedAssignment,
      assignmentIds,
      note,
    })
  }

  public modifyBookedAssignment = async ({
    careProviderId,
    assignmentIds,
    reason,
    note,
    isActive,
    hasAbsence,
    hasOvertime,
  }: ModifyBookedAssignmentParams) => {
    if (!isActive) {
      return await this.modifyAssignment({
        careProviderId,
        assignmentIds,
        hasAbsence,
        hasOvertime,
        note,
      })
    }
    if (reason === ResourceUnassignmentReason.Unbooked) {
      return this.unbookAssignment({
        careProviderId,
        assignmentIds,
        note,
      })
    }
    return this.cancelAssignment({
      careProviderId,
      assignmentIds,
      cancelReason:
        reason === ResourceUnassignmentReason.Solved
          ? CancelReason.ResolvedInternally
          : CancelReason.Canceled,
    })
  }

  public createOpenShiftAssignment = async ({
    careProviderId,
    ...params
  }: CreateOpenShiftAssignmentParams) => {
    const { data } = await this.api.post<CreateOpenShiftAssignentResponse>(
      `/care-providers/${careProviderId}/need-assignments/create-open`,
      params
    )

    return data
  }

  public editOpenShiftAssignment = async ({
    careProviderId,
    assignmentId,
    ...params
  }: EditOpenShiftAssignmentParams) => {
    const { data } = await this.api.put<EditOpenShiftAssignentResponse>(
      `/care-providers/${careProviderId}/need-assignments/${assignmentId}/update-open`,
      params
    )

    return data
  }

  public getAssignmentData = async ({
    careProviderId,
    assignmentId,
  }: GetNeedAssignmentDataParams) => {
    const { data } = await this.api.get<GetNeedAssignmentDataResponse>(
      `/care-providers/${careProviderId}/need-assignments/${assignmentId}`
    )

    return data
  }

  public getAssignmentNotesHistory = async ({
    careProviderId,
    assignmentId,
  }: GetNeedAssignmentNotesHistoryParams) => {
    const { data } = await this.api.get<GetNeedAssignmentNotesHistoryResponse>(
      `/care-providers/${careProviderId}/need-assignments/${assignmentId}/history`
    )

    return data
  }

  private modifyExternalAssignment = async ({
    careProviderId,
    unitId,
    modifiedAssignment,
    note,
    assignmentIds,
    externalResource,
    isPreliminary,
  }: ModifyExternalAssignmentParams) => {
    if (modifiedAssignment.status === AssignmentStatus.Canceled)
      return this.modifyCanceledAssignment({
        careProviderId,
        assignmentIds,
        isPreliminary,
        note,
        externalResourceName: externalResource.name,
      })
    if (modifiedAssignment.status === AssignmentStatus.BookedExternally)
      return this.modifyAssignment({
        careProviderId,
        assignmentIds,
        isPreliminary,
        note,
        externalResourceName: externalResource.name,
        externalResourceHSAID: externalResource.hsaId,
      })
    return this.bookAssignmentsExternally({
      careProviderId,
      unitId,
      assignmentIds,
      isPreliminary,
      note,
      externalResourceHSAID: externalResource.hsaId,
      externalResourceName: externalResource.name,
    })
  }

  private closeAssignment = async ({
    careProviderId,
    modifiedAssignment,
    note,
    assignmentIds,
  }: CloseAssignmentParams) => {
    if (modifiedAssignment.status !== AssignmentStatus.Canceled) {
      return this.cancelAssignment({
        careProviderId,
        assignmentIds,
        note,
        cancelReason: CancelReason.Canceled,
      })
    }
    if (modifiedAssignment.cancelReason === CancelReason.ResolvedInternally) {
      return this.modifyCanceledAssignment({
        careProviderId,
        assignmentIds,
        note,
        cancelReason: CancelReason.Canceled,
      })
    }
    return this.modifyAssignment({ assignmentIds, careProviderId, note })
  }

  private resolveAssignmentInternally = async ({
    careProviderId,
    modifiedAssignment,
    assignmentIds,
    note,
  }: ResolveAssignmentInternallyParams) => {
    if (modifiedAssignment.status !== AssignmentStatus.Canceled) {
      return this.cancelAssignment({
        careProviderId,
        assignmentIds,
        note,
        cancelReason: CancelReason.ResolvedInternally,
      })
    }
    if (modifiedAssignment.cancelReason === CancelReason.Canceled) {
      return this.modifyCanceledAssignment({
        careProviderId,
        assignmentIds,
        note,
        cancelReason: CancelReason.ResolvedInternally,
      })
    }
    return this.modifyAssignment({ careProviderId, assignmentIds, note })
  }

  private resetAssignment = async ({
    careProviderId,
    modifiedAssignment,
    assignmentIds,
    note,
  }: ResetAssignmentParams) => {
    if (modifiedAssignment.status === AssignmentStatus.Canceled) {
      return this.uncancelAssignment({ careProviderId, assignmentIds, note })
    }
    if (
      modifiedAssignment.status === AssignmentStatus.BookedExternally ||
      modifiedAssignment.status === AssignmentStatus.NoResources
    ) {
      return this.unbookAssignment({ careProviderId, assignmentIds, note })
    }
    return this.modifyAssignment({ careProviderId, assignmentIds, note })
  }

  private changeAssignmentStateToNoResources = async ({
    careProviderId,
    unitId,
    modifiedAssignment,
    note,
    assignmentIds,
  }: ChangeAssignmentStateToNoResourcesParams) => {
    if (modifiedAssignment.status === AssignmentStatus.Canceled) {
      return this.modifyCanceledAssignment({
        careProviderId,
        assignmentIds,
        note,
      })
    }
    if (modifiedAssignment.status === AssignmentStatus.NoResources) {
      return this.modifyAssignment({ careProviderId, assignmentIds, note })
    }
    return this.setAssignmentAsNoResources({
      assignmentIds,
      unitId,
      careProviderId,
      note,
    })
  }
}

export default NeedAssignments
