import { styled, alpha } from '@mui/material'

interface BadgeProps {
  notification?: boolean
  flagged?: boolean
  adjusted?: boolean
}

export const Badge = styled('div')<BadgeProps>(
  ({ theme, notification, flagged, adjusted }) => ({
    ...(adjusted && {
      position: 'absolute',
      left: theme.spacing(0.5),
      top: '50%',
      translate: 'transform(-50%)',
    }),
    ...(!adjusted && { position: 'relative', marginTop: theme.spacing(0.5) }),
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.background.secondary,
    boxShadow: `0px 2px 7px ${alpha(theme.palette.common.black, 0.28)}`,
    ...(flagged && {
      backgroundColor: theme.palette.pill.yellow.main,
      borderColor: theme.palette.pill.yellow.dark,
    }),
    ...(notification && {
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.dark,
    }),
  })
)
