import { useCallback, useMemo } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { Theme, Tooltip } from '@mui/material'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { formatISO, isValid } from 'date-fns'
import DateFieldInput from './DateFieldInput'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MASK,
  RangeOptions,
  useRange,
} from './DateField.utils'

export interface DateFieldProps
  extends Partial<DatePickerProps<Date, Date | string>>,
    RangeOptions {
  name: string
  defaultValue?: Date | null
  disabledMessage?: string
  placeholder?: string
  helperText?: string
  clearable?: boolean
  formatToIsoString?: boolean
  'data-test-id'?: string
  /**
   * If true, when DateField's current value is outside of currently set date range,
   * the value will automatically update to a value within the range.
   * If false, in the above case, the value will update to null.
   * @default true
   */
  adjustToRange?: boolean
}

const DateField = ({
  name,
  label,
  disabled,
  disabledMessage,
  minRangeDate,
  maxRangeDate,
  clearable,
  defaultValue = null,
  placeholder,
  helperText,
  formatToIsoString = false,
  adjustToRange = true,
  ...props
}: DateFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, onChange, value, ...formFieldProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })
  const handleChange = useCallback(
    (date: Date | string | null) => {
      if (
        formatToIsoString &&
        date &&
        typeof date !== 'string' &&
        isValid(date)
      ) {
        return onChange(formatISO(date))
      }

      return onChange(date)
    },
    [formatToIsoString, onChange]
  )

  const rangeProps = useRange({
    minRangeDate,
    maxRangeDate,
    value,
    handleChange,
    adjustToRange,
  })

  const popperProps = useMemo(
    () => ({
      sx: {
        zIndex: (theme: Theme) => theme.zIndex.modal + 1,
        '& .MuiPickersDay-root.Mui-selected': {
          '&:focus': {
            backgroundColor: 'primary.main',
          },
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        },
      },
    }),
    []
  )

  const isClearable = !!(clearable && value)

  const Picker = (
    <DatePicker
      value={value}
      label={label}
      inputRef={ref}
      closeOnSelect={true}
      inputFormat={DEFAULT_DATE_FORMAT}
      mask={DEFAULT_MASK}
      PopperProps={popperProps}
      onChange={handleChange}
      disabled={disabled}
      renderInput={renderInputProps => (
        <DateFieldInput
          renderInputProps={renderInputProps}
          error={error}
          isClearable={isClearable}
          onChange={onChange}
          placeholder={placeholder}
          helperText={helperText}
          dataTestId={props['data-test-id']}
        />
      )}
      {...rangeProps}
      {...formFieldProps}
      {...props}
    />
  )

  return disabledMessage ? (
    <Tooltip
      title={disabledMessage}
      disableFocusListener={!disabled}
      disableHoverListener={!disabled}
      disableTouchListener={!disabled}
    >
      <span>{Picker}</span>
    </Tooltip>
  ) : (
    Picker
  )
}

export default DateField
