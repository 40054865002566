import { useMemo, MouseEvent, MouseEventHandler, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssignmentsNotifications } from '../../hooks'
import { ScheduleDaysAssignment } from '../../types'
import {
  MoreAssignmentsButton,
  Container,
  StyledBadge,
  AssignmentCircle,
  LightTooltip,
  CirclesWrapper,
} from './MoreAssignmentPill.style'

interface MoreAssignmentPillProps {
  assignments: ScheduleDaysAssignment[]
  numberOfDisplayedPills: number
  shorthand?: boolean
  showNotifications?: boolean
  showAssignmentsSummary?: boolean
  onClick: (anchor: MouseEvent<HTMLElement>) => void
}

const MoreAssignmentPill = ({
  assignments,
  numberOfDisplayedPills,
  shorthand = false,
  showNotifications = false,
  showAssignmentsSummary = false,
  onClick,
}: MoreAssignmentPillProps) => {
  const { t } = useTranslation()
  const { unreadNotifications } = useAssignmentsNotifications(
    assignments.slice(numberOfDisplayedPills)
  )

  const length = assignments.length - numberOfDisplayedPills
  const circlesData = useMemo(
    () =>
      assignments
        .slice(numberOfDisplayedPills)
        .map(({ status }) => status)
        .filter(
          (assignments, index, self) => self.indexOf(assignments) === index
        ),
    [assignments, numberOfDisplayedPills]
  )

  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    event => {
      if (onClick) onClick(event as any)
    },
    [onClick]
  )

  const CirclesComponent = useMemo(
    () =>
      circlesData.map(status => (
        <AssignmentCircle
          status={status}
          key={`assignment-circle-${status}`}
          onClick={handleClick}
        />
      )),
    [circlesData, handleClick]
  )

  return (
    <Container>
      {showAssignmentsSummary && !shorthand && CirclesComponent}
      <LightTooltip
        disableHoverListener={showAssignmentsSummary}
        placement="top"
        title={<CirclesWrapper>{CirclesComponent}</CirclesWrapper>}
      >
        <MoreAssignmentsButton
          showAssignmentsSummary={showAssignmentsSummary}
          shorthand={shorthand}
          onClick={handleClick}
        >
          {t(shorthand ? 'scheduler.moreShort' : 'scheduler.more', {
            count: length,
          })}
        </MoreAssignmentsButton>
      </LightTooltip>
      {showNotifications && unreadNotifications.length > 0 && (
        <StyledBadge variant="dot" color="error" />
      )}
    </Container>
  )
}

export default MoreAssignmentPill
