import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResourcePeriodType } from 'shared/types'

export const useAvailabilityTypes = () => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      [ResourcePeriodType.Availability, ResourcePeriodType.Absence].map(
        value => ({ value, label: t(`enums.ResourcePeriodType.${value}`) })
      ),
    [t]
  )
}
