import { useTheme } from '@mui/material'

const POS = '50%'
const SUB_LABEL_FONT_SIZE = 8
const SUB_LABEL_DY = '12px'
const FONT_WEIGHT = 400

interface CenterTemplateProps {
  fontSize: number
  label?: string
  subLabel?: string
}

const CenterTemplate = ({ fontSize, label, subLabel }: CenterTemplateProps) => {
  const theme = useTheme()
  return (
    <svg>
      <text
        x={POS}
        y={POS}
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={fontSize}
        fontWeight={FONT_WEIGHT}
        fontFamily={theme.typography.fontFamily}
        fill={theme.palette.neutral.contrastText}
      >
        {label && (
          <tspan x={POS} y={POS}>
            {label}
          </tspan>
        )}
        {subLabel && (
          <tspan
            x={POS}
            y={POS}
            dy={SUB_LABEL_DY}
            fontSize={SUB_LABEL_FONT_SIZE}
          >
            {subLabel}
          </tspan>
        )}
      </text>
    </svg>
  )
}

export default CenterTemplate
