import { useMemo, useEffect } from 'react'
import { useForm, useWatch, Control } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { endOfDay, parseISO, startOfDay } from 'date-fns'
import { SchemaOf, object, boolean, array, string } from 'yup'
import { useDispatch, useSelector } from '../../../../hooks'
import { useOrganizationId, useQuery } from '../../../../hooks'
import { actions, selectors } from '../../../../store'
import {
  AssignmentDetailedInfo,
  AssignmentRequestScheduleDays,
  AssignmentRequestStatus,
  Entity,
  MatchingResource,
  SchedulerDates,
} from '../../../../types'
import { FormProps } from '../../../Form'
import { RadioFieldItem } from '../../../RadioField'
import { useModalProps } from '../../AssignmentModal.utils'
import { RequestedResourceStatus } from '../RequestAssignmentContent.utils'

export interface ResourcesSectionFormValues {
  bookedResource?: Entity
  requestedResources: Entity[]
}

export type OnResourceChange = (
  ne: AssignmentDetailedInfo,
  formValues: ResourcesSectionFormValues
) => void

export enum ResourcesSectionFields {
  BookedResource = 'bookedResource',
  AllRequestedResources = 'allRequestedResources',
  RequestedResources = 'requestedResources',
  AlreadyRequestedResources = 'alreadyRequestedResources',
}

export interface ResourcesSectionValues {
  [ResourcesSectionFields.BookedResource]: string
  [ResourcesSectionFields.AllRequestedResources]: boolean
  [ResourcesSectionFields.RequestedResources]: string[]
  [ResourcesSectionFields.AlreadyRequestedResources]: string[]
}

export const resourceSectionDefaultValues: ResourcesSectionValues = {
  [ResourcesSectionFields.BookedResource]: '',
  [ResourcesSectionFields.AllRequestedResources]: false,
  [ResourcesSectionFields.RequestedResources]: [],
  [ResourcesSectionFields.AlreadyRequestedResources]: [],
}

export const useResourcesSectionValidationSchema =
  (): SchemaOf<ResourcesSectionValues> => {
    return object()
      .shape({
        [ResourcesSectionFields.BookedResource]: string(),
        [ResourcesSectionFields.AllRequestedResources]: boolean(),
        [ResourcesSectionFields.RequestedResources]: array().of(string()),
        [ResourcesSectionFields.AlreadyRequestedResources]: array().of(
          string()
        ),
      })
      .required()
  }

export const useOnResourcesSectionSubmit = (
  matchingResources: UseMatchingResourcesReturn
) => {
  const { needAssignment, onResourceChange, handleClose } = useModalProps()
  return (values: ResourcesSectionValues) => {
    if (needAssignment && matchingResources) {
      const { bookable, requestable } = matchingResources
      onResourceChange(needAssignment, {
        bookedResource: bookable.find(
          resource =>
            parseInt(values[ResourcesSectionFields.BookedResource], 10) ===
            resource.id
        ),
        requestedResources: requestable.filter(resource =>
          values.requestedResources.some(
            requested => parseInt(requested, 10) === resource.id
          )
        ),
      })
    }
    handleClose()
  }
}

export const useResourcesSectionProps = (): Omit<
  FormProps<ResourcesSectionValues>,
  'onSubmit'
> => {
  const schema = useResourcesSectionValidationSchema()
  const methods = useForm<ResourcesSectionValues>({
    defaultValues: resourceSectionDefaultValues,
    resolver: yupResolver(schema),
  })
  return methods
}

export const useResourceSelectionState = (
  control: Control<ResourcesSectionValues>
) => {
  const [booked, requested] = useWatch({
    control,
    name: [
      ResourcesSectionFields.BookedResource,
      ResourcesSectionFields.RequestedResources,
    ],
  }) as [
    ResourcesSectionValues[ResourcesSectionFields.BookedResource],
    ResourcesSectionValues[ResourcesSectionFields.RequestedResources]
  ]

  const isResourceSelected = Boolean(booked || requested.length > 0)

  return { isResourceSelected, booked }
}

export const getRequestableResourceStatus = (
  resource: MatchingResource,
  requestedResources: AssignmentRequestScheduleDays[]
) => {
  const requestedResource = requestedResources.find(
    r => r.resource.id === resource.id
  )

  switch (requestedResource?.status) {
    case AssignmentRequestStatus.Pending:
      return RequestedResourceStatus.Requested
    case AssignmentRequestStatus.Rejected:
      return RequestedResourceStatus.Rejected
    default:
      return RequestedResourceStatus.Requestable
  }
}

interface RequestableResource extends MatchingResource {
  status: RequestedResourceStatus
}

export interface UseMatchingResourcesReturn {
  pendingResources: {
    value: number
    label: string
    status: RequestedResourceStatus
  }[]
  requestable: RequestableResource[]
  bookable: MatchingResource[]
}

export const useMatchingResources = (): UseMatchingResourcesReturn => {
  const careProviderId = useOrganizationId()
  const dispatch = useDispatch()
  const matchingResources = useSelector(
    selectors.matchingResources.getMatchingResources
  )
  const { needAssignment } = useModalProps()
  const { dateFrom, dateTo } = useQuery<SchedulerDates>()

  useEffect(() => {
    if (
      needAssignment &&
      needAssignment.need &&
      careProviderId &&
      dateFrom &&
      dateTo
    ) {
      dispatch(
        actions.matchingResources.getMatchingResources({
          careProviderId,
          dateFrom: startOfDay(parseISO(needAssignment.startDate)),
          dateTo: endOfDay(parseISO(needAssignment.endDate)),
          unitId: needAssignment.need.unit.id,
          assignmentId: needAssignment.id,
        })
      )
    }
  }, [dispatch, needAssignment, careProviderId, dateFrom, dateTo])

  const { requestable } = matchingResources

  const pendingResources = useMemo(
    () =>
      (needAssignment?.requests || [])
        .filter(request => request.status === AssignmentRequestStatus.Pending)
        .map(request => ({
          value: request.resource.id,
          label: request.resource.name,
          status: RequestedResourceStatus.Requested,
        })),
    [needAssignment?.requests]
  )

  const resourcesToRequest = useMemo(() => {
    const requestedResources = needAssignment?.requests || []
    return requestable
      .filter(({ id }) => !pendingResources.some(({ value }) => value === id))
      .map(resource => {
        const resourceStatus = getRequestableResourceStatus(
          resource,
          requestedResources
        )

        return {
          ...resource,
          status: resourceStatus,
        }
      })
  }, [needAssignment?.requests, pendingResources, requestable])

  return {
    ...matchingResources,
    pendingResources,
    requestable: resourcesToRequest,
  }
}

export const mapResourcesToOptions = (resources: MatchingResource[]) => {
  return resources.map(
    ({ id, name, matchingAssignments: { bookableAssignments } }) => {
      const [bookableAssignment] = bookableAssignments || []
      const [availability] = bookableAssignment?.availabilities || []
      const isOpenForOvertime = !!availability?.openForOvertime
      return { value: id, label: name, isOpenForOvertime }
    }
  )
}

export interface RequestableOptions extends RadioFieldItem {
  status: RequestedResourceStatus
}

export const mapRequestablesToOptions = (
  resources: RequestableResource[]
): RequestableOptions[] =>
  resources.map(({ id, name, status }) => ({ value: id, label: name, status }))
