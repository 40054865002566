import { Components } from '@mui/material'

const MuiButton: Components['MuiButton'] = {
  defaultProps: { fullWidth: true },
  styleOverrides: {
    text: { padding: 0 },
    outlined: { borderWidth: 2 },
    outlinedPrimary: {
      color: '#F38E64',
      border: '2px solid #F38E64',
      '&:hover': { border: '2px solid #F38E64' },
    },
    outlinedSecondary: {
      color: '#595959',
      border: '2px solid #595959',
      '&:hover': { border: '2px solid #595959' },
    },
  },
}

export default MuiButton
