import { useMemo } from 'react'
import { selectors } from '../store'
import { ScheduleDaysAssignment } from '../types'
import { useAppSelector as useSelector } from './redux'

export const useAssignmentsNotifications = (
  assignments?: ScheduleDaysAssignment[]
) => {
  const schedulesUnreadNotifications = useSelector(
    selectors.notifications.getSchedulesUnreadNotifications
  )

  const assignmentsIds = assignments?.map(({ id }) => id)

  const unreadNotifications = useMemo(
    () =>
      schedulesUnreadNotifications
        .flatMap(({ notifications }) => notifications)
        .filter(({ data }) => assignmentsIds?.includes(data.assignmentId)),
    [assignmentsIds, schedulesUnreadNotifications]
  )

  const unreadNotificationsIds = unreadNotifications.map(
    ({ data }) => data.assignmentId
  )

  return { unreadNotifications, unreadNotificationsIds }
}
