import { Message } from '@mui/icons-material'
import { Chip as BaseChip, Grid, styled } from '@mui/material'
import { AssignmentStatus, NotificationType } from '../../../../../types'
import BaseButtonLink from '../../../../ButtonLink'

export const NotificationRow = styled(Grid)(({ theme }) => ({
  minWidth: 540,
  '& >:first-child': {
    padding: theme.spacing(1, 1.5, 1, 0.5),
  },
}))

export const NotificationCell = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 0),
}))

export const ButtonLink = styled(BaseButtonLink)({
  padding: 0,
})

const chipStyles = {
  height: 16,
  '& .MuiChip-label': {
    fontSize: '0.725rem',
    marginRight: 0,
  },
}

interface AssignmentChipProps {
  notificationType: NotificationType
  assignmentStatus?: AssignmentStatus
}

export const AssignmentRequestChip = styled(BaseChip)<AssignmentChipProps>(
  ({ theme, notificationType }) => {
    const assignmentRequestChipColors: Partial<
      Record<NotificationType, string>
    > = {
      [NotificationType.NeedAssignmentRequestAccepted]:
        theme.palette.pill.green.main,
      [NotificationType.NeedAssignmentRequestCreated]:
        theme.palette.pill.yellow.main,
      [NotificationType.NeedAssignmentRequestRejected]:
        theme.palette.pill.red.main,
    }

    return {
      ...chipStyles,
      backgroundColor:
        assignmentRequestChipColors[notificationType] ||
        theme.palette.pill.yellow.main,
    }
  }
)

interface BookingChipProps {
  externalResource?: boolean
}

export const BookingChip = styled(BaseChip)<BookingChipProps>(
  ({ theme, externalResource }) => ({
    ...chipStyles,
    backgroundColor: externalResource
      ? theme.palette.pill.blue.main
      : theme.palette.pill.yellow.main,
  })
)

export const MessageIcon = styled(Message)(({ theme }) => ({
  fontSize: '0.750rem',
  marginLeft: theme.spacing(0.75),
}))

export const AssignmentChip = styled(BaseChip)<AssignmentChipProps>(
  ({ theme, ...props }) => {
    const getAssignmentsStatusColor = ({
      notificationType,
      assignmentStatus,
    }: AssignmentChipProps) => {
      const assignmentChipColors: Partial<Record<NotificationType, string>> = {
        [NotificationType.NeedAssignmentCanceled]: theme.palette.pill.gray.dark,
      }

      if (assignmentStatus === AssignmentStatus.BookedExternally)
        return theme.palette.pill.blue.main

      return (
        assignmentChipColors[notificationType] || theme.palette.pill.yellow.main
      )
    }

    return {
      ...chipStyles,
      backgroundColor: getAssignmentsStatusColor(props),
      color:
        props.assignmentStatus === AssignmentStatus.Canceled
          ? theme.palette.common.white
          : theme.palette.common.black,
    }
  }
)
