import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { useMaxDisplayedAssignmentsCount } from '../../hooks'
import { Shift } from '../../types'
import Pill from './Pill'
import { EventsWrapper } from './EventsTile.style'
import {
  getEventsForSpecificShift,
  Event,
  removeOneEvent,
} from './Scheduler.utils'

interface EventsTileProps {
  events: Event[]
  shift: Shift
  day: Date
  onChange: (events: Event[]) => void
  highlight?: boolean
  preview?: 'add' | 'remove' | null
  showOnly?: boolean
  disabled: boolean
  monthlyView?: boolean
  defaultPillName?: string
}

const EventsTile = memo(
  ({
    events,
    shift,
    day,
    onChange,
    highlight,
    preview,
    showOnly,
    disabled,
    monthlyView,
    defaultPillName,
  }: EventsTileProps) => {
    const { t } = useTranslation()
    const [isHover, setIsHover] = useState(false)

    const visiblePillsCount = useMaxDisplayedAssignmentsCount() - 1
    const theme = useTheme()

    const onHover = useCallback(() => setIsHover(true), [])
    const onLeave = useCallback(() => setIsHover(false), [])

    const shiftEvents = useMemo(
      () => getEventsForSpecificShift(events, shift, day),
      [events, shift, day]
    )

    const eventsCounter = shiftEvents.length

    const removeEvent = useCallback(
      (event: Event) => onChange(removeOneEvent(events, event)),
      [events, onChange]
    )

    const addEvent = useCallback(
      (event: Event) => onChange([...events, event]),
      [events, onChange]
    )

    const isRemovePreviewActive = useMemo(
      () => !disabled && highlight && preview === 'remove',
      [disabled, highlight, preview]
    )

    const isAddPreviewActive = useMemo(
      () => !disabled && highlight && preview === 'add',
      [disabled, highlight, preview]
    )

    const shouldShowAddPill = useMemo(
      () => !showOnly && !disabled && isHover,
      [showOnly, disabled, isHover]
    )

    const shouldShowCounter = useMemo(
      () => eventsCounter > visiblePillsCount,
      [eventsCounter, visiblePillsCount]
    )

    const handleClick = useCallback(
      () =>
        addEvent({
          shift,
          date: day,
          name: events[0]?.name || defaultPillName,
        }),
      [events, day, shift, defaultPillName, addEvent]
    )

    return (
      <EventsWrapper
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        highlight={!!highlight}
        disabled={disabled}
        maxDisplayedAssignmentsCount={visiblePillsCount}
      >
        {shiftEvents.slice(0, visiblePillsCount).map((event, index) => (
          <Pill
            key={`pill-${shift.name}-${event.date}-${index}`}
            onClick={() => removeEvent(event)}
            isRemovePreviewActive={isRemovePreviewActive}
            color={event.color}
            name={event.name}
            monthlyView={monthlyView}
          />
        ))}
        {isAddPreviewActive && !shouldShowCounter && <Pill />}
        {shouldShowAddPill && !shouldShowCounter && (
          <Pill key="addEvent" onClick={handleClick} add />
        )}
        {shouldShowCounter && (
          <Pill
            {...(shouldShowAddPill && { onClick: handleClick })}
            color={
              isAddPreviewActive
                ? theme.palette.pill.green.main
                : theme.palette.common.white
            }
            counter
            name={t(monthlyView ? 'scheduler.moreShort' : 'scheduler.more', {
              count: eventsCounter - visiblePillsCount,
            })}
          />
        )}
      </EventsWrapper>
    )
  }
)

export default EventsTile
