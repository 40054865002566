import { ReactNode, MouseEvent, useCallback } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Popover, IconButton, Typography, PopoverProps } from '@mui/material'
import { useModal } from '../../../../hooks'
import DefaultNotificationComponent from './DefaultNotificationComponent'
import NotificationsList, { NotificationsListProps } from './NotificationsList'
import { Container, StyledBadge } from './Notifications.style'

export interface NotificationsDropdownProps extends NotificationsListProps {
  label: ReactNode
  hideArrow?: boolean
  unreadCount?: number
  onOpen?: () => void
  popoverProps?: Partial<PopoverProps>
}

const NotificationsDropdown = ({
  label,
  notifications,
  unreadCount,
  onOpen = () => {},
  onClose = () => {},
  onLoadMore = () => {},
  totalCount = 0,
  loading = false,
  initialized = false,
  hideArrow = false,
  hideLoadMore = false,
  NotificationComponent = DefaultNotificationComponent,
  notificationTitle,
  popoverProps,
}: NotificationsDropdownProps) => {
  const { isModalOpen, openModal, closeModal, anchorEl } = useModal()

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      openModal(event)
      onOpen()
    },
    [openModal, onOpen]
  )

  const handleClose = useCallback(() => {
    closeModal()
    onClose()
  }, [closeModal, onClose])

  return (
    <>
      <Container onClick={handleOpen}>
        <Typography>{label}</Typography>
        {!!unreadCount && (
          <StyledBadge badgeContent={unreadCount} color="error" />
        )}
        {!hideArrow && (
          <IconButton size="small" color="inherit">
            {isModalOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Container>
      <Popover
        open={isModalOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        {...popoverProps}
      >
        <NotificationsList
          hideLoadMore={hideLoadMore}
          loading={loading}
          initialized={initialized}
          notifications={notifications}
          totalCount={totalCount}
          onClose={handleClose}
          onLoadMore={onLoadMore}
          NotificationComponent={NotificationComponent}
          notificationTitle={notificationTitle}
        />
      </Popover>
    </>
  )
}

export default NotificationsDropdown
