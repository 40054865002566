import { isSameDay, parseISO } from 'date-fns'
import resource from 'shared/resource'
import { PaginationData, ShiftRequest, BookedAssignment } from 'shared/types'
import { State } from './reducer'

export const addBookingsToState = (
  state: State,
  payload: { data: BookedAssignment[]; meta: PaginationData }
) => {
  resource.setSucceeded(
    state.getBookings,
    [...state.getBookings.data, ...payload.data],
    payload.meta
  )
}

export const addRequestsToState = (
  state: State,
  payload: { data: ShiftRequest[]; meta: PaginationData }
) => {
  const data = payload.data.map(data => ({
    ...data,
    acceptRequest: resource.getInitial(),
    rejectRequest: resource.getInitial(),
  }))
  resource.setSucceeded(
    state.getRequests,
    [...state.getRequests.data, ...data],
    payload.meta
  )
}

export const removeRequestsInSameDay = (
  state: State,
  request: ShiftRequest
) => {
  state.getRequests.data = state.getRequests.data.filter(
    ({ assignment, id }) =>
      request.id === id ||
      !isSameDay(
        parseISO(assignment.startDate),
        parseISO(request.assignment.startDate)
      )
  )
}
