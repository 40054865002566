import { InputAdornment, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

interface StartAdornmentProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}

const StartAdornment = ({ icon: Icon }: StartAdornmentProps) => (
  <InputAdornment position="start">
    <Icon color="action" />
  </InputAdornment>
)

export default StartAdornment
