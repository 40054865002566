import { useCallback } from 'react'
import { actions } from '../store'
import { AutocompleteValue, SchedulerDates } from '../types'
import { useAppDispatch as useDispatch } from './redux'
import { useNeedIds } from './useNeedIds'
import { useOrganizationId } from './useOrganization'
import { useQuery } from './useQuery'
import { useUnitId } from './useUnitId'

type QueryParams = SchedulerDates & {
  resource: AutocompleteValue
}

export const useGetSchedulesUnreadNotifications = () => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const unitId = useUnitId()
  const needIds = useNeedIds()

  const { dateFrom, dateTo, resource } = useQuery<QueryParams>()

  return useCallback(() => {
    if (!dateFrom || !dateTo) return
    dispatch(
      actions.notifications.getSchedulesUnreadNotifications({
        careProviderId,
        dateFrom,
        dateTo,
        unitId,
        needIds,
        resourceId: resource?.value,
      })
    )
    // eslint-disable-next-line
  }, [
    dispatch,
    careProviderId,
    dateFrom,
    dateTo,
    unitId,
    needIds?.length,
    resource?.value,
  ])
}
