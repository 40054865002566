import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { SchemaOf, object, boolean, string, array, number } from 'yup'
import { useDispatch, useSelector } from '../../../../hooks'
import { useAssignmentModal, useOrganizationId } from '../../../../hooks'
import { actions, selectors } from '../../../../store'
import {
  AssignmentDetailedInfo,
  AssignmentStatus,
  CancelReason,
} from '../../../../types'
import { FormProps } from '../../../Form'
import { useModalProps } from '../../AssignmentModal.utils'

const CHARACTER_LIMIT = 50

export type OnStateChange = (
  assignment: AssignmentDetailedInfo,
  formValues: AssignmentStateSectionValues
) => void

export enum AssignmentStateSectionFields {
  AssignmentsIds = 'assignmentIds',
  Notes = 'notes',
  ResourceNote = 'resourceNote',
  ExternalResourceName = 'externalResourceName',
  ExternalResourceHSAID = 'externalResourceHSAID',
  IsClosed = 'isClosed',
  IsResolvedInternally = 'isResolvedInternally',
  IsPreliminary = 'isPreliminary',
  HasExternalResource = 'hasExternalResource',
  HasNoResource = 'hasNoResource',
}

export interface AssignmentStateSectionValues {
  [AssignmentStateSectionFields.AssignmentsIds]: number[]
  [AssignmentStateSectionFields.ExternalResourceName]: string
  [AssignmentStateSectionFields.ExternalResourceHSAID]: string
  [AssignmentStateSectionFields.Notes]: string
  [AssignmentStateSectionFields.ResourceNote]: string
  [AssignmentStateSectionFields.IsClosed]: boolean
  [AssignmentStateSectionFields.IsResolvedInternally]: boolean
  [AssignmentStateSectionFields.IsPreliminary]: boolean
  [AssignmentStateSectionFields.HasExternalResource]: boolean
  [AssignmentStateSectionFields.HasNoResource]: boolean
}

export const useAssignmentStateSectionDefaultValues = () => {
  const { needAssignment } = useAssignmentModal()
  return {
    [AssignmentStateSectionFields.AssignmentsIds]: needAssignment
      ? [needAssignment?.id]
      : [],
    [AssignmentStateSectionFields.IsClosed]:
      needAssignment?.status === AssignmentStatus.Canceled &&
      needAssignment.cancelReason === CancelReason.Canceled,
    [AssignmentStateSectionFields.IsResolvedInternally]:
      needAssignment?.status === AssignmentStatus.Canceled &&
      needAssignment.cancelReason === CancelReason.ResolvedInternally,
    [AssignmentStateSectionFields.HasExternalResource]:
      needAssignment?.status === AssignmentStatus.BookedExternally,
    [AssignmentStateSectionFields.ExternalResourceName]:
      (needAssignment?.status === AssignmentStatus.BookedExternally &&
        needAssignment?.externalResourceName) ||
      '',
    [AssignmentStateSectionFields.ExternalResourceHSAID]:
      (needAssignment?.status === AssignmentStatus.BookedExternally &&
        needAssignment?.externalResourceHSAID) ||
      '',
    [AssignmentStateSectionFields.IsPreliminary]:
      needAssignment?.isPreliminary || false,
    [AssignmentStateSectionFields.Notes]: needAssignment?.note || '',
    [AssignmentStateSectionFields.ResourceNote]:
      needAssignment?.resourceNote || '',
    [AssignmentStateSectionFields.HasNoResource]:
      needAssignment?.status === AssignmentStatus.NoResources,
  }
}

export const useAssignmentStateSectionValidationSchema =
  (): SchemaOf<AssignmentStateSectionValues> => {
    const { t } = useTranslation()
    return object()
      .shape({
        [AssignmentStateSectionFields.AssignmentsIds]: array()
          .of(number())
          .min(1, t('validation.required')),
        [AssignmentStateSectionFields.IsClosed]: boolean(),
        [AssignmentStateSectionFields.IsResolvedInternally]: boolean(),
        [AssignmentStateSectionFields.HasExternalResource]: boolean(),
        [AssignmentStateSectionFields.ExternalResourceName]: string().when(
          [AssignmentStateSectionFields.HasExternalResource],
          { is: true, then: string().required(t('validation.required')) }
        ),
        [AssignmentStateSectionFields.ExternalResourceHSAID]: string().when(
          [AssignmentStateSectionFields.HasExternalResource],
          {
            is: true,
            then: string().max(
              CHARACTER_LIMIT,
              t('validation.characterLimit', {
                characterLimit: CHARACTER_LIMIT,
              })
            ),
          }
        ),
        [AssignmentStateSectionFields.IsPreliminary]: boolean().when(
          [AssignmentStateSectionFields.HasExternalResource],
          { is: true, then: boolean() }
        ),
        [AssignmentStateSectionFields.Notes]: string().nullable(),
        [AssignmentStateSectionFields.ResourceNote]: string().nullable(),
        [AssignmentStateSectionFields.HasNoResource]: boolean(),
      })
      .required()
  }

export const useOnAssignmentStateSectionSubmit = () => {
  const { needAssignment, handleClose, onStateChange } = useModalProps()

  return (values: AssignmentStateSectionValues) => {
    if (!needAssignment) return
    onStateChange(needAssignment, values)
    handleClose()
  }
}

export const useAssignmentStateSectionProps =
  (): FormProps<AssignmentStateSectionValues> => {
    const schema = useAssignmentStateSectionValidationSchema()
    const onSubmit = useOnAssignmentStateSectionSubmit()
    const defaultValues = useAssignmentStateSectionDefaultValues()
    const methods = useForm<AssignmentStateSectionValues>({
      defaultValues,
      resolver: yupResolver(schema),
    })

    return { ...methods, onSubmit }
  }

const getCancelField = (cancelReason?: CancelReason) => {
  if (cancelReason === CancelReason.Canceled)
    return AssignmentStateSectionFields.IsClosed

  return AssignmentStateSectionFields.IsResolvedInternally
}

const getFieldFromStatus = (assignment: AssignmentDetailedInfo | null) => {
  switch (assignment?.status) {
    case AssignmentStatus.Canceled:
      return getCancelField(assignment?.cancelReason)
    case AssignmentStatus.NoResources:
      return AssignmentStateSectionFields.HasNoResource
    case AssignmentStatus.BookedExternally:
      return AssignmentStateSectionFields.HasExternalResource
    default:
      return ''
  }
}

export const useSelectedOption = (
  assignment: AssignmentDetailedInfo | null
) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    getFieldFromStatus(assignment)
  )

  return { selectedOption, setSelectedOption }
}

interface UseRemoveAssignmentsProps {
  assignmentIds: number[]
  onSuccess?: () => void
}

export const useRemoveAssignments = ({
  assignmentIds,
  onSuccess = () => {},
}: UseRemoveAssignmentsProps) => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const isRemoving = useSelector(
    selectors.schedules.getIsRemoveAssignmentsLoading
  )

  const removeAssignments = () => {
    if (!assignmentIds?.length) return
    dispatch(
      actions.schedules.removeAssignments({
        careProviderId,
        assignmentIds,
        onSuccess: () => {
          onSuccess()
          enqueueSnackbar(t('assignmentModal.removeShiftSuccess'))
        },
        onFailure: msg => {
          enqueueSnackbar(msg || t('assignmentModal.removeShiftFailure'), {
            variant: 'error',
          })
        },
      })
    )
  }
  return { removeAssignments, isRemoving }
}
