import Api from '../api'
import {
  GetHourRatesParams,
  GetHourRatesResponse,
  CreateHourRateParams,
  CreateHourRateResponse,
  UpdateHourRateParams,
  UpdateHourRateResponse,
  DeleteHourRateParams,
  DeleteHourRateResponse,
} from './HourRates.types'

class HourRates extends Api {
  public getRates = async ({
    careProviderId,
    ...params
  }: GetHourRatesParams) => {
    const response = await this.api.get<GetHourRatesResponse>(
      `/care-providers/${careProviderId}/hour-rates`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public createRate = async ({
    careProviderId,
    ...params
  }: CreateHourRateParams) => {
    const response = await this.api.post<CreateHourRateResponse>(
      `/care-providers/${careProviderId}/hour-rates`,
      params
    )
    return response.data
  }

  public updateRate = async ({
    careProviderId,
    hourRateId,
    ...params
  }: UpdateHourRateParams) => {
    const response = await this.api.put<UpdateHourRateResponse>(
      `/care-providers/${careProviderId}/hour-rates/${hourRateId}`,
      params
    )
    return response.data
  }

  public deleteRate = async ({
    careProviderId,
    hourRateId,
    ...params
  }: DeleteHourRateParams) => {
    const response = await this.api.delete<DeleteHourRateResponse>(
      `/care-providers/${careProviderId}/hour-rates/${hourRateId}`,
      params
    )
    return response.data
  }
}

export default HourRates
