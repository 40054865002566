import { SvgIcon, SvgIconProps } from '@mui/material'

const Reply = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.70711 0.292893C7.09763 0.683417 7.09763 1.31658 6.70711 1.70711L3.41421 5H9C15.0753 5 20 9.92472 20 16V17C20 17.5523 19.5523 18 19 18C18.4477 18 18 17.5523 18 17V16C18 11.0293 13.9707 7 9 7H3.41421L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Reply
