import { Grid, Chip, IconButton, styled, Typography } from '@mui/material'

export const NameGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: `0 !important`,
})

export const Name = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.neutral.contrastText,
  paddingLeft: theme.spacing(0.5),
}))

export const ShiftsGrid = styled(Grid)({
  paddingTop: `0 !important`,
})

export const DividerText = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  color: theme.palette.neutral.contrastText,
  textTransform: 'uppercase',
  letterSpacing: 1,
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: 0,
}))

export const BookedAssignmentGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
})

interface StatusChipProps {
  externalResource: boolean
}

export const StatusChip = styled(Chip)<StatusChipProps>(
  ({ theme, externalResource }) => ({
    marginLeft: theme.spacing(2),

    height: 16,
    '& .MuiChip-label': {
      fontSize: '0.725rem',
      marginRight: 0,
    },
    ...(externalResource && {
      color: theme.palette.common.white,
      background: theme.palette.pill.blue.main,
    }),
    ...(!externalResource && {
      color: theme.palette.common.black,
      background: theme.palette.pill.yellow.main,
    }),
  })
)
