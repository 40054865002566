import { ComponentType, Fragment, useCallback, useMemo, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import {
  TableBody,
  Grid,
  TableCell,
  TableRow,
  TablePaginationProps,
} from '@mui/material'
import config from 'config'
import { ResourceData, useResourceTable } from '../../resource'
import { LoadingStatus } from '../../types'
import { FiltersProps } from '../Filters'
import DataTableEmptyMessage from './DataTableEmptyMessage'
import DataTableErrorHandler from './DataTableErrorHandler'
import DataTableHead from './DataTableHead'
import DataTableLoader from './DataTableLoader'
import DataTablePagination from './DataTablePagination'
import DataTableRow, { DataTableRowProps } from './DataTableRow'
import {
  StyledTableContainer,
  StyledTable,
  SummaryRow,
  GroupingRow,
  SpannedTableCell,
  BottomLoader,
} from './DataTable.style'
import { Column, TableHeadVariant, GroupedData } from './DataTable.types'

export interface DataTableProps<Row, Payload> {
  columns: Column[]
  resource: ResourceData<Row[], Payload>
  groupedData?: GroupedData<Row>
  dense?: boolean
  nested?: boolean
  disableClick?: boolean
  disableOverflow?: boolean
  renderColumnLabels?: boolean
  hidePagination?: boolean
  loadMoreByGroupedRows?: boolean
  onRowClick?: (row: Row) => void
  onLoadMore?: (skip: Payload) => void
  onDelete?: (row: Row) => void
  onEdit?: (row: Row) => void
  ExpandComponent?: DataTableRowProps['ExpandComponent']
  shouldCollapseExpandComponents?: boolean
  Filters?: ComponentType<FiltersProps>
  RowComponent?: ComponentType<DataTableRowProps>
  PaginationComponent?: ComponentType<TablePaginationProps>
  pageSize?: number
  tableSummary?: Row
  tableHeadVariant?: TableHeadVariant
}

const DataTable = <Row extends {}, Payload>({
  resource: { data, meta, isInitialized, loading, error, getData },
  columns,
  tableSummary,
  disableClick,
  Filters,
  groupedData = [],
  ExpandComponent,
  onDelete,
  onEdit,
  onRowClick = () => {},
  onLoadMore,
  loadMoreByGroupedRows = false,
  disableOverflow = false,
  dense = false,
  nested = false,
  hidePagination = false,
  renderColumnLabels = true,
  tableHeadVariant = 'default',
  pageSize = config.pagination.defaultPageSize,
  RowComponent = DataTableRow,
  PaginationComponent = DataTablePagination,
  shouldCollapseExpandComponents = false,
  ...props
}: DataTableProps<Row, Payload>) => {
  const { fetchData, sorting, pagination, sort, changePage } =
    useResourceTable<Payload>(getData, pageSize)

  const isEmpty = data.length === 0
  const hasError = loading === LoadingStatus.Failed
  const isLoading =
    loading === LoadingStatus.Idle || loading === LoadingStatus.Pending

  const currentDataCount = useMemo(
    () =>
      loadMoreByGroupedRows
        ? groupedData.reduce((acc, { rows }) => acc + rows.length, 0)
        : groupedData.length,
    [groupedData, loadMoreByGroupedRows]
  )

  const [currentFilters, setCurrentFilters] = useState<Payload>()

  const shouldLoadMore =
    meta?.count && meta.count > currentDataCount && onLoadMore && !isLoading

  const submitFilters = useCallback(
    (payload: Payload) => {
      fetchData(payload)
      setCurrentFilters(payload)
    },
    [fetchData]
  )

  return (
    <>
      <StyledTableContainer
        noOverflow={disableOverflow}
        nested={nested}
        {...props}
      >
        {Filters && <Filters onSubmit={submitFilters} />}
        <DataTableErrorHandler visible={hasError}>
          {error}
        </DataTableErrorHandler>
        <DataTableLoader
          overlay={isInitialized}
          loading={isLoading}
          columns={columns.length}
          dense={dense}
          nested={nested}
          columnsLabelsVisible={renderColumnLabels}
        >
          <StyledTable
            stickyHeader={nested}
            dense={dense}
            size={dense ? 'small' : 'medium'}
            nested={nested}
            tableHeadVariant={tableHeadVariant}
          >
            {renderColumnLabels && (
              <DataTableHead
                columns={columns}
                sort={sort}
                sorting={sorting}
                nested={nested}
                tableHeadVariant={tableHeadVariant}
              />
            )}
            <TableBody>
              {isInitialized && isEmpty && (
                <DataTableEmptyMessage colSpan={columns.length} />
              )}
              {groupedData.length > 0
                ? groupedData.map(({ column, rows }, i) => (
                    <Fragment key={`grouped-${i}`}>
                      <GroupingRow>
                        <TableCell data-test-id={columns[0].field}>
                          {column}
                        </TableCell>
                        <TableCell colSpan={columns.length - 1} />
                      </GroupingRow>
                      {rows.map((row: Row, index: number) => (
                        <RowComponent
                          key={`row-${i}-${index}`}
                          columns={columns}
                          row={row}
                          flat={dense}
                          nested={nested}
                          onDelete={onDelete ? () => onDelete(row) : null}
                          onEdit={onEdit ? () => onEdit(row) : null}
                          onClick={() => onRowClick(row)}
                          ExpandComponent={ExpandComponent}
                          shouldCollapseExpandComponents={
                            shouldCollapseExpandComponents
                          }
                          disableClick={disableClick}
                          {...(index === 0 && {
                            spanningRow: (
                              <SpannedTableCell rowSpan={rows.length} />
                            ),
                          })}
                        />
                      ))}
                    </Fragment>
                  ))
                : data.map((row, i) => (
                    <RowComponent
                      key={`row-${i}`}
                      columns={columns}
                      row={row}
                      flat={dense}
                      nested={nested}
                      onDelete={onDelete ? () => onDelete(row) : null}
                      onEdit={onEdit ? () => onEdit(row) : null}
                      onClick={() => onRowClick(row)}
                      ExpandComponent={ExpandComponent}
                      shouldCollapseExpandComponents={
                        shouldCollapseExpandComponents
                      }
                      disableClick={disableClick}
                    />
                  ))}
              {!!tableSummary && (
                <SummaryRow
                  key={`row-summary`}
                  columns={columns}
                  row={tableSummary}
                  flat={dense}
                  disableClick
                />
              )}
              {isLoading && onLoadMore && (
                <TableRow key="loading-row">
                  <TableCell colSpan={columns.length}>
                    <BottomLoader size={50} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </DataTableLoader>
        {!!shouldLoadMore && currentFilters && (
          <Waypoint
            key="waypoint"
            onEnter={() =>
              onLoadMore({ ...currentFilters, skip: currentDataCount })
            }
          />
        )}
        {!hidePagination && (
          <Grid container item justifyContent="flex-end">
            <PaginationComponent
              onPageChange={(_, newPage) => changePage(newPage)}
              count={meta?.count ?? config.pagination.defaultCount}
              page={pagination.page ?? config.pagination.defaultPage}
              rowsPerPage={
                pagination.pageSize || config.pagination.defaultPageSize
              }
            />
          </Grid>
        )}
      </StyledTableContainer>
    </>
  )
}

export default DataTable
