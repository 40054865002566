import { useCallback, useEffect, useMemo } from 'react'
import { NavigateBefore, Edit } from '@mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import { useOrganizationId } from '../../../hooks'
import { AssignmentStatus } from '../../../types'
import AssignmentStateSection, {
  useAssignmentStateSectionProps,
} from './AssignmentStateSection'
import { useResourcesSectionProps } from './CPResourcesSection'
import ResourcesSection from './ResourcesSection'
import { useModalProps } from '../AssignmentModal.utils'
import {
  TitleGrid,
  Title,
  StyledIconButton,
  StyledEditButton,
  HeaderGrid,
} from './RequestAssignmentContent.style'
import {
  useFieldsDisabling,
  useModalTitle,
} from './RequestAssignmentContent.utils'

export const RequestAssignmentContent = () => {
  const {
    needAssignment,
    data,
    handleClose,
    refreshScheduler = () => {},
    onBack,
    openModal,
  } = useModalProps()
  const resourcesSectionProps = useResourcesSectionProps()
  const assignmentStateSectionProps = useAssignmentStateSectionProps()
  const { title, isOnOpenShift } = useModalTitle()
  const careProviderId = useOrganizationId()

  const onRemove = useCallback(() => {
    refreshScheduler()
    handleClose()
  }, [refreshScheduler, handleClose])

  const { disabledBooking, disabledState, enableResourcesSection } =
    useFieldsDisabling(
      resourcesSectionProps.control,
      assignmentStateSectionProps.control
    )

  useEffect(() => {
    return () => {
      resourcesSectionProps.reset()
      assignmentStateSectionProps.reset()
    }
  }, []) //eslint-disable-line

  const isAssignmentBooked = useMemo(
    () =>
      needAssignment?.status === AssignmentStatus.BookedInternally ||
      needAssignment?.status === AssignmentStatus.BookedExternally,
    [needAssignment?.status]
  )

  const handleEdit = useCallback(() => {
    if (!data || !data.shiftId || !data.shiftDate || !needAssignment) return
    openModal({
      careProviderId,
      shiftId: needAssignment.shift.id,
      shiftName: needAssignment.shift.name,
      isOpenShift: true,
      shiftDate: data.shiftDate,
      anchorPosition: data.anchorPosition,
    })
  }, [needAssignment, careProviderId, data, openModal])

  if (!needAssignment) return null

  return (
    <>
      <HeaderGrid container justifyContent="space-between" alignItems="center">
        <TitleGrid item container spacing={1} alignItems="center">
          {!!data?.assignments && (
            <Grid item>
              <StyledIconButton edge="start" onClick={onBack}>
                <NavigateBefore color="inherit" fontSize="large" />
              </StyledIconButton>
            </Grid>
          )}
          <Tooltip title={title} placement="top">
            <Grid item>
              <Title>{title}</Title>
            </Grid>
          </Tooltip>
          {isOnOpenShift && !isAssignmentBooked && (
            <Grid item>
              <StyledEditButton size="small" onClick={handleEdit}>
                <Edit />
              </StyledEditButton>
            </Grid>
          )}
        </TitleGrid>
        <Grid item>
          <Typography color="textSecondary">
            {needAssignment.need?.role.shortCode}
          </Typography>
        </Grid>
      </HeaderGrid>
      {enableResourcesSection && (
        <>
          <ResourcesSection
            disabledBooking={disabledBooking}
            {...resourcesSectionProps}
          />
        </>
      )}
      <AssignmentStateSection
        {...assignmentStateSectionProps}
        disableSection={disabledState}
        onRemoveAssignmentsSuccess={onRemove}
      />
    </>
  )
}

export default RequestAssignmentContent
