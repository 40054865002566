import { IconButton, styled, Typography } from '@mui/material'

const BUTTON_SIZE = 40

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))

export const Button = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1.5, 0.5),
  padding: theme.spacing(1, 0),
  width: BUTTON_SIZE,
}))
