import { ReactNode, useCallback } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import {
  Checkbox,
  FormControlLabelProps,
  FormControlLabel,
} from '@mui/material'
import { Row } from '../types'

export type CheckboxFieldProps = Partial<FormControlLabelProps> & {
  name: string
  label?: ReactNode
  multiple?: boolean
  value?: string | number | boolean | Row
  indeterminate?: boolean
  onChange?: () => void
}

const CheckboxField = ({
  name,
  value,
  checked,
  label = '',
  multiple = false,
  indeterminate = false,
  ...props
}: CheckboxFieldProps) => {
  const { control, setValue } = useFormContext()
  const {
    field: { ref },
    field,
  } = useController({ control, name })

  const isChecked = multiple ? field.value.includes(value) : !!field.value

  const handleChange = useCallback(() => {
    if (!multiple) {
      return setValue(name, !field.value, { shouldDirty: true })
    }
    return setValue(
      name,
      isChecked
        ? field.value.filter((v: string) => v !== value)
        : [...field.value, value],
      { shouldDirty: true }
    )
  }, [field.value, isChecked, multiple, name, setValue, value])

  return (
    <FormControlLabel
      inputRef={ref}
      label={label}
      onChange={handleChange}
      checked={checked ?? isChecked}
      control={
        <Checkbox
          indeterminate={!isChecked && indeterminate}
          name={name}
          color="primary"
          size="small"
        />
      }
      {...props}
    />
  )
}

export default CheckboxField
