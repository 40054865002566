import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import { Collapse, Grid, Slide, Tooltip } from '@mui/material'
import UpdateMessageModal, {
  useAdminUpdateMessageModal,
} from './UpdateMessageModal'
import {
  Container,
  CloseButton,
  UpdateMessageChip,
  ReadMoreButton,
  ButtonText,
  MessageText,
  StyledGrid,
} from './AdminMessage.style'

interface AdminMessageProps {
  bar?: boolean
  update?: boolean
  message: string
  open: boolean
  close: () => void
  messageId?: number
}

const AdminMessage = ({
  bar,
  message,
  open,
  close,
  update = false,
  messageId,
}: AdminMessageProps) => {
  const { t } = useTranslation()
  const Wrapper = useMemo(() => (bar ? Collapse : Slide), [bar])
  const { onOpen: onModalOpen } = useAdminUpdateMessageModal(messageId)
  const onReadMoreClick = () => {
    onModalOpen()
    close()
  }

  return (
    <>
      {update && <UpdateMessageModal />}
      <Wrapper in={open} direction="up" mountOnEnter unmountOnExit>
        <Container bar={bar}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            spacing={2}
          >
            <StyledGrid item>
              <Grid container alignItems="center" spacing={3}>
                {update && (
                  <Grid item>
                    <UpdateMessageChip
                      label={t('adminMessage.news')}
                      size="small"
                    />
                  </Grid>
                )}
                <StyledGrid item xs>
                  <Tooltip title={message || ''} placement="bottom">
                    <MessageText variant="body2" color="inherit">
                      {message}
                    </MessageText>
                  </Tooltip>
                </StyledGrid>
              </Grid>
            </StyledGrid>
            <Grid item xs="auto">
              <Grid container spacing={3} flexWrap="nowrap">
                {update && (
                  <Grid item>
                    <ReadMoreButton
                      variant="outlined"
                      onClick={onReadMoreClick}
                    >
                      <ButtonText>{t('adminMessage.readMore')}</ButtonText>
                    </ReadMoreButton>
                  </Grid>
                )}
                <Grid item>
                  <CloseButton size="small" onClick={close}>
                    <Close />
                  </CloseButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    </>
  )
}

export default AdminMessage
