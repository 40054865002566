import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import {
  DepartmentsSummary,
  LoadingStatus,
  ShiftsSummary,
  UnitsSummary,
} from 'shared/types'
import {
  exportOrganizationStatistics,
  exportUnitStatistics,
  getDepartmentsStatistics,
  getShiftsStatistics,
  getUnitsStatistics,
} from './actions'

interface State {
  loading: LoadingStatus
  error?: string | null
  getDepartmentsSummary: Resource<DepartmentsSummary>
  getUnitsSummary: Resource<UnitsSummary>
  getShiftsSummary: Resource<ShiftsSummary>
  exportUnitStatistics: Resource
  exportOrganizationStatistics: Resource
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  getDepartmentsSummary: resource.getInitial<DepartmentsSummary>(),
  getUnitsSummary: resource.getInitial<UnitsSummary>(),
  getShiftsSummary: resource.getInitial<ShiftsSummary>(),
  exportUnitStatistics: resource.getInitial(),
  exportOrganizationStatistics: resource.getInitial(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getDepartmentsStatistics.pending, state => {
      resource.setPending(state.getDepartmentsSummary)
    })
    .addCase(getDepartmentsStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.getDepartmentsSummary, action.payload.data)
    })
    .addCase(getDepartmentsStatistics.rejected, (state, action) => {
      resource.setFailed(state.getDepartmentsSummary, action.error.message)
    })
    .addCase(getUnitsStatistics.pending, state => {
      resource.setPending(state.getUnitsSummary)
    })
    .addCase(getUnitsStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUnitsSummary, action.payload.data)
    })
    .addCase(getUnitsStatistics.rejected, (state, action) => {
      resource.setFailed(state.getUnitsSummary, action.error.message)
    })
    .addCase(getShiftsStatistics.pending, state => {
      resource.setPending(state.getShiftsSummary)
    })
    .addCase(getShiftsStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.getShiftsSummary, action.payload.data)
    })
    .addCase(getShiftsStatistics.rejected, (state, action) => {
      resource.setFailed(state.getShiftsSummary, action.error.message)
    })
    .addCase(exportUnitStatistics.pending, state => {
      resource.setPending(state.exportUnitStatistics)
    })
    .addCase(exportUnitStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportUnitStatistics)
    })
    .addCase(exportUnitStatistics.rejected, (state, action) => {
      resource.setFailed(state.exportUnitStatistics, action.error.message)
    })
    .addCase(exportOrganizationStatistics.pending, state => {
      resource.setPending(state.exportOrganizationStatistics)
    })
    .addCase(exportOrganizationStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportOrganizationStatistics)
    })
    .addCase(exportOrganizationStatistics.rejected, (state, action) => {
      resource.setFailed(
        state.exportOrganizationStatistics,
        action.error.message
      )
    })
)
