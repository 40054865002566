export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum UserRole {
  SuperCareProvider = 'SUPER_CARE_PROVIDER',
  CareProvider = 'CARE_PROVIDER',
  SuperStaffProvider = 'SUPER_STAFF_PROVIDER',
  StaffProvider = 'STAFF_PROVIDER',
  UnitAdmin = 'UNIT_ADMIN',
  Resource = 'CARE_PROVIDER_RESOURCE',
}

export enum RoleGroup {
  SystemAdmin = 'SYSTEM_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  UnitAdmin = 'UNIT_ADMIN',
  Resource = 'RESOURCE',
  Admin = 'ADMIN',
}

export enum AgreementType {
  Framework = 'FRAMEWORK',
  Direct = 'DIRECT',
  Public = 'PUBLIC',
}

export enum EmployeeType {
  Substitute = 'SUBSTITUTE',
  Ordinary = 'ORDINARY_STAFF',
}

export enum EmploymentType {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Hourly = 'HOURLY',
}

export enum PreferredWorkTime {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Night = 'NIGHT',
  Weekend = 'WEEKEND',
}

export enum CareProviderResourceDocumentType {
  CV = 'CV',
  Reference = 'REFERENCE',
  HOSP = 'HOSP',
  IVO = 'IVO',
  QualifyingPeriod = 'QUALIFYING_PERIOD',
  Other = 'OTHER',
}

export enum NeedStatus {
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED',
}

export enum AssignmentStatus {
  BookedExternally = 'BOOKED_EXTERNALLY',
  BookedInternally = 'BOOKED_INTERNALLY',
  Empty = 'EMPTY',
  Canceled = 'CANCELED',
  RequestedInternally = 'REQUESTED_INTERNALLY',
  RequestedExternally = 'REQUESTED_EXTERNALLY',
  Bookable = 'BOOKABLE',
  Available = 'AVAILABLE',
  Requestable = 'REQUESTABLE',
  Other = 'OTHER',
  NoResources = 'NO_RESOURCES',
}

export enum ResourceType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum NotificationType {
  // Need
  NeedCreated = 'NEED_CREATED',

  // Need assignment request
  NeedAssignmentRequestCreated = 'NEED_ASSIGNMENT_REQUEST_CREATED',
  NeedAssignmentRequestAccepted = 'NEED_ASSIGNMENT_REQUEST_ACCEPTED',
  NeedAssignmentRequestRejected = 'NEED_ASSIGNMENT_REQUEST_REJECTED',

  // Need assignment
  NeedAssignmentBooked = 'NEED_ASSIGNMENT_BOOKED',
  NeedAssignmentCanceled = 'NEED_ASSIGNMENT_CANCELED',
  NeedAssignmentNoteAdded = 'NEED_ASSIGNMENT_NOTE_ADDED',
  NeedAssignmentResourceNoteAdded = 'NEED_ASSIGNMENT_RESOURCE_NOTE_ADDED',

  Other = 'other',
}

export enum GroupOrientation {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

export enum ResourcePeriodType {
  Availability = 'availability',
  Absence = 'absence',
  Assignment = 'assignment',
  AssignmentRequest = 'assignmentRequest',
  AssignmentRejected = 'assignmentRejected',
  AssignmentCanceled = 'assignmentCanceled',
}

export enum AbsenceReason {
  Sickness = 'SICKNESS',
  Vacation = 'VACATION',
  ParentalRelief = 'PARENTAL_RELIEF',
}

export enum AssignmentRequestStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
}

export enum BooleanType {
  Yes = 'yes',
  No = 'no',
}

export enum ResourceActions {
  Accept = 'accept',
  Reject = 'reject',
}

export enum CancelReason {
  Canceled = 'CANCELED',
  ResolvedInternally = 'RESOLVED_INTERNALLY',
}

export enum ActionType {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export enum NeedAssignmentStatus {
  BookedInternally = 'BOOKED_INTERNALLY',
  BookedExternally = 'BOOKED_EXTERNALLY',
  Canceled = 'CANCELED',
  NoResources = 'NO_RESOURCES',
  Empty = 'EMPTY',
  RequestedInternally = 'REQUESTED_INTERNALLY',
  RequestedExternally = 'REQUESTED_EXTERNALLY',
  OtherAssignments = 'OTHER_ASSIGNMENTS',
}

export enum AuthChallenge {
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  MFARequired = 'MFA_REQUIRED',
}

export enum SchedulerType {
  Scheme = 'scheme',
  Booking = 'booking',
}

export enum CalendarView {
  Weekly = 'Week',
  Monthly = 'Month',
}

export enum MessageDeliveryType {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export enum StatisticsTimeInterval {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
}

export enum ShiftTimeType {
  Day = 'DAY',
  Evening = 'EVENING',
  Night = 'NIGHT',
}

export enum ResourceUnassignmentReason {
  Closed = 'closed',
  Unbooked = 'unbooked',
  Solved = 'solved',
}

export enum SchedulerEditingModeDialogType {
  Save = 'save',
  Abort = 'abort',
  UnsavedChanges = 'unsavedChanges',
}

export enum OpenShiftNeedType {
  ExistingNeed = 'existingNeed',
  NewNeed = 'newNeed',
}

export enum ShiftType {
  Defined = 'DEFINED',
  Open = 'OPEN',
}

export enum ApplicationType {
  Any = 'ANY',
  ViviumX = 'VIVIUM_X',
  ViviumI = 'VIVIUM_I',
}

export enum StringBoolean {
  True = 'true',
  False = 'false',
}

export enum LisitingAssignmentStatus {
  Empty = 'EMPTY',
  Accepted = 'ACCEPTED',
  Booked = 'BOOKED',
  Canceled = 'CANCELED',
}

export enum ListingAssignmentCancelReason {
  Canceled = 'CANCELED',
  Emptied = 'EMPTIED',
  ResolvedInternally = 'RESOLVED_INTERNALLY',
}

export enum AssignmentType {
  ListingAssignment = 'LISTING_ASSIGNMENT',
  NeedAssignment = 'NEED_ASSIGNMENT',
}

export enum AdminMessageFeedbackRateType {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
}

export enum Country {
  Sweden = 'Sweden',
  Norway = 'Norway',
}
