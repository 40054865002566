import {
  InputLabel as BaseInputLabel,
  Typography,
  Theme,
  styled,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

interface ErrorStyleProps {
  error: boolean
}

export const InputLabel = styled(BaseInputLabel)<ErrorStyleProps>(
  ({ theme, error }) => ({
    marginBottom: theme.spacing(1.5),
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  })
)

export const ErrorWrapper = styled(Typography)(({ theme }) => ({
  marginTop: 3,
  width: '100%',
  color: theme.palette.error.main,
  fontSize: '0.75rem',
}))

export const useRadioStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))

export const StyledRadioGroup = styled(RadioGroup)<ErrorStyleProps>(
  ({ theme, error }) => ({
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: error ? theme.palette.error.main : 'transparent',
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  })
)
