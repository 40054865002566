import { useTranslation } from 'react-i18next'
import { useAssignmentsDatesText } from '.'
import { AssignmentStatus, ShiftType } from '../types'

interface UseStatusPillLabelParams {
  status: AssignmentStatus
  shiftType?: ShiftType
  startDate?: string
  endDate?: string
  hasAbsence?: boolean
}

export const useStatusPillLabel = ({
  endDate,
  shiftType,
  startDate,
  status,
  hasAbsence,
}: UseStatusPillLabelParams) => {
  const { t } = useTranslation()

  const absenceLabel = t('scheduler.veto')
  const requestLabel = t('scheduler.request')
  const getEnumLabel = (status: AssignmentStatus) =>
    t(`scheduler.status.${status}`)
  const assignmentsDatesText = useAssignmentsDatesText(startDate, endDate)

  if (hasAbsence) return absenceLabel

  if (shiftType === ShiftType.Open && !!startDate && !!endDate)
    return assignmentsDatesText

  switch (status) {
    case AssignmentStatus.RequestedInternally:
    case AssignmentStatus.RequestedExternally:
      return requestLabel
    case AssignmentStatus.Available:
    case AssignmentStatus.Bookable:
    case AssignmentStatus.BookedInternally:
    case AssignmentStatus.BookedExternally:
    case AssignmentStatus.Empty:
    case AssignmentStatus.Canceled:
    case AssignmentStatus.NoResources:
      return getEnumLabel(status)
    default:
      return ''
  }
}
