import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { LoadingStatus } from 'shared/types'

export const getGetChatsResource = (state: RootState) => state.messages.getChats

export const isGetChatsLoading = createSelector(
  [getGetChatsResource],
  ({ loading }) => loading === LoadingStatus.Pending
)

export const getGetMessagesResource = (state: RootState) => {
  const { data, ...resource } = state.messages.getChatMessages
  return {
    ...resource,
    data: Object.entries(data).map(([key, value]) => ({
      id: parseInt(key),
      ...value,
    })),
  }
}

export const getSelectedChatResource = (state: RootState) =>
  state.messages.getSelectedChat

export const getSendMessageLoading = (state: RootState) =>
  state.messages.sendChatMessage.loading

export const getIsSubmittingChatForm = (state: RootState) =>
  [
    state.messages.createChat.loading,
    state.messages.addUsersToChat.loading,
  ].includes(LoadingStatus.Pending)

export const getIsArchivingChat = (state: RootState) =>
  state.messages.archiveChat.loading === LoadingStatus.Pending

export const getChatsFiltersParams = (state: RootState) =>
  state.messages.chatsFilterParams
