import { Components } from '@mui/material'

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    input: { padding: '8px 4px 12px' },
    inputAdornedStart: { paddingLeft: 0 },
    inputAdornedEnd: { paddingRight: 0 },
  },
}

export default MuiInputBase
