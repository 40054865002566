import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ReadChatNotificationsParams } from 'shared/services/chat'
import { Resources, Chat } from '../../services'
import Notifications, {
  GetNotificationsParams,
  GetUnreadNotificationsParams,
  GetSchedulesUnreadNotificationsParams,
  MarkAdminMessageAsReadParams,
  GetAdminUpdateMessageParams,
  SendAdminMessageFeedbackParams,
  OpenAdminUpdateMessageModalParams,
} from '../../services/notifications'
import { GetResourceRequestsParams } from '../../services/resources'
import { NAMESPACE } from '../../strings'
import {
  ApplicationType,
  AssignmentRequestStatus,
  NotificationType,
} from '../../types'
import { createAsyncThunkWithErrorHandling } from '../../utils'
import {
  AddChatNotificationPayload,
  GetUnreadChatNotificationsPayload,
  MarkAsReadPayload,
  SetNotificationCountPayload,
} from './actions.types'

const notifications = new Notifications()
const resources = new Resources()
const chat = new Chat()

export const setNotificationsCount = createAction<SetNotificationCountPayload>(
  `${NAMESPACE}/setNotificationsCount`
)

export const getUnreadNotifications = createAsyncThunk(
  `${NAMESPACE}/getUnreadNotifications`,
  (payload: GetUnreadNotificationsParams) =>
    notifications.getUnreadNotifications(payload)
)

export const getSchedulesUnreadNotifications = createAsyncThunk(
  `${NAMESPACE}/getSchedulesUnreadNotifications`,
  (payload: GetSchedulesUnreadNotificationsParams) =>
    notifications.getSchedulesUnreadNotifications(payload)
)

export const getNotifications = createAsyncThunk(
  `${NAMESPACE}/getNotifications`,
  (payload: GetNotificationsParams) => notifications.getNotifications(payload)
)

export const getResourceBookingsNotifications = createAsyncThunk(
  `${NAMESPACE}/getResourceBookingsNotifications`,
  (payload: GetNotificationsParams) =>
    notifications.getNotifications({
      ...payload,
      notificationTypes: [
        NotificationType.NeedAssignmentBooked,
        NotificationType.NeedAssignmentNoteAdded,
      ],
    })
)

export const getResourceRequestsNotifications = createAsyncThunk(
  `${NAMESPACE}/getResourceRequestsNotifications`,
  (payload: GetNotificationsParams) =>
    notifications.getNotifications({
      ...payload,
      notificationTypes: [NotificationType.NeedAssignmentRequestCreated],
    })
)

export const getNeedsCreatedNotifications = createAsyncThunk(
  `${NAMESPACE}/getNeedsCreatedNotifications`,
  (payload: GetNotificationsParams) =>
    notifications.getNotifications({
      ...payload,
      notificationTypes: [NotificationType.NeedCreated],
    })
)

export const resetNotifications = createAction(
  `${NAMESPACE}/resetNotifications`
)

export const resetRequestsNotifications = createAction(
  `${NAMESPACE}/resetRequestsNotifications`
)

export const resetBookingsNotifications = createAction(
  `${NAMESPACE}/resetBookingsNotifications`
)

export const markAsRead = createAsyncThunk(
  `${NAMESPACE}/markAsRead`,
  async (
    {
      notificationIds,
      careProviderId,
      resourceId,
      onSuccess,
    }: MarkAsReadPayload,
    { dispatch }
  ) => {
    const data = await notifications.markAsRead(notificationIds)
    if (careProviderId)
      dispatch(
        getUnreadNotifications({
          careProviderId,
          ...(resourceId && { resourceId }),
        })
      )
    if (onSuccess) onSuccess()
    return data
  }
)

export const getRequestsCount = createAsyncThunk(
  `${NAMESPACE}/getRequestsCount`,
  (payload: GetResourceRequestsParams) =>
    resources.getRequests({
      ...payload,
      requestStatus: AssignmentRequestStatus.Pending,
    })
)

export const getLatestAdminInfoMessage = createAsyncThunk(
  `${NAMESPACE}/getLatestAdminInfoMessage`,
  () => notifications.getLatestAdminInfoMessage()
)

export const getLatestAdminUpdateMessage = createAsyncThunk(
  `${NAMESPACE}/getLatestAdminUpdateMessage`,
  () => notifications.getLatestAdminUpdateMessage()
)

export const getAdminUpdateMessage = createAsyncThunk(
  `${NAMESPACE}/getAdminUpdateMessage`,
  (payload: GetAdminUpdateMessageParams) =>
    notifications.getAdminUpdateMessage(payload)
)

export const openAdminUpdateMessageModal = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/openAdminUpdateMessageModal`,
  (payload: OpenAdminUpdateMessageModalParams) =>
    notifications.getAdminUpdateMessage(payload)
)

export const closeAdminUpdateMessageModal = createAction(
  `${NAMESPACE}/setAdminUpdateMessageModalIsOpen`
)

export const markAdminMessageAsRead = createAsyncThunk(
  `${NAMESPACE}/markAdminMessageAsRead`,
  (payload: MarkAdminMessageAsReadParams) =>
    notifications.markAdminMessageAsRead(payload)
)

export const sendAdminMessageFeedback = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/sendAdminMessageFeedback`,
  (payload: SendAdminMessageFeedbackParams) =>
    notifications.sendAdminMessageFeedback(payload)
)

export const getUnreadChatNotifications = createAsyncThunk(
  `${NAMESPACE}/getUnreadChatNotifications`,
  (payload: GetUnreadChatNotificationsPayload) =>
    chat.getChatNotifications({ app: ApplicationType.ViviumI, ...payload })
)

export const readChatNotifications = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/readChatNotifications`,
  (payload: ReadChatNotificationsParams) => chat.readChatNotifications(payload)
)

export const addChatNotification = createAction<AddChatNotificationPayload>(
  `${NAMESPACE}/addChatNotification`
)
