import { alpha, styled } from '@mui/material'

interface StylesProps {
  monthlyView: boolean
  firstWeekInRow: boolean
}

export const Header = styled('div')<StylesProps>(
  ({ theme, monthlyView, firstWeekInRow }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(1),
    background: monthlyView ? '' : theme.palette.grey[200],
    borderBottom: monthlyView
      ? `4px solid ${alpha(theme.palette.common.black, 0.3)}`
      : 'none',
    margin: monthlyView ? theme.spacing(0, 0.25) : '',
    marginLeft: monthlyView && firstWeekInRow ? theme.spacing(10) : '',
  })
)

export const Main = styled('div')<StylesProps>(
  ({ theme, monthlyView, firstWeekInRow }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    padding: monthlyView ? '' : theme.spacing(2),
    borderLeft: firstWeekInRow
      ? ''
      : `1px solid ${alpha(theme.palette.common.black, 0.3)}`,
    boxShadow: monthlyView
      ? ''
      : `0px 2px 10px ${alpha(theme.palette.common.black, 0.12)}`,
  })
)

export const Month = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  marginLeft: theme.spacing(1),
}))
