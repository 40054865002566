import { useTranslation } from 'react-i18next'
import { Grid, Typography, Box, BoxProps } from '@mui/material'
import { ReactComponent as Empty } from '../../assets/empty.svg'

interface DataTableEmptyMessageProps extends BoxProps {
  colSpan?: number
}

const DataTableEmptyMessage = ({
  colSpan,
  ...props
}: DataTableEmptyMessageProps) => {
  const { t } = useTranslation()
  return (
    <tr>
      <td colSpan={colSpan}>
        <Box paddingY={3} {...props}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <Empty />
            </Grid>
            <Grid item>
              <Typography variant="body1" color="textSecondary">
                {t('dataTable.noItems')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </td>
    </tr>
  )
}

export default DataTableEmptyMessage
