import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { CheckboxContainer } from '../../AssignmentModal.style'
import { useModalProps } from '../../AssignmentModal.utils'
import { MessageText } from '../RequestAssignmentContent.style'
import {
  RejectedRequestLabel,
  RequestContainer,
  RequestLabel,
  RequestsContainerLabel,
} from './UAResourcesSection.style'
import { useRequestedResources } from './UAResourcesSection.utils'

interface UAResourcesSectionProps {}

const UAResourcesSection = (props: UAResourcesSectionProps) => {
  const { t } = useTranslation()
  const { needAssignment } = useModalProps()
  const { pendingRequests, rejectedRequests } =
    useRequestedResources(needAssignment)

  return (
    <>
      <RequestsContainerLabel>
        {t('assignmentModal.requestedResources', {
          count: pendingRequests.length + rejectedRequests.length,
        })}
      </RequestsContainerLabel>
      <CheckboxContainer opaque item container direction="column">
        {pendingRequests.length === 0 && rejectedRequests.length === 0 ? (
          <Grid item>
            <MessageText variant="body2">
              {t('assignmentModal.noDirectResources')}
            </MessageText>
          </Grid>
        ) : (
          <>
            {pendingRequests.map(request => (
              <RequestContainer item key={`request-${request.id}`}>
                <RequestLabel>{request.resource.name}</RequestLabel>
              </RequestContainer>
            ))}

            {rejectedRequests.map(request => (
              <RequestContainer
                container
                item
                spacing={1}
                key={`request-${request.id}`}
              >
                <Grid item>
                  <RequestLabel>{request.resource.name}</RequestLabel>
                </Grid>
                <Grid item>
                  <RejectedRequestLabel color="error">
                    {t('assignmentModal.rejectedMessage')}
                  </RejectedRequestLabel>
                </Grid>
              </RequestContainer>
            ))}
          </>
        )}
      </CheckboxContainer>
    </>
  )
}

export default UAResourcesSection
