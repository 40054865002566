import { trimString } from '../../utils'
import { StyledPill } from './Pill.style'

const MAX_LENGTH = 9

interface PillProps {
  name?: string
  add?: boolean
  counter?: boolean
  onClick?: () => void
  color?: string
  isRemovePreviewActive?: boolean
  monthlyView?: boolean
}

const Pill = ({
  name,
  onClick,
  add = false,
  counter = false,
  isRemovePreviewActive,
  color,
  monthlyView = false,
}: PillProps) => {
  return (
    <StyledPill
      onClick={onClick}
      isTypeAdd={add}
      isCounter={counter}
      isRemovePreviewActive={!!isRemovePreviewActive}
      color={color}
      monthlyView={monthlyView}
    >
      {add ? '+' : name && trimString(name, MAX_LENGTH)}
    </StyledPill>
  )
}

export default Pill
