import { useTranslation } from 'react-i18next'
import { NavigateBefore } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { ViviumX } from '../../../icons'
import AssignmentFields from './components'
import { useModalProps } from '../AssignmentModal.utils'
import {
  ButtonWrapper,
  IconWrapper,
  Title,
} from './ListingAssignmentComponent.styles'
import { useModalTitle } from './ListingAssignmentComponent.utils'

const ListingAssignmentComponent = () => {
  const { t } = useTranslation()
  const { data, handleClose, onBack } = useModalProps()
  const title = useModalTitle()

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item container alignItems="center" xs={10}>
          {!!data?.assignments && (
            <Grid item>
              <IconButton edge="start" onClick={onBack}>
                <NavigateBefore color="inherit" fontSize="large" />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Title>{title}</Title>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography align="right" color="textSecondary">
            {data?.roleShortCode}
          </Typography>
        </Grid>
      </Grid>
      <IconWrapper>
        <ViviumX />
        <Typography variant="body2">
          {t('assignmentModal.shiftExportedToViviumX')}
        </Typography>
      </IconWrapper>
      <AssignmentFields />
      <ButtonWrapper>
        <Button color="primary" onClick={handleClose} fullWidth={false}>
          {t('assignmentModal.cancel')}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default ListingAssignmentComponent
