import { SvgIcon, SvgIconProps } from '@mui/material'

const AddFile = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 25 25" {...props}>
    <path
      d="M11.25 3.75V8.125C11.25 8.62228 11.4475 9.09919 11.7992 9.45083C12.1508 9.80246 12.6277 10 13.125 10H17.5V19.375C17.5 19.8723 17.3025 20.3492 16.9508 20.7008C16.5992 21.0525 16.1223 21.25 15.625 21.25H10.8288C12.1591 20.3169 13.1159 18.9428 13.5295 17.3714C13.9432 15.8 13.7868 14.1329 13.0882 12.6658C12.3897 11.1987 11.194 10.0265 9.71342 9.357C8.2328 8.68755 6.56293 8.56417 5 9.00875V5.625C5 5.12772 5.19754 4.65081 5.54917 4.29917C5.90081 3.94754 6.37772 3.75 6.875 3.75H11.25Z"
      fill="currentColor"
    />
    <path
      d="M12.5 4.0625V8.125C12.5 8.29076 12.5658 8.44973 12.6831 8.56694C12.8003 8.68415 12.9592 8.75 13.125 8.75H17.1875L12.5 4.0625Z"
      fill="currentColor"
    />
    <path
      d="M12.5 15.625C12.5 17.1168 11.9074 18.5476 10.8525 19.6025C9.79758 20.6574 8.36684 21.25 6.875 21.25C5.38316 21.25 3.95242 20.6574 2.89752 19.6025C1.84263 18.5476 1.25 17.1168 1.25 15.625C1.25 14.1332 1.84263 12.7024 2.89752 11.6475C3.95242 10.5926 5.38316 10 6.875 10C8.36684 10 9.79758 10.5926 10.8525 11.6475C11.9074 12.7024 12.5 14.1332 12.5 15.625ZM7.5 13.125C7.5 12.9592 7.43415 12.8003 7.31694 12.6831C7.19973 12.5658 7.04076 12.5 6.875 12.5C6.70924 12.5 6.55027 12.5658 6.43306 12.6831C6.31585 12.8003 6.25 12.9592 6.25 13.125V15H4.375C4.20924 15 4.05027 15.0658 3.93306 15.1831C3.81585 15.3003 3.75 15.4592 3.75 15.625C3.75 15.7908 3.81585 15.9497 3.93306 16.0669C4.05027 16.1842 4.20924 16.25 4.375 16.25H6.25V18.125C6.25 18.2908 6.31585 18.4497 6.43306 18.5669C6.55027 18.6842 6.70924 18.75 6.875 18.75C7.04076 18.75 7.19973 18.6842 7.31694 18.5669C7.43415 18.4497 7.5 18.2908 7.5 18.125V16.25H9.375C9.54076 16.25 9.69973 16.1842 9.81694 16.0669C9.93415 15.9497 10 15.7908 10 15.625C10 15.4592 9.93415 15.3003 9.81694 15.1831C9.69973 15.0658 9.54076 15 9.375 15H7.5V13.125Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default AddFile
