import { useTranslation } from 'react-i18next'
import { HighlightOff } from '@mui/icons-material'
import { Tooltip } from '../../components'
import { StyledIconButton } from './SupportWidgetButton.styles'
import { useSupportWidgetButton } from './SupportWidgetButton.utils'

const SupportWidgetButton = () => {
  const { t } = useTranslation()
  const {
    isSupportWidgetVisible,
    isSupportPopUpOpen,
    isAuthenticated,
    onClick,
  } = useSupportWidgetButton()

  if (!isSupportWidgetVisible || isSupportPopUpOpen) return null

  return (
    <StyledIconButton size="small" onClick={() => onClick()}>
      <Tooltip
        title={t('supportWidget.closeInfo')}
        placement="top-start"
        disabled={!isAuthenticated}
      >
        <HighlightOff />
      </Tooltip>
    </StyledIconButton>
  )
}

export default SupportWidgetButton
