import { useCallback, useMemo } from 'react'
import { isAfter, isBefore, parse } from 'date-fns'
import { useDateFns } from '../hooks'
import { generateTimes } from '../utils'
import SelectField, { SelectFieldProps } from './SelectField'

export interface TimeFieldProps extends Partial<SelectFieldProps> {
  name: string
  format?: string
  step?: number
  offset?: number
  minTime?: string
  maxTime?: string
}

const useParseTime = (format: string) => {
  const dateOptions = useDateFns()
  return useCallback(
    (time?: string) =>
      time ? parse(time, format, new Date(), dateOptions) : null,
    [format, dateOptions]
  )
}

const TimeField = ({
  minTime,
  maxTime,
  format = 'HH.mm',
  step = 15,
  offset = 0,
  ...props
}: TimeFieldProps) => {
  const parseTime = useParseTime(format)
  const options = useMemo(
    () =>
      generateTimes(step, offset, format).map(value => {
        const date = parseTime(value)
        const minDate = parseTime(minTime)
        const maxDate = parseTime(maxTime)
        const isDisabled = Boolean(
          !date ||
            (minDate && isBefore(date, minDate)) ||
            (maxDate && isAfter(date, maxDate))
        )
        return {
          value,
          label: value,
          disabled: isDisabled,
        }
      }),
    [step, offset, format, maxTime, minTime, parseTime]
  )
  return <SelectField options={options} {...props} />
}

export default TimeField
