import Api from '../api'
import {
  GetMeResponse,
  GetAccountsParams,
  GetAccountsResponse,
  CreateUserParams,
  CreateUserResponse,
  GetUserParams,
  GetUserResponse,
  UpdateUserParams,
  UpdateUserResponse,
  ResetLoginInfoParams,
  ResetLoginInfoResponse,
  DeleteUserResponse,
  DeleteUserParams,
  ChangeUserStatusParams,
  ChangeUserStatusResponse,
  GetChatAccountsResponse,
  GetChatAccountsParams,
  SetCurrentUserLanguageParams,
  SetCurrentUserLanguageResponse,
} from './Accounts.types'

class Accounts extends Api {
  getMe = async () => {
    const { data } = await this.api.get<GetMeResponse>('/accounts/me')
    return data
  }

  setCurrentUserLanguage = async ({
    ...payload
  }: SetCurrentUserLanguageParams) => {
    const { data } = await this.api.post<SetCurrentUserLanguageResponse>(
      `/accounts/me/set-language`,
      payload
    )
    return data
  }

  public getAccounts = async ({
    careProviderId,
    ...params
  }: GetAccountsParams) => {
    const { data } = await this.api.get<GetAccountsResponse>(
      `/care-providers/${careProviderId}/accounts`,
      { params }
    )
    return data
  }

  public getChatAccounts = async (params: GetChatAccountsParams) => {
    const { data } = await this.api.get<GetChatAccountsResponse>(
      `/accounts/chat`,
      { params }
    )
    return data.data
  }

  public createUser = async ({
    careProviderId,
    ...payload
  }: CreateUserParams) => {
    const headers = await this.getCsrfHeaders()
    const { data } = await this.api.post<CreateUserResponse>(
      `/care-providers/${careProviderId}/accounts`,
      payload,
      { headers }
    )
    return data
  }

  public updateUser = async ({
    careProviderId,
    userId,
    ...payload
  }: UpdateUserParams) => {
    const { data } = await this.api.put<UpdateUserResponse>(
      `/care-providers/${careProviderId}/accounts/${userId}`,
      payload
    )
    return data
  }

  public deleteUser = async ({ careProviderId, userId }: DeleteUserParams) => {
    const { data } = await this.api.delete<DeleteUserResponse>(
      `/care-providers/${careProviderId}/accounts/${userId}`
    )
    return data
  }

  public changeUserStatus = async ({
    careProviderId,
    userId,
    action,
  }: ChangeUserStatusParams) => {
    const { data } = await this.api.post<ChangeUserStatusResponse>(
      `/care-providers/${careProviderId}/accounts/${userId}/${action}`
    )
    return data
  }

  public getUser = async ({ careProviderId, userId }: GetUserParams) => {
    const { data } = await this.api.get<GetUserResponse>(
      `/care-providers/${careProviderId}/accounts/${userId}`
    )
    return data
  }

  public resetLoginInfo = async ({
    careProviderId,
    accountId,
    ...params
  }: ResetLoginInfoParams) => {
    const { data } = await this.api.post<ResetLoginInfoResponse>(
      `/care-providers/${careProviderId}/accounts/${accountId}/reset-password`,
      null,
      { params }
    )
    return data
  }
}

export default Accounts
