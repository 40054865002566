import Api from '../api'
import {
  CreateResourceParams,
  CreateResourceResponse,
  GetResourcesTableInfoParams,
  GetResourcesTableInfoResponse,
  GetResourceDetailsParams,
  GetResourceDetailsResponse,
  GetResourcesParams,
  GetResourcesResponse,
  GetResourceSchedulesParams,
  GetResourceSchedulesResponse,
  CreateResourceAvailabilityParams,
  CreateResourceAvailabilityResponse,
  CreateResourceAvailabilitiesParams,
  CreateResourceAvailabilitiesResponse,
  CreateResourceAbsenceParams,
  CreateResourceAbsenceResponse,
  CreateResourceAbsencesParams,
  CreateResourceAbsencesResponse,
  UpdateResourceAbsenceParams,
  UpdateResourceAbsenceResponse,
  UpdateResourceAvailabilityParams,
  UpdateResourceAvailabilityResponse,
  DeleteResourceAvailabilityParams,
  DeleteResourceAvailabilityResponse,
  DeleteResourceAbsenceParams,
  DeleteResourceAbsenceResponse,
  GetResourceStatisticsParams,
  GetResourceStatisticsResponse,
  UpdateResourceParams,
  UpdateResourceResponse,
  ResourceRequestActionParams,
  ResourceRequestActionResponse,
  DeleteResourceAvailabilitiesParams,
  GetResourceRequestsParams,
  GetResourceRequestsResponse,
  DeleteResourceParams,
  DeleteResourceResponse,
  ChangeResourceStatusParams,
  ChangeResourceStatusResponse,
  GetResourceBookingsParams,
  GetResourceBookingsResponse,
  GetStaffingTableInfoParams,
  GetStaffingTableInfoResponse,
  GetAvailabilityTableInfoParams,
  GetAvailabilityTableInfoResponse,
  ExportResourceStaffingStatisticsParams,
  ExportResourceStaffingStatisticsResponse,
  ExportResourceAvailabilityStatisticsParams,
  ExportResourceAvailabilityStatisticsResponse,
  GetMatchingAssignmentsParams,
  GetMatchingAssignmentsResponse,
} from './Resources.types'

class Resources extends Api {
  public getResourceDetails = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetResourceDetailsParams) => {
    const { data } = await this.api.get<GetResourceDetailsResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}`,
      { params }
    )
    return data
  }

  public getResourcesTableInfo = async ({
    careProviderId,
    ...params
  }: GetResourcesTableInfoParams) => {
    const response = await this.api.get<GetResourcesTableInfoResponse>(
      `/care-providers/${careProviderId}/resources/details`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getStaffingTableInfo = async ({
    careProviderId,
    ...params
  }: GetStaffingTableInfoParams) => {
    const response = await this.api.get<GetStaffingTableInfoResponse>(
      `/care-providers/${careProviderId}/resources/need-assignments/statistics`,
      { params }
    )
    const { data, ...meta } = response.data

    return { data, meta }
  }

  public exportResourceStaffingStatistics = async ({
    careProviderId,
    specialtyIds,
    unitIds,
    roleId,
    ...params
  }: ExportResourceStaffingStatisticsParams) => {
    const { data } =
      await this.api.post<ExportResourceStaffingStatisticsResponse>(
        `/care-providers/${careProviderId}/resources/need-assignments/statistics/export`,
        { specialtyIds, unitIds, roleId },
        { params, responseType: 'arraybuffer' }
      )
    return data
  }

  public getAvailabilityTableInfo = async ({
    careProviderId,
    ...params
  }: GetAvailabilityTableInfoParams) => {
    const response = await this.api.get<GetAvailabilityTableInfoResponse>(
      `/care-providers/${careProviderId}/resources/accessibility/statistics`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public exportResourceAvailabilityStatistics = async ({
    careProviderId,
    resourceIds,
    specialtyIds,
    roleId,
    ...params
  }: ExportResourceAvailabilityStatisticsParams) => {
    const { data } =
      await this.api.post<ExportResourceAvailabilityStatisticsResponse>(
        `/care-providers/${careProviderId}/resources/accessibility/statistics/export`,
        { resourceIds, specialtyIds, roleId },
        { params, responseType: 'arraybuffer' }
      )
    return data
  }

  public getResources = async ({
    careProviderId,
    ...params
  }: GetResourcesParams) => {
    const { data } = await this.api.get<GetResourcesResponse>(
      `/care-providers/${careProviderId}/resources`,
      { params }
    )
    return data.data
  }

  public updateResource = async ({
    careProviderId,
    resourceId,
    ...payload
  }: UpdateResourceParams) => {
    const { data } = await this.api.put<UpdateResourceResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}`,
      payload
    )
    return data
  }

  public createResource = async ({
    careProviderId,
    ...payload
  }: CreateResourceParams) => {
    const headers = await this.getCsrfHeaders()
    const { data } = await this.api.post<CreateResourceResponse>(
      `/care-providers/${careProviderId}/resources`,
      payload,
      { headers }
    )
    return data
  }

  public deleteResource = async ({
    careProviderId,
    resourceId,
    ...params
  }: DeleteResourceParams) => {
    const { data } = await this.api.delete<DeleteResourceResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}`,
      params
    )
    return data
  }

  public getResourceSchedules = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetResourceSchedulesParams) => {
    const { data } = await this.api.get<GetResourceSchedulesResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/schedules-v2`,
      { params }
    )
    return data.data
  }

  public createResourceAvailability = async ({
    careProviderId,
    resourceId,
    ...payload
  }: CreateResourceAvailabilityParams) => {
    const { data } = await this.api.post<CreateResourceAvailabilityResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/availabilities`,
      { data: [payload] }
    )
    return data
  }

  public createResourceAvailabilities = async ({
    careProviderId,
    resourceId,
    periods,
  }: CreateResourceAvailabilitiesParams) => {
    const { data } = await this.api.post<CreateResourceAvailabilitiesResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/availabilities`,
      { data: periods }
    )
    return data
  }

  public updateResourceAvailability = async ({
    careProviderId,
    resourceId,
    id,
    ...payload
  }: UpdateResourceAvailabilityParams) => {
    const { data } = await this.api.put<UpdateResourceAvailabilityResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/availabilities/${id}`,
      payload
    )
    return data
  }

  public deleteResourceAvailability = async ({
    careProviderId,
    resourceId,
    id,
  }: DeleteResourceAvailabilityParams) => {
    const { data } = await this.api.delete<DeleteResourceAvailabilityResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/availabilities`,
      { data: { ids: [id] } }
    )
    return data
  }

  public deleteResourceAvailabilities = async ({
    careProviderId,
    resourceId,
    ids,
  }: DeleteResourceAvailabilitiesParams) => {
    const { data } = await this.api.delete<DeleteResourceAvailabilityResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/availabilities`,
      { data: { ids } }
    )
    return data
  }

  public createResourceAbsence = async ({
    careProviderId,
    resourceId,
    ...payload
  }: CreateResourceAbsenceParams) => {
    const { data } = await this.api.post<CreateResourceAbsenceResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/absences`,
      { data: [payload] }
    )
    return data
  }

  public createResourceAbsences = async ({
    careProviderId,
    resourceId,
    periods,
  }: CreateResourceAbsencesParams) => {
    const { data } = await this.api.post<CreateResourceAbsencesResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/absences`,
      { data: periods }
    )
    return data
  }

  public updateResourceAbsence = async ({
    careProviderId,
    resourceId,
    id,
    ...payload
  }: UpdateResourceAbsenceParams) => {
    const { data } = await this.api.put<UpdateResourceAbsenceResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/absences/${id}`,
      payload
    )
    return data
  }

  public deleteResourceAbsence = async ({
    careProviderId,
    resourceId,
    id,
  }: DeleteResourceAbsenceParams) => {
    const { data } = await this.api.delete<DeleteResourceAbsenceResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/absences`,
      { data: { ids: [id] } }
    )
    return data
  }

  public getResourceStatistics = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetResourceStatisticsParams) => {
    const { data } = await this.api.get<GetResourceStatisticsResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/statistics`,
      { params }
    )
    return data
  }

  public requestAction = async ({
    careProviderId,
    resourceId,
    requestId,
    action,
  }: ResourceRequestActionParams) => {
    const { data } = await this.api.post<ResourceRequestActionResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/requests/${requestId}/${action}`
    )
    return data
  }

  public getRequests = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetResourceRequestsParams) => {
    const response = await this.api.get<GetResourceRequestsResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/requests`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getBookings = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetResourceBookingsParams) => {
    const response = await this.api.get<GetResourceBookingsResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/need-assignments`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public changeResourceStatus = async ({
    careProviderId,
    resourceId,
    action,
  }: ChangeResourceStatusParams) => {
    const { data } = await this.api.post<ChangeResourceStatusResponse>(
      `/care-providers/${careProviderId}/resources/${resourceId}/${action}`
    )
    return data
  }

  public getMatchingAssignments = async ({
    careProviderId,
    resourceId,
    ...params
  }: GetMatchingAssignmentsParams) => {
    const { data } = await this.api.get<GetMatchingAssignmentsResponse>(
      `care-providers/${careProviderId}/matching/resources/${resourceId}/assignments/details`,
      { params }
    )
    return data
  }
}

export default Resources
