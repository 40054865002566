import { State } from './reducer'

export const activateResourceState = (state: State) => {
  if (state.getResourceDetails.data) {
    state.getResourceDetails.data.account.isActive = true
    state.getResourceDetails.data.account.isBlocked = false
  }
}

export const deactivateResourceState = (state: State) => {
  if (state.getResourceDetails.data) {
    state.getResourceDetails.data.account.isActive = false
  }
}
