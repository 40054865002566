import { Message } from '@mui/icons-material'
import { styled, Typography as BaseTypography, Chip } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  '& >*': {
    padding: theme.spacing(1),
  },
}))

export const Typography = styled(BaseTypography)({
  fontSize: '0.8rem',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.pill.yellow.main,
  height: theme.spacing(0.6),
  width: theme.spacing(1.8),
  '&.MuiChip-outlined': {
    border: `2px solid  ${theme.palette.pill.yellow.main}`,
    backgroundColor: 'transparent',
  },
}))

export const MessageIcon = styled(Message)(({ theme }) => ({
  fontSize: '0.875rem',
  marginLeft: theme.spacing(0.75),
}))
