import { io, ManagerOptions, SocketOptions } from 'socket.io-client'
import config from 'config'

export const getWebsocketUrl = (path: string) => `${config.apiUrl}/ws${path}`

type CreateSocketOptions = Partial<ManagerOptions & SocketOptions>

export const createSocket = (path: string, options?: CreateSocketOptions) =>
  io(getWebsocketUrl(path), {
    autoConnect: false,
    withCredentials: true,
    secure: true,
    transports: ['websocket'],
    ...{ options },
  })
