import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { differenceInDays, format, isBefore, isValid, parseISO } from 'date-fns'
import { Column } from 'shared/components/DataTable'
import { useDispatch, useOrganizationId } from 'shared/hooks'
import { useResource } from 'shared/resource'
import {
  Absence,
  AutocompleteFieldOption,
  Availability,
  LoadingStatus,
} from 'shared/types'
import { actions, selectors } from '../../../store'
import {
  AbsenceText,
  AvailabilityText,
  StyledDataTable,
} from './ResourceAvailabilityTable.styles'

const HOUR_FORMAT = 'HH:mm'

interface ResourceAvailabilityTableProps {
  resource: AutocompleteFieldOption
  dateFrom: Date
  dateTo: Date
}

const ResourceAvailabilityTable = ({
  resource,
  dateFrom,
  dateTo,
}: ResourceAvailabilityTableProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const columns: Column[] = [
    {
      field: 'date',
      label: t('availabilityModal.resourceAvailabilityTable.date'),
      sortable: false,
      renderValue: value => (
        <Typography>{format(parseISO(value), 'd/M')}</Typography>
      ),
    },
    {
      field: 'resources',
      label: t('availabilityModal.resourceAvailabilityTable.availabilities'),
      sortable: false,
      renderValue: value => (
        <AvailabilityText>
          {value[0].availabilities
            .map(
              ({ startDate, endDate }: Availability) =>
                `${format(parseISO(startDate), HOUR_FORMAT)} - ${format(
                  parseISO(endDate),
                  HOUR_FORMAT
                )}`
            )
            .join('\r\n')}
        </AvailabilityText>
      ),
    },
    {
      field: 'resources',
      label: t('availabilityModal.resourceAvailabilityTable.absences'),
      sortable: false,
      renderValue: value => (
        <AbsenceText>
          {value[0].absences
            .map(
              ({ startDate, endDate }: Absence) =>
                `${format(parseISO(startDate), HOUR_FORMAT)} - ${format(
                  parseISO(endDate),
                  HOUR_FORMAT
                )}`
            )
            .join('\r\n')}
        </AbsenceText>
      ),
    },
  ]

  useEffect(() => {
    if (isBefore(dateTo, dateFrom) || !isValid(dateTo) || !isValid(dateFrom))
      return
    dispatch(
      actions.accessibilities.getSingleResourceAccessibilityTableInfo({
        careProviderId,
        dateFrom,
        dateTo,
        resourceIds: [resource.value],
        take: differenceInDays(dateTo, dateFrom) + 1,
      })
    )
  }, [careProviderId, dateFrom, dateTo, dispatch, resource.value])

  const tableResource = useResource(
    actions.accessibilities.getSingleResourceAccessibilityTableInfo,
    selectors.accessibilities.getSingleResourceAccessibilityTableInfo
  )

  const { loading } = tableResource

  return (
    <StyledDataTable
      columns={columns}
      //@ts-expect-error
      resource={tableResource}
      dense
      hidePagination
      isLoading={loading === LoadingStatus.Pending}
    />
  )
}

export default ResourceAvailabilityTable
