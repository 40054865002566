import {
  styled,
  alpha,
  Grid,
  Select as BaseSelect,
  Typography,
} from '@mui/material'

const SHIFT_COLUMN_WIDTH = 80
const SHIFT_NAME_WIDTH = SHIFT_COLUMN_WIDTH - 10

export const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  width: SHIFT_COLUMN_WIDTH,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}))

export const RowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.neutral.dark}`,
  '&:first-child': {
    borderBottom: 'none',
  },
}))

export const ControlWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  background: alpha(theme.palette.grey[600], 0.9),
}))

export const GridWithBackground = styled(Grid)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: `0px 2px 10px ${alpha(theme.palette.common.black, 0.12)}`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2, 3),
  margin: theme.spacing(1),
}))

export const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.grey[200],
}))

export const Select = styled(BaseSelect)(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(1),
  height: theme.spacing(5),
}))

export const ShiftName = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  width: SHIFT_NAME_WIDTH,
  textAlign: 'center',
}))
