import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import { useSelector } from '../../../../hooks'
import { selectors } from '../../../../store'
import { LoadingStatus } from '../../../../types'
import Loader from '../../../Loader'
import BookingSection from '../BookingSection'
import RequestSection from '../RequestSection'
import { MessageText } from '../RequestAssignmentContent.style'
import { SendRequestGrid } from './CPResourcesSection.styles'
import {
  mapRequestablesToOptions,
  mapResourcesToOptions,
  ResourcesSectionValues,
  UseMatchingResourcesReturn,
  useResourceSelectionState,
} from './CPResourcesSection.utils'

interface CPResourcesSectionProps {
  disabledBooking?: boolean
  matchingResources: UseMatchingResourcesReturn
}

const CPResourcesSection = ({
  disabledBooking,
  matchingResources,
}: CPResourcesSectionProps) => {
  const { t } = useTranslation()
  const { loading, isInitialized } = useSelector(
    selectors.matchingResources.getResourcesSideEffects
  )
  const { control } = useFormContext<ResourcesSectionValues>()
  const { isResourceSelected, booked } = useResourceSelectionState(control)
  const { bookable, requestable, pendingResources } = matchingResources

  const bookableResources = useMemo(
    () => mapResourcesToOptions(bookable),
    [bookable]
  )
  const requestableResources = useMemo(
    () => mapRequestablesToOptions(requestable),
    [requestable]
  )

  if (loading === LoadingStatus.Failed) {
    return (
      <Typography color="error">
        {t('assignmentModal.failedToLoadResources')}
      </Typography>
    )
  }

  if (loading === LoadingStatus.Pending || !isInitialized) {
    return <Loader />
  }

  return (
    <>
      <Grid item>
        <BookingSection
          disabledBooking={disabledBooking}
          bookableResources={bookableResources}
        />
      </Grid>
      <Grid item>
        <RequestSection
          disabledBooking={disabledBooking}
          requestableResources={requestableResources}
          pendingResources={pendingResources}
        />
      </Grid>
      <SendRequestGrid item container direction="column" alignItems="flex-end">
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={disabledBooking || !isResourceSelected}
          >
            {t(`assignmentModal.${booked ? 'book' : 'send'}`)}
          </Button>
        </Grid>
        <Grid item>
          <MessageText variant="body2">
            {t('assignmentModal.selectResourcesMessage')}
          </MessageText>
        </Grid>
      </SendRequestGrid>
    </>
  )
}

export default CPResourcesSection
