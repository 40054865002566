import { useEffect, useCallback } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import { paths } from 'config'
import { useOrganizationId } from '../hooks'
import { actions, selectors } from '../store'
import { RoleGroup, User, UserRole } from '../types'
import { generatePathWithQuery } from '../utils'
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from './redux'

const getUserOrganizationId = (user: User | null) =>
  user?.careProviders[0]?.id || null

const getInitialPath = (user: User | null) => {
  switch (user?.role) {
    case UserRole.SuperCareProvider: {
      return paths.organizations
    }
    case UserRole.CareProvider: {
      const organizationId = getUserOrganizationId(user)
      return organizationId
        ? generatePath(paths.organizationUnits, {
            organizationId: organizationId.toString(),
          })
        : paths.forbidden
    }
    case UserRole.Resource:
    case UserRole.UnitAdmin: {
      const organizationId = getUserOrganizationId(user)
      return organizationId
        ? generatePath(paths.organizationSchedules, {
            organizationId: organizationId.toString(),
          })
        : paths.forbidden
    }
    default:
      return paths.signIn
  }
}

export const useSignIn = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector(selectors.auth.getSignIn)

  const signIn = useCallback(
    (email: string, password: string) => {
      const onNewPasswordRequired = (token?: string) =>
        navigate(
          generatePathWithQuery({
            path: paths.activateAccount,
            params: {},
            qs: { token },
          })
        )
      const onMFARequired = (token?: string) =>
        navigate(
          generatePathWithQuery({
            path: paths.multifactorAuthentication,
            params: {},
            qs: { token },
          })
        )
      return dispatch(
        actions.auth.signIn({
          params: {
            email,
            password,
            onNewPasswordRequired,
            onMFARequired,
          },
        })
      )
    },
    [dispatch, navigate]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetSignIn()),
    [dispatch]
  )

  return { ...data, signIn, resetState }
}

export const useSignOut = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getSignOut)

  const signOut = useCallback(
    () => dispatch(actions.auth.signOut({ params: {} })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetSignOut()),
    [dispatch]
  )

  return { ...data, signOut, resetState }
}

export const useResetPassword = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getResetPassword)

  const resetPassword = useCallback(
    (email: string) =>
      dispatch(actions.auth.resetPassword({ params: { email } })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetResetPassword()),
    [dispatch]
  )

  return { ...data, resetPassword, resetState }
}

export const useSetNewPassword = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getSetNewPassword)

  const setNewPassword = useCallback(
    (password: string, token: string) =>
      dispatch(actions.auth.setNewPassword({ params: { token, password } })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetSetNewPassword()),
    [dispatch]
  )

  return { ...data, setNewPassword, resetState }
}

export const useConfirmForgotPassword = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getConfirmForgotPassword)

  const confirmForgotPassword = useCallback(
    (password: string, token: string) =>
      dispatch(
        actions.auth.confirmForgotPassword({ params: { token, password } })
      ),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetConfirmForgotPassword()),
    [dispatch]
  )

  return { ...data, confirmForgotPassword, resetState }
}

export const useResolveChallenge = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectors.auth.getResolveChallenge)

  const resolveChallenge = useCallback(
    (code: string, token: string) =>
      dispatch(actions.auth.resolveChallenge({ params: { token, code } })),
    [dispatch]
  )

  const resetState = useCallback(
    () => dispatch(actions.auth.resetResolveChallenge()),
    [dispatch]
  )

  return { ...data, resolveChallenge, resetState }
}

const getUserRoleGroup = (userRole?: UserRole): RoleGroup | null => {
  switch (userRole) {
    case UserRole.SuperCareProvider:
    case UserRole.SuperStaffProvider:
      return RoleGroup.SystemAdmin
    case UserRole.CareProvider:
    case UserRole.StaffProvider:
      return RoleGroup.OrganizationAdmin
    case UserRole.UnitAdmin:
      return RoleGroup.UnitAdmin
    case UserRole.Resource:
      return RoleGroup.Resource
    default:
      return null
  }
}

export const useAuth = () => {
  const user = useSelector(selectors.auth.getUser)
  const loading = useSelector(selectors.auth.getAuthLoading)
  const error = useSelector(selectors.auth.getAuthError)

  const isAuthenticated = !!user
  const hasOrganization = (user?.careProviders || []).length > 0

  const hasAllowedRole = (roles?: UserRole[]) =>
    isAuthenticated && (!roles || (user && roles.includes(user.role)))

  const initialPath = getInitialPath(user)

  const userRoleGroup = getUserRoleGroup(user?.role)

  return {
    user,
    hasOrganization,
    isAuthenticated,
    initialPath,
    loading,
    error,
    userRoleGroup,
    hasAllowedRole,
  }
}

export const useCurrentUser = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, hasAllowedRole } = useAuth()
  const { signOut } = useSignOut()
  const isStaffProvider = hasAllowedRole([
    UserRole.StaffProvider,
    UserRole.SuperStaffProvider,
  ])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.auth.getCurrentUser({ params: {} }))
    }
  }, [isAuthenticated, dispatch])

  useEffect(() => {
    // log SP out in case they log in or their session transfers to VIVIUM I from VIVIUM X (see VIVIUMI-1572)
    if (isStaffProvider) {
      signOut()
    }
  }, [isStaffProvider, signOut])
}

export const useSettings = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, hasAllowedRole } = useAuth()

  const isAllowedUser = hasAllowedRole([
    UserRole.SuperCareProvider,
    UserRole.CareProvider,
    UserRole.UnitAdmin,
  ])

  const careProviderId = useOrganizationId()

  useEffect(() => {
    if (!careProviderId || !isAllowedUser) return
    dispatch(actions.settings.getNeedSettings({ careProviderId }))
  }, [careProviderId, isAuthenticated, dispatch, isAllowedUser])
}
