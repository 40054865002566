import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  debounce,
  Grid,
  AutocompleteRenderOptionState,
} from '@mui/material'
import { useDialog } from '../../hooks'
import { AutocompleteFieldOption, LoadingStatus } from '../../types'
import { AddIcon, StyledIconButton } from './AutocompleteField.style'
import { AutocompleteFieldProps } from './AutocompleteField.types'

const DEBOUNCE_TIMEOUT = 500

export const useAutocomplete = ({
  fetchOptions,
  fetchOnInputChange = false,
  options: defaultOptions = [],
}: Partial<AutocompleteFieldProps>) => {
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(LoadingStatus.Idle)
  const [isOpen, , handleOpen, handleClose] = useDialog()
  const [options, setOptions] = useState<AutocompleteFieldOption[]>([
    ...defaultOptions,
  ])

  const handleInputChange = useCallback((event: ChangeEvent<{}>) => {
    const { value } = (event as ChangeEvent<HTMLInputElement>)?.target || {}
    if (typeof value !== 'string') return
    setInputValue(value)
  }, [])

  const getOptions = useMemo(
    () =>
      debounce(async (value?: string) => {
        if (!fetchOptions) return
        try {
          setLoading(LoadingStatus.Pending)
          const data = await fetchOptions(value)
          setOptions(data)
          setLoading(LoadingStatus.Succeeded)
        } catch {
          setLoading(LoadingStatus.Failed)
        }
      }, DEBOUNCE_TIMEOUT),
    [fetchOptions]
  )

  useEffect(() => {
    getOptions()
  }, [getOptions])

  useEffect(() => {
    if (isOpen && fetchOnInputChange) {
      getOptions(inputValue)
    }
  }, [isOpen, inputValue, fetchOnInputChange, getOptions])

  return {
    options,
    loading,
    isOpen,
    handleOpen,
    handleClose,
    handleInputChange,
  }
}

export const getOptionSelected = (
  option: AutocompleteFieldOption,
  value: AutocompleteFieldOption
) => String(option.value) === String(value.value)

export const defaultGetOptionLabel = (
  option: string | AutocompleteFieldOption
) => (typeof option === 'string' ? option : option.label || '')

type UseGetMultiplePropsOptions = Pick<
  AutocompleteFieldProps,
  'withAddIcons' | 'withChips' | 'getOptionLabel'
>

export const useGetMultipleProps = ({
  withChips,
  withAddIcons,
  getOptionLabel = defaultGetOptionLabel,
}: UseGetMultiplePropsOptions) =>
  useCallback(
    () => ({
      disableCloseOnSelect: true,
      renderTags: withChips
        ? () => null
        : (value: AutocompleteFieldOption[]) =>
            value.map(v => v.label).join(', '),
      renderOption: (
        props: any,
        option: AutocompleteFieldOption,
        { selected }: AutocompleteRenderOptionState
      ) =>
        withAddIcons ? (
          <li {...props}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>{getOptionLabel(option)}</Grid>
              <Grid item>
                <StyledIconButton size="small">
                  <AddIcon />
                </StyledIconButton>
              </Grid>
            </Grid>
          </li>
        ) : (
          <>
            <li {...props}>
              <Checkbox checked={selected} color="primary" />
              {getOptionLabel(option)}
            </li>
          </>
        ),
    }),
    [getOptionLabel, withAddIcons, withChips]
  )
