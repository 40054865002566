import { useDispatch, useSelector } from '../../../../hooks'
import { actions, selectors } from '../../../../store'
import { AdminMessageFeedbackRateType } from '../../../../types'

export const useFeedbackSection = () => {
  const dispatch = useDispatch()
  const { data } = useSelector(selectors.notifications.getAdminUpdateMessage)

  if (!data) return {}

  const onSuccess = () =>
    dispatch(
      actions.notifications.getAdminUpdateMessage({ messageId: data.id })
    )
  const sendAdminMessageFeedback = (rate: AdminMessageFeedbackRateType) => {
    dispatch(
      actions.notifications.sendAdminMessageFeedback({
        params: { messageId: data.id, rate },
        onSuccess,
      })
    )
  }
  const shouldShowForm = !data.isFeedbackGivenByCurrentAccount

  return {
    shouldShowForm,
    sendAdminMessageFeedback,
  }
}
