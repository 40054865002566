import { Badge, styled, ListItemButton } from '@mui/material'

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  height: theme.spacing(4.5),
  marginBottom: theme.spacing(0.75),
  borderRadius: theme.spacing(0.25),
}))

export const PillWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(16),
  margin: theme.spacing(0, 2.5, 0, 1),
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  left: 12,

  '& .MuiBadge-dot': {
    height: '0.563rem',
    width: '0.563rem',
    border: `0.5px solid ${theme.palette.primary.contrastText}`,
  },
}))
