import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Close, ExpandMore } from '@mui/icons-material'
import { Grid, Typography, Skeleton } from '@mui/material'
import { useAuth } from '../../hooks'
import { BaseNotification, NotificationType, UserRole } from '../../types'
import {
  ConfirmationModal,
  IDChip,
  SkeletonContainer,
  StyledBadge,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DeselectNeedsButton,
} from './IDsSection.style'
import { useIDsSection, useRemoveDialog } from './IDsSection.utils'

export interface IDsSectionProps {
  needsCount?: number
  unitId?: number
  readOnly?: boolean
  showNotifications?: boolean
}

const IDsSection = ({
  unitId,
  readOnly = false,
  showNotifications = false,
}: IDsSectionProps) => {
  const { t } = useTranslation()
  const { hasAllowedRole } = useAuth()
  const { openModal, closeModal, selectedNeedId, deleting, ...modalProps } =
    useRemoveDialog()
  const {
    areNeedsInitialized,
    areNeedsLoading,
    isEmpty,
    selectedIds,
    unitNeeds,
    handleNeedClick,
    deselectAllNeeds,
  } = useIDsSection(unitId)

  const getNewNeedNotifications = useCallback(
    (notifications: BaseNotification[]) =>
      showNotifications
        ? notifications.filter(
            ({ type }) => type === NotificationType.NeedCreated
          )
        : [],
    [showNotifications]
  )

  const handleDeselectNeedsClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      deselectAllNeeds()
      event.stopPropagation()
    },
    [deselectAllNeeds]
  )

  return (
    <>
      <ConfirmationModal
        {...modalProps}
        onCancel={closeModal}
        mainContent={t('idSection.removeMessage')}
        immediateClose={false}
        loading={deleting}
        confirmationButtonLabel={t('idSection.removeButtonLabel')}
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={areNeedsLoading}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                <strong>
                  {t('idSection.needIds', {
                    count: unitNeeds?.length || 0,
                  })}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              {selectedIds.length > 0 && (
                <DeselectNeedsButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={t('idSection.selected', { count: selectedIds.length })}
                  deleteIcon={<Close />}
                  onDelete={handleDeselectNeedsClick}
                  onClick={handleDeselectNeedsClick}
                />
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {areNeedsLoading && !areNeedsInitialized ? (
              <SkeletonContainer>
                <Skeleton width={70} />
                <Skeleton width={50} />
              </SkeletonContainer>
            ) : isEmpty ? (
              <Typography variant="caption" color="textSecondary">
                {t('idSection.noNeedIds')}
              </Typography>
            ) : (
              unitNeeds.map(
                ({
                  id,
                  name,
                  canBeDeleted,
                  isFullyProcessed,
                  notifications,
                }) => {
                  const isSelected = selectedIds.includes(id)
                  const isHidden = readOnly && !isSelected
                  const isDeletable =
                    hasAllowedRole([
                      UserRole.CareProvider,
                      UserRole.SuperCareProvider,
                    ]) && canBeDeleted
                  const newNeedNotifications =
                    getNewNeedNotifications(notifications)
                  const hasNotification = newNeedNotifications.length > 0

                  return isHidden ? null : (
                    <StyledBadge
                      key={`chip-${id}`}
                      variant="dot"
                      color="error"
                      invisible={!hasNotification || !showNotifications}
                    >
                      <IDChip
                        variant={isSelected ? 'filled' : 'outlined'}
                        label={name}
                        onClick={() =>
                          !readOnly && handleNeedClick(id, newNeedNotifications)
                        }
                        isSelected={isSelected}
                        deleteIcon={<Close />}
                        onDelete={() => openModal(id)}
                        isDeletable={isDeletable}
                        isFullyProcessed={isFullyProcessed}
                        isEmpty={canBeDeleted}
                        hasNotification={hasNotification}
                      />
                    </StyledBadge>
                  )
                }
              )
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default IDsSection
