import { default as parseHTML } from 'html-react-parser'
import { Close } from '@mui/icons-material'
import { DialogContentText } from '@mui/material'
import FeedbackSection from './FeedbackSection'
import { CloseButton } from '../../AdminMessage.style'
import { Header, StyledDialogContent, Title } from '../UpdateMessageModal.style'
import { useAdminUpdateMessageModal } from '../UpdateMessageModal.utils'

const Content = () => {
  const { title, body, onClose } = useAdminUpdateMessageModal()

  return (
    <>
      <Header
        container
        justifyContent="space-between"
        alignItems="center"
        padding={1}
        flexWrap="nowrap"
      >
        <Title variant="body2">{title}</Title>
        <CloseButton size="small" onClick={onClose}>
          <Close />
        </CloseButton>
      </Header>
      <StyledDialogContent>
        <DialogContentText>{parseHTML(body || '')}</DialogContentText>
      </StyledDialogContent>
      <FeedbackSection />
    </>
  )
}

export default Content
