import { IconButton, IconButtonProps, CircularProgress } from '@mui/material'

const SPINNER_SIZE = '1rem'

export interface LoadingIconButtonProps extends IconButtonProps {
  loading?: boolean
}

const LoadingIconButton = ({
  children,
  disabled,
  loading = false,
  ...props
}: LoadingIconButtonProps) => (
  <IconButton {...props} disabled={loading || disabled} size="large">
    {loading ? (
      <CircularProgress color="inherit" size={SPINNER_SIZE} />
    ) : (
      children
    )}
  </IconButton>
)

export default LoadingIconButton
