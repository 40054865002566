import { useCallback, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import {
  useDispatch,
  useSelector,
  useOrganizationId,
  useNeedIds,
  useQuery,
  useAssignmentModal,
} from '../../../hooks'
import { actions, selectors } from '../../../store'
import {
  AssignmentStatus,
  LoadingStatus,
  NeedAssignmentStatus,
  SchedulerDates,
} from '../../../types'

interface UseShiftsOptions {
  resourceId?: number
  status?: AssignmentStatus
  preliminary?: boolean
}

export const useShifts = ({
  resourceId,
  status,
  preliminary = false,
}: UseShiftsOptions) => {
  const { needAssignment } = useAssignmentModal()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const { enqueueSnackbar } = useSnackbar()
  const needIds = useNeedIds()
  const { dateFrom, dateTo, includeAssignmentsExportedToViviumX } = useQuery<
    SchedulerDates & { includeAssignmentsExportedToViviumX: boolean }
  >()
  const { loading, error } = useSelector(
    selectors.schedules.getPickerAssignmentsResource
  )
  const data = useSelector(selectors.schedules.getPickerAssignmentsData)

  const getPickerAssignments = useCallback(() => {
    if (!needAssignment) return
    if (!status || !needAssignment.need?.unit.id) {
      return enqueueSnackbar(t('error.missingRequestData'), {
        variant: 'error',
      })
    }
    const assignmentStatuses = getNeedAssignmentsStatuses(status)
    dispatch(
      actions.schedules.getPickerAssignments({
        careProviderId,
        needIds,
        dateFrom,
        dateTo,
        includeAssignmentsExportedToViviumX,
        assignmentStatuses: assignmentStatuses,
        unitId: needAssignment.need.unit.id,
        ...(resourceId && { resourceId }),
      })
    )
  }, [
    needAssignment,
    careProviderId,
    dateFrom,
    dateTo,
    dispatch,
    enqueueSnackbar,
    includeAssignmentsExportedToViviumX,
    needIds,
    resourceId,
    status,
    t,
  ])

  const resetGetPickerAssignments = useCallback(
    () => dispatch(actions.schedules.resetGetPickerAssignments()),
    [dispatch]
  )

  useEffect(() => {
    getPickerAssignments()
    return () => {
      resetGetPickerAssignments()
    }
  }, []) // eslint-disable-line

  const shifts = useMemo(
    () =>
      data
        .filter(shift => !!shift.isPreliminary === !!preliminary)
        .map(({ id, startDate, shiftName }) => ({
          value: id,
          label: `${format(parseISO(startDate), 'dd/MM')} - ${shiftName}`,
        })),
    [data, preliminary]
  )

  return {
    error,
    shifts,
    isLoading: loading === LoadingStatus.Pending,
  }
}

const getNeedAssignmentsStatuses = (
  status: AssignmentStatus
): NeedAssignmentStatus[] => {
  switch (status) {
    case AssignmentStatus.BookedExternally:
      return [NeedAssignmentStatus.BookedExternally]
    case AssignmentStatus.BookedInternally:
      return [NeedAssignmentStatus.BookedInternally]
    case AssignmentStatus.Canceled:
      return [NeedAssignmentStatus.Canceled]
    case AssignmentStatus.RequestedExternally:
      return [NeedAssignmentStatus.RequestedExternally]
    case AssignmentStatus.RequestedInternally:
      return [NeedAssignmentStatus.RequestedInternally]
    case AssignmentStatus.NoResources:
      return [NeedAssignmentStatus.NoResources]
    default:
      return [NeedAssignmentStatus.Empty]
  }
}
