import { styled, Typography } from '@mui/material'

export const PillLabelContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0.5),
}))

interface MainTextContainerProps {
  shouldShowDatesText?: boolean
  hasViviumXIcon?: boolean
}

const getMainTextMarginRight = ({
  hasViviumXIcon,
  shouldShowDatesText,
}: MainTextContainerProps) => {
  if (shouldShowDatesText) return 0

  if (hasViviumXIcon) return 0.25

  return 0.75
}

export const MainTextContainer = styled('div')<MainTextContainerProps>(
  ({ theme, shouldShowDatesText, hasViviumXIcon }) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
    marginRight: theme.spacing(
      getMainTextMarginRight({ shouldShowDatesText, hasViviumXIcon })
    ),
    ...(shouldShowDatesText && { fontSize: 10, width: '100%' }),
  })
)

export interface StyledTypographyProps {
  roleFontColor: string
}

export const StyledTypography = styled(Typography)<StyledTypographyProps>(
  ({ roleFontColor }) => ({
    fontSize: '0.625rem',
    color: roleFontColor,
  })
)

export const RoleShortCodeContainer = styled('div')({
  display: 'inline-block',
  flexShrink: 2,
})

interface XIconProps {
  color: string
  background: string
}

export const IconWrapper = styled('div')<{ iconProps: XIconProps }>(
  ({ theme, iconProps }) => ({
    textAlign: 'center',
    paddingRight: theme.spacing(0.5),
    '& svg': {
      height: '0.75em',
      width: '0.75em',
      fontSize: '0.8rem',
      display: 'block',
      ...iconProps,
    },
  })
)
