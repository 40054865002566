import { LinearProgress, styled } from '@mui/material'

interface LoaderProps {
  columnsLabelsVisible: boolean
}

export const Loader = styled(LinearProgress)<LoaderProps>(
  ({ theme, columnsLabelsVisible }) => ({
    margin: columnsLabelsVisible
      ? theme.spacing(2, 0, -2.5)
      : theme.spacing(-2, 0, 1.5),
  })
)
