import { ListItem, ListItemProps, styled, Typography } from '@mui/material'

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingBottom: 0,
  paddingTop: 0,
})) as React.ComponentType<ListItemProps>

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '0.688rem',
  paddingLeft: theme.spacing(1),
}))
