import Form, { FormProps } from '../../Form'
import CPResourcesSection, {
  ResourcesSectionValues,
  useMatchingResources,
  useOnResourcesSectionSubmit,
} from './CPResourcesSection'
import UAResourcesSection from './UAResourcesSection'
import { useHasCPPermissions } from '../AssignmentModal.utils'

interface ResourcesSectionProps
  extends Omit<FormProps<ResourcesSectionValues>, 'onSubmit'> {
  disabledBooking: boolean
}

const ResourcesSection = ({
  disabledBooking,
  ...formProps
}: ResourcesSectionProps) => {
  const hasCareProviderPermissions = useHasCPPermissions()
  const matchingResources = useMatchingResources()
  const onSubmit = useOnResourcesSectionSubmit(matchingResources)
  if (hasCareProviderPermissions)
    return (
      <Form onSubmit={onSubmit} {...formProps}>
        <CPResourcesSection
          disabledBooking={disabledBooking}
          matchingResources={matchingResources}
        />
      </Form>
    )

  return <UAResourcesSection />
}

export default ResourcesSection
