import { ViviumX } from '../../icons'
import {
  AssignmentStatus,
  ScheduleDaysAssignment,
  ShiftType,
} from '../../types'
import { getExportedAssignmentStatus } from '../../utils'
import {
  IconWrapper,
  PillLabelContainer,
  MainTextContainer,
  RoleShortCodeContainer,
  StyledTypography,
} from './PillLabel.style'
import {
  usePillLabelText,
  useRoleFontColor,
  useViviumXColors,
} from './PillLabel.utils'

interface PillLabelProps {
  labelText?: string
  assignment?: Partial<ScheduleDaysAssignment>
  shorthand?: boolean
  xIcon?: boolean
}

const PillLabel = ({
  assignment,
  labelText,
  shorthand,
  xIcon,
}: PillLabelProps) => {
  const recalculatedStatus = getExportedAssignmentStatus(
    assignment?.status,
    assignment?.listingAssignment?.status
  )

  const shouldShowDatesText =
    assignment?.type === ShiftType.Open &&
    recalculatedStatus === AssignmentStatus.Empty

  const label = usePillLabelText({
    assignment,
    isShorthand: shorthand,
    shouldShowDatesText,
  })
  const roleFontColor = useRoleFontColor({
    assignmentStatus: recalculatedStatus,
    isPreliminary: assignment?.isPreliminary,
  })
  const viviumXIconColors = useViviumXColors({
    status: recalculatedStatus,
    hasAbsence:
      assignment?.hasAbsence || assignment?.listingAssignment?.hasAbsece,
  })

  return (
    <PillLabelContainer>
      {xIcon && (
        <IconWrapper iconProps={viviumXIconColors}>
          <ViviumX />
        </IconWrapper>
      )}
      {!shorthand && (
        <MainTextContainer
          shouldShowDatesText={shouldShowDatesText}
          hasViviumXIcon={xIcon}
        >
          {labelText || label}
        </MainTextContainer>
      )}
      {!shouldShowDatesText && assignment?.roleShortCode && (
        <RoleShortCodeContainer>
          <StyledTypography roleFontColor={roleFontColor} variant="caption">
            {assignment.roleShortCode}
          </StyledTypography>
        </RoleShortCodeContainer>
      )}
    </PillLabelContainer>
  )
}

export default PillLabel
