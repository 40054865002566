import { combineReducers } from 'redux'
import accessibilities from './accessibilities'
import type { State as AccessibilitiesState } from './accessibilities'
import auth from './auth'
import type { State as AuthState } from './auth'
import matchingResources from './matchingResources'
import type { State as MatchingResourcesState } from './matchingResources'
import notifications from './notifications'
import type { State as NotificationsState } from './notifications'
import schedules from './schedules'
import type { State as SchedulesState } from './schedules'
import settings from './settings'
import type { State as SettingsState } from './settings'
import tables from './tables'
import type { State as TablesState } from './tables'
import tags from './tags'
import type { State as TagsState } from './tags'

export interface CommonState {
  common: {
    auth: AuthState
    tags: TagsState
    tables: TablesState
    schedules: SchedulesState
    notifications: NotificationsState
    matchingResources: MatchingResourcesState
    settings: SettingsState
    accessibilities: AccessibilitiesState
  }
}

export const actions = {
  auth: auth.actions,
  tags: tags.actions,
  tables: tables.actions,
  schedules: schedules.actions,
  notifications: notifications.actions,
  matchingResources: matchingResources.actions,
  settings: settings.actions,
  accessibilities: accessibilities.actions,
}

export const selectors = {
  auth: auth.selectors,
  tags: tags.selectors,
  tables: tables.selectors,
  schedules: schedules.selectors,
  notifications: notifications.selectors,
  matchingResources: matchingResources.selectors,
  settings: settings.selectors,
  accessibilities: accessibilities.selectors,
}

export const reducer = combineReducers({
  auth: auth.reducer,
  tags: tags.reducer,
  tables: tables.reducer,
  schedules: schedules.reducer,
  notifications: notifications.reducer,
  matchingResources: matchingResources.reducer,
  settings: settings.reducer,
  accessibilities: accessibilities.reducer,
})
