import { FC, ReactNode } from 'react'
import { Divider as MuiDivider, Grid } from '@mui/material'

interface Props {
  children: ReactNode
}

const Divider: FC<Props> = ({ children }) => (
  <Grid container alignItems="center" spacing={1}>
    <Grid item xs>
      <MuiDivider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <MuiDivider />
    </Grid>
  </Grid>
)

export default Divider
