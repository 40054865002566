import Api from '../api'
import {
  GetTagsParams,
  GetTagsResponse,
  CreateTagParams,
  CreateTagResponse,
  DeleteTagParams,
  DeleteTagResponse,
} from './Tags.types'

class Tags extends Api {
  public getTags = async ({ careProviderId, ...params }: GetTagsParams) => {
    const response = await this.api.get<GetTagsResponse>(
      `/care-providers/${careProviderId}/tags`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public createTag = async ({
    careProviderId,
    ...payload
  }: CreateTagParams) => {
    const { data } = await this.api.post<CreateTagResponse>(
      `/care-providers/${careProviderId}/tags`,
      payload
    )
    return data
  }

  public deleteTag = async ({ careProviderId, tagId }: DeleteTagParams) => {
    const { data } = await this.api.delete<DeleteTagResponse>(
      `/care-providers/${careProviderId}/tags/${tagId}`
    )
    return data
  }
}

export default Tags
