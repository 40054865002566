import { useTranslation } from 'react-i18next'
import { IconButton, Menu, MenuItem, CircularProgress } from '@mui/material'
import { useAuth, useModal, useSignOut } from '../../../../hooks'
import { LoadingStatus } from '../../../../types'
import { getInitialLetters } from '../../../../utils'
import { Avatar, ExitToApp, InfoIcon } from './UserMenu.styles'
import { useRedirectToHelpPage } from './UserMenu.utils'

const LOADER_SIZE = 20

const UserMenu = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { loading, signOut } = useSignOut()
  const { openModal, closeModal, anchorEl, isModalOpen } = useModal()
  const redirectToHelpPage = useRedirectToHelpPage()

  const initials = getInitialLetters(user?.name, '')
  const isSigningOut = loading === LoadingStatus.Pending

  const handleSignOut = () => {
    signOut()
    closeModal()
  }

  return (
    <>
      <IconButton
        edge="start"
        data-test-id="icon-avatar-button"
        color="inherit"
        onClick={openModal}
        size="large"
      >
        <Avatar>
          {isSigningOut ? <CircularProgress size={LOADER_SIZE} /> : initials}
        </Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl} open={isModalOpen} onClose={closeModal}>
        <MenuItem
          disabled={isSigningOut}
          data-test-id="log-out-button"
          onClick={handleSignOut}
        >
          <ExitToApp />
          {t('topbar.logout')}
        </MenuItem>
        <MenuItem onClick={redirectToHelpPage}>
          <InfoIcon />
          {t('topbar.help')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
