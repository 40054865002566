import { createContext, useContext, useMemo } from 'react'
import { useAssignmentModal, useAuth } from '../../hooks'
import { AssignmentStatus, LoadingStatus, UserRole } from '../../types'
import BookedAssignmentContent from './BookedAssignmentContent'
import ListingAssignmentComponent from './ListingAssignmentComponent'
import MoreAssignmentsContent from './MoreAssignmentsContent'
import OpenShiftAssignmentContent from './OpenShiftAssignmentContent'
import RequestAssignmentContent from './RequestAssignmentContent'
import { ModalContextValues } from './AssignmentModal.types'

export const useHasCPPermissions = () => {
  const { hasAllowedRole } = useAuth()
  const hasCareProviderPermissions = hasAllowedRole([
    UserRole.CareProvider,
    UserRole.SuperCareProvider,
  ])
  return hasCareProviderPermissions
}

const defaultValues: ModalContextValues = {
  onBookedAssignmentChange: () => {},
  refreshScheduler: () => {},
  handleClose: () => {},
  bookedLoading: LoadingStatus.Idle,
  stateSectionLoading: LoadingStatus.Idle,
  onResourceChange: () => {},
  onStateChange: () => {},
  showNotifications: false,
}

export const ModalContext = createContext(defaultValues)

export const useModalProps = () => {
  const contextProps = useContext(ModalContext)
  const modalData = useAssignmentModal()

  return {
    ...contextProps,
    ...modalData,
  }
}

export const useGetAssignmentModalContent = () => {
  const { data, needAssignment, listingAssignment } = useAssignmentModal()

  const hasCareProviderPermissions = useHasCPPermissions()

  const isBookingContent = useMemo(
    () =>
      needAssignment?.status === AssignmentStatus.BookedInternally ||
      (needAssignment?.status === AssignmentStatus.BookedExternally &&
        !hasCareProviderPermissions),
    [needAssignment?.status, hasCareProviderPermissions]
  )

  return useMemo(() => {
    if (data && data.assignments && !needAssignment && !listingAssignment) {
      return MoreAssignmentsContent
    }

    if (data && data.isOpenShift) {
      return OpenShiftAssignmentContent
    }

    if (data && listingAssignment) return ListingAssignmentComponent

    return isBookingContent ? BookedAssignmentContent : RequestAssignmentContent
  }, [data, needAssignment, listingAssignment, isBookingContent])
}
