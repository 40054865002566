import { useTranslation } from 'react-i18next'
import { Clear } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import LoadingButton from '../LoadingButton'
import { ModalWrapper, Title } from './ConfirmationModal.style'

interface ConfirmationModalProps extends DialogProps {
  open: boolean
  mainContent: string
  title?: string
  onCancel: () => void
  cancelButtonLabel?: string
  onConfirmation: () => void
  confirmationButtonLabel?: string
  onClose?: () => void
  loading?: boolean
  immediateClose?: boolean
}

const ConfirmationModal = ({
  open,
  mainContent,
  onCancel,
  onConfirmation,
  onClose,
  cancelButtonLabel,
  confirmationButtonLabel,
  className,
  loading = false,
  immediateClose = true,
  title,
}: ConfirmationModalProps) => {
  const { t } = useTranslation()

  const handleConfirmation = () => {
    onConfirmation()
    if (immediateClose) onCancel()
  }

  return (
    <Dialog open={open} onClose={onClose || onCancel}>
      {onClose && (
        <Grid container justifyContent="flex-end">
          <IconButton onClick={onClose} size="large">
            <Clear fontSize="small" />
          </IconButton>
        </Grid>
      )}
      <ModalWrapper className={className}>
        <Grid container direction="column" spacing={3}>
          {title && (
            <Grid item>
              <Title>{title}</Title>
            </Grid>
          )}
          {mainContent && (
            <Grid item>
              <Typography>{mainContent}</Typography>
            </Grid>
          )}
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={onCancel}
                data-test-id="cancel-button"
              >
                {cancelButtonLabel || t('modal.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={handleConfirmation}
                data-test-id="confirmation-button"
                loading={loading}
              >
                {confirmationButtonLabel || t('modal.accept')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </ModalWrapper>
    </Dialog>
  )
}

export default ConfirmationModal
