import { Control, FieldValues, Path, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectors as commonSelectors } from '../store'
import { AutocompleteValue } from '../types'
import { potentialExternalAssignmentsStatuses } from '../utils'

interface UseIsShowViviumXAssignmentsButtonVisibleProps<T> {
  name: Path<T>
  control: Control<T, any>
}

export const useIsShowViviumXAssignmentsButtonVisible = <
  T extends FieldValues
>({
  name,
  control,
}: UseIsShowViviumXAssignmentsButtonVisibleProps<T>) => {
  const isViviumXAccessableApplication = useSelector(
    commonSelectors.settings.isViviumXAccessableApplication
  )
  const statuses = useWatch<T>({
    name,
    control,
  }) as unknown as AutocompleteValue[]

  const assignmentStatusesValues = statuses?.map(status => status?.value)
  const isShowViviumXAssingmentsButtonVisible =
    isViviumXAccessableApplication &&
    (assignmentStatusesValues.length === 0 ||
      assignmentStatusesValues?.some(value =>
        potentialExternalAssignmentsStatuses.includes(value)
      ))

  return isShowViviumXAssingmentsButtonVisible
}
