import Api from '../api'
import {
  GetShiftsResponse,
  GetShiftsParams,
  GetDetailedUnitsParams,
  GetDetailedUnitsResponse,
  GetUnitParams,
  GetUnitsParams,
  GetUnitsResponse,
  GetMatchingResourcesParams,
  GetMatchingResourcesResponse,
  GetUnitResponse,
  CreateUnitParams,
  UpdateUnitParams,
  GetNeedAssignmentsStatisticsParams,
  GetNeedAssignmentsStatisticsResponse,
  GetBookedResourcesParams,
  GetBookedResourcesResponse,
  ChangeUnitStatusParams,
  ChangeUnitStatusResponse,
  DeleteUnitParams,
  GetUnitsStatisticsParams,
  GetUnitsStatisticsResponse,
  GetShiftsStatisticsParams,
  GetShiftsStatisticsResponse,
  GetUnitNeedAssignmentsParams,
  GetUnitNeedAssignmentsResponse,
  GetAdminsParams,
  GetAdminsResponse,
  ExportUnitStatisticsParams,
  ExportUnitStatisticsResponse,
} from './Units.types'

class Units extends Api {
  public exportStatistics = async ({
    careProviderId,
    ...params
  }: ExportUnitStatisticsParams) => {
    const { data } = await this.api.post<ExportUnitStatisticsResponse>(
      `/care-providers/${careProviderId}/units/export-statistics`,
      {},
      { params, responseType: 'arraybuffer' }
    )
    return data
  }

  public getUnit = async ({ careProviderId, unitId }: GetUnitParams) => {
    const { data } = await this.api.get<GetUnitResponse>(
      `/care-providers/${careProviderId}/units/${unitId}`
    )
    return data
  }

  public getUnits = async ({ careProviderId, ...params }: GetUnitsParams) => {
    const response = await this.api.get<GetUnitsResponse>(
      `/care-providers/${careProviderId}/units`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getDetailedUnits = async ({
    careProviderId,
    ...params
  }: GetDetailedUnitsParams) => {
    const response = await this.api.get<GetDetailedUnitsResponse>(
      `/care-providers/${careProviderId}/units/details`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getShifts = async ({ careProviderId, unitId }: GetShiftsParams) => {
    const { data } = await this.api.get<GetShiftsResponse>(
      `/care-providers/${careProviderId}/units/${unitId}/shifts`
    )

    return data.data
  }

  public getMatchingResources = async ({
    careProviderId,
    unitId,
    ...params
  }: GetMatchingResourcesParams) => {
    const response = await this.api.get<GetMatchingResourcesResponse>(
      `/care-providers/${careProviderId}/units/${unitId}/matching-resources-v2`,
      { params }
    )
    const { data: matchingResources, ...meta } = response.data
    const {
      totalAssignmentsCount,
      bookableResourcesCount,
      totalResourcesCount,
    } = meta
    const data = {
      matchingResources,
      totalAssignmentsCount,
      bookableResourcesCount,
      totalResourcesCount,
    }

    return { data, meta }
  }

  public createUnit = async ({
    careProviderId,
    ...payload
  }: CreateUnitParams) => {
    const { data } = await this.api.post<string>(
      `/care-providers/${careProviderId}/units`,
      payload
    )
    return data
  }

  public updateUnit = async ({
    careProviderId,
    unitId,
    ...payload
  }: UpdateUnitParams) => {
    const { data } = await this.api.put<string>(
      `/care-providers/${careProviderId}/units/${unitId}`,
      payload
    )
    return data
  }

  public deleteUnit = async ({
    careProviderId,
    unitId,
    ...payload
  }: DeleteUnitParams) => {
    const { data } = await this.api.delete<string>(
      `/care-providers/${careProviderId}/units/${unitId}`,
      payload
    )
    return data
  }

  public changeUnitStatus = async ({
    careProviderId,
    unitId,
    action,
  }: ChangeUnitStatusParams) => {
    const { data } = await this.api.post<ChangeUnitStatusResponse>(
      `/care-providers/${careProviderId}/units/${unitId}/${action}`
    )
    return data
  }

  public getNeedAssignmentsStatistics = async ({
    careProviderId,
    ...params
  }: GetNeedAssignmentsStatisticsParams) => {
    const { data } = await this.api.get<GetNeedAssignmentsStatisticsResponse>(
      `/care-providers/${careProviderId}/need-assignments/statistics`,
      { params }
    )
    return data
  }

  public getUnitsStatistics = async ({
    careProviderId,
    ...params
  }: GetUnitsStatisticsParams) => {
    const { data } = await this.api.get<GetUnitsStatisticsResponse>(
      `/care-providers/${careProviderId}/units/statistics`,
      { params }
    )
    return data
  }

  public getShiftsStatistics = async ({
    careProviderId,
    unitId,
    ...params
  }: GetShiftsStatisticsParams) => {
    const { data } = await this.api.get<GetShiftsStatisticsResponse>(
      `/care-providers/${careProviderId}/units/${unitId}/shifts/statistics`,
      { params }
    )
    return data
  }

  public getBookedResources = async ({
    careProviderId,
    unitId,
    ...params
  }: GetBookedResourcesParams) => {
    const data = await this.api.get<GetBookedResourcesResponse>(
      `/care-providers/${careProviderId}/units/${unitId}/booked-resources`,
      { params }
    )

    return data.data
  }

  public getAdmins = async ({ careProviderId, ...params }: GetAdminsParams) => {
    const response = await this.api.get<GetAdminsResponse>(
      `/care-providers/${careProviderId}/accounts/admins`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getUnitNeedAssignments = async ({
    careProviderId,
    unitId,
    ...params
  }: GetUnitNeedAssignmentsParams) => {
    const response = await this.api.get<GetUnitNeedAssignmentsResponse>(
      `/care-providers/${careProviderId}/units/${unitId}/need-assignments`,
      { params }
    )
    const { data, ...meta } = response.data

    return { data, meta }
  }
}

export default Units
