import {
  ComponentType,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import {
  Tab,
  TabProps,
  Tabs as BaseTabs,
  TabsProps as BaseTabsProps,
} from '@mui/material'
import { useQuery, useUpdateQuery } from '../hooks'

const DEFAULT_TAB = 0
const DEFAULT_TAB_KEY = 'tab'

interface TabsProps extends BaseTabsProps {
  tabs: TabProps[]
  defaultTab?: number
  tabKey?: string
  panels: (string | ReactNode | JSX.Element)[]
  panelsContainer?: ComponentType<PropsWithChildren<any>>
}

type TabsQuery<T extends string> = { [key in T]?: string }

const Tabs = ({
  tabs,
  panels,
  panelsContainer: PanelsContainer = Fragment,
  defaultTab = DEFAULT_TAB,
  tabKey = DEFAULT_TAB_KEY,
  ...props
}: TabsProps) => {
  const query = useQuery<TabsQuery<typeof tabKey>>()
  const updateQuery = useUpdateQuery()

  const currentTab = useMemo(
    () => Number(query[tabKey] ?? defaultTab),
    [defaultTab, query, tabKey]
  )

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: number) =>
      updateQuery({ [tabKey]: value }),
    [tabKey, updateQuery]
  )

  return (
    <>
      <BaseTabs
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        {...props}
      >
        {tabs.map((tab, i) => (
          <Tab value={i} key={`tab-${i}`} {...tab} data-test-id={`tab-${i}`} />
        ))}
      </BaseTabs>
      <PanelsContainer>
        {panels.find((_, i) => currentTab === i) || null}
      </PanelsContainer>
    </>
  )
}
export default Tabs
