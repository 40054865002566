import { HTMLAttributes, memo } from 'react'
import { Add, Remove } from '@mui/icons-material'
import { ControlButton } from './Control.style'
import { BulkActionTypes, Event } from './Scheduler.utils'

type ControlProps = HTMLAttributes<HTMLButtonElement> & {
  type: BulkActionTypes
  onClick?: (events: Event[]) => void
}

const Control = memo(
  ({ type, onClick, onMouseEnter, onMouseLeave }: ControlProps) => (
    <ControlButton
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {type === 'add' ? <Add /> : <Remove />}
    </ControlButton>
  )
)

export default Control
