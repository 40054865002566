import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { Organization } from 'shared/types'
import { getOrganizations } from './actions'

interface State {
  organizations: Resource<Organization[]>
}

const initialState: State = {
  organizations: resource.getInitial<Organization[]>([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getOrganizations.pending, state => {
      resource.setPending(state.organizations)
    })
    .addCase(getOrganizations.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.organizations,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getOrganizations.rejected, (state, action) => {
      resource.setFailed(state.organizations, action.error.message)
    })
)
