import { FormControlLabel, Grid, Popover, styled } from '@mui/material'
import AutocompleteField from '../../AutocompleteField'
import CheckboxField from '../../CheckboxField'

const OPEN_SHIFT_MODAL_WIDTH = 470
const NEED_AUTOCOMPLETE_WIDTH = 250

export const OpenShiftPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(3),
    width: OPEN_SHIFT_MODAL_WIDTH,
  },
}))

export const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiTypography-root': {
    fontWeight: 500,
  },
})

export const StyledCheckboxField = styled(CheckboxField)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  marginRight: 0,
  '& .MuiTypography-root': {
    whiteSpace: 'nowrap',
  },
  '& .MuiButtonBase-root': {
    padding: theme.spacing(0.75),
  },
}))

export const CheckboxesContainer = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

export const NeedAutocompleteField = styled(AutocompleteField)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(1),
  width: NEED_AUTOCOMPLETE_WIDTH,
}))
