import { Components } from '@mui/material'
import MuiAutocomplete from './MuiAutocomplete'
import MuiAvatar from './MuiAvatar'
import MuiBadge from './MuiBadge'
import MuiButton from './MuiButton'
import MuiButtonBase from './MuiButtonBase'
import MuiCheckbox from './MuiCheckbox'
import MuiChip from './MuiChip'
import MuiCssBaseline from './MuiCssBaseline'
import MuiFormControl from './MuiFormControl'
import MuiFormLabel from './MuiFormLabel'
import MuiInput from './MuiInput'
import MuiInputBase from './MuiInputBase'
import MuiInputLabel from './MuiInputLabel'
import MuiLink from './MuiLink'
import MuiListItemIcon from './MuiListItemIcon'
import MuiSkeleton from './MuiSkeleton'
import MuiStepper from './MuiStepper'
import MuiTab from './MuiTab'
import MuiTableCell from './MuiTableCell'
import MuiTableContainer from './MuiTableContainer'
import MuiTabs from './MuiTabs'
import MuiTextField from './MuiTextField'

const components: Components = {
  MuiTextField,
  MuiInput,
  MuiFormControl,
  MuiButton,
  MuiButtonBase,
  MuiLink,
  MuiInputLabel,
  MuiSkeleton,
  MuiCssBaseline,
  MuiListItemIcon,
  MuiInputBase,
  MuiFormLabel,
  MuiBadge,
  MuiAvatar,
  MuiTabs,
  MuiTab,
  MuiTableContainer,
  MuiTableCell,
  MuiAutocomplete,
  MuiStepper,
  MuiChip,
  MuiCheckbox,
}

export default components
