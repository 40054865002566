const env = {
  env: process.env.REACT_APP_ENV || 'development',
  apiUrl: process.env.REACT_APP_API_URL || '',
  apiKey: process.env.REACT_APP_API_KEY || '',
  applicationId: process.env.REACT_APP_APPLICATION_ID || '',
  landingPageUrl: process.env.REACT_APP_LANDING_PAGE_URL || '',
  currentVersion: process.env.REACT_APP_CURRENT_VERSION || '',
  chat: {
    appId: process.env.REACT_APP_CHAT_APP_ID || '',
    authKey: process.env.REACT_APP_CHAT_AUTH_KEY || '',
    authSecret: process.env.REACT_APP_CHAT_AUTH_SECRET || '',
    accountKey: process.env.REACT_APP_CHAT_ACCOUNT_KEY || '',
    endpoints: {
      api: process.env.REACT_APP_CHAT_ENDPOINT_API || '',
      chat: process.env.REACT_APP_CHAT_ENDPOINT_CHAT || '',
    },
  },
  resourceVideoUrl: process.env.REACT_APP_RESOURCE_VIDEO_URL || '',
  unitAdminVideoUrl: process.env.REACT_APP_UNIT_ADMIN_VIDEO_URL || '',
  supportEmailAddress: process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS || '',
  salesEmailAddress: process.env.REACT_APP_SALES_EMAIL_ADDRESS || '',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  requestEncryptionAESSecretKey:
    process.env.REACT_APP_REQUEST_ENCRYPTION_AES_SECRET_KEY || '',
  requestEncryptionAESIv: process.env.REACT_APP_REQUEST_ENCRYPTION_AES_IV || '',
}

Object.entries(env).forEach(([key, value]) => {
  if (!value) {
    if (key === 'sentryDsn' && env.env !== 'production') {
      return
    }

    console.error(`Missing ${key} env variable.`)
  }
})

export default env
