import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useAssignmentModal } from '../../../hooks'
import { OpenShiftNeedType } from '../../../types'
import AutocompleteField from '../../AutocompleteField'
import Form from '../../Form'
import LoadingButton from '../../LoadingButton'
import TextAreaField from '../../TextAreaField'
import TextField from '../../TextField'
import TimeField from '../../TimeField'
import Tooltip from '../../Tooltip'
import { useModalProps } from '../AssignmentModal.utils'
import {
  CheckboxesContainer,
  NeedAutocompleteField,
  StyledCheckboxField,
  StyledFormControlLabel,
} from './OpenShiftAssignmentContent.styles'
import {
  useFormProps,
  OpenShiftAssignmentFields,
  useOpenShiftNeed,
  useOnSubmit,
  MIN_BREAK_DURATION,
  TIME_FORMAT,
  useOpenShiftModalAutocompletes,
  useBreakDuration,
} from './OpenShiftAssignmentContent.utils'

const OpenShiftAssignmentContent = () => {
  const { t } = useTranslation()
  const { data, closeModal, onBack } = useAssignmentModal()
  const formProps = useFormProps()
  const { getValues, setValue, control, reset, trigger, formState } = formProps
  const { isDirty } = formState
  const { needType, handleNeedTypeChange, isNewNeedOptionSelected } =
    useOpenShiftNeed(setValue, trigger)
  const { getSpecialtyOptions, getRoleOptions, getNeedsOptions, isRoleEmpty } =
    useOpenShiftModalAutocompletes(setValue, control, isDirty)
  const isBreakDurationDisabled = useBreakDuration(setValue, control)

  const { refreshScheduler } = useModalProps()

  const title = useMemo(
    () => (data?.shiftDate ? format(data?.shiftDate, 'd/M') : ''),
    [data?.shiftDate]
  )

  const handleClose = () => {
    reset()
    onBack()
    closeModal()
  }

  const { onSubmit, isSubmitting } = useOnSubmit({
    refreshScheduler,
    handleClose,
    shiftDate: data?.shiftDate,
    shiftId: data?.shiftId,
  })

  return (
    <Form {...formProps} onSubmit={() => onSubmit(getValues())}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <AutocompleteField
              name={OpenShiftAssignmentFields.Role}
              label={t('openShiftModal.role')}
              placeholder={t('openShiftModal.select')}
              fetchOptions={getRoleOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteField
              multiple
              name={OpenShiftAssignmentFields.Specialties}
              label={t('openShiftModal.specialties')}
              placeholder={t('openShiftModal.select')}
              disabled={isRoleEmpty}
              fetchOptions={getSpecialtyOptions}
            />
          </Grid>
        </Grid>
        <Grid item>
          <RadioGroup
            name={OpenShiftAssignmentFields.NeedType}
            onChange={handleNeedTypeChange}
          >
            <StyledFormControlLabel
              control={<Radio color="primary" />}
              value={OpenShiftNeedType.ExistingNeed}
              label={t(
                `enums.OpenShiftNeedType.${OpenShiftNeedType.ExistingNeed}`
              )}
              checked={needType === OpenShiftNeedType.ExistingNeed}
            />
            <Tooltip
              title={t('openShiftModal.roleRequired') || ''}
              disabled={
                !isRoleEmpty || needType !== OpenShiftNeedType.ExistingNeed
              }
            >
              <NeedAutocompleteField
                name={OpenShiftAssignmentFields.Need}
                label=""
                placeholder={t('openShiftModal.select')}
                disabled={isNewNeedOptionSelected || isRoleEmpty}
                fetchOptions={getNeedsOptions}
              />
            </Tooltip>

            <StyledFormControlLabel
              control={<Radio color="primary" />}
              value={OpenShiftNeedType.NewNeed}
              label={t(`enums.OpenShiftNeedType.${OpenShiftNeedType.NewNeed}`)}
              checked={needType === OpenShiftNeedType.NewNeed}
            />
          </RadioGroup>
        </Grid>
        <Grid item container spacing={1} alignItems="center">
          <Grid item xs>
            <TimeField
              name={OpenShiftAssignmentFields.StartTime}
              format={TIME_FORMAT}
            />
          </Grid>
          <Grid item xs>
            <TimeField
              name={OpenShiftAssignmentFields.EndTime}
              format={TIME_FORMAT}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <CheckboxesContainer item container direction="column" xs="auto">
            <Grid item>
              <StyledCheckboxField
                name={OpenShiftAssignmentFields.Break}
                label={t('openShiftModal.break')}
              />
            </Grid>
            <Grid item>
              <StyledCheckboxField
                name={OpenShiftAssignmentFields.MealBreak}
                label={t('openShiftModal.mealBreak')}
              />
            </Grid>
          </CheckboxesContainer>
          <Grid item xs={2}>
            <TextField
              name={OpenShiftAssignmentFields.BreakDuration}
              type="number"
              disabled={isBreakDurationDisabled}
              InputProps={{
                inputProps: {
                  min: MIN_BREAK_DURATION,
                },
              }}
            />
          </Grid>
          <Grid item xs>
            <Typography>{t('time.minutesShort')}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextAreaField
            name={OpenShiftAssignmentFields.Notes}
            multiline
            rows={2}
            label={t('openShiftModal.notes')}
          />
        </Grid>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button color="primary" onClick={() => handleClose()}>
              {t('openShiftModal.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              color="primary"
              type="submit"
            >
              {t('openShiftModal.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default OpenShiftAssignmentContent
