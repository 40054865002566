import { MouseEventHandler, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Grid, ListItemButton } from '@mui/material'
import { useDispatch } from '../../../../../../hooks'
import {
  useOrganizationId,
  useOrganizationResourceId,
} from '../../../../../../hooks'
import { actions } from '../../../../../../store'
import { RequestNotification, NotificationType } from '../../../../../../types'
import { Badge } from '../../../../../Badge'
import {
  Container,
  MessageIcon,
  StyledChip,
  Typography,
} from './ResourceNotification.style'
import { getDate, useHref } from './ResourceNotificationComponent.utils'

export interface NotificationProps {
  notification: RequestNotification
  onClick?: MouseEventHandler<HTMLElement>
}

const ResourceNotificationComponent = ({
  notification: { data, id, isRead, type },
  onClick,
}: NotificationProps) => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const date = getDate(data?.assignmentStartDate)
  const href = useHref(data?.assignmentStartDate)
  const resourceId = useOrganizationResourceId()

  const markAsRead = useCallback(
    () =>
      id &&
      dispatch(
        actions.notifications.markAsRead({
          careProviderId,
          ...(resourceId && { resourceId }),
          notificationIds: [id],
        })
      ),
    [id, dispatch, careProviderId, resourceId]
  )

  const handleClick: MouseEventHandler<HTMLElement> = useCallback(event => {
    if (!isRead) {
      markAsRead()
    }
    if (onClick) onClick(event)
  }, []) //eslint-disable-line

  return (
    <ListItemButton onClick={handleClick} component={Link} to={href}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            {!isRead && <Badge notification />}
          </Grid>
          <Grid item xs={1}>
            <StyledChip
              variant={
                type === NotificationType.NeedAssignmentRequestCreated
                  ? 'outlined'
                  : 'filled'
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>
              <strong>{date}</strong>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{data?.shiftName}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{data?.unitName}</Typography>
          </Grid>
          {type === NotificationType.NeedAssignmentNoteAdded && (
            <Grid item xs>
              <MessageIcon />
            </Grid>
          )}
        </Grid>
      </Container>
    </ListItemButton>
  )
}

export default ResourceNotificationComponent
