import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { Text, DateText } from './DividedAvailabilityInfo.styles'
import { useDividedAvailabilityInfo } from './DividedAvailabilityInfo.utils'

interface DividedAvailabilityInfoProps {
  timeFrom: string
  timeTo: string
  startDate: Date | string
  endDate: Date | string
}

const DividedAvailabilityInfo = ({
  timeFrom,
  timeTo,
  startDate,
  endDate,
}: DividedAvailabilityInfoProps) => {
  const { t } = useTranslation()

  const {
    isMoreThan24Hours,
    fromStartDate,
    fromEndDate,
    toStartDate,
    toEndDate,
  } = useDividedAvailabilityInfo(timeFrom, timeTo, startDate, endDate)

  if (!isMoreThan24Hours) return null

  return (
    <Box border={1} borderRadius={1} padding={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Text>
            {t('availabilityModal.dividedInfo.availabilityWillChange')}
          </Text>
        </Grid>
        <Grid item container>
          <Grid item container xs={12}>
            <Grid item xs={2}>
              <Text size="large">
                {t('availabilityModal.dividedInfo.from')}
              </Text>
            </Grid>
            <Grid item xs={10}>
              <DateText>
                {t('availabilityModal.dividedInfo.dates', {
                  startDate: fromStartDate,
                  endDate: fromEndDate,
                })}
              </DateText>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={2}>
              <Text size="large">{t('availabilityModal.dividedInfo.to')}</Text>
            </Grid>
            <Grid item xs={10}>
              <DateText>
                {t('availabilityModal.dividedInfo.dates', {
                  startDate: toStartDate,
                  endDate: toEndDate,
                })}
              </DateText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Text>{t('availabilityModal.dividedInfo.availabilitySlots')}</Text>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DividedAvailabilityInfo
