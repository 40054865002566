import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import {
  ResourceDetails,
  ResourceTableInfo,
  ResourceDocument,
  Unit,
  Document,
  StaffingTableInfo,
  AvailabilityTableInfo,
  StaffingTableAssignments,
  Entity,
  ResourceMatchingAssignments,
} from 'shared/types'
import {
  getResourcesTableInfo,
  createResource,
  resetCreateResource,
  getResourceDetails,
  uploadResourceDocument,
  deleteResourceDocument,
  updateResource,
  resetUpdateResource,
  getMatchingUnits,
  deleteResource,
  resetDeleteResource,
  deactivateResource,
  resetDeactivateResource,
  activateResource,
  resetActivateResource,
  getStaffingTableInfo,
  getAvailabilityTableInfo,
  exportStaffingStatistics,
  exportAvailabilityStatistics,
  toggleAvailabilityTableEditingMode,
  resetAvailabilityTableEditingMode,
  getResourceMatchingAssignments,
  bookMatchingAssignment,
  requestMatchingAssignment,
} from './actions'
import { activateResourceState, deactivateResourceState } from './reducer.utils'

export interface State {
  getResourceDetails: Resource<ResourceDetails | null>
  resourceDocuments: Document[]
  getResources: Resource<ResourceTableInfo[]>
  createResource: Resource<string>
  updateResource: Resource<string>
  deleteResource: Resource<number>
  activateResource: Resource<string>
  deactivateResource: Resource<string>
  uploadResourceDocument: Resource<ResourceDocument | null>
  deleteResourceDocument: Resource<number>
  getMatchingUnits: Resource<Entity[]>
  resourcePhoneNumber: string
  getStaffingTableInfo: Resource<StaffingTableInfo[]>
  getAvailabilityTableInfo: Resource<AvailabilityTableInfo[]>
  exportStaffingStatistics: Resource<ArrayBuffer>
  exportAvailabilityStatistics: Resource<ArrayBuffer>
  isAvailabilityTableEditingMode: boolean
  getResourceMatchingAssignments: Resource<ResourceMatchingAssignments>
  bookMatchingAssignment: Resource<string>
  requestMatchingAssignment: Resource<string>
}

const initialState: State = {
  getResourceDetails: resource.getInitial<ResourceDetails | null>(null),
  resourceDocuments: [],
  getResources: resource.getInitial<ResourceTableInfo[]>([]),
  createResource: resource.getInitial(''),
  updateResource: resource.getInitial(''),
  activateResource: resource.getInitial(''),
  deactivateResource: resource.getInitial(''),
  deleteResource: resource.getInitial<number>(),
  uploadResourceDocument: resource.getInitial<ResourceDocument | null>(null),
  deleteResourceDocument: resource.getInitial<number>(),
  getMatchingUnits: resource.getInitial<Unit[]>([]),
  resourcePhoneNumber: '',
  getStaffingTableInfo: resource.getInitial<StaffingTableInfo[]>([]),
  getAvailabilityTableInfo: resource.getInitial<AvailabilityTableInfo[]>([]),
  exportStaffingStatistics: resource.getInitial<ArrayBuffer>(),
  exportAvailabilityStatistics: resource.getInitial<ArrayBuffer>(),
  isAvailabilityTableEditingMode: false,
  getResourceMatchingAssignments: resource.getInitial(),
  bookMatchingAssignment: resource.getInitial(''),
  requestMatchingAssignment: resource.getInitial(''),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getResourcesTableInfo.pending, state => {
      resource.setPending(state.getResources)
    })
    .addCase(getResourcesTableInfo.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getResources,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getResourcesTableInfo.rejected, (state, action) => {
      resource.setFailed(state.getResources, action.error.message)
    })
    .addCase(createResource.pending, state => {
      resource.setPending(state.createResource)
    })
    .addCase(createResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.createResource, action.payload)
    })
    .addCase(createResource.rejected, (state, action) => {
      resource.setFailed(
        state.createResource,
        (action.payload as string) || action.error.message
      )
    })
    .addCase(resetCreateResource, state => {
      resource.reset(state.createResource, initialState.createResource.data)
      state.resourceDocuments = initialState.resourceDocuments
    })
    .addCase(updateResource.pending, state => {
      resource.setPending(state.updateResource)
    })
    .addCase(updateResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateResource, action.payload)
      if (state.getResourceDetails.data)
        state.getResourceDetails.data.name = action.meta.arg.name
      state.resourcePhoneNumber = action.meta.arg.contactPhoneNumber
    })
    .addCase(updateResource.rejected, (state, action) => {
      resource.setFailed(
        state.updateResource,
        (action.payload as string) || action.error.message
      )
    })
    .addCase(resetUpdateResource, state => {
      resource.reset(state.updateResource, initialState.updateResource.data)
      state.resourceDocuments = initialState.resourceDocuments
    })
    .addCase(getResourceDetails.pending, state => {
      resource.setPending(state.getResourceDetails)
    })
    .addCase(getResourceDetails.fulfilled, (state, action) => {
      resource.setSucceeded(state.getResourceDetails, action.payload)
      state.resourceDocuments = action.payload.documents
      state.resourcePhoneNumber = action.payload.contactPhoneNumber
    })
    .addCase(getResourceDetails.rejected, (state, action) => {
      resource.setFailed(state.getResourceDetails, action.error.message)
    })
    .addCase(uploadResourceDocument.pending, state => {
      resource.setPending(state.uploadResourceDocument)
    })
    .addCase(uploadResourceDocument.fulfilled, (state, action) => {
      resource.setSucceeded(state.uploadResourceDocument)
      if (action.payload) {
        state.resourceDocuments.push(action.payload)
      }
    })
    .addCase(uploadResourceDocument.rejected, (state, action) => {
      resource.setFailed(state.uploadResourceDocument, action.error.message)
    })
    .addCase(deleteResourceDocument.pending, state => {
      resource.setPending(state.uploadResourceDocument)
    })
    .addCase(deleteResourceDocument.fulfilled, (state, action) => {
      resource.setSucceeded(state.uploadResourceDocument)
      state.resourceDocuments = state.resourceDocuments.filter(
        document => document.id !== action.meta.arg.params.documentId
      )
    })
    .addCase(deleteResourceDocument.rejected, (state, action) => {
      resource.setFailed(state.uploadResourceDocument, action.error.message)
    })
    .addCase(getMatchingUnits.pending, state => {
      resource.setPending(state.getMatchingUnits)
    })
    .addCase(getMatchingUnits.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMatchingUnits, action.payload)
    })
    .addCase(getMatchingUnits.rejected, (state, action) => {
      resource.setFailed(state.getMatchingUnits, action.error.message)
    })
    .addCase(deleteResource.pending, state => {
      resource.setPending(state.deleteResource)
    })
    .addCase(deleteResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteResource, action.payload)
    })
    .addCase(deleteResource.rejected, (state, action) => {
      resource.setFailed(state.deleteResource, action.error.message)
    })
    .addCase(resetDeleteResource, state => {
      resource.reset(state.deleteResource)
    })
    .addCase(deactivateResource.pending, state => {
      resource.setPending(state.deactivateResource)
    })
    .addCase(deactivateResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.deactivateResource, action.payload)
      deactivateResourceState(state)
    })
    .addCase(deactivateResource.rejected, (state, action) => {
      resource.setFailed(state.deactivateResource, action.error.message)
    })
    .addCase(resetDeactivateResource, state => {
      resource.reset(state.deactivateResource)
    })
    .addCase(activateResource.pending, state => {
      resource.setPending(state.activateResource)
    })
    .addCase(activateResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.activateResource, action.payload)
      activateResourceState(state)
    })
    .addCase(activateResource.rejected, (state, action) => {
      resource.setFailed(state.activateResource, action.error.message)
    })
    .addCase(resetActivateResource, state => {
      resource.reset(state.activateResource)
    })
    .addCase(getStaffingTableInfo.pending, state => {
      resource.setPending(state.getStaffingTableInfo)
    })
    .addCase(getStaffingTableInfo.fulfilled, (state, action) => {
      if (action.meta.arg.skip && action.meta.arg.skip > 0) {
        const lastUnitAssignments = (
          state.getStaffingTableInfo.data[
            state.getStaffingTableInfo.data.length - 1
          ].assignments as unknown as StaffingTableAssignments[]
        ).concat(action.payload.data[0].assignments)

        const lastUnitCombined = {
          ...state.getStaffingTableInfo.data[
            state.getStaffingTableInfo.data.length - 1
          ],
          assignments: lastUnitAssignments,
        }
        resource.setSucceeded(
          state.getStaffingTableInfo,
          [
            ...state.getStaffingTableInfo.data.slice(0, -1),
            lastUnitCombined,
            ...action.payload.data.slice(1),
          ],
          action.payload.meta
        )
      } else {
        resource.setSucceeded(
          state.getStaffingTableInfo,
          action.payload.data,
          action.payload.meta
        )
      }
    })
    .addCase(getStaffingTableInfo.rejected, (state, action) => {
      resource.setFailed(state.getStaffingTableInfo, action.error.message)
    })
    .addCase(exportStaffingStatistics.pending, state => {
      resource.setPending(state.exportStaffingStatistics)
    })
    .addCase(exportStaffingStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportStaffingStatistics, action.payload)
    })
    .addCase(exportStaffingStatistics.rejected, (state, action) => {
      resource.setFailed(state.exportStaffingStatistics, action.error.message)
    })
    .addCase(getAvailabilityTableInfo.pending, state => {
      resource.setPending(state.getAvailabilityTableInfo)
    })
    .addCase(getAvailabilityTableInfo.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getAvailabilityTableInfo,
        action.meta.arg.skip && action.meta.arg.skip > 0
          ? [...state.getAvailabilityTableInfo.data, ...action.payload.data]
          : action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getAvailabilityTableInfo.rejected, (state, action) => {
      resource.setFailed(state.getAvailabilityTableInfo, action.error.message)
    })
    .addCase(exportAvailabilityStatistics.pending, state => {
      resource.setPending(state.exportAvailabilityStatistics)
    })
    .addCase(exportAvailabilityStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportAvailabilityStatistics, action.payload)
    })
    .addCase(exportAvailabilityStatistics.rejected, (state, action) => {
      resource.setFailed(
        state.exportAvailabilityStatistics,
        action.error.message
      )
    })
    .addCase(toggleAvailabilityTableEditingMode, state => {
      state.isAvailabilityTableEditingMode =
        !state.isAvailabilityTableEditingMode
    })
    .addCase(resetAvailabilityTableEditingMode, state => {
      state.isAvailabilityTableEditingMode = false
    })
    .addCase(getResourceMatchingAssignments.pending, state => {
      resource.setPending(state.getResourceMatchingAssignments)
    })
    .addCase(getResourceMatchingAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getResourceMatchingAssignments,
        action.payload
      )
    })
    .addCase(getResourceMatchingAssignments.rejected, (state, action) => {
      resource.setFailed(
        state.getResourceMatchingAssignments,
        action.error.message
      )
    })
    .addCase(bookMatchingAssignment.pending, state => {
      resource.setPending(state.bookMatchingAssignment)
    })
    .addCase(bookMatchingAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.bookMatchingAssignment, action.payload)
    })
    .addCase(bookMatchingAssignment.rejected, (state, action) => {
      resource.setFailed(state.bookMatchingAssignment, action.error.message)
    })
    .addCase(requestMatchingAssignment.pending, state => {
      resource.setPending(state.requestMatchingAssignment)
    })
    .addCase(requestMatchingAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.requestMatchingAssignment, action.payload)
    })
    .addCase(requestMatchingAssignment.rejected, (state, action) => {
      resource.setFailed(state.requestMatchingAssignment, action.error.message)
    })
)
