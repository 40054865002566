import { useCallback, useEffect, useMemo } from 'react'
import { Resource } from '@devexpress/dx-react-scheduler'
import { useTranslation } from 'react-i18next'
import { Theme } from '@mui/material'
import {
  isAfter,
  isBefore,
  isEqual as isDateEqual,
  isSameDay,
  parseISO,
  setHours,
  startOfToday,
} from 'date-fns'
import { isEqual, isEmpty, xorWith } from 'lodash'
import { useSnackbar } from 'notistack'
import config from 'config'
import { ResourcesSectionFormValues } from '../components'
import { useDispatch, useSelector } from '../hooks'
import { useOrganizationId } from '../hooks'
import { actions, selectors } from '../store'
import {
  AssignmentRequestStatus,
  AssignmentStatus,
  AssignmentSummaryCounts,
  AssignmentToAdd,
  DateShift,
  MonthlyViewAppointment,
  ResourceSchedule,
  ScheduleDays,
  ScheduleDaysAssignment,
  SchedulerDates,
  Shift,
  ShiftType,
  UnitNeed,
  LisitingAssignmentStatus,
} from '../types'

export type SortFunction = (
  a: ScheduleDaysAssignment,
  b: ScheduleDaysAssignment
) => number

export const getStartEndDate = (date: string) => ({
  startDate: setHours(parseISO(date), config.scheduler.startDayHour),
  endDate: setHours(parseISO(date), config.scheduler.endDayHour),
})

export const defaultSortingOrder: AssignmentStatus[] = [
  AssignmentStatus.BookedInternally,
  AssignmentStatus.BookedExternally,
  AssignmentStatus.RequestedInternally,
  AssignmentStatus.RequestedExternally,
  AssignmentStatus.Canceled,
  AssignmentStatus.Empty,
]

export const editingModeSortingOrder: AssignmentStatus[] = [
  AssignmentStatus.Empty,
  AssignmentStatus.BookedInternally,
  AssignmentStatus.BookedExternally,
  AssignmentStatus.RequestedInternally,
  AssignmentStatus.RequestedExternally,
  AssignmentStatus.Canceled,
]

export const createSortByAssignmentStatusFn =
  <T extends { status: AssignmentStatus }>(
    sortingOrder: AssignmentStatus[] = defaultSortingOrder
  ) =>
  (a: T, b: T) =>
    sortingOrder.indexOf(a.status) - sortingOrder.indexOf(b.status)

const defaultSortByStatusFn = createSortByAssignmentStatusFn()
const editingModeSortByStatusFn = createSortByAssignmentStatusFn(
  editingModeSortingOrder
)

export const sortAssignmentsByStatus = <T extends { status: AssignmentStatus }>(
  assignments: T[],
  sortFn: (a: T, b: T) => number = defaultSortByStatusFn
) => [...assignments].sort(sortFn) // mutation applied not to modify read-only arrays

export const getGroupedAssignmentsByShift = (
  assignments: ScheduleDaysAssignment[]
) =>
  assignments.reduce((previous, currentAssignment) => {
    const currentShiftId = currentAssignment.shiftId
    const currShift = previous[currentShiftId]
    if (!currShift)
      return { ...previous, [currentShiftId]: [currentAssignment] }

    return { ...previous, [currentShiftId]: [...currShift, currentAssignment] }
  }, {} as Record<number, ScheduleDaysAssignment[]>)

export const getDateShifts = (
  data: ScheduleDays,
  assignmentIdsToDelete: number[] = [],
  sortFn: SortFunction = defaultSortByStatusFn
): DateShift[] => {
  const shiftDates = getStartEndDate(data.date)
  const remainingAssignments = data.assignments.filter(
    assignment => !assignmentIdsToDelete.includes(assignment.id)
  )
  const groupedAssignments = getGroupedAssignmentsByShift(remainingAssignments)

  return Object.entries(groupedAssignments).map(([shiftId, assignments]) => ({
    shiftId: Number(shiftId),
    shiftType: assignments[0]?.type || ShiftType.Defined,
    assignments: sortAssignmentsByStatus(assignments, sortFn),
    ...shiftDates,
  }))
}
interface MapDaysToShiftsParams {
  data: ScheduleDays[]
  assignmentIdsToDelete?: number[]
  isSchedulerEditingModeEnabled?: boolean
  sortFnParam?: SortFunction
  shifts: Shift[]
}

export const mapDaysToShifts = ({
  data,
  assignmentIdsToDelete = [],
  isSchedulerEditingModeEnabled = false,
  sortFnParam = defaultSortByStatusFn,
  shifts,
}: MapDaysToShiftsParams): DateShift[] => {
  const sortFn = isSchedulerEditingModeEnabled
    ? editingModeSortByStatusFn
    : sortFnParam

  const emptyDateShifts: DateShift[] = data.reduce((previous, current) => {
    const emptyShifts: DateShift[] = shifts.map(shift => ({
      assignments: [],
      shiftId: shift.id,
      shiftType: shift.type,
      availabilities: current.availabilities,
      absences: current.absences,
      ...getStartEndDate(current.date),
    }))
    return [...previous, ...emptyShifts]
  }, [] as DateShift[])

  const shiftsWithAssignments = data.reduce(
    (previous, current) => [
      ...previous,
      ...getDateShifts(current, assignmentIdsToDelete, sortFn),
    ],
    [] as DateShift[]
  )

  return emptyDateShifts.map(shift => {
    const filledShift = shiftsWithAssignments.find(
      ({ shiftId, startDate }) =>
        shiftId === shift.shiftId && isSameDay(startDate, shift.startDate)
    )
    return filledShift
      ? {
          ...filledShift,
          shiftType: shift.shiftType,
          availabilities: shift.availabilities,
          absences: shift.absences,
        }
      : shift
  })
}

const defaultAssignmentSummaryCounts: AssignmentSummaryCounts = {
  BOOKED_EXTERNALLY: 0,
  BOOKED_INTERNALLY: 0,
  CANCELED: 0,
  EMPTY: 0,
  NO_RESOURCES: 0,
  OTHER_ASSIGNMENTS: 0,
  REQUESTED_EXTERNALLY: 0,
  REQUESTED_INTERNALLY: 0,
}

export const mapShiftsToResource = (
  shifts: Shift[],
  title: string = ''
): Resource[] => [
  {
    title,
    fieldName: config.scheduler.resourceName,
    instances: shifts.map(({ id, name }) => ({ id, text: name })),
  },
]

export const getDatesResourceAssignments = (
  data: ResourceSchedule
): MonthlyViewAppointment => {
  const assignments = [
    ...data.assignments,
    ...data.requests
      .filter(({ status }) => status === AssignmentRequestStatus.Pending)
      .map(({ assignment }) => assignment),
  ]
  return {
    assignments: sortAssignmentsByStatus(assignments),
    availabilities: data.availabilities,
    absences: data.absences,
    counts: defaultAssignmentSummaryCounts,
    total: 0,
    ...getStartEndDate(data.date),
  }
}

export const mapResourceSchedulesToAssignments = (
  data: ResourceSchedule[]
): MonthlyViewAppointment[] => data.map(getDatesResourceAssignments)

export interface AssignResourcesToAssignmentPayload {
  careProviderId: number
  unitId: number
  modifiedAssignment: ScheduleDaysAssignment
  values: ResourcesSectionFormValues
}

export interface SetSchedulerDatesPayload extends SchedulerDates {}

export const useOnSubmitSnackbars = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const handleSuccess = useCallback(
    (message?: string) => {
      enqueueSnackbar(message || t('scheduler.modificationSuccess'), {
        variant: 'success',
      })
      onSuccess()
    },
    [enqueueSnackbar, onSuccess, t]
  )

  const handleFailure = useCallback(
    (message?: string) => {
      enqueueSnackbar(message || t('error.defaultMessage'), {
        variant: 'error',
      })
      onError()
    },
    [enqueueSnackbar, onError, t]
  )

  return { onSuccess: handleSuccess, onFailure: handleFailure }
}

export const parseDate = <T extends Date | string | number | null | undefined>(
  date: T
) =>
  (typeof date === 'string'
    ? parseISO(date)
    : typeof date === 'number'
    ? new Date(date)
    : date || null) as T extends NonNullable<T> ? Date : null

export const resourcesPlaceholder: Resource[] = [
  {
    fieldName: config.scheduler.resourceName,
    instances: [{ id: 1, text: '' }],
  },
]

interface GetBookedExternallyColorParams {
  theme: Theme
  hasAbsence?: boolean
  isPreliminary?: boolean
}

const getBookedExternallyBackground = ({
  theme,
  hasAbsence,
  isPreliminary,
}: GetBookedExternallyColorParams) => {
  if (hasAbsence) return theme.palette.pill.red.light

  if (isPreliminary)
    return `repeating-linear-gradient(45deg, ${theme.palette.pill.blue.light}, ${theme.palette.pill.blue.light} 4px, ${theme.palette.pill.blue.dark} 4px,  ${theme.palette.pill.blue.dark} 8px)`

  return theme.palette.pill.blue.main
}
interface GetAssignmentPillStyleOptions {
  theme: Theme
  status: AssignmentStatus
  hasAbsence?: boolean
  isPreliminary?: boolean
}

export const getAssignmentPillStyle = ({
  theme,
  status,
  hasAbsence,
  isPreliminary,
}: GetAssignmentPillStyleOptions) => {
  switch (status) {
    case AssignmentStatus.BookedInternally:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.black,
        },
        background: hasAbsence
          ? theme.palette.pill.red.light
          : theme.palette.pill.yellow.main,
        border: 'none',
      }
    case AssignmentStatus.BookedExternally:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: isPreliminary
            ? theme.palette.common.black
            : theme.palette.common.white,
        },
        background: getBookedExternallyBackground({
          theme,
          hasAbsence,
          isPreliminary,
        }),
        border: isPreliminary
          ? `2px solid ${theme.palette.pill.blue.main}`
          : 'none',
      }
    case AssignmentStatus.RequestedInternally:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.black,
        },
        borderColor: theme.palette.pill.yellow.main,
        border: `1px dashed ${theme.palette.pill.yellow.main}`,
        background: theme.palette.common.white,
      }
    case AssignmentStatus.RequestedExternally:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.black,
        },
        borderColor: theme.palette.pill.blue.main,
        border: `1px dashed ${theme.palette.pill.blue.main}`,
        background: theme.palette.common.white,
      }
    case AssignmentStatus.Empty:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.pill.gray.contrastText,
        },
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.pill.gray.contrastText}`,
      }
    case AssignmentStatus.Canceled:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.white,
        },
        background: theme.palette.pill.gray.dark,
        border: 'none',
      }
    case AssignmentStatus.Bookable:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.black,
        },
        background: theme.palette.pill.pink.main,
        border: `1px solid ${theme.palette.common.black}`,
      }
    case AssignmentStatus.Available:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.white,
        },
        background: theme.palette.pill.gray.light,
        border: 'none',
        cursor: 'default',
      }
    case AssignmentStatus.Requestable:
      return {
        border: `1px dashed ${theme.palette.pill.border.main}`,
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.black,
        },
        background: theme.palette.common.white,
      }
    case AssignmentStatus.NoResources:
      return {
        '& > *, & .MuiChip-deleteIcon': {
          color: theme.palette.common.black,
        },
        background: theme.palette.neutral.main,
        border: `1px solid ${theme.palette.secondary.dark}`,
      }
    default:
      return {
        background: 'red',
      }
  }
}

//TODO: remove when backend changes otherwise refactor
export const filterRequests = (assignment: ScheduleDaysAssignment) => {
  // const pendingRequests = assignment.requests?.filter(
  //   request => request.status === AssignmentRequestStatus.Pending
  // )

  return {
    ...assignment,
    // requests: pendingRequests,
  }
}

interface UsePillsParamsArgs {
  assignmentsCount: number
  initialPillsDisplayLimit: number
  isEditingMode?: boolean
  assignmentsToAddCount?: number
}

const EDITING_MODE_RESERVED_SLOTS_COUNT = 1
const DEFAULT_RESERVED_SLOTS_COUNT = 0
const DEFAULT_PILLS_TO_ADD_DISPLAY_LIMIT = 0
const DEFAULT_AGGREGATED_PILLS_TO_ADD_COUNT = 0

export const usePillsParams = ({
  assignmentsCount,
  initialPillsDisplayLimit,
  isEditingMode = false,
  assignmentsToAddCount = 0,
}: UsePillsParamsArgs) =>
  useMemo(() => {
    const defaultReservedCount = isEditingMode
      ? EDITING_MODE_RESERVED_SLOTS_COUNT
      : DEFAULT_RESERVED_SLOTS_COUNT
    const shouldShowMoreAssignmentPill =
      assignmentsCount > initialPillsDisplayLimit - defaultReservedCount
    const reservedPillSlotsCount = shouldShowMoreAssignmentPill
      ? defaultReservedCount + 1
      : defaultReservedCount
    const exisitngPillsDisplayLimit =
      initialPillsDisplayLimit - reservedPillSlotsCount
    const pillsToAddDisplayLimit = shouldShowMoreAssignmentPill
      ? DEFAULT_PILLS_TO_ADD_DISPLAY_LIMIT
      : exisitngPillsDisplayLimit - assignmentsCount
    const aggregatedPillsToAddCount = assignmentsToAddCount
      ? assignmentsToAddCount - pillsToAddDisplayLimit
      : DEFAULT_AGGREGATED_PILLS_TO_ADD_COUNT

    return {
      shouldShowMoreAssignmentPill,
      exisitngPillsDisplayLimit,
      pillsToAddDisplayLimit,
      aggregatedPillsToAddCount,
    }
  }, [
    assignmentsCount,
    assignmentsToAddCount,
    initialPillsDisplayLimit,
    isEditingMode,
  ])

interface GetNeedDate {
  needs: UnitNeed[]
  selectedIds: number[]
  defaultDate?: string
}

export const getEarliestNeedDate = ({
  needs,
  selectedIds,
  defaultDate,
}: GetNeedDate): Date => {
  const selectedStartDates = needs
    .filter(({ id }) => selectedIds.includes(id))
    .map(({ startDate }) => parseISO(startDate))

  if (selectedStartDates.length === 0)
    return defaultDate ? parseISO(defaultDate) : startOfToday()

  return selectedStartDates.reduce(
    (curr, acc) => (isBefore(curr, acc) ? curr : acc),
    selectedStartDates[0]
  )
}

export const getLatestNeedDate = ({
  needs,
  selectedIds,
  defaultDate,
}: GetNeedDate): Date => {
  const selectedEndDates = needs
    .filter(({ id }) => selectedIds.includes(id))
    .map(({ endDate }) => parseISO(endDate))

  if (selectedEndDates.length === 0)
    return defaultDate ? parseISO(defaultDate) : startOfToday()

  return selectedEndDates.reduce(
    (curr, acc) => (isAfter(curr, acc) ? curr : acc),
    selectedEndDates[0]
  )
}

interface ShiftData {
  id: number
  startDate: Date
  endDate: Date
}

export const matchAssignmentToAddWithShift = (
  assignment: AssignmentToAdd,
  shiftData: ShiftData
) =>
  assignment.shiftId === shiftData.id &&
  isDateEqual(assignment.startDate, shiftData.startDate) &&
  isDateEqual(assignment.endDate, shiftData.endDate)

export const isArrayEqual = <T>(x?: T[], y?: T[]) =>
  isEmpty(xorWith(x, y, isEqual))

export const useSelectedAssignments = (
  assignments: ScheduleDaysAssignment[] = []
) => {
  const { data: selectedAssignment } = useSelector(
    selectors.schedules.getSelectedAssignment
  )
  return useMemo(
    () =>
      selectedAssignment
        ? assignments.map(assignment =>
            assignment.id === selectedAssignment.id
              ? { ...assignment, userSelected: true }
              : assignment
          )
        : assignments,
    [assignments, selectedAssignment]
  )
}

export const shiftsSortingOrder: ShiftType[] = [
  ShiftType.Defined,
  ShiftType.Open,
]

const sortShifts = (a: Shift, b: Shift) =>
  shiftsSortingOrder.indexOf(a.type) - shiftsSortingOrder.indexOf(b.type)

export const useSchedulerResources = (unitId?: number) => {
  const { t } = useTranslation()
  const { loading, data } = useSelector(
    selectors.schedules.getSchedulesShiftsResource
  )
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()

  useEffect(() => {
    if (!unitId) return
    dispatch(actions.schedules.getSchedulesShifts({ careProviderId, unitId }))
  }, [dispatch, unitId, careProviderId])

  const sortedShifts = useMemo(() => [...data].sort(sortShifts), [data])

  const resources = useMemo(
    () =>
      sortedShifts.length > 0
        ? mapShiftsToResource(sortedShifts, t('booking.shifts'))
        : resourcesPlaceholder,
    [sortedShifts, t]
  )

  return {
    resources,
    shifts: sortedShifts,
    resourcesLoading: loading,
  }
}

export const getSplitedShifts = (shifts: Shift[]) => {
  const definedShifts = shifts.filter(shift => shift.type === ShiftType.Defined)
  const openShifts = shifts.filter(shift => shift.type === ShiftType.Open)

  return { openShifts, definedShifts }
}

export const getExportedAssignmentStatus = (
  needAssignmentStatus?: AssignmentStatus,
  lisitingAssignmentStatus?: LisitingAssignmentStatus
) => {
  switch (lisitingAssignmentStatus) {
    case LisitingAssignmentStatus.Canceled:
      return AssignmentStatus.Canceled
    case LisitingAssignmentStatus.Booked:
      return AssignmentStatus.BookedExternally
    default:
      return needAssignmentStatus
  }
}
