import Api from '../api'
import {
  GetSystemUpdateMessagesParams,
  GetSystemUpdateMessagesResponse,
} from './SystemMessages.types'

class SystemMessages extends Api {
  public getSystemUpdateMessages = async (
    params: GetSystemUpdateMessagesParams
  ) => {
    const response = await this.api.get<GetSystemUpdateMessagesResponse>(
      '/system-messages/update',
      { params }
    )

    const { data, ...meta } = response.data

    return { data, meta }
  }
}

export default SystemMessages
