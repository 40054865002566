import { useTranslation } from 'react-i18next'
import { ViviumX } from '../../icons'
import { AssignmentStatus } from '../../types'
import { MessageTooltipContainer } from './AssignmentPill.style'

interface ViviumXTooltipProps {
  resourceName?: string
  assignmentStatus: AssignmentStatus
  roleShortCode?: string
}

const ViviumXTooltip = ({
  roleShortCode,
  assignmentStatus,
  resourceName = '',
}: ViviumXTooltipProps) => {
  const { t } = useTranslation()
  const statusMessage =
    assignmentStatus === AssignmentStatus.BookedExternally
      ? resourceName
      : t(`scheduler.status.${assignmentStatus}`)

  const label = `${statusMessage} - ${roleShortCode}`

  return (
    <MessageTooltipContainer>
      <ViviumX fontSize="small" />
      &nbsp;&nbsp;{label}
    </MessageTooltipContainer>
  )
}

export default ViviumXTooltip
