import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute, UserRole } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.organizationAdministration,
    element: <Loadable component={() => import('./pages/Administration')} />,
  },
  {
    path: paths.organizationAdministrationNewUnit,
    allowedRoles: [UserRole.SuperCareProvider, UserRole.CareProvider],
    element: <Loadable component={() => import('./pages/NewUnit')} />,
  },
  {
    path: paths.organizationAdministrationUnit,
    allowedRoles: [
      UserRole.SuperCareProvider,
      UserRole.CareProvider,
      UserRole.UnitAdmin,
    ],
    element: <Loadable component={() => import('./pages/EditUnit')} />,
  },
  {
    path: paths.organizationAdministrationNewDepartment,
    allowedRoles: [UserRole.SuperCareProvider, UserRole.CareProvider],
    element: <Loadable component={() => import('./pages/NewDepartment')} />,
  },
  {
    path: paths.organizationAdministrationDepartment,
    allowedRoles: [UserRole.SuperCareProvider, UserRole.CareProvider],
    element: <Loadable component={() => import('./pages/EditDepartment')} />,
  },
  {
    path: paths.organizationAdministrationNewUser,
    allowedRoles: [UserRole.SuperCareProvider, UserRole.CareProvider],
    element: <Loadable component={() => import('./pages/NewUser')} />,
  },
  {
    path: paths.organizationAdministrationUser,
    allowedRoles: [UserRole.SuperCareProvider, UserRole.CareProvider],
    element: <Loadable component={() => import('./pages/User')} />,
  },
  {
    path: paths.organizationHelp,
    allowedRoles: [
      UserRole.UnitAdmin,
      UserRole.Resource,
      UserRole.CareProvider,
      UserRole.SuperCareProvider,
    ],
    element: (
      <Loadable
        component={() =>
          import('./pages/HelpAndInstructions/HelpAndInstructions')
        }
      />
    ),
  },
]

export default routes
