import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { ShiftRequest, BookedAssignment } from 'shared/types'
import {
  getBookings,
  getMoreBookings,
  getRequests,
  acceptRequest,
  rejectRequest,
  getMoreRequests,
  resetBookings,
  resetRequests,
} from './actions'
import {
  addBookingsToState,
  addRequestsToState,
  removeRequestsInSameDay,
} from './reducer.utils'

interface ShiftRequestWithActions extends ShiftRequest {
  acceptRequest: Resource
  rejectRequest: Resource
}

export interface State {
  getBookings: Resource<BookedAssignment[]>
  getMoreBookings: Resource
  getRequests: Resource<ShiftRequestWithActions[]>
  getMoreRequests: Resource
}

const initialState: State = {
  getBookings: resource.getInitial<BookedAssignment[]>([]),
  getMoreBookings: resource.getInitial(),
  getRequests: resource.getInitial<ShiftRequestWithActions[]>([]),
  getMoreRequests: resource.getInitial(),
}

const getRequest = (state: State, requestId: number) =>
  state.getRequests.data.find(({ id }) => id === requestId)

export default createReducer(initialState, builder =>
  builder
    .addCase(getBookings.pending, state => {
      resource.setPending(state.getBookings)
    })
    .addCase(getBookings.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getBookings,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getBookings.rejected, (state, action) => {
      resource.setFailed(state.getBookings, action.error.message)
    })
    .addCase(resetBookings, state => {
      resource.reset(state.getBookings, initialState.getBookings.data)
    })
    .addCase(getMoreBookings.pending, state => {
      resource.setPending(state.getMoreBookings)
    })
    .addCase(getMoreBookings.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMoreBookings)
      addBookingsToState(state, action.payload)
    })
    .addCase(getMoreBookings.rejected, (state, action) => {
      resource.setFailed(state.getMoreBookings, action.error.message)
    })
    .addCase(getRequests.pending, state => {
      resource.setPending(state.getRequests)
    })
    .addCase(getRequests.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getRequests,
        action.payload.data.map(data => ({
          ...data,
          acceptRequest: resource.getInitial(),
          rejectRequest: resource.getInitial(),
        })),
        action.payload.meta
      )
    })
    .addCase(getRequests.rejected, (state, action) => {
      resource.setFailed(state.getRequests, action.error.message)
    })
    .addCase(resetRequests, state => {
      resource.reset(state.getRequests, initialState.getRequests.data)
    })
    .addCase(acceptRequest.pending, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) resource.setPending(request.acceptRequest)
    })
    .addCase(acceptRequest.fulfilled, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) {
        resource.setSucceeded(request.acceptRequest)
        removeRequestsInSameDay(state, request)
      }
    })
    .addCase(acceptRequest.rejected, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request)
        resource.setFailed(request.acceptRequest, action.error.message)
    })
    .addCase(rejectRequest.pending, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) resource.setPending(request.rejectRequest)
    })
    .addCase(rejectRequest.fulfilled, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request) resource.setSucceeded(request.rejectRequest)
    })
    .addCase(rejectRequest.rejected, (state, action) => {
      const { requestId } = action.meta.arg
      const request = getRequest(state, requestId)
      if (request)
        resource.setFailed(request.rejectRequest, action.error.message)
    })
    .addCase(getMoreRequests.pending, state => {
      resource.setPending(state.getMoreRequests)
    })
    .addCase(getMoreRequests.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMoreRequests)
      addRequestsToState(state, action.payload)
    })
    .addCase(getMoreRequests.rejected, (state, action) => {
      resource.setFailed(state.getMoreRequests, action.error.message)
    })
)
