import { alpha, styled } from '@mui/material'

const PILL_HEIGHT = 20
const EVENT_TILE_HEIGHT_OFFSET = 33

interface TileStylesProps {
  highlight: boolean
  disabled: boolean
  maxDisplayedAssignmentsCount: number
}

export const EventsWrapper = styled('div')<TileStylesProps>(
  ({ theme, highlight, disabled, maxDisplayedAssignmentsCount }) => ({
    height: `calc(${maxDisplayedAssignmentsCount} *${PILL_HEIGHT}px + ${EVENT_TILE_HEIGHT_OFFSET}px)`,
    minWidth: theme.spacing(4.5),
    flex: '2',
    borderRight: `1px solid ${alpha(theme.palette.common.black, 0.05)}`,
    position: 'relative',
    background: disabled
      ? theme.palette.grey[200]
      : highlight
      ? alpha(theme.palette.grey[200], 0.1)
      : '',
    '&:hover': {
      background: disabled ? '' : alpha(theme.palette.grey[200], 0.1),
    },
  })
)
