import { useMemo } from 'react'
import { NeedAssignmentStatus } from '../types'
import { useEnumOptions } from './useEnumOptions'

export const useNeedAssignmentStatusOptions = () => {
  const statusOptions = useEnumOptions(NeedAssignmentStatus, 'AssignmentStatus')
  return useMemo(
    () =>
      statusOptions.filter(
        ({ value }) => value !== NeedAssignmentStatus.OtherAssignments
      ),
    [statusOptions]
  )
}
