import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AssignmentHistoryChange } from '../../types'

export const useStateText = (assignmentsChanges: AssignmentHistoryChange[]) => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      assignmentsChanges
        .map(change => t(`enums.AssignmentHistoryChange.${change}`))
        .join(' - '),
    [assignmentsChanges, t]
  )
}
