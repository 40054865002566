import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DialogProps, Button, Grid, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import {
  AutocompleteField,
  CheckboxField,
  LoadingButton,
  RadioField,
  TimeField,
  ModifiedByIcon,
  TextAreaField,
  ConfirmationModal,
} from '../../components'
import {
  useDateFns,
  useEnumOptions,
  useResourceScheduleModificationIconLabel,
  useAvailabilityTypes,
} from '../../hooks'
import {
  AbsenceReason,
  ResourceCalendarEvent,
  ResourcePeriodType,
} from '../../types'
import DividedAvailabilityInfo from './DividedAvailabilityInfo'
import {
  Dialog,
  StyledDialogContent,
  StyledForm,
} from './EditAvailabilityModal.styles'
import {
  EditAccessibilityFormFields,
  EditAccessibilityFormValues,
  useFormProps,
  useOnDelete,
  useOnUpdate,
} from './EditAvailabilityModal.utils'

interface PeriodFormProps extends Partial<DialogProps> {
  onClose: () => void
  accessibility: ResourceCalendarEvent
  open: boolean
  name?: string
  resourceId: number
  onSuccess: () => void
  resourceForm?: boolean
}

const EditAccessibilityForm = ({
  onClose,
  accessibility,
  open,
  name,
  resourceId,
  onSuccess,
  resourceForm = false,
  ...props
}: PeriodFormProps) => {
  const { t } = useTranslation()
  const dateOptions = useDateFns()

  const formProps = useFormProps(accessibility)
  const { control, reset } = formProps

  const typeOptions = useAvailabilityTypes()
  const reasonOptions = useEnumOptions(AbsenceReason, 'AbsenceReason')

  const { onUpdate, isUpdating } = useOnUpdate({
    resourceId,
    accessibility,
    onClose,
    onSuccess,
  })
  const { onDelete, isDeleting } = useOnDelete({
    resourceId,
    accessibility,
    onClose,
    onSuccess,
  })

  const [type, timeFrom, timeTo] = useWatch({
    control,
    name: [
      EditAccessibilityFormFields.Type,
      EditAccessibilityFormFields.TimeFrom,
      EditAccessibilityFormFields.TimeTo,
    ],
  }) as [
    EditAccessibilityFormValues[EditAccessibilityFormFields.Type],
    EditAccessibilityFormValues[EditAccessibilityFormFields.TimeFrom],
    EditAccessibilityFormValues[EditAccessibilityFormFields.TimeTo]
  ]

  const { createdBy, modifiedBy, startDate, endDate } = accessibility
  const modificationIconLabel = useResourceScheduleModificationIconLabel({
    createdBy,
    modifiedBy,
    type,
  })
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  useEffect(() => {
    if (!open) reset()
  }, [open, reset])

  return (
    <>
      <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open} {...props}>
        <StyledDialogContent>
          <StyledForm onSubmit={onUpdate} {...formProps}>
            <Grid container spacing={3}>
              <Grid container item xs={12} alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body1" display="inline">
                    <b>
                      {name
                        ? `${format(
                            parseISO(startDate),
                            'dd/M',
                            dateOptions
                          )} - ${name}`
                        : format(parseISO(startDate), 'dd/M', dateOptions)}
                    </b>
                  </Typography>
                </Grid>

                {modificationIconLabel && (
                  <>
                    <Grid item lineHeight={0}>
                      <ModifiedByIcon tooltip={false} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" display="inline">
                        <b>{`${t(
                          'resourceAccessibility.modifiedBy'
                        )}: ${modificationIconLabel}`}</b>
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <RadioField
                      row
                      name={EditAccessibilityFormFields.Type}
                      options={typeOptions}
                    />
                  </Grid>
                  {type === ResourcePeriodType.Absence && (
                    <Grid item xs={12}>
                      <AutocompleteField
                        name={EditAccessibilityFormFields.Reason}
                        options={reasonOptions}
                        placeholder={t('availabilityModal.reasonPlaceholder')}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TimeField
                  name={EditAccessibilityFormFields.TimeFrom}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={4}>
                <TimeField
                  name={EditAccessibilityFormFields.TimeTo}
                  variant="standard"
                />
              </Grid>
              {type === ResourcePeriodType.Availability && (
                <Grid item xs={4}>
                  <CheckboxField
                    name={EditAccessibilityFormFields.IsOpenForOvertime}
                    label={t('availabilityModal.isOpenForOvertimeShort')}
                  />
                </Grid>
              )}
              {resourceForm && (
                <Grid item xs={12}>
                  <TextAreaField
                    name={EditAccessibilityFormFields.Note}
                    label={t('availabilityModal.note')}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <DividedAvailabilityInfo
                  timeFrom={timeFrom}
                  timeTo={timeTo}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Button onClick={onClose} color="primary" fullWidth={false}>
                      {t('availabilityModal.cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      {!resourceForm && (
                        <Grid item>
                          <LoadingButton
                            color="primary"
                            variant="outlined"
                            fullWidth={false}
                            loading={isDeleting}
                            onClick={onDelete}
                          >
                            {t('form.delete')}
                          </LoadingButton>
                        </Grid>
                      )}
                      <Grid item>
                        <LoadingButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth={false}
                          loading={isUpdating}
                        >
                          {t('form.save')}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledForm>
        </StyledDialogContent>
      </Dialog>
      <ConfirmationModal
        open={isConfirmationModalOpen && resourceForm}
        mainContent={t('resourceCalendar.periodForm.deleteConfirmation')}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirmation={() => onDelete}
        confirmationButtonLabel={t(
          'resourceCalendar.periodForm.deleteAvailability'
        )}
      />
    </>
  )
}

export default EditAccessibilityForm
