import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckboxFieldProps } from '../../CheckboxField'
import { StyledCheckboxField } from '../AssignmentModal.style'

interface CheckboxProps extends CheckboxFieldProps {
  selectedField: string
  onFieldChange: (name: string) => void
}

const Checkbox = ({
  selectedField,
  onFieldChange,
  name,
  ...props
}: CheckboxProps) => {
  const { setValue, getValues } = useFormContext()
  const isChecked = name === selectedField || !!getValues(name)

  useEffect(() => {
    setValue(name, name === selectedField)
  }, [selectedField, setValue, name])

  const handleChange = () => onFieldChange(name === selectedField ? '' : name)

  return (
    <StyledCheckboxField
      name={name}
      checked={isChecked}
      onChange={handleChange}
      {...props}
    />
  )
}

export default Checkbox
