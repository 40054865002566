import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from '../../../hooks'
import { actions, selectors } from '../../../store'
import { Row } from '../../../types'

export const useExpandRow = (
  row: Row,
  shouldCollapseExpandComponents: boolean
) => {
  const dispatch = useDispatch()
  const expandRows = useSelector(selectors.tables.getExpandRows)
  const expandRow = expandRows.find(expandRow => row.id === expandRow?.id)

  const [isOpen, setIsOpen] = useState(!!expandRow)
  const [isExpandComponentVisible, setIsExpandComponentVisible] =
    useState(isOpen)

  useEffect(() => {
    if (shouldCollapseExpandComponents) {
      dispatch(actions.tables.resetExpandRows())
    }
  }, [dispatch, shouldCollapseExpandComponents])

  useEffect(() => {
    if (isOpen) {
      setIsExpandComponentVisible(true)
    }
  }, [isOpen])

  const handleExpandRow = useCallback(() => {
    setIsOpen(!isOpen)
    dispatch(
      isOpen
        ? actions.tables.removeExpandRow(row)
        : actions.tables.addExpandRow(row)
    )
  }, [dispatch, isOpen, row])

  const hideExpandComponent = () => setIsExpandComponentVisible(false)

  return {
    isOpen,
    handleExpandRow,
    isExpandComponentVisible,
    hideExpandComponent,
  }
}
