import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns'
import {
  ScheduleDays,
  Availability,
  AssignmentStatus,
  MatchingResource,
  ScheduleDaysAssignment,
  Entity,
  BookedResource,
  MatchingAssignment,
  ShiftTimeType,
  Shift,
  ShiftType,
} from 'shared/types'
import { mapDaysAfterAssignmentChange } from 'shared/utils'

const TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_ASSIGNMENT_ID = 0
const NOTE_TIME_FORMAT = 'HH:mm'
const DEFAULT_SHIFTS_NUMBER = 2

const DEFAULT_SHIFT: Shift = {
  id: 1,
  name: '',
  startTime: '',
  endTime: '',
  hasBreak: false,
  breakMinutes: 0,
  hasMealBreak: false,
  mealBreakMinutes: 0,
  timeType: ShiftTimeType.Day,
  type: ShiftType.Defined,
}

export const DEFAULT_SHIFTS = Array.from(
  { length: DEFAULT_SHIFTS_NUMBER },
  _ => DEFAULT_SHIFT
)

export const getParsedAvailabilityDates = (
  startDate: string,
  endDate?: string
) => {
  const parsedStartDate = parseISO(startDate)

  return !!endDate
    ? { endDate: parseISO(endDate), startDate: parsedStartDate }
    : {
        endDate: startOfDay(addDays(parsedStartDate, 1)),
        startDate: parsedStartDate,
      }
}

const getAssignmentPropsFromAvailability = (availabilities: Availability[]) => {
  const lastAvailability = availabilities.slice(-1)[0]

  return {
    hasOvertime: !!lastAvailability?.openForOvertime,
    resourceNote: availabilities
      .filter(({ note }) => !!note)
      .map(({ note, ...availability }) => {
        const { startDate, endDate } = getParsedAvailabilityDates(
          availability.startDate,
          availability.endDate
        )

        return `${format(startDate, NOTE_TIME_FORMAT)}-${format(
          endDate,
          NOTE_TIME_FORMAT
        )}: ${note}`
      })
      .join('\n'),
  }
}

const getAvailabilityAssignments = (
  availabilities: Availability[],
  shifts: Shift[],
  resource: Entity
): ScheduleDaysAssignment[] => {
  if (!availabilities.length) return []

  return shifts.reduce((previous, current) => {
    const shiftStartDate = parse(
      current.startTime,
      TIME_FORMAT,
      parseISO(availabilities[0].startDate)
    )

    const shiftEndDate = parse(
      current.endTime,
      TIME_FORMAT,
      parseISO(availabilities[0].startDate)
    )
    const matchingAvailability = availabilities.find(
      ({ startDate, endDate }) =>
        !isBefore(shiftStartDate, parseISO(startDate)) &&
        !isAfter(shiftEndDate, parseISO(endDate))
    )

    if (!matchingAvailability) return previous

    const { startDate, endDate } = getParsedAvailabilityDates(
      matchingAvailability.startDate,
      matchingAvailability.endDate
    )

    const newAssignment: ScheduleDaysAssignment = {
      id: DEFAULT_ASSIGNMENT_ID,
      shiftId: current.id,
      resourceNote: matchingAvailability.note || '',
      endDate: endDate.toISOString(),
      startDate: startDate.toISOString(),
      resource: resource,
      status: AssignmentStatus.Available,
      type: ShiftType.Defined,
    }

    return [...previous, newAssignment]
  }, [] as ScheduleDaysAssignment[])
}

export const mapAvailabilitiesAndRequestsToDay = (
  day: ScheduleDays,
  selectedResource: MatchingResource,
  shifts: Shift[]
): ScheduleDays => {
  const availabilities = selectedResource.availabilities.filter(
    ({ startDate }) => isSameDay(parseISO(day.date), parseISO(startDate))
  )
  const resourceEntity: Entity = {
    id: selectedResource.id,
    name: selectedResource.name,
  }
  const availabilityAssignments = getAvailabilityAssignments(
    availabilities,
    shifts,
    resourceEntity
  )
  const assignmentsWithRequestables = day.assignments.map(assignment =>
    selectedResource.matchingAssignments.requestableAssignments?.some(
      requestableAssignment => assignment.id === requestableAssignment.id
    )
      ? {
          ...assignment,
          status: AssignmentStatus.Requestable,
          resource: {
            id: selectedResource.id,
            name: selectedResource.name,
          },
        }
      : assignment
  )

  const filteredAvailabilityAssignments = availabilityAssignments.filter(
    availability =>
      !assignmentsWithRequestables.some(
        assignment =>
          assignment.shiftId === availability.shiftId &&
          availability.resource?.id === assignment.resource?.id
      )
  )

  return {
    ...day,
    assignments: [
      ...assignmentsWithRequestables,
      ...filteredAvailabilityAssignments,
    ],
  }
}

export const mapRequestableDatesToDays = (
  daysData: ScheduleDays[],
  selectedResource: MatchingResource,
  shifts: Shift[]
) => {
  return daysData.map(day => {
    const dayDate = parseISO(day.date)
    const hasAvailabilityDates =
      selectedResource.availabilities.some(date =>
        isSameDay(parseISO(date.startDate), dayDate)
      ) ||
      selectedResource.matchingAssignments.requestableAssignments?.some(date =>
        isSameDay(parseISO(date.startDate), dayDate)
      ) //TODO: remove second some after backend is fixed

    return hasAvailabilityDates
      ? mapAvailabilitiesAndRequestsToDay(day, selectedResource, shifts)
      : day
  })
}

const mapBookableDatesToAssignments = (
  assignments: ScheduleDaysAssignment[],
  selectedResource: MatchingResource
): ScheduleDaysAssignment[] =>
  assignments.map(assignment => {
    const matchingAssignment =
      selectedResource.matchingAssignments.bookableAssignments?.find(
        bookableAssignment => assignment.id === bookableAssignment.id
      )

    return !!matchingAssignment
      ? {
          ...assignment,
          status: AssignmentStatus.Bookable,
          resource: {
            id: selectedResource.id,
            name: selectedResource.name,
          },
          ...getAssignmentPropsFromAvailability(
            matchingAssignment.availabilities
          ),
        }
      : assignment
  })

export const mapBookableDatesToDays = (
  daysData: ScheduleDays[],
  selectedResource: MatchingResource
): ScheduleDays[] =>
  daysData.map(day => ({
    ...day,
    assignments: mapBookableDatesToAssignments(
      day.assignments,
      selectedResource
    ),
  }))

export const displayBookedResource = (
  daysData: ScheduleDays[],
  bookedResource: BookedResource | null
) =>
  daysData.map(day => ({
    ...day,
    assignments: day.assignments.reduce(
      (acc, assignment) =>
        assignment.resource?.id === bookedResource?.id
          ? [...acc, assignment]
          : acc,
      [] as ScheduleDaysAssignment[]
    ),
  }))

interface MapAvailabilitiesToDaysOptions {
  daysData: ScheduleDays[]
  selectedResource: MatchingResource | null
  selectedBookedResource: BookedResource | null
  selectedAssignments: MatchingAssignment[] | null
  shifts: Shift[]
}

export const mapAvailabilitiesToDays = ({
  daysData = [],
  selectedResource,
  selectedBookedResource,
  selectedAssignments,
  shifts,
}: MapAvailabilitiesToDaysOptions): ScheduleDays[] => {
  if (selectedResource && selectedAssignments)
    return mapSelectedAssignmentsToDays(
      daysData,
      selectedResource,
      selectedAssignments
    )

  if (selectedResource?.matchingAssignments.bookableAssignments?.length)
    return mapBookableDatesToDays(daysData, selectedResource)

  if (selectedResource?.matchingAssignments.requestableAssignments?.length)
    return mapRequestableDatesToDays(daysData, selectedResource, shifts)

  if (selectedBookedResource)
    return displayBookedResource(daysData, selectedBookedResource)

  return daysData
}

export const mapSelectedAssignmentsToDays = (
  daysData: ScheduleDays[],
  selectedResource: MatchingResource,
  selectedAssignments: MatchingAssignment[]
) =>
  mapDaysAfterAssignmentChange(daysData, assignment =>
    selectedAssignments.some(selected => selected.id === assignment.id)
      ? {
          ...assignment,
          resource: { id: selectedResource.id, name: selectedResource.name },
          status: selectedResource.matchingAssignments.bookableAssignments
            ?.length
            ? AssignmentStatus.Bookable
            : AssignmentStatus.Requestable,
        }
      : assignment
  )

export const getNonRequestableResource = (
  resource: MatchingResource
): MatchingResource => ({
  ...resource,
  matchingAssignments: {
    bookableAssignmentsCount: 0,
    requestableAssignmentsCount: 0,
    bookableAssignments: [],
    requestableAssignments: [],
  },
  availabilities: [],
})
