import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { object, SchemaOf, boolean, string, array, number } from 'yup'
import {
  AssignmentDetailedInfo,
  ResourceUnassignmentReason,
} from '../../../types'
import { FormProps } from '../../Form'
import { ModalFormSubmitProps } from '../AssignmentModal.types'
import { useModalProps } from '../AssignmentModal.utils'

export type OnBookedAssignmentChange = (
  assignment: AssignmentDetailedInfo,
  formValues: BookedAssignmentContentValues
) => void
export interface SubmitProps extends ModalFormSubmitProps {
  updateScheduler: OnBookedAssignmentChange
}

export enum BookedAssignmentContentFields {
  Every = 'every',
  Shifts = 'shifts',
  Notes = 'notes',
  ResourceNote = 'resourceNote',
  Overtime = 'overtime',
  Absence = 'absence',
  Active = 'active',
  Reason = 'reason',
}

export interface BookedAssignmentContentValues {
  [BookedAssignmentContentFields.Every]: boolean
  [BookedAssignmentContentFields.Shifts]: number[]
  [BookedAssignmentContentFields.Notes]: string
  [BookedAssignmentContentFields.Overtime]: boolean
  [BookedAssignmentContentFields.Absence]: boolean
  [BookedAssignmentContentFields.Active]: boolean
  [BookedAssignmentContentFields.Reason]: ResourceUnassignmentReason
}

export const useBookedAssignmentDefaultValues =
  (): BookedAssignmentContentValues => {
    const { needAssignment } = useModalProps()
    return {
      [BookedAssignmentContentFields.Every]: false,
      [BookedAssignmentContentFields.Shifts]: needAssignment
        ? [needAssignment.id]
        : [],
      [BookedAssignmentContentFields.Notes]: needAssignment?.note || '',
      [BookedAssignmentContentFields.Overtime]: !!needAssignment?.hasOvertime,
      [BookedAssignmentContentFields.Absence]: !!needAssignment?.hasAbsence,
      [BookedAssignmentContentFields.Active]: false,
      [BookedAssignmentContentFields.Reason]: ResourceUnassignmentReason.Closed,
    }
  }

export const useValidationSchema =
  (): SchemaOf<BookedAssignmentContentValues> => {
    const { t } = useTranslation()
    return object()
      .shape({
        [BookedAssignmentContentFields.Every]: boolean(),
        [BookedAssignmentContentFields.Shifts]: array()
          .of(number())
          .min(1, t('validation.required')),
        [BookedAssignmentContentFields.Notes]: string(),
        [BookedAssignmentContentFields.Overtime]: boolean(),
        [BookedAssignmentContentFields.Absence]: boolean(),
        [BookedAssignmentContentFields.Active]: boolean(),
        [BookedAssignmentContentFields.Reason]: string().oneOf(
          Object.values(ResourceUnassignmentReason)
        ),
      })
      .required()
  }

export const useOnSubmit = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { needAssignment, handleClose, onBookedAssignmentChange } =
    useModalProps()

  return (values: BookedAssignmentContentValues) => {
    if (!needAssignment) {
      return enqueueSnackbar(t('error.missingRequestData'))
    }
    onBookedAssignmentChange(needAssignment, values)
    handleClose()
  }
}
export const useFormProps = (): FormProps<BookedAssignmentContentValues> => {
  const schema = useValidationSchema()
  const onSubmit = useOnSubmit()
  const defaultValues = useBookedAssignmentDefaultValues()
  const methods = useForm<BookedAssignmentContentValues>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  return { ...methods, onSubmit }
}
