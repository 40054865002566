import { useTranslation } from 'react-i18next'
import {
  SentimentNeutral,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
} from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import { AdminMessageFeedbackRateType } from '../../../../types'
import { StyledFeedbackButton } from '../UpdateMessageModal.style'
import { useFeedbackSection } from './FeedbackSection.utils'

const feedbackIconMap = {
  [AdminMessageFeedbackRateType.Positive]: SentimentSatisfiedAlt,
  [AdminMessageFeedbackRateType.Neutral]: SentimentNeutral,
  [AdminMessageFeedbackRateType.Negative]: SentimentVeryDissatisfied,
}

interface FeedbackButtonProps {
  rate: AdminMessageFeedbackRateType
}

export const FeedbackButton = ({ rate }: FeedbackButtonProps) => {
  const { t } = useTranslation()
  const { sendAdminMessageFeedback } = useFeedbackSection()

  if (!sendAdminMessageFeedback) return null

  const Icon = feedbackIconMap[rate]

  return (
    <Tooltip title={t(`enums.FeedbackRateType.${rate}`)}>
      <StyledFeedbackButton onClick={() => sendAdminMessageFeedback(rate)}>
        <Icon fontSize="large" color="primary" />
      </StyledFeedbackButton>
    </Tooltip>
  )
}

export const TextContent = () => {
  const { t } = useTranslation()
  const { shouldShowForm } = useFeedbackSection()
  const TextLine = (text: string) => (
    <Typography variant="caption" fontWeight="500" textAlign="center">
      {text}
    </Typography>
  )

  if (shouldShowForm)
    return (
      <>
        {TextLine(t('adminMessage.feedback.questionLineOne'))}
        {TextLine(t('adminMessage.feedback.questionLineTwo'))}
      </>
    )

  return TextLine(t('adminMessage.feedback.thanks'))
}
