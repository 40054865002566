import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Control,
  useForm,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { format, parse } from 'date-fns'
import { useSnackbar } from 'notistack'
import { array, boolean, mixed, number, object, SchemaOf, string } from 'yup'
import {
  useGetSpecialtyOptions,
  useDispatch,
  useSelector,
  useAssignmentModal,
  useGetRoleOptions,
  useGetCareProviderNeedsOptions,
  useOrganizationId,
  useParamsUnitId,
} from '../../../hooks'
import { actions, selectors } from '../../../store'
import {
  AutocompleteValue,
  LoadingStatus,
  OpenShiftNeedType,
  NeedStatus,
} from '../../../types'
import {
  getAutocompleteValue,
  getAutocompleteValues,
  parseDate,
} from '../../../utils'
import { validateEmptyStringToNull } from '../../../utils'
import { FormProps } from '../../Form'

export const TIME_FORMAT = 'HH:mm'
export const MIN_BREAK_DURATION = 0

export enum OpenShiftAssignmentFields {
  Role = 'role',
  Specialties = 'specialties',
  NeedType = 'needType',
  Need = 'need',
  StartTime = 'startTime',
  EndTime = 'endTime',
  Break = 'break',
  MealBreak = 'mealBreak',
  BreakDuration = 'breakDuration',
  Notes = 'notes',
}

export interface OpenShiftAssignmentValues {
  [OpenShiftAssignmentFields.Role]: AutocompleteValue
  [OpenShiftAssignmentFields.Specialties]: AutocompleteValue[] | []
  [OpenShiftAssignmentFields.NeedType]: OpenShiftNeedType
  [OpenShiftAssignmentFields.Need]: AutocompleteValue
  [OpenShiftAssignmentFields.StartTime]: string
  [OpenShiftAssignmentFields.EndTime]: string
  [OpenShiftAssignmentFields.Break]: boolean
  [OpenShiftAssignmentFields.MealBreak]: boolean
  [OpenShiftAssignmentFields.BreakDuration]: number | string
  [OpenShiftAssignmentFields.Notes]: string
}

export const useValidationSchema = (): SchemaOf<OpenShiftAssignmentValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [OpenShiftAssignmentFields.Role]: mixed<AutocompleteValue>().required(
        t('validation.required')
      ),
      [OpenShiftAssignmentFields.Specialties]: array().of(
        mixed<AutocompleteValue>()
      ),
      [OpenShiftAssignmentFields.NeedType]: string().required(
        t('validation.required')
      ),
      [OpenShiftAssignmentFields.Need]: mixed<AutocompleteValue>().when(
        OpenShiftAssignmentFields.NeedType,
        {
          is: OpenShiftNeedType.ExistingNeed,
          then: mixed<AutocompleteValue>()
            .nullable()
            .required(t('validation.required')),
          otherwise: mixed<AutocompleteValue>().nullable(),
        }
      ),
      [OpenShiftAssignmentFields.StartTime]: string().required(
        t('validation.required')
      ),
      [OpenShiftAssignmentFields.EndTime]: string()
        .required(t('validation.required'))
        .test(
          'end-time-after-start-time',
          t('openShiftModal.endTimeValidation'),
          function (value) {
            return Boolean(
              value && value > this.parent[OpenShiftAssignmentFields.StartTime]
            )
          }
        ),
      [OpenShiftAssignmentFields.Break]: boolean(),
      [OpenShiftAssignmentFields.MealBreak]: boolean(),
      [OpenShiftAssignmentFields.BreakDuration]: number().when(
        [OpenShiftAssignmentFields.MealBreak, OpenShiftAssignmentFields.Break],
        {
          is: (MealBreak: boolean, Break: boolean) => MealBreak || Break,
          then: schema =>
            schema
              .min(
                MIN_BREAK_DURATION,
                t('validation.valueShoudBeGreater', {
                  value: MIN_BREAK_DURATION,
                })
              )
              .typeError(t('validation.required')),
          otherwise: schema =>
            schema.transform(validateEmptyStringToNull).nullable(),
        }
      ),
      [OpenShiftAssignmentFields.Notes]: string(),
    })
    .required()
}

interface UseOnSubmitOptions {
  refreshScheduler?: () => void
  handleClose: () => void
  shiftDate?: Date | null
  shiftId?: number
}

export const useOnSubmit = ({
  refreshScheduler,
  handleClose,
  shiftDate,
  shiftId,
}: UseOnSubmitOptions) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const unitId = useParamsUnitId()
  const { enqueueSnackbar } = useSnackbar()
  const { needAssignment } = useAssignmentModal()

  const createLoading = useSelector(
    selectors.schedules.createOpenShiftAssignmentLoading
  )

  const editLoading = useSelector(
    selectors.schedules.editOpenShiftAssignmentLoading
  )

  const isSubmitting = useMemo(
    () => [createLoading, editLoading].includes(LoadingStatus.Pending),
    [createLoading, editLoading]
  )

  const onSuccess = useCallback(
    (message: string) => {
      if (refreshScheduler) refreshScheduler()
      handleClose()
      enqueueSnackbar(message, { variant: 'success' })
    },
    [enqueueSnackbar, handleClose, refreshScheduler]
  )

  const onFailure = useCallback(
    (message?: string) => {
      enqueueSnackbar(message || t('error.defaultMessage'), {
        variant: 'error',
      })
    },
    [enqueueSnackbar, t]
  )

  const onCreate = useCallback(
    (values: OpenShiftAssignmentValues) => {
      if (!shiftDate || !shiftId) return

      const startDate = parse(
        values[OpenShiftAssignmentFields.StartTime],
        TIME_FORMAT,
        shiftDate
      )

      const endDate = parse(
        values[OpenShiftAssignmentFields.EndTime],
        TIME_FORMAT,
        shiftDate
      )

      dispatch(
        actions.schedules.createOpenShiftAssignment({
          onFailure,
          onSuccess: () => onSuccess(t('openShiftModal.createSuccess')),
          careProviderId,
          shiftId,
          startDate,
          endDate,
          hasBreak: values[OpenShiftAssignmentFields.Break],
          hasMealBreak: values[OpenShiftAssignmentFields.MealBreak],
          breakMinutes: values[OpenShiftAssignmentFields.BreakDuration] || 0,
          note: values[OpenShiftAssignmentFields.Notes],
          ...(values[OpenShiftAssignmentFields.NeedType] ===
            OpenShiftNeedType.ExistingNeed &&
            values[OpenShiftAssignmentFields.Need]?.value && {
              needId: values[OpenShiftAssignmentFields.Need]?.value as number,
            }),
          ...(values[OpenShiftAssignmentFields.NeedType] ===
            OpenShiftNeedType.NewNeed && {
            need: {
              unitId,
              roleId: values[OpenShiftAssignmentFields.Role]?.value as number,
              specialtyIds: values[OpenShiftAssignmentFields.Specialties].map(
                specialty => specialty?.value
              ) as number[],
            },
          }),
        })
      )
    },
    [
      careProviderId,
      dispatch,
      onFailure,
      onSuccess,
      shiftDate,
      shiftId,
      t,
      unitId,
    ]
  )

  const onEdit = useCallback(
    (values: OpenShiftAssignmentValues) => {
      if (!needAssignment || !shiftDate || !shiftId) return

      const startDate = parse(
        values[OpenShiftAssignmentFields.StartTime],
        TIME_FORMAT,
        shiftDate
      )

      const endDate = parse(
        values[OpenShiftAssignmentFields.EndTime],
        TIME_FORMAT,
        shiftDate
      )

      dispatch(
        actions.schedules.editOpenShiftAssignment({
          onSuccess: () => onSuccess(t('openShiftModal.editSuccess')),
          onFailure,
          careProviderId,
          assignmentId: needAssignment.id,
          shiftId,
          startDate,
          endDate,
          hasBreak: values[OpenShiftAssignmentFields.Break],
          hasMealBreak: values[OpenShiftAssignmentFields.MealBreak],
          breakMinutes: values[OpenShiftAssignmentFields.BreakDuration] || 0,
          note: values[OpenShiftAssignmentFields.Notes],
          ...(values[OpenShiftAssignmentFields.NeedType] ===
            OpenShiftNeedType.ExistingNeed &&
            values[OpenShiftAssignmentFields.Need]?.value && {
              needId: values[OpenShiftAssignmentFields.Need]?.value as number,
            }),
          ...(values[OpenShiftAssignmentFields.NeedType] ===
            OpenShiftNeedType.NewNeed && {
            need: {
              unitId,
              roleId: values[OpenShiftAssignmentFields.Role]?.value as number,
              specialtyIds: values[OpenShiftAssignmentFields.Specialties].map(
                specialty => specialty?.value
              ) as number[],
            },
          }),
        })
      )
    },
    [
      needAssignment,
      careProviderId,
      dispatch,
      onFailure,
      onSuccess,
      shiftDate,
      shiftId,
      t,
      unitId,
    ]
  )

  return { onSubmit: needAssignment ? onEdit : onCreate, isSubmitting }
}

const useDefaultValues = () => {
  const { needAssignment } = useAssignmentModal()
  return {
    [OpenShiftAssignmentFields.Role]: needAssignment?.need?.role
      ? getAutocompleteValue(needAssignment?.need?.role)
      : null,
    [OpenShiftAssignmentFields.Specialties]: needAssignment?.need?.specialties
      ? getAutocompleteValues(needAssignment?.need?.specialties)
      : [],
    [OpenShiftAssignmentFields.NeedType]: OpenShiftNeedType.ExistingNeed,
    [OpenShiftAssignmentFields.Need]: needAssignment?.need
      ? getAutocompleteValue(needAssignment?.need)
      : null,
    [OpenShiftAssignmentFields.StartTime]: needAssignment
      ? format(parseDate(needAssignment.startDate), 'HH:mm')
      : '',
    [OpenShiftAssignmentFields.EndTime]: needAssignment
      ? format(parseDate(needAssignment.endDate), 'HH:mm')
      : '',
    [OpenShiftAssignmentFields.Break]: needAssignment?.hasBreak || false,
    [OpenShiftAssignmentFields.MealBreak]:
      needAssignment?.hasMealBreak || false,
    [OpenShiftAssignmentFields.BreakDuration]:
      needAssignment?.breakMinutes || '',
    [OpenShiftAssignmentFields.Notes]: needAssignment?.note || '',
  }
}

export const useFormProps = (): Omit<
  FormProps<OpenShiftAssignmentValues>,
  'onSubmit'
> => {
  const schema = useValidationSchema()
  const defaultValues = useDefaultValues()
  const methods = useForm<OpenShiftAssignmentValues>({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  return methods
}

export const useOpenShiftNeed = (
  setValue: UseFormSetValue<OpenShiftAssignmentValues>,
  trigger: UseFormTrigger<OpenShiftAssignmentValues>
) => {
  const defaultValues = useDefaultValues()
  const [needType, setNeedType] = useState(
    defaultValues[OpenShiftAssignmentFields.NeedType]
  )
  const handleNeedTypeChange = (e: ChangeEvent<HTMLInputElement>) =>
    setNeedType(e.target.value as OpenShiftNeedType)

  const isNewNeedOptionSelected = useMemo(
    () => needType !== OpenShiftNeedType.ExistingNeed,
    [needType]
  )

  useEffect(() => {
    return () => {
      setNeedType(defaultValues[OpenShiftAssignmentFields.NeedType])
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    setValue(OpenShiftAssignmentFields.NeedType, needType, {
      shouldDirty: true,
    })
    if (needType === OpenShiftNeedType.NewNeed)
      trigger(OpenShiftAssignmentFields.Need)
  }, [needType, setValue, trigger])

  return {
    needType,
    isNewNeedOptionSelected,
    handleNeedTypeChange,
  }
}

export const useOpenShiftModalAutocompletes = (
  setValue: UseFormSetValue<OpenShiftAssignmentValues>,
  control: Control<OpenShiftAssignmentValues, object>,
  isDirty: boolean
) => {
  const unitId = useParamsUnitId()
  const [role, specialties, needType] = useWatch({
    control,
    name: [
      OpenShiftAssignmentFields.Role,
      OpenShiftAssignmentFields.Specialties,
      OpenShiftAssignmentFields.NeedType,
    ],
  }) as [
    OpenShiftAssignmentValues[OpenShiftAssignmentFields.Role],
    OpenShiftAssignmentValues[OpenShiftAssignmentFields.Specialties],
    OpenShiftAssignmentValues[OpenShiftAssignmentFields.NeedType]
  ]

  useEffect(() => {
    if (isDirty) setValue(OpenShiftAssignmentFields.Specialties, [])
  }, [setValue, role]) //eslint-disable-line

  useEffect(() => {
    if (isDirty) setValue(OpenShiftAssignmentFields.Need, null)
  }, [setValue, role, specialties, needType]) //eslint-disable-line

  const roleIds = useMemo(
    () => (role?.value ? [role.value] : []),
    [role?.value]
  )

  const getSpecialtyOptions = useGetSpecialtyOptions({ roleIds })
  const getRoleOptions = useGetRoleOptions()
  const getNeedsOptions = useGetCareProviderNeedsOptions({
    needStatus: NeedStatus.Published,
    unitId,
    roleId: role?.value,
    specialtyIds: specialties.map(specialty => specialty?.value),
  })

  const isRoleEmpty = useMemo(() => !role, [role])

  return {
    getSpecialtyOptions,
    getRoleOptions,
    getNeedsOptions,
    isRoleEmpty,
  }
}

export const useBreakDuration = (
  setValue: UseFormSetValue<OpenShiftAssignmentValues>,
  control: Control<OpenShiftAssignmentValues, object>
) => {
  const defaultValues = useDefaultValues()
  const [normalBreak, mealBreak] = useWatch({
    control,
    name: [
      OpenShiftAssignmentFields.Break,
      OpenShiftAssignmentFields.MealBreak,
    ],
  }) as [
    OpenShiftAssignmentValues[OpenShiftAssignmentFields.Break],
    OpenShiftAssignmentValues[OpenShiftAssignmentFields.MealBreak]
  ]

  const isBreakDurationDisabled = useMemo(
    () => !(normalBreak || mealBreak),
    [mealBreak, normalBreak]
  )

  useEffect(() => {
    if (isBreakDurationDisabled) {
      setValue(
        OpenShiftAssignmentFields.BreakDuration,
        defaultValues[OpenShiftAssignmentFields.BreakDuration]
      )
    }
  }, [isBreakDurationDisabled]) //eslint-disable-line

  return isBreakDurationDisabled
}
