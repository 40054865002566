import { Components } from '@mui/material'

const MuiInputLabel: Components['MuiInputLabel'] = {
  defaultProps: { shrink: true },
  styleOverrides: {
    root: {
      fontSize: '0.625rem',
      letterSpacing: 1.5,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    shrink: { transform: 'translate(0, 5px) scale(1)' },
  },
}

export default MuiInputLabel
