import { Components } from '@mui/material'

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      '& .MuiSvgIcon-root': {
        zIndex: 1,
      },

      '& .PrivateSwitchBase-input': {
        width: 'auto',
        height: 'auto',
        top: 'auto',
        left: 'auto',
        opacity: '1',
        visibility: 'hidden',

        '&::before': {
          content: '""',
          position: 'absolute',
          background: 'white',
          height: '100%',
          width: '100%',
          visibility: 'visible',
        },
      },
    },
  },
}

export default MuiCheckbox
