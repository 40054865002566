import { Button, Fab, styled } from '@mui/material'
import ButtonLink from '../../../ButtonLink'

export const StyledButton = styled(props => (
  <Button {...props} component={ButtonLink} />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.primary.main,
  '&:hover': { backgroundColor: '#DDDDDD' },
})) as typeof Button

export const StyledFab = styled(props => (
  <Fab {...props} component={ButtonLink} />
))(({ theme }) => ({
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.contrastText,
  position: 'fixed',
  bottom: theme.spacing(2.5),
  right: theme.spacing(1),
})) as typeof Button

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
}))
