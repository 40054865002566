import {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from '@mui/icons-material'
import { ChipProps, Grid, Tooltip } from '@mui/material'
import { useAssignmentNotification } from '../../hooks'
import { AssignmentStatus, ScheduleDaysAssignment } from '../../types'
import { filterRequests } from '../../utils'
import PillLabel from '../PillLabel'
import {
  Pill,
  StyledBadge,
  Container,
  StyledClear,
} from './AssignmentPill.style'
import { useLabelProps, usePillTooltip } from './AssignmentPill.utils'

interface AssignmentPillProps extends Partial<ChipProps> {
  assignment: ScheduleDaysAssignment
  showNotifications?: boolean
  showUnitName?: boolean
  shorthand?: boolean
  editingMode?: boolean
  onClick?: (anchor: MouseEvent<HTMLElement>) => void
}

const AssignmentPill = ({
  onClick,
  assignment,
  showNotifications = false,
  showUnitName = false,
  shorthand = false,
  editingMode = false,
  ...props
}: AssignmentPillProps) => {
  const { t } = useTranslation()
  const { unreadNotifications, markAsRead } = useAssignmentNotification(
    assignment.id
  )
  const filteredAssignment: ScheduleDaysAssignment = useMemo(
    () => filterRequests(assignment),
    [assignment]
  )
  const [isPillHovered, setIsPillHovered] = useState(false)
  const shouldShowDeleteState = useMemo(
    () =>
      editingMode &&
      isPillHovered &&
      assignment.status === AssignmentStatus.Empty,
    [assignment.status, editingMode, isPillHovered]
  )
  const tooltip = usePillTooltip({
    assignment: filteredAssignment,
    hasUnitName: showUnitName,
    shouldShowDeleteState: shouldShowDeleteState,
  })

  const { hasMessageIcon, isIconVisible, hasXIcon } = useLabelProps(assignment)

  const InfoIcon = useMemo(
    () => (hasMessageIcon ? <Message /> : <div>{t('scheduler.overtime')}</div>),
    [hasMessageIcon, t]
  )

  const isAssignmentAvailable = assignment.status === AssignmentStatus.Available

  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    event => {
      markAsRead()
      if (onClick && !isAssignmentAvailable) onClick(event as any)
    },
    [markAsRead, onClick, isAssignmentAvailable]
  )

  return (
    <Container
      onMouseEnter={() => setIsPillHovered(true)}
      onMouseLeave={() => setIsPillHovered(false)}
    >
      <Tooltip title={tooltip || ''} placement="top">
        <Pill
          withMessageIcon={hasMessageIcon}
          assignment={filteredAssignment}
          label={
            shouldShowDeleteState ? (
              <Grid container justifyContent="center" alignItems="center">
                <StyledClear />
              </Grid>
            ) : (
              <PillLabel
                assignment={assignment}
                shorthand={shorthand}
                xIcon={hasXIcon}
              />
            )
          }
          onClick={handleClick}
          shorthand={shorthand}
          deleteIcon={InfoIcon}
          withDeleteState={shouldShowDeleteState}
          {...(isIconVisible && { onDelete: event => handleClick(event) })}
          {...props}
        />
      </Tooltip>
      {showNotifications && unreadNotifications.length > 0 && (
        <StyledBadge variant="dot" color="error" />
      )}
    </Container>
  )
}

export default AssignmentPill
