import { ReactNode } from 'react'
import { Clear } from '@mui/icons-material'
import { styled, Chip, Badge, alpha } from '@mui/material'
import { LisitingAssignmentStatus, ScheduleDaysAssignment } from '../../types'
import {
  getAssignmentPillStyle,
  getExportedAssignmentStatus,
} from '../../utils'

interface PillProps {
  assignment: ScheduleDaysAssignment
  withMessageIcon: boolean
  shorthand?: boolean
  label: ReactNode | string
  withDeleteState?: boolean
  disableClick?: boolean
}

export const Pill = styled(Chip)<PillProps>(
  ({
    theme,
    assignment,
    shorthand,
    label,
    withMessageIcon,
    withDeleteState,
    disableClick,
  }) => {
    const {
      status,
      hasAbsence,
      isPreliminary,
      userSelected,
      listingAssignment,
    } = assignment

    const recalculatedStatus = getExportedAssignmentStatus(
      status,
      listingAssignment?.status
    )

    const style = getAssignmentPillStyle({
      theme,
      status: recalculatedStatus || status,
      hasAbsence: hasAbsence || listingAssignment?.hasAbsece,
      isPreliminary:
        !!isPreliminary ||
        listingAssignment?.status === LisitingAssignmentStatus.Accepted,
    })
    const hasLabel = !!label

    return {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      height: 16,
      width: '100%',
      cursor: disableClick ? 'default' : 'pointer',
      ...(shorthand && {
        fontSize: '0.725rem',
      }),
      '&, &:active, &:focus, &:hover': {
        ...style,
        ...(userSelected && {
          border: `2px solid ${theme.palette.common.black}`,
          boxShadow: `1px 0 0.25em 0.3em ${alpha(
            theme.palette.common.black,
            0.2
          )}`,
        }),
        ...(!userSelected && {
          boxShadow: 'none',
        }),
        ...(withDeleteState && {
          backgroundColor: theme.palette.pill.red.main,
        }),
      },
      '& .MuiChip-deleteIcon': {
        height: 15,
        ...(!withMessageIcon && {
          fontSize: '0.8rem',
          margin: theme.spacing(0.125, 0.75, 0.75, 0),
          fontWeight: 600,
        }),
        ...(withMessageIcon && {
          margin: 0,
          marginRight: shorthand ? 0 : theme.spacing(0.25),
          fontWeight: 600,
        }),
      },
      '& .MuiChip-label': {
        width: '100%',
        textOverflow: 'ellipsis',
        paddingRight: 0,
        paddingLeft: 0,
        marginRight: 0,
        display: 'inline-block',
        [theme.breakpoints.down('xl')]: {
          padding: theme.spacing(0, 0.5),
          ...(shorthand && {
            padding: hasLabel ? theme.spacing(0, 0.5) : 0,
          }),
        },
      },
      '& *': {
        pointerEvents: 'none',
      },
    }
  }
)

export const Container = styled('div')({
  position: 'relative',
})

export const StyledBadge = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  '& .MuiBadge-dot': {
    height: '0.563rem',
    minWidth: '0.563rem',
    border: `0.5px solid ${theme.palette.primary.contrastText}`,
  },
}))

export const MessageTooltipContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.8rem',
})

export const StyledClear = styled(Clear)(({ theme }) => ({
  height: '14px',
  width: '14px',
  color: theme.palette.common.white,
}))
