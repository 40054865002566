import { ReactNode, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import { Grid, Typography, Container } from '@mui/material'
import { format } from 'date-fns'
import { BackButton, CheckboxField, TimeField } from '../../../components'
import { useDateFns } from '../../../hooks'
import DailyAvailabilityDialogActions from './DailyAvailabilityDialogActions'
import DailyAvailabilityHeader from './DailyAvailabilityHeader'
import {
  RemoveButton,
  Shadow,
  WeekDay,
  DateLabel,
  FieldGrid,
  StyledDialogContent,
} from './DailyAvailability.style'
import {
  DailyAvailabilityItemFields,
  DailyAvailabilityField,
  useFields,
  TIME_FORMAT,
} from './DailyAvailability.utils'

const WEEK_DAY_FORMAT = 'EEE'
const DATE_FORMAT = 'd/M'
export interface DailyAvailabilityProps {
  name: string
  onClose: () => void
  openForOvertimeComponent?: ReactNode
  resourceForm: boolean
}

const DailyAvailability = ({
  name,
  onClose,
  openForOvertimeComponent,
  resourceForm,
  ...props
}: DailyAvailabilityProps) => {
  const dateOptions = useDateFns()
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const { fields, resetFields } = useFields({
    name,
  })

  const createRemoveButtonHandler = useCallback(
    (field: DailyAvailabilityField, index: number) => () =>
      setValue(
        `${name}.${index}.${DailyAvailabilityItemFields.IsRemoved}`,
        !field[DailyAvailabilityItemFields.IsRemoved]
      ),
    [name, setValue]
  )
  const handleClose = () => {
    resetFields()
    onClose()
  }

  return (
    <>
      {resourceForm && (
        <BackButton label={t('availabilityModal.back')} onClick={handleClose} />
      )}
      <DailyAvailabilityHeader
        openForOvertimeComponent={openForOvertimeComponent}
      />
      <Shadow>
        <StyledDialogContent dividers>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              {fields.map((field, i) => (
                <FieldGrid
                  key={field.id}
                  item
                  xs={12}
                  faded={field[DailyAvailabilityItemFields.IsRemoved]}
                >
                  <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <WeekDay variant="caption">
                        {format(
                          field[DailyAvailabilityItemFields.Date],
                          WEEK_DAY_FORMAT,
                          dateOptions
                        )}
                      </WeekDay>
                      <DateLabel variant="caption">
                        {format(
                          field[DailyAvailabilityItemFields.Date],
                          DATE_FORMAT,
                          dateOptions
                        )}
                      </DateLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <TimeField
                        name={`${name}.${i}.${DailyAvailabilityItemFields.TimeFrom}`}
                        format={TIME_FORMAT}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TimeField
                        name={`${name}.${i}.${DailyAvailabilityItemFields.TimeTo}`}
                        format={TIME_FORMAT}
                        variant="standard"
                      />
                    </Grid>
                    {!!openForOvertimeComponent && (
                      <Grid item>
                        <CheckboxField
                          name={`${name}.${i}.${DailyAvailabilityItemFields.IsOpenForOvertime}`}
                          label={
                            <Typography color="textSecondary">
                              {t('availabilityModal.isOpenForOvertimeShort')}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <RemoveButton
                        size="small"
                        selected={field[DailyAvailabilityItemFields.IsRemoved]}
                        onClick={createRemoveButtonHandler(field, i)}
                      >
                        <Close />
                      </RemoveButton>
                    </Grid>
                  </Grid>
                </FieldGrid>
              ))}
            </Grid>
          </Container>
        </StyledDialogContent>
        <DailyAvailabilityDialogActions
          onClose={handleClose}
          onSave={onClose}
        />
      </Shadow>
    </>
  )
}

export default DailyAvailability
