import { useState, Fragment, useMemo } from 'react'
import { Grid, Box } from '@mui/material'
import { useDateFns } from '../../hooks'
import { Shift } from '../../types'
import { chunks } from '../../utils'
import Week from './Week'
import {
  GridWithBackground,
  Header, // TODO: Uncomment after scheduler optimization.
  // Select,
} from './sharedSchedulerStyles'
import {
  Event,
  SchedulerView,
  getWeeks,
  getMonthNameByWeek,
  WEEKS_IN_ROW,
  BulkActionTypes,
  SelectedId,
} from './Scheduler.utils'

interface SchedulerProps {
  shifts: Shift[]
  events: Event[]
  startDate: Date
  endDate: Date
  showOnly?: boolean
  initialView?: SchedulerView
  defaultPillName?: string
  onChange: (events: Event[]) => void
}

const Scheduler = ({
  shifts,
  events,
  startDate,
  endDate,
  showOnly,
  defaultPillName = '',
  initialView = SchedulerView.Monthly,
  onChange,
}: SchedulerProps) => {
  // TODO: Uncomment after scheduler optimization.
  // const { t } = useTranslation()
  // const [view, setView] = useState<SchedulerView>(initialView)
  const [hoverType, setHoverType] = useState<BulkActionTypes | null>(null)
  const [selectedId, setSelectedId] = useState<SelectedId | null>(null)
  const dateOptions = useDateFns()

  // TODO: Uncomment after scheduler optimization.
  // const isViewMonthly = useMemo(() => view === SchedulerView.Monthly, [view])
  const isViewMonthly = useMemo(
    () => initialView === SchedulerView.Monthly,
    [initialView]
  )

  const weeks = useMemo(
    () => getWeeks(startDate, endDate, { isViewMonthly, dateOptions }),
    [startDate, endDate, isViewMonthly, dateOptions]
  )

  // TODO: Uncomment after scheduler optimization.
  // const changeView = useCallback(
  //   (e: ChangeEvent<{ value: unknown }>) =>
  //     setView(e.target.value as SchedulerView),
  //   []
  // )

  const fiveWeeksArray = useMemo(() => chunks(weeks, WEEKS_IN_ROW), [weeks])

  return (
    <Box paddingX={3}>
      <Grid container>
        {/* TODO: Uncomment after scheduler optimization. */}
        {/* <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          {t('scheduler.viewBy')}
          <Select value={view} variant="outlined" onChange={changeView}>
            <MenuItem value={SchedulerView.Weekly}>
              {t('scheduler.weekly')}
            </MenuItem>
            <MenuItem value={SchedulerView.Monthly}>
              {t('scheduler.monthly')}
            </MenuItem>
          </Select>
        </Grid> */}
        <GridWithBackground item xs={12}>
          {isViewMonthly
            ? fiveWeeksArray.map((weeks, i) => {
                const { start } = weeks[0]
                const { end } = weeks[weeks.length - 1]
                return (
                  <Fragment key={`weeks-group-${i}`}>
                    <Header>
                      {getMonthNameByWeek(start, end, dateOptions)}
                    </Header>
                    <Box display="flex">
                      {weeks.map((week, index) => (
                        <Week
                          id={i}
                          week={week}
                          events={events}
                          shifts={shifts}
                          key={`monthly-week-${week.number}`}
                          onChange={onChange}
                          showOnly={showOnly}
                          monthlyView
                          firstWeekInRow={index === 0}
                          startDate={startDate}
                          endDate={endDate}
                          rowStartDate={start}
                          rowEndDate={end}
                          defaultPillName={defaultPillName}
                          hoverType={hoverType}
                          onHoverTypeChange={setHoverType}
                          selectedId={selectedId}
                          onShiftSelect={setSelectedId}
                        />
                      ))}
                    </Box>
                  </Fragment>
                )
              })
            : weeks.map((week, i) => (
                <Week
                  id={i}
                  week={week}
                  events={events}
                  shifts={shifts}
                  key={`week-${week.number}`}
                  onChange={onChange}
                  showOnly={showOnly}
                  firstWeekInRow
                  startDate={startDate}
                  endDate={endDate}
                  defaultPillName={defaultPillName}
                  hoverType={hoverType}
                  onHoverTypeChange={setHoverType}
                  selectedId={selectedId}
                  onShiftSelect={setSelectedId}
                />
              ))}
        </GridWithBackground>
      </Grid>
    </Box>
  )
}

export default Scheduler
