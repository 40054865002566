import { useStatusPillLabel } from '../../hooks'
import { AssignmentStatus, ShiftType } from '../../types'
import PillLabel from '../PillLabel'
import { Pill } from './StatusPill.style'

interface StatusPillProps {
  status: AssignmentStatus
  shiftType?: ShiftType
  startDate?: string
  endDate?: string
  listingAssignmentId?: number | null
}

const StatusPill = (props: StatusPillProps) => {
  const label = useStatusPillLabel(props)
  const hasXIcon = !!props.listingAssignmentId

  return (
    <Pill
      viviumXAffiliation={hasXIcon}
      status={props.status}
      label={
        <PillLabel labelText={label} xIcon={hasXIcon} assignment={props} />
      }
    />
  )
}

export default StatusPill
