import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Tags } from '../../services'
import {
  CreateTagParams,
  DeleteTagParams,
  GetTagsParams,
} from '../../services/tags'
import { NAMESPACE } from '../../strings'

const tags = new Tags()

export const createTag = createAsyncThunk(
  `${NAMESPACE}/createTag`,
  (payload: CreateTagParams) => tags.createTag(payload)
)

export const resetCreateTag = createAction(`${NAMESPACE}/resetCreateTag`)

export const deleteTag = createAsyncThunk(
  `${NAMESPACE}/deleteTag`,
  (payload: DeleteTagParams) => tags.deleteTag(payload)
)

export const resetDeleteTag = createAction(`${NAMESPACE}/resetDeleteTag`)

export const getTags = createAsyncThunk(
  `${NAMESPACE}/getTags`,
  (payload: GetTagsParams) => tags.getTags(payload)
)

export const resetGetTags = createAction(`${NAMESPACE}/resetGetTags`)
