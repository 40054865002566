import { useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { useDateFns } from './useDateFns'

const DEFAULT_DATE_FORMAT = 'HH.mm'

export const useAssignmentsDatesText = (
  startDate?: string,
  endDate?: string
): string => {
  const dateOptions = useDateFns()

  return useMemo(() => {
    if (!startDate || !endDate) return ''

    return `${format(
      parseISO(startDate),
      DEFAULT_DATE_FORMAT,
      dateOptions
    )} - ${format(parseISO(endDate), DEFAULT_DATE_FORMAT, dateOptions)}`
  }, [dateOptions, endDate, startDate])
}
