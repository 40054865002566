import { Grid, Tooltip, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useAuth } from '../../hooks'
import { AssignmentHistoryEntry } from '../../types'
import { getInitialLetters } from '../../utils'
import { NoteText, StatusText, StyledAvatar } from './NotesSection.styles'
import { useStateText } from './NotesSection.utils'

interface NoteEntryProps {
  historyEntry: AssignmentHistoryEntry
}

const NoteEntry = ({ historyEntry }: NoteEntryProps) => {
  const { changes, createdAt, createdBy, note } = historyEntry
  const userInitials = getInitialLetters(createdBy?.name, '')
  const stateText = useStateText(changes)
  const { user } = useAuth()

  return (
    <Grid container item spacing={1} alignItems="center">
      <Grid item>
        <Typography variant="caption">
          {format(parseISO(createdAt), 'dd/MM')}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={createdBy?.name || ''} placement="top">
          <StyledAvatar myMessage={user?.id === createdBy?.id}>
            <Typography variant="caption">{userInitials}</Typography>
          </StyledAvatar>
        </Tooltip>
      </Grid>
      <Grid container item direction="column" xs>
        {changes.length > 0 && (
          <Grid item>
            <StatusText color="textSecondary">{stateText}</StatusText>
          </Grid>
        )}
        <NoteText item>{note}</NoteText>
      </Grid>
    </Grid>
  )
}

export default NoteEntry
