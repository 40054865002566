import { PropsWithChildren } from 'react'
import { Box, Grid } from '@mui/material'
import { Skeleton } from '@mui/material'
import { Loader } from './DataTableLoader.style'

const LOADER_ROWS = 6
const LOADER_NORMAL_HEIGHT = 85
const LOADER_DENSE_HEIGHT = 50
const LOADER_COLUMN_WIDTH = 40

interface DataTableLoaderProps {
  columns: number
  rows?: number
  dense?: boolean
  loading?: boolean
  overlay?: boolean
  nested?: boolean
  columnsLabelsVisible?: boolean
}

const DataTableLoader = ({
  columns,
  children,
  rows = LOADER_ROWS,
  dense = false,
  loading = true,
  overlay = false,
  nested = false,
  columnsLabelsVisible = true,
}: PropsWithChildren<DataTableLoaderProps>) =>
  loading ? (
    overlay ? (
      <>
        <Loader
          variant="indeterminate"
          columnsLabelsVisible={columnsLabelsVisible}
        />
        {children}
      </>
    ) : (
      <Box paddingTop={nested ? 0 : 4.5}>
        <Grid container spacing={3} direction="column">
          <Grid item container justifyContent="space-around" spacing={2}>
            {Array.from({ length: columns }, (_, i) => (
              <Grid item key={`loader-column-${i}`}>
                <Skeleton width={LOADER_COLUMN_WIDTH} variant="text" />
              </Grid>
            ))}
          </Grid>
          <Grid item container spacing={dense ? 1 : 2}>
            {Array.from({ length: rows }, (_, i) => (
              <Grid item xs={12} key={`loader-row-${i}`}>
                <Skeleton
                  height={dense ? LOADER_DENSE_HEIGHT : LOADER_NORMAL_HEIGHT}
                  variant="rectangular"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    )
  ) : (
    <>{children}</>
  )

export default DataTableLoader
