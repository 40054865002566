import Api from '../api'
import {
  GetDepartmentsParams,
  GetDepartmentsResponse,
  GetDepartmentsStatisticsParams,
  GetDepartmentsStatisticsResponse,
  CreateDepartmentParams,
  CreateDepartmentResponse,
  GetDetailedDepartmentsParams,
  GetDetailedDepartmentsResponse,
  GetDepartmentParams,
  GetDepartmentResponse,
  UpdateDepartmentParams,
  UpdateDepratmentResponse,
  DeleteDepartmentParams,
  DeleteDepartmentResponse,
} from './Departments.types'

class Departments extends Api {
  public getDepartments = async ({
    careProviderId,
    ...params
  }: GetDepartmentsParams) => {
    const { data } = await this.api.get<GetDepartmentsResponse>(
      `/care-providers/${careProviderId}/departments`,
      { params }
    )
    return data.data
  }

  public getDepartmentsStatistics = async ({
    careProviderId,
    ...params
  }: GetDepartmentsStatisticsParams) => {
    const { data } = await this.api.get<GetDepartmentsStatisticsResponse>(
      `/care-providers/${careProviderId}/departments/statistics`,
      { params }
    )
    return data
  }

  public getDepartment = async ({
    careProviderId,
    departmentId,
  }: GetDepartmentParams) => {
    const { data } = await this.api.get<GetDepartmentResponse>(
      `/care-providers/${careProviderId}/departments/${departmentId}`
    )
    return data
  }

  public getDetailedDepartments = async ({
    careProviderId,
    ...params
  }: GetDetailedDepartmentsParams) => {
    const response = await this.api.get<GetDetailedDepartmentsResponse>(
      `/care-providers/${careProviderId}/departments/details`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public createDepartment = async ({
    careProviderId,
    ...payload
  }: CreateDepartmentParams) => {
    const { data } = await this.api.post<CreateDepartmentResponse>(
      `/care-providers/${careProviderId}/departments`,
      payload
    )
    return data
  }

  public updateDepartment = async ({
    careProviderId,
    id,
    ...payload
  }: UpdateDepartmentParams) => {
    const { data } = await this.api.put<UpdateDepratmentResponse>(
      `/care-providers/${careProviderId}/departments/${id}`,
      payload
    )
    return data
  }

  public deleteDepartment = async ({
    careProviderId,
    departmentId,
  }: DeleteDepartmentParams) => {
    const { data } = await this.api.delete<DeleteDepartmentResponse>(
      `/care-providers/${careProviderId}/departments/${departmentId}`
    )
    return data
  }
}

export default Departments
