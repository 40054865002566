import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { LoadingStatus } from '../types'

interface UseLoadingStatusEffect {
  loading: LoadingStatus
  successMessage: string
  errorMessage?: string
  onSuccess?: () => void
  onError?: () => void
}

export const useLoadingStatusEffect = ({
  loading,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
}: UseLoadingStatusEffect) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (onSuccess) onSuccess()
    }
    if (loading === LoadingStatus.Failed) {
      enqueueSnackbar(errorMessage || t('error.defaultMessage'), {
        variant: 'error',
      })
      if (onError) onError()
    }
  }, [loading]) // eslint-disable-line
}
