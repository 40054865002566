import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { format, parse, parseISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import { object, SchemaOf, string, boolean } from 'yup'
import { useDispatch, useSelector, useOrganizationId } from 'shared/hooks'
import { AutocompleteValue } from 'shared/types'
import {
  AbsenceReason,
  LoadingStatus,
  ResourceCalendarEvent,
  ResourcePeriodType,
} from 'shared/types'
import { actions, selectors } from '../../store'

export enum EditAccessibilityFormFields {
  Type = 'availabilityType',
  TimeFrom = 'timeFrom',
  TimeTo = 'timeTo',
  IsOpenForOvertime = 'isOpenForOvertime',
  Reason = 'reason',
  Note = 'note',
}

export interface EditAccessibilityFormValues {
  [EditAccessibilityFormFields.Type]:
    | ResourcePeriodType.Availability
    | ResourcePeriodType.Absence
  [EditAccessibilityFormFields.TimeFrom]: string
  [EditAccessibilityFormFields.TimeTo]: string
  [EditAccessibilityFormFields.IsOpenForOvertime]?: boolean
  [EditAccessibilityFormFields.Reason]?: AutocompleteValue
  [EditAccessibilityFormFields.Note]?: string
}

const DEFAULT_START_TIME = '07.00'
const DEFAULT_END_TIME = '16.00'
const TIME_FORMAT = 'HH.mm'

export const useDefaultValues = (
  accessibility: Partial<ResourceCalendarEvent>
): EditAccessibilityFormValues => {
  const { t } = useTranslation()
  const { type, startDate, endDate, absenceReason, openForOvertime } =
    accessibility

  return {
    [EditAccessibilityFormFields.Type]: (type ||
      ResourcePeriodType.Availability) as EditAccessibilityFormValues[EditAccessibilityFormFields.Type],
    [EditAccessibilityFormFields.TimeFrom]: startDate
      ? format(parseISO(startDate), TIME_FORMAT)
      : DEFAULT_START_TIME,
    [EditAccessibilityFormFields.TimeTo]: endDate
      ? format(parseISO(endDate), TIME_FORMAT)
      : DEFAULT_END_TIME,
    [EditAccessibilityFormFields.IsOpenForOvertime]: openForOvertime || false,
    [EditAccessibilityFormFields.Note]: accessibility.note || '',
    [EditAccessibilityFormFields.Reason]: absenceReason
      ? {
          label: t(`enums.AbsenceReason.${absenceReason}`),
          value: absenceReason,
        }
      : null,
  }
}

export const useValidationSchema =
  (): SchemaOf<EditAccessibilityFormValues> => {
    const { t } = useTranslation()
    return object()
      .shape({
        [EditAccessibilityFormFields.Type]: string().required(
          t('validation.required')
        ),
        [EditAccessibilityFormFields.TimeFrom]: string().required(
          t('validation.required')
        ),
        [EditAccessibilityFormFields.Note]: string(),
        [EditAccessibilityFormFields.TimeTo]: string()
          .required(t('validation.required'))
          .test(
            'timeTo !== timeFrom',
            t('validation.differentThan', { value: t('time.from') }),
            function (timeTo) {
              return this.parent.timeFrom !== timeTo
            }
          ),
        [EditAccessibilityFormFields.IsOpenForOvertime]: boolean(),
        [EditAccessibilityFormFields.Reason]: object().when(
          EditAccessibilityFormFields.Type,
          {
            is: ResourcePeriodType.Absence,
            then: object()
              .shape({ label: string(), value: string() })
              .nullable()
              .required(t('validation.required')),
            otherwise: object().nullable(),
          }
        ),
      })
      .required()
  }

const updateMessages: Partial<Record<ResourcePeriodType, string>> = {
  [ResourcePeriodType.Absence]: 'absenceUpdated',
  [ResourcePeriodType.Availability]: 'availabilityUpdated',
}

interface useOnUpdateAndOnDeleteProps {
  resourceId: number
  accessibility: ResourceCalendarEvent
  onClose: () => void
  onSuccess: () => void
}

export const useOnUpdate = ({
  resourceId,
  accessibility,
  onClose,
  onSuccess,
}: useOnUpdateAndOnDeleteProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()

  const { loading } = useSelector(
    selectors.accessibilities.getUpdateResourceAccessibilities
  )

  const onFailure = (error?: string) =>
    enqueueSnackbar(error || t('error.defaultMessage'), {
      variant: 'error',
    })

  const onUpdate = (values: EditAccessibilityFormValues) => {
    if (!accessibility?.id || !resourceId) {
      return onFailure(t('error.missingRequestData'))
    }

    const type = values[EditAccessibilityFormFields.Type]
    const startDate = parse(
      values[EditAccessibilityFormFields.TimeFrom],
      TIME_FORMAT,
      parseISO(accessibility.startDate)
    )
    const endDate = parse(
      values[EditAccessibilityFormFields.TimeTo],
      TIME_FORMAT,
      parseISO(accessibility.endDate)
    )
    const onSuccessActions = () => {
      onClose()
      enqueueSnackbar(t(`resourceAccessibility.${updateMessages[type]}`), {
        variant: 'success',
      })
      onSuccess()
    }

    return dispatch(
      actions.accessibilities.updateResourceAccessibility({
        onFailure,
        onSuccess: onSuccessActions,
        params: {
          careProviderId,
          resourceId,
          startDate,
          endDate,
          type,
          originalType: accessibility.type,
          id: accessibility.id,
          note: values[EditAccessibilityFormFields.Note] || accessibility.note,
          reason:
            values[EditAccessibilityFormFields.Reason]?.value ||
            AbsenceReason.Vacation,
          isOpenForOvertime:
            values[EditAccessibilityFormFields.IsOpenForOvertime] || false,
        },
      })
    )
  }

  return { onUpdate, isUpdating: loading === LoadingStatus.Pending }
}

const deleteMessages: Partial<Record<ResourcePeriodType, string>> = {
  [ResourcePeriodType.Absence]: 'absenceRemoved',
  [ResourcePeriodType.Availability]: 'availabilityRemoved',
}

export const useOnDelete = ({
  accessibility,
  onClose,
  onSuccess,
  resourceId,
}: useOnUpdateAndOnDeleteProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const { loading } = useSelector(
    selectors.accessibilities.getDeleteResourceAccessibilities
  )

  const onFailure = (error?: string) =>
    enqueueSnackbar(error || t('error.defaultMessage'), {
      variant: 'error',
    })

  const onDelete = () => {
    if (!accessibility?.id) {
      return onFailure(t('error.missingRequestData'))
    }

    const onSuccessActions = () => {
      onClose()
      enqueueSnackbar(
        t(`resourceAccessibility.${deleteMessages[accessibility.type]}`),
        {
          variant: 'success',
        }
      )
      onSuccess()
    }

    return dispatch(
      actions.accessibilities.deleteResourceAccessibility({
        onFailure,
        onSuccess: onSuccessActions,
        params: {
          careProviderId,
          resourceId,
          id: accessibility.id,
          type: accessibility.type,
        },
      })
    )
  }

  return { onDelete, isDeleting: loading === LoadingStatus.Pending }
}

export const useFormProps = (accessibility: ResourceCalendarEvent) => {
  const schema = useValidationSchema()
  const defaultValues = useDefaultValues(accessibility)
  const methods = useForm<EditAccessibilityFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  return methods
}
