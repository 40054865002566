import {
  alpha,
  Badge,
  List as BaseList,
  styled,
  Typography,
} from '@mui/material'

export const NotificationsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > *': {
    margin: theme.spacing(0, 2),
  },
}))

export const List = styled(BaseList)(({ theme }) => ({
  maxHeight: '80vh',
  overflowY: 'auto',
  width: theme.breakpoints.values.sm,
  '& > a': {
    padding: theme.spacing(0),
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
  },

  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
}))

export const ExpandActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 1),
}))

export const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  fontWeight: 600,
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  '& .MuiBadge-badge': {
    position: 'relative',
  },
  '& .MuiBadge-anchorOriginTopRightRectangular': {
    transform: 'none',
  },
  color: 'green',
}))

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:hover': {
    cursor: 'pointer',
    userSelect: 'none',
  },
})
