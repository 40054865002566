import { ReactNode, useCallback, MouseEvent } from 'react'
import { PopoverProps, Typography } from '@mui/material'
import { useModal } from '../../../../../hooks'
import NotificationsList from '../NotificationsList'
import ResourceNotificationComponent from './ResourceNotificationComponent'
import { Container, StyledBadge } from '../Notifications.style'
import {
  ListContainer,
  StyledPopover,
  StyledBackdrop,
  backdropStyles,
} from './ResourceNotificationsDropdown.style'
import {
  useBookingsNotifications,
  useRequestsNotifications,
} from './ResourceNotificationsDropdown.utils'

export interface ResourceNotificationsDropdownProps {
  label: ReactNode
  hideLoadMore?: boolean
  unreadCount?: number
  popoverProps?: Partial<PopoverProps>
}

const ResourceNotificationsDropdown = ({
  label,
  unreadCount,
  popoverProps,
  hideLoadMore = false,
}: ResourceNotificationsDropdownProps) => {
  const {
    getRequestsNotifications,
    resetRequestsNotifications,
    ...requestsNotificationsListProps
  } = useRequestsNotifications()
  const {
    getBookingsNotifications,
    resetBookingsNotifications,
    ...bookingsNotificationsListProps
  } = useBookingsNotifications()

  const { isModalOpen, openModal, closeModal, anchorEl } = useModal()

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      openModal(event)
      getRequestsNotifications()
      getBookingsNotifications()
    },
    [getBookingsNotifications, getRequestsNotifications, openModal]
  )

  const handleClose = useCallback(() => {
    closeModal()
    resetRequestsNotifications()
    resetBookingsNotifications()
  }, [closeModal, resetBookingsNotifications, resetRequestsNotifications])

  return (
    <>
      <Container onClick={handleOpen}>
        <Typography>{label}</Typography>
        {!!unreadCount && (
          <StyledBadge badgeContent={unreadCount} color="error" />
        )}
      </Container>
      <StyledBackdrop open={isModalOpen} style={backdropStyles}>
        <StyledPopover
          open={isModalOpen}
          marginThreshold={0}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          {...popoverProps}
        >
          <ListContainer>
            <NotificationsList
              {...requestsNotificationsListProps}
              hideLoadMore={hideLoadMore}
              onClose={handleClose}
              NotificationComponent={ResourceNotificationComponent}
            />
          </ListContainer>
          <ListContainer>
            <NotificationsList
              {...bookingsNotificationsListProps}
              hideLoadMore={hideLoadMore}
              onClose={handleClose}
              NotificationComponent={ResourceNotificationComponent}
            />
          </ListContainer>
        </StyledPopover>
      </StyledBackdrop>
    </>
  )
}

export default ResourceNotificationsDropdown
