import { ChangeEvent } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { Radio, RadioGroupProps, FormControlLabel } from '@mui/material'
import {
  InputLabel,
  ErrorWrapper,
  useRadioStyles,
  StyledRadioGroup,
} from './RadioField.style'

export interface RadioFieldItem {
  label: string
  value: string | number | boolean
  disabled?: boolean
}

export type RadioFieldProps = RadioGroupProps & {
  name: string
  label?: string
  required?: boolean
  options: RadioFieldItem[]
  disabled?: boolean
}

const RadioField = ({
  name,
  label,
  options,
  defaultValue,
  required,
  disabled,
  ...props
}: RadioFieldProps) => {
  const { control, setValue, clearErrors } = useFormContext()
  const {
    field: { value },
    fieldState: { error },
  } = useController({ control, name, defaultValue })
  const radioClasses = useRadioStyles()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value, { shouldDirty: true })
    clearErrors(name)
  }

  return (
    <>
      {label && (
        <InputLabel required={required} error={!!error}>
          {label}
        </InputLabel>
      )}
      <StyledRadioGroup
        name={name}
        onChange={handleChange}
        error={!!error}
        {...props}
      >
        {options.map(option => (
          <FormControlLabel
            key={`radio-${name}/${option.label}`}
            value={option.value}
            control={<Radio classes={radioClasses} size="small" />}
            label={option.label}
            checked={option.value.toString() === value}
            disabled={disabled || option.disabled}
          />
        ))}
      </StyledRadioGroup>
      {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
    </>
  )
}

export default RadioField
