import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { NeedAssignments } from 'shared/services'
import Documents from 'shared/services/documents'
import {
  BookAssignmentsInternallyParams,
  RequestAssignmentsParams,
} from 'shared/services/need-assignments'
import Resources, {
  CreateResourceParams,
  DeleteResourceParams,
  GetAvailabilityTableInfoParams,
  GetStaffingTableInfoParams,
  GetResourceDetailsParams,
  GetResourcesTableInfoParams,
  UpdateResourceParams,
  GetMatchingAssignmentsParams,
} from 'shared/services/resources'
import Suggestions, {
  GetCareProviderUnitsSuggestionsParams,
} from 'shared/services/suggestions'
import { ActionType } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME } from '../strings'
import {
  ChangeResourceStatusPayload,
  DeleteResourceDocumentPayload,
  ExportResourceAvailabilityStatisticsPayload,
  ExportResourceStaffingStatisticsPayload,
  UploadResourceDocumentParams,
} from './actions.types'

const resources = new Resources()
const documents = new Documents()
const sugestions = new Suggestions()
const needAssignments = new NeedAssignments()

export const getResourcesTableInfo = createAsyncThunk(
  `${MODULE_NAME}/getResourcesTableInfo`,
  (payload: GetResourcesTableInfoParams) =>
    resources.getResourcesTableInfo(payload)
)

export const getStaffingTableInfo = createAsyncThunk(
  `${MODULE_NAME}/getStaffingTableInfo`,
  (payload: GetStaffingTableInfoParams) =>
    resources.getStaffingTableInfo(payload)
)

export const exportStaffingStatistics = createAsyncThunk(
  `${MODULE_NAME}/exportStaffingStatistics`,
  async ({
    onSuccess,
    onFailure,
    ...payload
  }: ExportResourceStaffingStatisticsPayload) => {
    try {
      const file = await resources.exportResourceStaffingStatistics(payload)
      onSuccess(file)
      return file
    } catch {
      onFailure()
    }
  }
)

export const getAvailabilityTableInfo = createAsyncThunk(
  `${MODULE_NAME}/getAvailabilityTableInfo`,
  (payload: GetAvailabilityTableInfoParams) =>
    resources.getAvailabilityTableInfo(payload)
)

export const exportAvailabilityStatistics = createAsyncThunk(
  `${MODULE_NAME}/exportAvailabilityStatistics`,
  async ({
    onSuccess,
    onFailure,
    ...payload
  }: ExportResourceAvailabilityStatisticsPayload) => {
    try {
      const file = await resources.exportResourceAvailabilityStatistics(payload)
      onSuccess(file)
      return file
    } catch {
      onFailure()
    }
  }
)

export const createResource = createAsyncThunk(
  `${MODULE_NAME}/createResource`,
  (payload: CreateResourceParams, { rejectWithValue }) =>
    resources.createResource(payload).catch((error: AxiosError) => {
      if (!error.response) throw error
      return rejectWithValue(error.response.data.message)
    })
)

export const resetCreateResource = createAction(
  `${MODULE_NAME}/resetCreateResource`
)

export const updateResource = createAsyncThunk(
  `${MODULE_NAME}/updateResource`,
  (payload: UpdateResourceParams, { rejectWithValue }) =>
    resources.updateResource(payload).catch((error: AxiosError) => {
      if (!error.response) throw error
      return rejectWithValue(error.response.data.message)
    })
)

export const resetUpdateResource = createAction(
  `${MODULE_NAME}/resetUpdateResource`
)

export const deleteResource = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteResource`,
  (payload: DeleteResourceParams) => resources.deleteResource(payload)
)

export const resetDeleteResource = createAction(
  `${MODULE_NAME}/resetDeleteResource`
)

export const getResourceDetails = createAsyncThunk(
  `${MODULE_NAME}/getResourceDetails`,
  (payload: GetResourceDetailsParams) => resources.getResourceDetails(payload)
)

export const uploadResourceDocument = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/uploadResourceDocument`,
  async ({ careProviderId, type, file }: UploadResourceDocumentParams) => {
    const documentId = await documents.uploadResourceDocument(
      careProviderId,
      type,
      file.file
    )
    const data = await documents.getResourceDocument({
      careProviderId,
      documentId,
    })
    return data
  }
)

export const deleteResourceDocument = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/deleteResourceDocument`,
  (payload: DeleteResourceDocumentPayload) =>
    documents.deleteResourceDocument(payload)
)

export const getMatchingUnits = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/getMatchingUnits`,
  async (payload: GetCareProviderUnitsSuggestionsParams) => {
    if (!payload.tagIds?.length) return []

    return await sugestions.getCareProviderUnits(payload)
  }
)

export const deactivateResource = createAsyncThunk(
  `${MODULE_NAME}/deactivateResource`,
  (payload: ChangeResourceStatusPayload) =>
    resources.changeResourceStatus({
      ...payload,
      action: ActionType.Deactivate,
    })
)

export const resetDeactivateResource = createAction(
  `${MODULE_NAME}/resetDeactivateResource`
)

export const activateResource = createAsyncThunk(
  `${MODULE_NAME}/activateResource`,
  (payload: ChangeResourceStatusPayload) =>
    resources.changeResourceStatus({
      ...payload,
      action: ActionType.Activate,
    })
)

export const resetActivateResource = createAction(
  `${MODULE_NAME}/resetActivateResource`
)

export const toggleAvailabilityTableEditingMode = createAction(
  `${MODULE_NAME}/toggleAvailabilityTableEditingMode`
)

export const resetAvailabilityTableEditingMode = createAction(
  `${MODULE_NAME}/resetAvailabilityTableEditingMode`
)

export const getResourceMatchingAssignments = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/getResourceMatchingAssignments`,
  async (payload: GetMatchingAssignmentsParams) =>
    resources.getMatchingAssignments(payload)
)

export const bookMatchingAssignment = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/bookMatchingAssignment`,
  async (payload: BookAssignmentsInternallyParams) =>
    needAssignments.bookAssignmentsInternally(payload)
)

export const requestMatchingAssignment = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/requestMatchingAssignment`,
  async (payload: RequestAssignmentsParams) =>
    needAssignments.requestAssignments(payload)
)
