import { useCallback, useMemo } from 'react'
import { selectors, actions } from '../store'
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from './redux'
import { useGetSchedulesUnreadNotifications } from './useGetSchedulesUnreadNotifications'
import { useOrganizationId } from './useOrganization'

export const useAssignmentNotification = (id: number) => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const getSchedulesUnreadNotifications = useGetSchedulesUnreadNotifications()

  const schedulesUnreadNotifications = useSelector(
    selectors.notifications.getSchedulesUnreadNotifications
  )
  const assignmentUnreadNotifications = useMemo(
    () =>
      schedulesUnreadNotifications
        .flatMap(({ notifications }) => notifications)
        .filter(({ data }) => data.assignmentId === id),
    [id, schedulesUnreadNotifications]
  )

  const assignmentUnreadNotificationsIds = assignmentUnreadNotifications.map(
    ({ id }) => id
  )

  const markAsRead = useCallback(() => {
    if (assignmentUnreadNotificationsIds.length === 0) return
    dispatch(
      actions.notifications.markAsRead({
        careProviderId,
        notificationIds: assignmentUnreadNotificationsIds,
        onSuccess: () => getSchedulesUnreadNotifications(),
      })
    )
  }, [
    dispatch,
    getSchedulesUnreadNotifications,
    assignmentUnreadNotificationsIds,
    careProviderId,
  ])

  return { unreadNotifications: assignmentUnreadNotifications, markAsRead }
}
