import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../resource'
import { MatchingResource } from '../../types'
import { getMatchingResources, resetMatchingResources } from './actions'

export interface GetMatchingResourcesData {
  matchingResources: MatchingResource[]
  totalAssignmentsCount: number
  bookableResourcesCount: number
  totalResourcesCount: number
}

export interface State {
  getMatchingResources: Resource<GetMatchingResourcesData>
}

const initialState: State = {
  getMatchingResources: resource.getInitial<GetMatchingResourcesData>(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getMatchingResources.pending, state => {
      resource.setPending(state.getMatchingResources)
    })
    .addCase(getMatchingResources.fulfilled, (state, action) => {
      resource.setSucceeded(state.getMatchingResources, {
        matchingResources: action.payload.data,
        ...action.payload.meta,
      })
    })
    .addCase(getMatchingResources.rejected, (state, action) => {
      resource.setFailed(state.getMatchingResources, action.error.message)
    })
    .addCase(resetMatchingResources, state =>
      resource.reset(
        state.getMatchingResources,
        initialState.getMatchingResources.data
      )
    )
)
