import { NeedSettings as NeedSettingsResponse } from '../../types'
import Api from '../api'
import { GetNeedSettingsParams } from './NeedSettings.types'

class NeedSettings extends Api {
  public getNeedSettings = async ({
    careProviderId,
  }: GetNeedSettingsParams) => {
    const { data } = await this.api.get<NeedSettingsResponse>(
      `care-providers/${careProviderId}/need-settings`
    )

    return data
  }

  public patchNeedSettings = async ({
    careProviderId,
    ...payload
  }: GetNeedSettingsParams) => {
    const { data } = await this.api.patch<string>(
      `care-providers/${careProviderId}/need-settings`,
      payload
    )

    return data
  }
}

export default NeedSettings
