import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckboxProps } from '@mui/material'
import { StyledCheckboxField } from '../../AssignmentModal.style'

interface BookingCheckboxProps extends CheckboxProps {
  openForOvertime: boolean
}

const BookingCheckbox = ({
  name = '',
  openForOvertime,
  ...props
}: BookingCheckboxProps) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()
  const bookingValue = useWatch({ control, name })

  const isChecked = bookingValue === props.value

  const handleChange = () =>
    setValue(name, bookingValue === props.value ? '' : props.value)

  return (
    <StyledCheckboxField
      checked={isChecked}
      name={name}
      // @ts-ignore
      onChange={handleChange}
      overtimeLabel={openForOvertime ? t('assignmentModal.overtime') : ''}
      {...props}
    />
  )
}

export default BookingCheckbox
