import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { Header } from './DailyAvailability.style'

interface DailyAvailabilityHeaderProps {
  openForOvertimeComponent?: ReactNode
}

const DailyAvailabilityHeader = ({
  openForOvertimeComponent,
}: DailyAvailabilityHeaderProps) => {
  const { t } = useTranslation()
  return (
    <Header>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            {t('availabilityModal.dateAndTime')}
          </Typography>
        </Grid>
        <Grid item>{openForOvertimeComponent}</Grid>
      </Grid>
    </Header>
  )
}

export default DailyAvailabilityHeader
