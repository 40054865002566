import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute, UserRole } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.organizations,
    allowedRoles: [UserRole.SuperCareProvider],
    element: <Loadable component={() => import('./pages/Organizations')} />,
  },
]

export default routes
