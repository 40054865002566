import { styled, alpha } from '@mui/material'

export const DayWrapper = styled('div')(({ theme }) => ({
  flex: '2',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.grey[200],
  borderRight: `1px solid ${alpha(theme.palette.common.black, 0.05)}`,
  position: 'relative',
}))
