import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from '../../hooks'
import { useAssignmentModal, useUpdateQuery, useNeedIds } from '../../hooks'
import {
  actions as commonActions,
  selectors as commonSelectors,
} from '../../store'
import { LoadingStatus } from '../../types'
import { getEarliestNeedDate } from '../../utils'
import Loader from '../Loader'
import AssignmentModalWrapper from './AssignmentModalWrapper'
import { ModalContextValues } from './AssignmentModal.types'
import {
  ModalContext,
  useGetAssignmentModalContent,
} from './AssignmentModal.utils'

interface AssignmentModalProps extends Omit<ModalContextValues, 'handleClose'> {
  onClose: () => void
}

const AssignmentModal = ({
  onClose,
  ...contextProps
}: AssignmentModalProps) => {
  const { data, onBack, onExit } = useAssignmentModal()
  const { loading } = useSelector(
    commonSelectors.schedules.getSelectedAssignment
  )

  const handleClose = useCallback(() => {
    onBack()
    onClose()
  }, [onClose, onBack])

  const AssignmentContent = useGetAssignmentModalContent()

  const { data: unitNeeds } = useSelector(
    commonSelectors.schedules.getUnitNeedsResource
  )
  const updateQuery = useUpdateQuery()
  const removeAssignmentsLoadingStatus = useSelector(
    commonSelectors.schedules.getRemoveAssignmentsLoadingStatus
  )
  const dispatch = useDispatch()
  const needIds = useNeedIds()

  //TODO: when refactoring store move this functionallity to onSuccess callback
  useEffect(() => {
    if (removeAssignmentsLoadingStatus !== LoadingStatus.Succeeded) return

    dispatch(commonActions.schedules.resetRemoveAssignments())

    const earliestDate = getEarliestNeedDate({
      needs: unitNeeds,
      selectedIds: needIds,
    })
    const filteredNeedIds = needIds.filter(needId =>
      unitNeeds.map(unitNeed => unitNeed.id).includes(needId)
    )
    updateQuery({
      needIds: filteredNeedIds,
      date: earliestDate,
    })
  }, [unitNeeds.length]) //eslint-disable-line

  if (!data) return null

  return (
    <ModalContext.Provider value={{ ...contextProps, handleClose }}>
      <AssignmentModalWrapper
        open
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={data?.anchorPosition}
        TransitionProps={{
          onExit,
        }}
      >
        {loading ? <Loader /> : <AssignmentContent />}
      </AssignmentModalWrapper>
    </ModalContext.Provider>
  )
}

export default AssignmentModal
