import { createBreakpoints, BreakpointsOptions } from '@mui/system'

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    tableMd: 1440,
    maxLaptop: 1600,
  },
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    tableMd: true
    maxLaptop: true
  }
}

export default createBreakpoints(breakpoints)
