import { RootState } from 'app'
import { LoadingStatus } from 'shared/types'
import { HourRate } from 'shared/types'

export const getUnitsData = (state: RootState) => state.administration.getUnits

export const getAdministrationUnit = (state: RootState) =>
  state.administration.getAdministrationUnit

export const getAddUnit = (state: RootState) => state.administration.createUnit

export const getAddUnitLoading = (state: RootState) =>
  state.administration.createUnit.loading

export const getUpdateUnit = (state: RootState) =>
  state.administration.updateUnit

export const getUpdateUnitLoading = (state: RootState) =>
  state.administration.updateUnit.loading

export const getConnectedResources = (state: RootState) =>
  state.administration.getConnectedResources

export const getDefinedShiftLoading = (index: number) => (state: RootState) =>
  state.administration.definedShifts[index]?.loading === LoadingStatus.Pending

export const getOpenShiftLoading = (index: number) => (state: RootState) =>
  state.administration.openShifts[index]?.loading === LoadingStatus.Pending

export const getHourRatesLoading = (state: RootState) =>
  state.administration.hourRates.loading

export const getHourRates = (state: RootState) =>
  state.administration.hourRates.data.reduce(
    (acc, data) => [...acc, data.data],
    [] as HourRate[]
  )

export const getHourRateLoading = (i: number) => (state: RootState) =>
  state.administration.hourRates.data[i]?.loading

export const getMyInformation = (state: RootState) =>
  state.administration.getMyInformation

export const updateMyInformationLoading = (state: RootState) =>
  state.administration.updateMyInformation.loading

export const getInactivateUnit = (state: RootState) =>
  state.administration.inactivateUnit

export const getActivateUnit = (state: RootState) =>
  state.administration.activateUnit

export const getIsUnitActive = (state: RootState) =>
  state.administration.getAdministrationUnit.data?.isActive

export const getDeleteUnit = (state: RootState) =>
  state.administration.deleteUnit

export const getDetailedDepartments = (state: RootState) =>
  state.administration.getDetailedDepartments

export const getDepartment = (state: RootState) =>
  state.administration.getDepartment

export const getCreateDepartment = (state: RootState) =>
  state.administration.createDepartment

export const getUpdateDepartment = (state: RootState) =>
  state.administration.updateDepartment

export const getCreateUser = (state: RootState) =>
  state.administration.createUser

export const getUpdateUser = (state: RootState) =>
  state.administration.updateUser

export const getDeleteUser = (state: RootState) =>
  state.administration.deleteUser

export const getDeactivateUser = (state: RootState) =>
  state.administration.deactivateUser

export const getActivateUser = (state: RootState) =>
  state.administration.activateUser

export const getGetUser = (state: RootState) => state.administration.getUser

export const getIsUserActive = (state: RootState) =>
  state.administration.getUser.data?.isActive

export const getSuperCareProviders = (state: RootState) =>
  state.administration.getSuperCareProviders

export const getCareProviders = (state: RootState) =>
  state.administration.getCareProviders

export const getUnitAdmins = (state: RootState) =>
  state.administration.getUnitAdmins

export const getUserPhoneNumber = (state: RootState) =>
  state.administration.userPhoneNumber

export const getSystemUpdateMessages = (state: RootState) =>
  state.administration.getSystemUpdateMessages

export const getFAQs = (state: RootState) => state.administration.getFAQs
