import { NavigateBefore } from '@mui/icons-material'
import { styled } from '@mui/material'

export const Container = styled('span')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

export const Icon = styled(NavigateBefore)(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(1),
  color: theme.palette.neutral.dark,
}))
