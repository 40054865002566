import { useState, MouseEvent } from 'react'

interface UseModal {
  openModal: (anchor: MouseEvent<HTMLElement>) => void
  closeModal: () => void
  anchorEl: HTMLElement | null
  isModalOpen: boolean
}

export const useModal = (): UseModal => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const openModal = (anchor: MouseEvent<HTMLElement>) =>
    setAnchorEl(anchor.currentTarget)

  const closeModal = () => setAnchorEl(null)

  const isModalOpen = !!anchorEl

  return { openModal, closeModal, anchorEl, isModalOpen }
}
