import { IconButton, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#515151',
  position: 'fixed',
  bottom: 50,
  zIndex: 1850,

  [theme.breakpoints.down('md')]: {
    left: 50,
  },
  [theme.breakpoints.up('md')]: {
    right: 15,
  },
}))
