import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { useDateFns } from '../../hooks'
import { Shift } from '../../types'
import DayHeader from './DayHeader'
import Row from './Row'
import { RowWrapper, Wrapper } from './sharedSchedulerStyles'
import {
  Week as WeekType,
  getWeekDays,
  Event,
  BulkActionTypes,
  isBeforeOrAfterRange,
  getMonthNameByWeek,
  SelectedId,
} from './Scheduler.utils'
import { Header, Main, Month } from './Week.style'

interface WeekProps {
  id: number
  week: WeekType
  shifts: Shift[]
  events: Event[]
  onChange: (events: Event[]) => void
  showOnly?: boolean
  monthlyView?: boolean
  firstWeekInRow?: boolean
  startDate: Date
  endDate: Date
  rowStartDate?: Date
  rowEndDate?: Date
  defaultPillName?: string
  hoverType: BulkActionTypes | null
  onHoverTypeChange: (arg: BulkActionTypes | null) => void
  selectedId: SelectedId | null
  onShiftSelect: (props: SelectedId | null) => void
}

const Week = memo(
  ({
    id,
    week,
    events,
    shifts,
    onChange,
    showOnly,
    monthlyView,
    firstWeekInRow,
    startDate,
    endDate,
    rowStartDate,
    rowEndDate,
    defaultPillName,
    hoverType,
    onHoverTypeChange,
    selectedId,
    onShiftSelect,
  }: WeekProps) => {
    const { t } = useTranslation()
    const dateOptions = useDateFns()
    const [selectedDay, setSelectedDay] = useState<Date | null>(null)

    const monthName = useMemo(
      () => getMonthNameByWeek(week.start, week.end, dateOptions),
      [week.start, week.end, dateOptions]
    )
    const days = useMemo(
      () => getWeekDays(week.start, week.end),
      [week.start, week.end]
    )

    return (
      <Grid item xs={12}>
        <Typography variantMapping={{ body1: 'div' }}>
          <Header monthlyView={!!monthlyView} firstWeekInRow={!!firstWeekInRow}>
            {!monthlyView && <Month>{monthName}</Month>}
            {t('dates.weekLong', { week: week.number })}
          </Header>
          <Main monthlyView={!!monthlyView} firstWeekInRow={!!firstWeekInRow}>
            <RowWrapper>
              {firstWeekInRow && <Wrapper />}
              {days.map((day, i) => (
                <DayHeader
                  day={day}
                  key={`day-${i}`}
                  setSelectedDay={setSelectedDay}
                  selectedDay={selectedDay}
                  onHoverTypeChange={onHoverTypeChange}
                  onChange={onChange}
                  events={events}
                  shifts={shifts}
                  showOnly={showOnly || monthlyView}
                  monthlyView={monthlyView}
                  disabled={isBeforeOrAfterRange(day, startDate, endDate)}
                  defaultPillName={defaultPillName}
                />
              ))}
            </RowWrapper>
            {shifts.map(shift => (
              <Row
                id={id}
                key={`row-${week}-${shift.name}`}
                shift={shift}
                events={events}
                onChange={onChange}
                days={days}
                selectedDay={selectedDay}
                onHoverTypeChange={onHoverTypeChange}
                hoverType={hoverType}
                showOnly={showOnly}
                monthlyView={monthlyView}
                firstWeekInRow={firstWeekInRow}
                startDate={startDate}
                endDate={endDate}
                rowStartDate={rowStartDate}
                rowEndDate={rowEndDate}
                defaultPillName={defaultPillName}
                selectedId={selectedId}
                onShiftSelect={onShiftSelect}
              />
            ))}
          </Main>
        </Typography>
      </Grid>
    )
  }
)

export default Week
