import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from '../../../hooks'
import { actions, selectors } from '../../../store'
import { LoadingStatus } from '../../../types'

export const useAdminUpdateMessageModal = (messageId?: number) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { data, loading, error, isModalOpen } = useSelector(
    selectors.notifications.getAdminUpdateMessage
  )

  const onFailure = () =>
    enqueueSnackbar(
      typeof error === 'string' ? error : t('adminMessage.error'),
      { variant: 'error' }
    )
  const onSuccess = (messageId: number) =>
    dispatch(actions.notifications.markAdminMessageAsRead({ messageId }))

  const onOpen = () => {
    if (!messageId) return

    dispatch(
      actions.notifications.openAdminUpdateMessageModal({
        params: { messageId },
        onSuccess: () => onSuccess(messageId),
        onFailure,
      })
    )
  }

  return {
    error,
    onOpen,
    title: data?.title,
    body: data?.body,
    isLoading: loading === LoadingStatus.Pending,
    isOpen: isModalOpen,
    onClose: () =>
      dispatch(actions.notifications.closeAdminUpdateMessageModal()),
  }
}
