import { PropsWithChildren } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { Breakpoint } from '@mui/system'

interface HiddenProps {
  lgDown?: boolean
  lgUp?: boolean
  mdDown?: boolean
  mdUp?: boolean
  smDown?: boolean
  smUp?: boolean
  xlDown?: boolean
  xlUp?: boolean
  xsDown?: boolean
  xsUp?: boolean
}

/**
 * Simplified reimplementation of MUIv4's Hidden (deprecated in MUIv5). It allows passing 1 prop only!
 */

const Hidden = ({ children, ...props }: PropsWithChildren<HiddenProps>) => {
  const breakpointProp = Object.keys(props)[0] || ''
  const breakpoint = breakpointProp.substring(0, 2) as Breakpoint
  const direction = breakpointProp.substring(2)

  const isHidden = useMediaQuery<Theme>(theme => {
    // always hide if no props are passed :
    if (!breakpointProp) {
      return theme.breakpoints.up('xs')
    }

    return direction === 'Up'
      ? theme.breakpoints.up(breakpoint)
      : theme.breakpoints.down(breakpoint)
  })

  if (isHidden) return null

  return <>{children}</>
}

export default Hidden
