import { AddCircleRounded } from '@mui/icons-material'
import { Chip, CircularProgress, IconButton, styled } from '@mui/material'

const AUTOCOMPLETE_CHIP_WIDTH = 200

interface LoaderProps {
  shifted?: boolean
}

export const Loader = styled(CircularProgress)<LoaderProps>(
  ({ theme, shifted }) => ({
    marginRight: theme.spacing(shifted ? 6 : 2),
    marginBottom: 0,
  })
)

export const AddIcon = styled(AddCircleRounded)(({ theme }) => ({
  color: theme.palette.neutral.dark,
  padding: 0,
  margin: 0,
}))

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(1.5),
  marginRight: theme.spacing(1),
  textOverflow: 'ellipsis',
  maxWidth: AUTOCOMPLETE_CHIP_WIDTH,
}))
