import {
  InputLabel as BaseInputLabel,
  alpha,
  styled,
  Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const InputLabel = styled(BaseInputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 1, 2, 3),
    backgroundColor: alpha(theme.palette.common.black, 0.13),
    fontSize: 12,
    boxShadow: `inset 0px 4px 4px ${alpha(theme.palette.common.black, 0.13)}`,
    borderRadius: 3,
  },
}))
