import { useTranslation } from 'react-i18next'
import { Grid, List } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useDateFns, useAssignmentsNotifications } from '../../../hooks'
import MoreAssignmentItem from './MoreAssignmentItem'
import { useModalProps } from '../AssignmentModal.utils'
import {
  TitleContainer,
  Title,
  CancelButton,
} from './MoreAssignmentsContent.style'

const MoreAssignmentsContent = () => {
  const { t } = useTranslation()
  const dateOptions = useDateFns()
  const { data, showNotifications, closeModal } = useModalProps()

  const { unreadNotificationsIds } = useAssignmentsNotifications(
    data?.assignments
  )

  if (!data || !data.assignments) return null
  const { assignments, shiftName } = data

  const date = parseISO(assignments[0].startDate)
  const datesTitle = date
    ? `${format(date, 'd MMMM')} | ${format(date, 'EEEE', dateOptions)}`
    : ''
  const shiftTitle = shiftName && ` | ${shiftName}`

  return (
    <Grid container direction="column">
      <TitleContainer item>
        <Title>
          {datesTitle}
          {shiftTitle}
        </Title>
      </TitleContainer>
      <List>
        {assignments.map((assignment, i) => {
          const hasNotification =
            unreadNotificationsIds.filter(id => id === assignment.id).length > 0

          return (
            <MoreAssignmentItem
              key={`more-assignment-item-${i}`}
              assignment={assignment}
              showNotifications={showNotifications && hasNotification}
            />
          )
        })}
      </List>
      <Grid item container alignItems="flex-start">
        <CancelButton color="primary" onClick={closeModal}>
          {t('assignmentModal.cancel')}
        </CancelButton>
      </Grid>
    </Grid>
  )
}

export default MoreAssignmentsContent
