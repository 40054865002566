import { useCallback } from 'react'
import { RootState } from 'app'
import { useDispatch, useSelector } from '../hooks'
import type { ResourceActionCreator, Resource, ResourceData } from './types'

export const useResource = <
  Data = unknown,
  Payload = unknown,
  AdditionalPayload = unknown
>(
  actionCreator: ResourceActionCreator<Payload>,
  selector: (state: RootState) => Resource<Data>,
  additionalPayload?: AdditionalPayload
): ResourceData<Data, Payload> => {
  const dispatch = useDispatch()
  const data = useSelector<Resource<Data>>(selector)
  const getData = useCallback(
    (payload: Payload) =>
      dispatch(actionCreator({ ...payload, ...additionalPayload })),
    [dispatch, actionCreator, additionalPayload]
  )
  return { ...data, getData }
}
