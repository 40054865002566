import { PaletteOptions } from '@mui/material/styles'

declare module '@mui/material/styles/' {
  interface TypeBackground {
    primary: string
    secondary: string
  }

  interface Palette {
    neutral: Palette['primary']
    pill: {
      green: Palette['primary']
      red: Palette['primary']
      yellow: Palette['primary']
      gray: Palette['primary']
      blue: Palette['primary']
      pink: Palette['primary']
      border: Palette['primary']
    }
    statusCircle: {
      gray: Palette['primary']
    }
    shadow: Palette['primary']
    viviumX: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
    pill: {
      green: PaletteOptions['primary']
      red: PaletteOptions['primary']
      yellow: PaletteOptions['primary']
      gray: PaletteOptions['primary']
      blue: PaletteOptions['primary']
      pink: PaletteOptions['primary']
      border: PaletteOptions['primary']
    }
    shadow: PaletteOptions['primary']
    viviumX: PaletteOptions['primary']
    statusCircle: {
      gray: PaletteOptions['primary']
    }
  }
}

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    light: '#f5a583',
    main: '#F38E64',
    dark: '#9c4827',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#DDDDDD',
    main: '#595959',
    dark: '#515151',
    contrastText: '#FFF',
  },
  neutral: {
    light: '#F2F2F2',
    main: '#EBEBEB',
    dark: '#CBCBCB',
    contrastText: 'rgba(0,0,0,0.8)',
  },
  background: {
    default: '#FAFAFA',
    paper: '#FFF',
    primary: '#FFF9F6',
    secondary: '#8A8A8A',
  },
  text: {
    secondary: '#828282',
  },
  pill: {
    green: {
      main: '#BCE7B5',
      dark: '#65BF73',
    },
    red: {
      light: '#F4ABAB',
      main: '#F97D7D',
      dark: '#E75D5D',
    },
    yellow: {
      light: '#FFDEAD',
      main: '#FFAF3A',
      dark: '#BC8E18',
    },
    gray: {
      light: '#B9B9B9',
      main: '#ACACAC',
      dark: '#606060',
      contrastText: '#B3B3B3',
    },
    blue: {
      main: '#36BCDA',
      light: '#BAEFFB',
      dark: '#8ED0E3',
    },
    pink: {
      main: '#FFEFE5',
    },
    border: {
      light: '#BDBDBD',
      main: '#303030',
    },
  },
  shadow: {
    main: 'rgba(0, 0, 0, 0.12)',
    dark: 'rgba(0, 0, 0, 0.28)',
  },
  statusCircle: {
    gray: {
      light: '#E6E6E6',
      main: '#B6B6B6',
    },
  },
  error: {
    main: '#f44336',
  },
  success: {
    main: '#4caf50',
  },
  viviumX: {
    light: '#52B9C4',
    main: '#089BAB',
    dark: '#06899A',
    contrastText: '#FFF',
  },
}

export default palette
