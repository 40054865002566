import {
  styled,
  Grid,
  DialogContent,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material'

interface StyledDialogParams {
  isLoading: boolean
}

export const StyledDialog = styled(Dialog)<StyledDialogParams>(
  ({ isLoading }) => ({
    '& .MuiDialog-paper': {
      ...(isLoading && {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
      }),
    },
  })
)

export const Header = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
}))

export const Title = styled(Typography)(({ theme }) => ({
  lineBreak: 'anywhere',
  marginRight: theme.spacing(1),
}))

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5),
}))

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  padding: theme.spacing(2),
  minHeight: 132,
}))

export const StyledFeedbackButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  margin: theme.spacing(0, 1),

  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}))
