import { InputBase, MenuItem, styled } from '@mui/material'

interface StyledInputProps {
  border: boolean
}

export const StyledInput = styled(InputBase)<StyledInputProps>(
  ({ theme, border }) => {
    const borderWidth = border ? 2 : 0

    return {
      '& .MuiInputBase-input': {
        padding: '0px 16px 0px 4px !important',
        backgroundColor: theme.palette.background.default,
        border: `${borderWidth}px solid ${theme.palette.primary.main}`,
      },

      '& .MuiSelect-icon': {
        color: theme.palette.primary.main,
        width: theme.spacing(2),
        height: theme.spacing(2),
        // custom icon position is required because the component is too small to leave default MUI positioning
        // positioning also has to accommodate to total container size, therefore to the border; thanks MUI
        top: 5 + borderWidth,
        right: 0 + borderWidth,
      },
    }
  }
)

export const StyledFlagIcon = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  width: theme.spacing(1.25),
}))

interface StyledMenuItemProps {
  selected: boolean
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(
  ({ theme, selected }) => ({
    ...(selected && { display: 'none' }),
    padding: theme.spacing(0.5, 1),
  })
)
