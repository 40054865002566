import {
  CareProviderResourceDocumentType,
  Country,
  WeekDay,
} from 'shared/types'
import env from './env'

const DAY_SECONDS = 24 * 60 * 60

const scheduler = {
  firstDayOfWeek: 1 as WeekDay,
  startDayHour: 9,
  endDayHour: 11,
  resourceName: 'shiftId',
}

const config = {
  ...env,
  scheduler,
  notificationsListenerInterval: 30000,
  messagesUpdateInterval: 15000,
  adminInfoMessageListenerInterval: 300000,
  newVersionListenerInterval: 300000,
  maxSnackbarsCount: 3,
  sessionLength: DAY_SECONDS,
  documentTypes: [
    CareProviderResourceDocumentType.CV,
    CareProviderResourceDocumentType.Reference,
    CareProviderResourceDocumentType.HOSP,
    CareProviderResourceDocumentType.IVO,
    CareProviderResourceDocumentType.QualifyingPeriod,
  ],
  versionFileName: '/version.txt',
  pagination: {
    defaultCount: 0,
    defaultPage: 0,
    defaultPageSize: 10,
  },
  localStorage: {
    schedulerAvailabilityVisibleKey: 'scheduler.isAvailabilityVisible',
    resourceAvailabilityTimeFrom: 'resourceAvailability.timeFrom',
    resourceAvailabilityTimeTo: 'resourceAvailability.timeTo',
  },
  defaultExportFileExtension: 'xlsx',
  defaultQueryArrayLimit: 200,
  defaultSearchBy: 'name',
  defaultSortBy: 'name',
  defaultCountry: Country.Sweden,
}

export default config
