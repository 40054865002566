import { useFormContext } from 'react-hook-form'
import { Tag } from '../types'
import { getAutocompleteValue } from '../utils'

export const useUpdateTags = (name: string) => {
  const { setValue, getValues } = useFormContext()
  return (tag: Tag) => {
    const previousTags = getValues(name)
    setValue(name, [...previousTags, getAutocompleteValue(tag)])
  }
}
