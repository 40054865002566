import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../resource'
import { GetSchedulesDaysParams } from '../../services/schedules'
import {
  AdministrationUnit,
  AssignmentModalData,
  ScheduleDays,
  OpenShiftAssignmentDraft,
  SchedulerDates,
  Shift,
  UnitNeed,
  ScheduleDaysAssignment,
  AssignmentDetailedInfo,
  ListingAssignment,
} from '../../types'
import {
  closeAssignmentModal,
  openAssignmentModal,
  setSchedulerDates,
  removeAssignments,
  resetRemoveAssignments,
  getPickerAssignments,
  resetGetPickerAssignments,
  setSelectedOpenShiftAssignment,
  deleteNeed,
  getUnitNeeds,
  markNeedsAsRead,
  getSchedulesDays,
  resetSchedulesDays,
  getUnit,
  resetUnit,
  getSchedulesShifts,
  createOpenShiftAssignment,
  resetSelectedOpenShiftAssignment,
  getListingAssignmentData,
  resetAssignmentData,
  editOpenShiftAssignment,
  getNeedAssignmentData,
  setAssignmentModalRole,
} from './actions'

export interface State {
  getSchedulesDays: Resource<ScheduleDays[], GetSchedulesDaysParams>
  assignmentModalData: AssignmentModalData | null
  selectedOpenShiftAssignment: OpenShiftAssignmentDraft | null
  schedulerDates: SchedulerDates | null
  getPickerAssignments: Resource<ScheduleDaysAssignment[]>
  removeAssignments: Resource
  deleteNeed: Resource<string>
  getUnitNeeds: Resource<UnitNeed[]>
  getUnit: Resource<AdministrationUnit>
  getSchedulesShifts: Resource<Shift[]>
  createOpenShiftAssignment: Resource<string>
  editOpenShiftAssignment: Resource<string>
  getNeedAssignmentData: Resource<AssignmentDetailedInfo>
  getListingAssignmentData: Resource<ListingAssignment>
}

const initialState: State = {
  getSchedulesDays: resource.getInitial<ScheduleDays[]>([]),
  assignmentModalData: null,
  selectedOpenShiftAssignment: null,
  schedulerDates: null,
  getPickerAssignments: resource.getInitial([]),
  removeAssignments: resource.getInitial(),
  deleteNeed: resource.getInitial<string>(''),
  getUnitNeeds: resource.getInitial<UnitNeed[]>([]),
  getUnit: resource.getInitial<AdministrationUnit>(),
  getSchedulesShifts: resource.getInitial<Shift[]>([]),
  createOpenShiftAssignment: resource.getInitial<string>(''),
  editOpenShiftAssignment: resource.getInitial<string>(''),
  getNeedAssignmentData: resource.getInitial<AssignmentDetailedInfo>(),
  getListingAssignmentData: resource.getInitial<ListingAssignment>(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getSchedulesDays.pending, (state, action) => {
      resource.setPending(state.getSchedulesDays, action.meta.arg)
      if (action.meta.arg.refreshOnPending) state.getSchedulesDays.data = []
    })
    .addCase(getSchedulesDays.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSchedulesDays, action.payload)
    })
    .addCase(getSchedulesDays.rejected, (state, action) => {
      resource.setFailed(state.getSchedulesDays, action.error.message)
    })
    .addCase(resetSchedulesDays, state => {
      resource.reset(state.getSchedulesDays, initialState.getSchedulesDays.data)
    })
    .addCase(openAssignmentModal, (state, action) => {
      state.assignmentModalData = action.payload
    })
    .addCase(setSelectedOpenShiftAssignment, (state, action) => {
      state.selectedOpenShiftAssignment = action.payload
    })
    .addCase(resetSelectedOpenShiftAssignment, (state, action) => {
      state.selectedOpenShiftAssignment = null
    })
    .addCase(closeAssignmentModal, state => {
      state.assignmentModalData = null
    })

    .addCase(setSchedulerDates, (state, action) => {
      state.schedulerDates = action.payload
    })
    .addCase(getPickerAssignments.pending, state => {
      resource.setPending(state.getPickerAssignments)
    })
    .addCase(getPickerAssignments.fulfilled, (state, action) => {
      const assignments = action.payload.reduce(
        (acc, curr) => [...acc, ...curr.assignments],
        [] as ScheduleDaysAssignment[]
      )
      resource.setSucceeded(state.getPickerAssignments, assignments)
    })
    .addCase(getPickerAssignments.rejected, (state, action) => {
      resource.setFailed(state.getPickerAssignments, action.error.message)
    })
    .addCase(resetGetPickerAssignments, state => {
      resource.reset(
        state.getPickerAssignments,
        initialState.getPickerAssignments.data
      )
    })
    .addCase(removeAssignments.pending, state => {
      resource.setPending(state.removeAssignments)
    })
    .addCase(removeAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.removeAssignments, action.payload)
    })
    .addCase(removeAssignments.rejected, (state, action) => {
      resource.setFailed(state.removeAssignments, action.error.message)
    })
    .addCase(resetRemoveAssignments, state => {
      resource.reset(
        state.removeAssignments,
        initialState.removeAssignments.data
      )
    })
    .addCase(deleteNeed.pending, state => {
      resource.setPending(state.deleteNeed)
    })
    .addCase(deleteNeed.fulfilled, (state, action) => {
      resource.setSucceeded(state.deleteNeed, action.payload)
      state.getUnitNeeds.data = state.getUnitNeeds.data.filter(
        ({ id }) => id !== action.meta.arg.params.needId
      )
    })
    .addCase(deleteNeed.rejected, (state, action) => {
      resource.setFailed(state.deleteNeed, action.error.message)
    })
    .addCase(getUnitNeeds.pending, state => {
      resource.setPending(state.getUnitNeeds)
    })
    .addCase(getUnitNeeds.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUnitNeeds, action.payload)
    })
    .addCase(getUnitNeeds.rejected, (state, action) => {
      resource.setFailed(state.getUnitNeeds, action.error.message)
    })
    .addCase(markNeedsAsRead.fulfilled, (state, action) => {
      state.getUnitNeeds.data = state.getUnitNeeds.data.map(unitNeed => {
        return {
          ...unitNeed,
          notifications: unitNeed.notifications.filter(
            notification =>
              !action.meta.arg.notificationIds.includes(notification.id)
          ),
        }
      })
    })
    .addCase(getUnit.pending, state => {
      resource.setPending(state.getUnit)
    })
    .addCase(getUnit.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUnit, action.payload)
    })
    .addCase(getUnit.rejected, (state, action) => {
      resource.setFailed(state.getUnit, action.error.message)
    })
    .addCase(resetUnit, state => {
      resource.reset(state.getUnit, initialState.getUnit.data)
    })
    .addCase(getSchedulesShifts.pending, state => {
      resource.setPending(state.getSchedulesShifts)
    })
    .addCase(getSchedulesShifts.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSchedulesShifts, action.payload)
    })
    .addCase(getSchedulesShifts.rejected, (state, action) => {
      resource.setFailed(state.getSchedulesShifts, action.error.message)
    })
    .addCase(createOpenShiftAssignment.pending, state => {
      resource.setPending(state.createOpenShiftAssignment)
    })
    .addCase(createOpenShiftAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.createOpenShiftAssignment, action.payload)
    })
    .addCase(createOpenShiftAssignment.rejected, (state, action) => {
      resource.setFailed(state.createOpenShiftAssignment, action.error.message)
    })
    .addCase(editOpenShiftAssignment.pending, state => {
      resource.setPending(state.editOpenShiftAssignment)
    })
    .addCase(editOpenShiftAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.editOpenShiftAssignment, action.payload)
    })
    .addCase(editOpenShiftAssignment.rejected, (state, action) => {
      resource.setFailed(state.editOpenShiftAssignment, action.error.message)
    })
    .addCase(getListingAssignmentData.pending, state => {
      resource.setPending(state.getListingAssignmentData)
    })
    .addCase(getListingAssignmentData.fulfilled, (state, action) => {
      resource.setSucceeded(state.getListingAssignmentData, action.payload)
    })
    .addCase(getListingAssignmentData.rejected, (state, action) => {
      resource.setFailed(state.getListingAssignmentData, action.error.message)
    })
    .addCase(getNeedAssignmentData.pending, state => {
      resource.setPending(state.getNeedAssignmentData)
    })
    .addCase(getNeedAssignmentData.fulfilled, (state, action) => {
      resource.setSucceeded(state.getNeedAssignmentData, action.payload)
    })
    .addCase(getNeedAssignmentData.rejected, (state, action) => {
      resource.setFailed(state.getNeedAssignmentData, action.error.message)
    })
    .addCase(resetAssignmentData, state => {
      resource.reset(
        state.getNeedAssignmentData,
        initialState.getNeedAssignmentData.data
      )
      resource.reset(
        state.getListingAssignmentData,
        initialState.getListingAssignmentData.data
      )
    })
    .addCase(setAssignmentModalRole, (state, action) => {
      if (!state.assignmentModalData) return
      state.assignmentModalData.roleShortCode = action.payload
    })
)
