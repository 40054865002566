import { Grid } from '@mui/material'
import { AssignmentHistoryEntry } from '../../types'
import NoteEntry from './NoteEntry'
import { SectionWrapper } from './NotesSection.styles'

interface NotesSectionProps {
  historyEntries?: AssignmentHistoryEntry[]
  fullWidth?: boolean
}

const NotesSection = ({ historyEntries, fullWidth }: NotesSectionProps) => {
  if (!historyEntries || historyEntries?.length === 0) return null

  return (
    <SectionWrapper fullWidth={fullWidth}>
      <Grid
        item
        container
        direction="column"
        justifyContent="space-around"
        spacing={1}
      >
        {historyEntries.map((entry, i) => (
          <NoteEntry historyEntry={entry} key={`history-entry-${i}`} />
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export default NotesSection
