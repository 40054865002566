import { memo, MouseEventHandler } from 'react'
import { Clear } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'

interface DateFieldInputProps {
  renderInputProps: TextFieldProps
  error: any
  isClearable: boolean
  onChange: (date: Date | string | null) => void
  placeholder?: string
  helperText?: string
  dataTestId?: string
}

const DateFieldInput = ({
  renderInputProps,
  error,
  isClearable,
  onChange,
  placeholder,
  helperText,
  dataTestId,
}: DateFieldInputProps) => {
  const hasCustomPlaceholder = !!placeholder
  const { inputProps: defaultInputProps } = renderInputProps
  const inputProps = {
    ...defaultInputProps,
    'data-test-id': dataTestId,
    placeholder: hasCustomPlaceholder
      ? placeholder
      : defaultInputProps?.placeholder,
    sx: {
      order: -1,
      textTransform: hasCustomPlaceholder ? 'none' : 'uppercase',
    },
  }
  const handleClear: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    onChange(null)
  }

  return (
    <TextField
      {...renderInputProps}
      error={!!error}
      helperText={helperText || error?.message}
      inputProps={inputProps}
      InputProps={{
        ...renderInputProps.InputProps,
        // This is a very rough solution to display the clear button like we used to prior to MUIv5. MUIv5 pickers use endAdornment for the popover/modal button, so we can't use endAdornment anymore.
        // We need this misleading startAdornment (it's actually at the end) and add `order: -1` css prop to the sibling <input> element above, which is pretty hacky and unreadable.
        // If this becomes a problem, we can try absolute positioning solution for the clear button. Note that for me it was causing problems with typing dates with keyboard in the input and size responsiveness problems in mobile.
        startAdornment: isClearable && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default memo(DateFieldInput)
