import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import { NavigateBefore } from '@mui/icons-material'
import { Grid, Button, Typography } from '@mui/material'
import { paths } from 'config'
import { useEnumOptions, useOrganizationId } from '../../../hooks'
import {
  AssignmentStatus,
  LoadingStatus,
  ResourceUnassignmentReason,
} from '../../../types'
import Divider from '../../Divider'
import Form from '../../Form'
import LoadingButton from '../../LoadingButton'
import NotesSection from '../../NotesSection'
import ShiftsSection from '../ShiftsSection'
import {
  Section,
  StyledCheckboxField,
  StyledRadioField,
  StyledTextAreaField,
} from '../AssignmentModal.style'
import { useHasCPPermissions, useModalProps } from '../AssignmentModal.utils'
import {
  BookedAssignmentGrid,
  NameGrid,
  ShiftsGrid,
  DividerText,
  StyledIconButton,
  StatusChip,
  Name,
} from './BookedAssignmentContent.style'
import {
  useFormProps,
  BookedAssignmentContentFields,
} from './BookedAssignmentContent.utils'

export const BookedAssignmentContent = () => {
  const { t } = useTranslation()
  const { needAssignment, onBack, handleClose, bookedLoading, data } =
    useModalProps()
  const organizationId = useOrganizationId()
  const formProps = useFormProps()
  const [active] = useWatch({
    control: formProps.control,
    name: [BookedAssignmentContentFields.Active],
  })
  const reasonOptions = useEnumOptions(ResourceUnassignmentReason, 'ReasonType')

  const name = useMemo(
    () =>
      needAssignment?.resource?.name || needAssignment?.externalResourceName,
    [needAssignment]
  )
  const chipLabel = useMemo(
    () =>
      needAssignment?.externalResourceName
        ? t('notification.external')
        : t('notification.internal'),
    [needAssignment?.externalResourceName, t]
  )
  const hasCPPermissions = useHasCPPermissions()

  const resourceId = needAssignment?.resource?.id
  const path = useMemo(
    () =>
      resourceId
        ? generatePath(paths.organizationResourceDetails, {
            organizationId: organizationId.toString(),
            resourceId: resourceId.toString(),
          })
        : generatePath(paths.organizationResources, {
            organizationId: organizationId.toString(),
          }),
    [organizationId, resourceId]
  )

  const notesLabel = hasCPPermissions ? '' : t('assignmentModal.notes')

  return (
    <Form {...formProps}>
      <BookedAssignmentGrid container spacing={2}>
        <NameGrid container item justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center">
              {!!data?.assignments && (
                <StyledIconButton edge="start" onClick={onBack}>
                  <NavigateBefore color="inherit" fontSize="large" />
                </StyledIconButton>
              )}
              {needAssignment?.resource ? (
                <Link to={path}>
                  <Name>{name}</Name>
                </Link>
              ) : (
                <Name>{name}</Name>
              )}
              <StatusChip
                externalResource={!!needAssignment?.externalResourceName}
                label={chipLabel}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography color="textSecondary">
              {needAssignment.need?.role.shortCode}
            </Typography>
          </Grid>
        </NameGrid>
        {needAssignment?.status === AssignmentStatus.BookedInternally && (
          <ShiftsGrid item>
            <ShiftsSection
              name={BookedAssignmentContentFields.Shifts}
              assignmentId={needAssignment?.id}
              resourceId={needAssignment?.resource?.id}
              assignmentStatus={needAssignment?.status}
              preliminary={needAssignment?.isPreliminary}
            />
          </ShiftsGrid>
        )}
        <Grid item>
          <Divider>
            <DividerText>{t('assignmentModal.notes')}</DividerText>
          </Divider>
        </Grid>
        <Grid item>
          {!!needAssignment && (
            <NotesSection historyEntries={needAssignment.history} />
          )}
        </Grid>
        <Grid item>
          <StyledTextAreaField
            name={BookedAssignmentContentFields.Notes}
            multiline
            rows={3}
            label={notesLabel}
          />
        </Grid>
        {Boolean(needAssignment?.resourceNote) && (
          <Grid item>
            <StyledTextAreaField
              name={BookedAssignmentContentFields.ResourceNote}
              value={needAssignment?.resourceNote}
              multiline
              InputProps={{
                readOnly: true,
              }}
              rows={3}
              label={t('assignmentModal.resourceNote')}
            />
          </Grid>
        )}
        {hasCPPermissions && (
          <>
            <Grid item>
              <Divider>
                <DividerText>{t('assignmentModal.manage')}</DividerText>
              </Divider>
            </Grid>
            <Grid item>
              <Section item>
                <StyledCheckboxField
                  name={BookedAssignmentContentFields.Overtime}
                  label={t('assignmentModal.markOvertime')}
                />
              </Section>
            </Grid>
            <Grid item>
              <Section item>
                <StyledCheckboxField
                  name={BookedAssignmentContentFields.Absence}
                  label={t('assignmentModal.markAbsence')}
                />
              </Section>
            </Grid>

            <Grid item>
              <Section
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <StyledCheckboxField
                    data-test-id="cancel-resource-label"
                    name={BookedAssignmentContentFields.Active}
                    label={t('assignmentModal.cancelResource')}
                  />
                </Grid>
                <Grid item>
                  <StyledRadioField
                    data-test-id="reason-radio-field"
                    name={BookedAssignmentContentFields.Reason}
                    options={reasonOptions}
                    disabled={!active}
                  />
                </Grid>
              </Section>
            </Grid>
          </>
        )}
        <Grid item>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button color="primary" onClick={handleClose}>
                {t('assignmentModal.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                fullWidth={false}
                loading={bookedLoading === LoadingStatus.Pending}
              >
                {t('assignmentModal.save')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </BookedAssignmentGrid>
    </Form>
  )
}

export default BookedAssignmentContent
