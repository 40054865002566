import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssignmentsDatesText } from '../../hooks'
import {
  AssignmentStatus,
  ScheduleDaysAssignment,
  ShiftType,
  UsePillTextsOptions,
} from '../../types'
import { joinStrings } from '../../utils'
import UserNote from './UserNote'
import ViviumXTooltip from './ViviumXTooltip'

export const RESOURCE_NOTE_ICON_STATUSES = [
  AssignmentStatus.Available,
  AssignmentStatus.Bookable,
  AssignmentStatus.BookedInternally,
  AssignmentStatus.BookedExternally,
  AssignmentStatus.Requestable,
]

export const usePillTooltipDetails = ({
  assignment,
  hasUnitName,
  shouldShowDeleteState,
}: UsePillTextsOptions) => {
  const { t } = useTranslation()
  return useMemo(() => {
    if (!assignment) return ''

    const {
      resource,
      externalResourceName,
      resourceNote,
      status,
      unit,
      roleShortCode,
      listingAssignmentId,
    } = assignment
    const unitName = hasUnitName ? unit?.name : ''
    const hasViviumXAffiliation = !!listingAssignmentId

    if (hasViviumXAffiliation && status)
      return (
        <ViviumXTooltip
          roleShortCode={roleShortCode}
          assignmentStatus={status}
          resourceName={externalResourceName}
        />
      )

    switch (status) {
      case AssignmentStatus.BookedInternally:
        return joinStrings(unitName, resource?.name, roleShortCode)
      case AssignmentStatus.BookedExternally:
        return joinStrings(unitName, externalResourceName, roleShortCode)
      case AssignmentStatus.Available:
      case AssignmentStatus.Bookable:
      case AssignmentStatus.Requestable:
        return resourceNote ? <UserNote note={resourceNote} /> : resource?.name
      case AssignmentStatus.RequestedExternally:
      case AssignmentStatus.RequestedInternally:
        return joinStrings(
          hasUnitName ? unit?.name : null,
          t(`scheduler.status.${status}`),
          roleShortCode
        )
      case AssignmentStatus.NoResources:
      case AssignmentStatus.Empty:
      case AssignmentStatus.Canceled:
        return shouldShowDeleteState
          ? t('form.remove')
          : joinStrings(
              unitName,
              t(`scheduler.status.${status}`),
              roleShortCode
            )
      default:
        return ''
    }
  }, [assignment, hasUnitName, shouldShowDeleteState, t])
}

export const usePillTooltip = (params: UsePillTextsOptions) => {
  const tooltipDetails = usePillTooltipDetails(params)
  const assignment = params.assignment
  const assignmentsDatesText = useAssignmentsDatesText(
    assignment?.startDate,
    assignment?.endDate
  )

  if (assignment?.type === ShiftType.Defined) return tooltipDetails

  return `${assignmentsDatesText} ${tooltipDetails}`
}

export const useLabelProps = (assignment: ScheduleDaysAssignment) => {
  const hasMessageIcon = useMemo(
    () =>
      !!assignment.hasNote ||
      (RESOURCE_NOTE_ICON_STATUSES.includes(assignment.status) &&
        !!assignment.resourceNote?.length) ||
      !!assignment.listingAssignment?.hasNote,
    [
      assignment.hasNote,
      assignment.listingAssignment?.hasNote,
      assignment.resourceNote?.length,
      assignment.status,
    ]
  )

  const hasXIcon = useMemo(
    () => !!assignment.listingAssignmentId,
    [assignment.listingAssignmentId]
  )

  const hasOvertime = useMemo(() => {
    const baseCheck =
      !!assignment.hasOvertime &&
      RESOURCE_NOTE_ICON_STATUSES.includes(assignment.status)

    if (!!assignment.listingAssignment)
      return baseCheck && assignment.listingAssignment.hasOvertime

    return baseCheck
  }, [assignment.hasOvertime, assignment.listingAssignment, assignment.status])

  const isIconVisible = useMemo(
    () => hasMessageIcon || hasOvertime,
    [hasMessageIcon, hasOvertime]
  )

  return { hasMessageIcon, hasXIcon, isIconVisible }
}
