import {
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material'
import {
  BackgroundCircle,
  Container,
  LabelContainer,
  useStyles,
} from './CircularProgressWithLabel.style'

const CIRCULAR_PROGRESS_THICKNESS = 3

const CircularProgressWithLabel = ({
  size = 48,
  ...props
}: CircularProgressProps) => {
  const classes = useStyles()
  return (
    <Container>
      <BackgroundCircle
        variant="determinate"
        value={100}
        size={size}
        thickness={CIRCULAR_PROGRESS_THICKNESS}
        color="inherit"
      />
      <CircularProgress
        size={size}
        thickness={CIRCULAR_PROGRESS_THICKNESS}
        color="inherit"
        classes={classes}
        {...props}
      />
      <LabelContainer>
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value || 0)}%`}</Typography>
      </LabelContainer>
    </Container>
  )
}

export default CircularProgressWithLabel
