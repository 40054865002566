import { Components } from '@mui/material'

const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    scroller: {
      borderBottomWidth: 1,
      borderBottomColor: '#EBEBEB',
      borderBottomStyle: 'solid',
      padding: '0px 24px',
    },
  },
}

export default MuiTabs
