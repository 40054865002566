import { CareProviderResourceDocumentType } from '../../types'
import Api from '../api'
import {
  DeleteResourceDocumentParams,
  GetResourceDocumentParams,
  GetResourceDocumentResponse,
  UploadDocumentResponse,
} from './Documents.types'

class Documents extends Api {
  public uploadResourceDocument = async (
    careProviderId: number,
    type: CareProviderResourceDocumentType,
    file: File
  ) => {
    const formData = new FormData()
    formData.append('type', type)
    formData.append('file', file)
    const { data } = await this.api.post<UploadDocumentResponse>(
      `/care-providers/${careProviderId}/resource-documents`,
      formData
    )
    return data
  }

  public deleteResourceDocument = ({
    careProviderId,
    documentId,
  }: DeleteResourceDocumentParams) =>
    this.api.delete(
      `/care-providers/${careProviderId}/resource-documents/${documentId}`
    )

  public getResourceDocument = async ({
    careProviderId,
    documentId,
    ...params
  }: GetResourceDocumentParams) => {
    const { data } = await this.api.get<GetResourceDocumentResponse>(
      `/care-providers/${careProviderId}/resource-documents/${documentId}`,
      { params }
    )
    return data
  }
}

export default Documents
