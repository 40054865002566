import { TablePagination, TablePaginationProps } from '@mui/material'
import { Container } from './DataTablePagination.style'

const DataTablePagination = (props: TablePaginationProps) => (
  <Container>
    {/* @ts-ignore */}
    <TablePagination
      labelRowsPerPage={null}
      rowsPerPageOptions={[]}
      component="div"
      {...props}
    />
  </Container>
)

export default DataTablePagination
