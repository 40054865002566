import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { paths } from 'config'
import { useDispatch, useSelector } from '../../../../hooks'
import { useOrganizationId, useOrganizationResourceId } from '../../../../hooks'
import { actions, selectors } from '../../../../store'
import { NotificationType, UserRole, Notification } from '../../../../types'
import {
  RequestNotificationItem,
  ResourceNotificationItem,
  AssignmentNotificationItem,
} from './NotificationItem'

export const NOTIFICATIONS_PAGE_SIZE = 10

export const useChatNotifications = (): Notification[] => {
  const { t } = useTranslation('messages')
  const organizationId = useOrganizationId()
  const count = useSelector(
    selectors.notifications.getUnreadChatNotificationsCount
  )

  return count > 0
    ? [
        {
          id: 0,
          data: {
            message: t('notification.unreadMessagesNotification', { count }),
            url: generatePath(paths.organizationMessages, {
              organizationId: organizationId.toString(),
            }),
          },
          type: NotificationType.Other,
          isRead: false,
        },
      ]
    : []
}

export const resourceNotificationsTypes = [
  NotificationType.NeedAssignmentBooked,
  NotificationType.NeedAssignmentNoteAdded,
  NotificationType.NeedAssignmentRequestCreated,
]

const cpNeedsNotificationsTypes = [
  NotificationType.NeedAssignmentRequestAccepted,
  NotificationType.NeedAssignmentRequestRejected,
]

const cpSchedulerNotificationsTypes = [
  NotificationType.NeedAssignmentNoteAdded,
  NotificationType.NeedAssignmentResourceNoteAdded,
  NotificationType.NeedAssignmentCanceled,
  NotificationType.NeedAssignmentBooked,
]

export const useNotificationDropdowns = (role?: UserRole) => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      role
        ? {
            [UserRole.StaffProvider]: [],
            [UserRole.SuperStaffProvider]: [],
            [UserRole.SuperCareProvider]: [
              {
                types: cpNeedsNotificationsTypes,
                label: t('notification.needs'),
                notificationComponent: RequestNotificationItem,
              },
              {
                types: cpSchedulerNotificationsTypes,
                label: t('notification.schedule'),
                notificationComponent: AssignmentNotificationItem,
              },
            ],
            [UserRole.CareProvider]: [
              {
                types: cpNeedsNotificationsTypes,
                label: t('notification.needs'),
                notificationComponent: RequestNotificationItem,
              },
              {
                types: cpSchedulerNotificationsTypes,
                label: t('notification.schedule'),
                notificationComponent: AssignmentNotificationItem,
              },
            ],
            [UserRole.UnitAdmin]: [
              {
                types: [
                  NotificationType.NeedAssignmentBooked,
                  NotificationType.NeedAssignmentRequestAccepted,
                  NotificationType.NeedAssignmentCanceled,
                  NotificationType.NeedAssignmentNoteAdded,
                  NotificationType.NeedAssignmentResourceNoteAdded,
                ],
                label: t('notification.schedule'),
                notificationComponent: AssignmentNotificationItem,
              },
            ],
            [UserRole.Resource]: [
              {
                types: [
                  NotificationType.NeedAssignmentBooked,
                  NotificationType.NeedAssignmentNoteAdded,
                  NotificationType.NeedAssignmentRequestCreated,
                ],
                label: t('notification.notifications'),
                notificationComponent: ResourceNotificationItem,
              },
            ],
          }[role]
        : [],
    [t, role]
  )
}

export const useGetNotifications = () => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const resourceId = useOrganizationResourceId()
  const notifications = useSelector(selectors.notifications.getNotifications)

  const getNotifications = (
    notificationTypes: NotificationType[],
    skip: number = 0
  ) =>
    dispatch(
      actions.notifications.getNotifications({
        notificationTypes,
        careProviderId,
        skip,
        take: NOTIFICATIONS_PAGE_SIZE,
        ...(resourceId && { resourceId }),
      })
    )

  const resetNotifications = () =>
    dispatch(actions.notifications.resetNotifications())

  return { notifications, getNotifications, resetNotifications }
}
