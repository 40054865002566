import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { MatchingResource } from '../../types'

export const getResourcesSideEffects = (state: RootState) => ({
  loading: state.common.matchingResources.getMatchingResources.loading,
  isInitialized:
    state.common.matchingResources.getMatchingResources.isInitialized,
})

export const getResourcesData = (state: RootState) =>
  state.common.matchingResources.getMatchingResources.data

export const getMatchingResources = createSelector(getResourcesData, data =>
  (data?.matchingResources || []).reduce(
    (acc, resource) => {
      const hasBookableAssignments =
        !!resource.matchingAssignments.bookableAssignments?.length

      const bookable = hasBookableAssignments
        ? [...acc.bookable, resource]
        : acc.bookable

      const requestable =
        !hasBookableAssignments &&
        !!resource.matchingAssignments.requestableAssignments?.length
          ? [...acc.requestable, resource]
          : acc.requestable

      return { requestable, bookable }
    },
    {
      requestable: [] as MatchingResource[],
      bookable: [] as MatchingResource[],
    }
  )
)
