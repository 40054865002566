import { forwardRef } from 'react'
import {
  styled,
  Slide,
  SlideProps,
  alpha,
  darken,
  Typography,
  IconButton,
  Grid,
  DialogContent,
} from '@mui/material'

export const StyledSlide = styled(Slide)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  height: '100%',
}))

export const Transition = forwardRef((props: SlideProps, ref) => (
  <StyledSlide direction="up" ref={ref} {...props} />
))

export const Shadow = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0px 2px 16px ${alpha(theme.palette.common.black, 0.2)}`,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  overflowY: 'auto',
}))

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  '&:first-child': {
    paddingTop: theme.spacing(1.5),
  },
}))

export const Header = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  paddingLeft: theme.spacing(2),
  minHeight: 42,
  '& > *': {
    height: '100%',
  },
}))

interface RemoveButtonProps {
  selected?: boolean
}

export const RemoveButton = styled(IconButton)<RemoveButtonProps>(
  ({ theme, selected }) => ({
    backgroundColor: theme.palette.neutral.dark,
    color: theme.palette.common.white,
    '&:hover,&:focus': {
      backgroundColor: darken(theme.palette.neutral.dark, 0.1),
    },
    '& > *': {
      transition: theme.transitions.create('transform'),
      transform: `rotate(${selected ? 45 : 0}deg)`,
    },
  })
)

export const WeekDay = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.875rem',
  color: theme.palette.text.disabled,
  textTransform: 'capitalize',
  display: 'block',
  lineHeight: 'normal',
}))

export const DateLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: 'block',
  lineHeight: 'normal',
}))

interface FieldGridProps {
  faded?: boolean
}

export const FieldGrid = styled(Grid)<FieldGridProps>(({ faded }) => ({
  opacity: faded ? 0.35 : 1,
}))
