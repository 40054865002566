import { styled, Typography } from '@mui/material'
import { Skeleton } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
}))

export const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

export const Title = styled(Typography)<{ fontSize: number }>(
  ({ theme, fontSize }) => ({
    fontSize: fontSize,
    color: theme.palette.neutral.contrastText,
    paddingTop: theme.spacing(1),
  })
)

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))
