import { useFormContext, useController } from 'react-hook-form'
import {
  Select,
  SelectProps,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
} from '@mui/material'

export interface SelectFieldOption {
  label: string
  value: any
}

export interface SelectFieldProps extends SelectProps {
  name: string
  placeholder?: string
  label?: string
  defaultValue?: any
  options: SelectFieldOption[]
}

const SelectField = ({
  name,
  label,
  placeholder,
  required,
  defaultValue = '',
  options = [],
  ...props
}: SelectFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })
  return (
    <FormControl>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        inputRef={ref}
        error={!!error}
        required={required}
        displayEmpty={!!placeholder}
        {...inputProps}
        {...props}
      >
        {placeholder && (
          <MenuItem value="" disabled={required}>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map(({ label, ...option }, i) => (
          <MenuItem key={`option-${i}`} {...(option as any)}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}

export default SelectField
