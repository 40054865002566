import { CSSProperties } from 'react'
import { alpha, Dialog as BaseDialog, styled, useTheme } from '@mui/material'

export const useBackdropStyle = (): CSSProperties => {
  const theme = useTheme()
  return {
    backgroundColor: alpha(theme.palette.common.white, 0.7),
    backdropFilter: 'blur(5px)',
  }
}

export const Dialog = styled(BaseDialog)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  marginTop: theme.spacing(5),
}))
