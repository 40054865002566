import { PropsWithChildren } from 'react'
import { styled } from '@mui/material'

export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

interface MailLinkProps {
  email: string
  label?: string
}

const MailLink = ({
  email,
  label,
  children,
}: PropsWithChildren<MailLinkProps>) => (
  <StyledLink href={`mailto:${email}`}>{children || label || email}</StyledLink>
)

export default MailLink
