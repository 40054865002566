import { alpha, Avatar, Grid, styled, Typography } from '@mui/material'

const AVATAR_DIMENSION = 30

interface SectionWrapperProps {
  fullWidth?: boolean
}

export const SectionWrapper = styled('div')<SectionWrapperProps>(
  ({ fullWidth, theme }) => ({
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    border: `1px solid ${alpha(theme.palette.common.black, 0.4)}`,
    maxHeight: 100,
    minHeight: 50,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: fullWidth ? '100%' : 420,
  })
)

interface StyledAvatarProps {
  myMessage: boolean
}

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>(
  ({ theme, myMessage }) => ({
    width: AVATAR_DIMENSION,
    height: AVATAR_DIMENSION,
    ...(myMessage && {
      border: `1px solid ${theme.palette.primary.main}`,
      background: 'none',
    }),
  })
)

export const StatusText = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.6rem',
})

export const NoteText = styled(Grid)({
  wordBreak: 'break-word',
})
