import { NotificationsNone, MailOutline } from '@mui/icons-material'
import { useSelector } from '../../../../hooks'
import { useAuth } from '../../../../hooks'
import { selectors } from '../../../../store'
import { LoadingStatus, NotificationType, UserRole } from '../../../../types'
import Hidden from '../../../Hidden'
import NotificationButton from './NotificationButton'
import NotificationsDropdown from './NotificationsDropdown'
import ResourceNotificationsDropdown from './ResourceNotificationsDropdown'
import { NotificationsContainer } from './Notifications.style'
import {
  useChatNotifications,
  useNotificationDropdowns,
  useGetNotifications,
  resourceNotificationsTypes,
} from './Notifications.utils'

interface NotificationsProps {}

const Notifications = (props: NotificationsProps) => {
  const { user, hasAllowedRole } = useAuth()
  const { role } = user || {}

  const { notifications, getNotifications, resetNotifications } =
    useGetNotifications()
  const unreadCount = useSelector(selectors.notifications.getUnreadCount)
  const notificationDropdowns = useNotificationDropdowns(role)
  const chatNotifications = useChatNotifications()

  if (!role) return null

  const sumUnreadCount = (types: NotificationType[]) =>
    types.reduce(
      (unreadNotifications, type) => unreadNotifications + unreadCount[type],
      0
    )

  return (
    <>
      <Hidden mdDown>
        <NotificationsContainer>
          {notificationDropdowns.map(
            ({ types, label, notificationComponent }, index) => {
              const count = notifications.meta?.count || 0
              return (
                <NotificationsDropdown
                  key={`dropdown-${index}`}
                  label={label}
                  unreadCount={sumUnreadCount(types)}
                  notifications={notifications.data}
                  totalCount={count}
                  loading={notifications.loading === LoadingStatus.Pending}
                  initialized={notifications.isInitialized}
                  onOpen={() => getNotifications(types)}
                  onClose={() => resetNotifications()}
                  onLoadMore={() =>
                    getNotifications(types, notifications.data.length)
                  }
                  NotificationComponent={notificationComponent}
                />
              )
            }
          )}
        </NotificationsContainer>
      </Hidden>
      {hasAllowedRole([UserRole.Resource]) && (
        <Hidden mdUp>
          <NotificationsDropdown
            hideArrow
            hideLoadMore
            notifications={chatNotifications}
            label={
              <NotificationButton
                icon={MailOutline}
                unreadCount={chatNotifications.length}
              />
            }
          />
          <ResourceNotificationsDropdown
            label={
              <NotificationButton
                icon={NotificationsNone}
                unreadCount={sumUnreadCount(resourceNotificationsTypes)}
              />
            }
          />
        </Hidden>
      )}
    </>
  )
}

export default Notifications
