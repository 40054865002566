import { KeyboardArrowDown } from '@mui/icons-material'
import {
  alpha,
  styled,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import Loader from '../Loader'
import DataTableRow, { DataTableRowProps } from './DataTableRow'
import { TableHeadVariant } from './DataTable.types'

const HEIGHT_CONTAINER = 600

interface StyledTableContainerProps {
  noOverflow?: boolean
  nested?: boolean
}

export const StyledTableContainer = styled(
  TableContainer
)<StyledTableContainerProps>(({ theme, noOverflow, nested }) => ({
  ...(noOverflow ? { overflowX: 'visible' } : { overflowY: 'auto' }),
  ...(nested && {
    maxHeight: HEIGHT_CONTAINER,
    paddingTop: 0,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  }),
}))

interface StyledTableProps {
  dense?: boolean
  nested?: boolean
  tableHeadVariant?: TableHeadVariant
}

export const StyledTable = styled(Table)<StyledTableProps>(
  ({ theme, dense, nested, tableHeadVariant }) => {
    const borderRadius = theme.spacing(0.5)
    const isTableHeadLight = tableHeadVariant === 'light'

    return {
      borderCollapse: 'separate',
      ...(dense
        ? {
            marginTop: nested ? 0 : theme.spacing(3),
            borderSpacing: 0,
            '& tr:first-child td:first-child': {
              borderTopLeftRadius: borderRadius,
            },
            '& tr:first-child td:last-child': {
              borderTopRightRadius: borderRadius,
            },
            '& tr:last-child td:first-child': {
              borderBottomLeftRadius: borderRadius,
            },
            '& tr:last-child td:last-child': {
              borderBottomRightRadius: borderRadius,
            },
            ...(isTableHeadLight && {
              '& thead tr:first-child th:first-child': {
                borderTopLeftRadius: borderRadius,
              },
              '& thead tr:last-child th:last-child': {
                borderTopRightRadius: borderRadius,
              },
            }),
            '& tbody': {
              borderRadius,
              boxShadow:
                isTableHeadLight || nested
                  ? 'none'
                  : `0px 2px 16px ${alpha(theme.palette.common.black, 0.2)}`,
            },
            boxShadow: isTableHeadLight
              ? `0px 2px 16px ${alpha(theme.palette.common.black, 0.2)}`
              : 'none',
          }
        : {
            borderSpacing: theme.spacing(0, 2),
          }),
    }
  }
)

interface TableSortLabelIconProps {
  active?: boolean
}

export const TableSortLabelIcon = styled(
  KeyboardArrowDown
)<TableSortLabelIconProps>(({ active }) => ({
  fontSize: '1.5rem',
  color: 'inherit !important',
  margin: 0,
  opacity: active ? 1 : 0.25,
}))

export const RowWithHighlightActive = styled(DataTableRow)<DataTableRowProps>(
  ({ row }) => ({
    opacity: row.isActive ? 1 : 0.5,
  })
)

export const GroupingRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.neutral.main,

  '& td': {
    borderBottom: `solid 1px ${theme.palette.common.black}`,
    '&:first-child': {
      borderRight: `solid 1px ${theme.palette.common.black}`,
    },
  },
}))

export const SpannedTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `solid 1px ${theme.palette.common.black}`,
}))

export const SummaryRow = styled(DataTableRow)({
  '& td': { fontSize: '1rem', fontWeight: 800 },
  cursor: 'default',
})

export const BottomLoader = styled(Loader)({
  '& .MuiCircularProgress-root': {
    marginBottom: 0,
  },
})
