import Api from '../api'
import {
  GetNotificationsParams,
  GetNotificationsResponse,
  GetUnreadNotificationsParams,
  GetUnreadNotificationsResponse,
  GetSchedulesUnreadNotificationsParams,
  GetSchedulesUnreadNotificationsResponse,
  GetLatestAdminInfoMessageResponse,
  MarkAdminMessageAsReadParams,
  MarkAdminMessageAsReadResponse,
  GetLatestAdminUpdateMessageResponse,
  GetAdminUpdateMessageResponse,
  GetAdminUpdateMessageParams,
  SendAdminMessageFeedbackResponse,
  SendAdminMessageFeedbackParams,
} from './Notifications.types'

class Notifications extends Api {
  public getUnreadNotifications = async (
    params: GetUnreadNotificationsParams
  ) => {
    const { data } = await this.api.get<GetUnreadNotificationsResponse>(
      '/notifications/unread',
      { params }
    )
    return data.data
  }

  public getNotifications = async (params: GetNotificationsParams) => {
    const response = await this.api.get<GetNotificationsResponse>(
      '/notifications',
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public markAsRead = async (notificationIds: number[]) =>
    this.api.post(`/notifications/read`, { notificationIds })

  public getSchedulesUnreadNotifications = async (
    params: GetSchedulesUnreadNotificationsParams
  ) => {
    const { data } =
      await this.api.get<GetSchedulesUnreadNotificationsResponse>(
        '/notifications/schedules/unread',
        { params }
      )
    return data.data
  }

  public getLatestAdminInfoMessage = async () => {
    const { data } = await this.api.get<GetLatestAdminInfoMessageResponse>(
      '/system-messages/info/latest'
    )
    return data
  }

  public getLatestAdminUpdateMessage = async () => {
    const { data } = await this.api.get<GetLatestAdminUpdateMessageResponse>(
      '/system-messages/update/latest'
    )
    return data
  }

  public getAdminUpdateMessage = async ({
    messageId,
  }: GetAdminUpdateMessageParams) => {
    const { data } = await this.api.get<GetAdminUpdateMessageResponse>(
      `/system-messages/update/${messageId}`
    )
    return data
  }

  public markAdminMessageAsRead = async ({
    messageId,
  }: MarkAdminMessageAsReadParams) => {
    const { data } = await this.api.post<MarkAdminMessageAsReadResponse>(
      `/system-messages/${messageId}/read`
    )
    return data
  }

  public sendAdminMessageFeedback = async ({
    messageId,
    ...params
  }: SendAdminMessageFeedbackParams) => {
    const { data } = await this.api.post<SendAdminMessageFeedbackResponse>(
      `/system-messages/feedback/${messageId}`,
      { ...params }
    )
    return data
  }
}

export default Notifications
