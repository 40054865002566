import { RootState } from 'app'
import { LoadingStatus } from 'shared/types'

export const getBookings = (state: RootState) =>
  state.resourceNotifications.getBookings

export const isGetBookingsLoading = (state: RootState) =>
  state.resourceNotifications.getBookings.loading === LoadingStatus.Pending

export const getMoreBookingsPossibility = (state: RootState) => {
  const bookings = state.resourceNotifications.getBookings
  const count = bookings.meta?.count || 0
  return bookings.data.length < count
}

export const getBookingsCount = (state: RootState) =>
  state.resourceNotifications.getBookings.meta?.count

export const getIsMoreBookingsLoading = (state: RootState) =>
  state.resourceNotifications.getMoreBookings.loading === LoadingStatus.Pending

const getRequest = (state: RootState, requestId: number) =>
  state.resourceNotifications.getRequests.data.find(
    ({ id }) => id === requestId
  )

export const getRequests = (state: RootState) =>
  state.resourceNotifications.getRequests

export const getMoreRequestsPossibility = (state: RootState) => {
  const requests = state.resourceNotifications.getRequests
  const count = requests.meta?.count || 0
  return requests.data.length < count
}

export const getRequestsCount = (state: RootState) =>
  state.resourceNotifications.getRequests.meta?.count

export const getIsMoreRequestsLoading = (state: RootState) =>
  state.resourceNotifications.getMoreRequests.loading === LoadingStatus.Pending

export const getIsRequestAcceptLoading =
  (requestId: number) => (state: RootState) =>
    getRequest(state, requestId)?.acceptRequest.loading ===
    LoadingStatus.Pending

export const getAccepted = (requestId: number) => (state: RootState) =>
  getRequest(state, requestId)?.acceptRequest.loading ===
  LoadingStatus.Succeeded

export const getIsRequestRejectLoading =
  (requestId: number) => (state: RootState) =>
    getRequest(state, requestId)?.rejectRequest.loading ===
    LoadingStatus.Pending

export const getRejected = (requestId: number) => (state: RootState) =>
  getRequest(state, requestId)?.rejectRequest.loading ===
  LoadingStatus.Succeeded
