import { memo } from 'react'
import { Skeleton } from '@mui/material'
import {
  StyledListItem,
  StyledSkeleton,
} from './LegendItemLoadingIndicator.style'

const SIZE = 8
const RECT_WIDTH = 110

interface LegendItemLoadingIndicatorProps {}

const LegendItemLoadingIndicator = memo(
  (props: LegendItemLoadingIndicatorProps) => {
    return (
      <StyledListItem>
        <Skeleton variant="circular" width={SIZE} height={SIZE} />
        <StyledSkeleton
          variant="rectangular"
          width={RECT_WIDTH}
          height={SIZE}
        />
      </StyledListItem>
    )
  }
)

export default LegendItemLoadingIndicator
