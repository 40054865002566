import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  LisitingAssignmentStatus,
  ListingAssignmentCancelReason,
  ResourceUnassignmentReason,
} from '../../../../types'
import { useModalProps } from '../../AssignmentModal.utils'

export enum ListingAssignmentFormFields {
  Notes = 'notes',
  Overtime = 'overtime',
  Absence = 'absence',
  Active = 'active',
  Reason = 'reason',
}

export interface ListingAssignmentFormValues {
  [ListingAssignmentFormFields.Notes]: string
  [ListingAssignmentFormFields.Overtime]: boolean
  [ListingAssignmentFormFields.Absence]: boolean
  [ListingAssignmentFormFields.Active]: boolean
  [ListingAssignmentFormFields.Reason]: ResourceUnassignmentReason
}

const getUnassignmentReason = (
  status: LisitingAssignmentStatus,
  cancelReason: ListingAssignmentCancelReason
) => {
  if (status === LisitingAssignmentStatus.Canceled) {
    switch (cancelReason) {
      case ListingAssignmentCancelReason.Canceled:
        return ResourceUnassignmentReason.Closed
      case ListingAssignmentCancelReason.ResolvedInternally:
        return ResourceUnassignmentReason.Solved
      case ListingAssignmentCancelReason.Emptied:
      default:
        return ResourceUnassignmentReason.Unbooked
    }
  }

  if (status === LisitingAssignmentStatus.Empty)
    return ResourceUnassignmentReason.Unbooked
}

export const useListingAssignmentFormValues = () => {
  const { listingAssignment } = useModalProps()

  return useMemo(
    () => ({
      [ListingAssignmentFormFields.Notes]: listingAssignment?.note || '',
      [ListingAssignmentFormFields.Overtime]: !!listingAssignment?.hasOvertime,
      [ListingAssignmentFormFields.Absence]: !!listingAssignment?.hasAbsence,
      [ListingAssignmentFormFields.Active]: false,
      [ListingAssignmentFormFields.Reason]: getUnassignmentReason(
        listingAssignment.status,
        listingAssignment.cancelReason
      ),
    }),
    [
      listingAssignment.cancelReason,
      listingAssignment?.hasAbsence,
      listingAssignment?.hasOvertime,
      listingAssignment?.note,
      listingAssignment.status,
    ]
  )
}

export const useListingAssignmentForm = () => {
  const defaultValues = useListingAssignmentFormValues()
  const formProps = useForm<ListingAssignmentFormValues>({
    defaultValues,
    reValidateMode: 'onChange',
  })

  const submitProps = useOnSubmit()

  return { ...formProps, ...submitProps }
}

export const useOnSubmit = () => {
  const onSubmit = (values: ListingAssignmentFormValues) => {}

  return {
    onSubmit,
  }
}
