//TODO: Remove this file once react-router v6 reimplements this hook - https://github.com/remix-run/react-router/issues/8139
import { useCallback, useContext, useEffect } from 'react'
import {
  Navigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom'
import { History, Transition } from 'history'

type ExtendNavigator = Navigator & Pick<History, 'block'>
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return

    const unblock = (navigator as ExtendNavigator).block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}

export function usePrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry()
    },
    [message]
  )

  useBlocker(blocker, when)
}
