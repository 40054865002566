import { CircularProgress, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  color: theme.palette.success.main,
}))

export const LabelContainer = styled('div')({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const BackgroundCircle = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.neutral.main,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}))

export const useStyles = makeStyles({
  circle: {
    strokeLinecap: 'round',
  },
})
