import { styled } from '@mui/material'

export const Container = styled('div')({
  display: 'flex',
  overflow: 'auto',
})

interface ContentProps {
  withTopbarFix?: boolean
}

export const Content = styled('main')<ContentProps>(
  ({ theme, withTopbarFix }) => ({
    ...(withTopbarFix && { paddingTop: theme.spacing(8) }),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
  })
)
