import { styled, Typography, TypographyProps } from '@mui/material'

export interface PageTitleProps extends TypographyProps {
  withTopPadding?: boolean
}

export const StyledTypography = styled(Typography)<PageTitleProps>(
  ({ theme, withTopPadding }) => ({
    padding: theme.spacing(withTopPadding ? 5.5 : 1, 0, 1, 5),
  })
)
