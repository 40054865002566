import { useCallback } from 'react'
import { useSelector, useDispatch } from '../../hooks'
import {
  useOrganizationId,
  useGetSchedulesUnreadNotifications,
} from '../../hooks'
import { selectors, actions } from '../../store'

export const useReadAllNotifications = () => {
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const getSchedulesUnreadNotifications = useGetSchedulesUnreadNotifications()

  const unreadNotificationsIds = useSelector(
    selectors.notifications.getSchedulesUnreadNotificationsIds
  )
  const loading = useSelector(selectors.notifications.markAsReadLoading)

  const markAsRead = useCallback(
    () =>
      unreadNotificationsIds &&
      dispatch(
        actions.notifications.markAsRead({
          careProviderId,
          notificationIds: unreadNotificationsIds,
          onSuccess: () => getSchedulesUnreadNotifications(),
        })
      ),
    [
      dispatch,
      getSchedulesUnreadNotifications,
      careProviderId,
      unreadNotificationsIds,
    ]
  )

  return { markAsRead, loading }
}
