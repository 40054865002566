import Api from '../api'
import {
  GetPublishedNeedsParams,
  GetPublishedNeedsResponse,
  PublishNeedParams,
  PublishNeedResponse,
  GetUnitNeedsParams,
  GetUnitNeedsSummariesParams,
  GetUnitNeedsSummariesResponse,
  DeleteNeedParams,
  GetUnitNeedsResponse,
  UpdateNeedParams,
} from './Needs.types'

class Needs extends Api {
  public getPublishedNeeds = async ({
    careProviderId,
    ...params
  }: GetPublishedNeedsParams) => {
    const response = await this.api.get<GetPublishedNeedsResponse>(
      `/care-providers/${careProviderId}/needs/details`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getUnitNeeds = async ({
    careProviderId,
    ...params
  }: GetUnitNeedsParams) => {
    const { data } = await this.api.get<GetUnitNeedsResponse>(
      `/care-providers/${careProviderId}/needs`,
      { params }
    )
    return data.data
  }

  public getUnitNeedsSummaries = async ({
    careProviderId,
    ...params
  }: GetUnitNeedsSummariesParams) => {
    const { data } = await this.api.get<GetUnitNeedsSummariesResponse>(
      `/care-providers/${careProviderId}/needs/summaries`,
      { params }
    )
    return data.data
  }

  public publishNeed = async ({
    careProviderId,
    ...payload
  }: PublishNeedParams) => {
    const { data } = await this.api.post<PublishNeedResponse>(
      `/care-providers/${careProviderId}/needs`,
      payload
    )
    return data
  }

  public deleteNeed = async ({ careProviderId, needId }: DeleteNeedParams) => {
    const { data } = await this.api.delete<string>(
      `/care-providers/${careProviderId}/needs/${needId}`
    )

    return data
  }

  public updateNeed = async ({
    careProviderId,
    needId,
    ...payload
  }: UpdateNeedParams) => {
    const { data } = await this.api.put<string>(
      `/care-providers/${careProviderId}/needs/${needId}`,
      payload
    )

    return data
  }
}

export default Needs
