import { Chat } from '@mui/icons-material'
import { MessageTooltipContainer } from './AssignmentPill.style'

interface UserNoteProps {
  note: string
}

const UserNote = ({ note }: UserNoteProps) => {
  return (
    <MessageTooltipContainer>
      <Chat fontSize="small" />: {note}
    </MessageTooltipContainer>
  )
}

export default UserNote
