import { styled, IconButton } from '@mui/material'

export const ControlButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  fontSize: theme.spacing(1.8),

  color: theme.palette.common.black,
  background: theme.palette.common.white,
  borderRadius: '50%',
}))
