import { useEffect } from 'react'
import { FieldValues, UseFormSetValue } from 'react-hook-form'
import { usePrevious } from './usePrevious'

export interface FormFieldResettingOptions<
  T extends FieldValues,
  DependencyType
> {
  setValue: UseFormSetValue<T>
  name: string
  dependency?: DependencyType | null
  defaultValue?: null | any[]
}

export const useFormFieldResetting = <T extends FieldValues, DependencyType>({
  name,
  setValue,
  dependency,
  defaultValue = null,
}: FormFieldResettingOptions<T, DependencyType>) => {
  const prevDependency = usePrevious(dependency)

  useEffect(() => {
    if (prevDependency === dependency) return

    //@ts-ignore
    setValue(name, defaultValue)
  }, [defaultValue, dependency, name, prevDependency, setValue])
}
