import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, ListItemButton, Typography, Skeleton } from '@mui/material'
import { Notification } from '../../../../types'
import LoadingButton from '../../../LoadingButton'
import DefaultNotificationComponent, {
  NotificationProps,
} from './DefaultNotificationComponent'
import NotificationsLoader from './NotificationsLoader'
import { ExpandActions, List, Title } from './Notifications.style'

export interface NotificationsListProps {
  hideLoadMore?: boolean
  loading?: boolean
  initialized?: boolean
  notifications: Notification[]
  totalCount?: number
  onClose?: () => void
  onLoadMore?: () => void
  NotificationComponent?: ComponentType<NotificationProps>
  notificationTitle?: string
}

const NotificationsList = ({
  notifications,
  onClose = () => {},
  onLoadMore = () => {},
  totalCount = 0,
  loading = false,
  initialized = false,
  hideLoadMore = false,
  NotificationComponent = DefaultNotificationComponent,
  notificationTitle,
}: NotificationsListProps) => {
  const { t } = useTranslation()

  const moreCount =
    totalCount >= notifications.length ? totalCount - notifications.length : 0
  const isLoadMoreDisabled = moreCount === 0
  const isInitializing = loading && !initialized
  const isEmpty = !isInitializing && notifications.length === 0

  return (
    <>
      {isEmpty ? (
        <List>
          {notificationTitle && (
            <>
              <Title>{notificationTitle}</Title>
              <Divider />
            </>
          )}

          <ListItemButton disabled>
            {t('notification.noNotifications')}
          </ListItemButton>
        </List>
      ) : (
        <List disablePadding={!hideLoadMore}>
          {isInitializing ? (
            <NotificationsLoader />
          ) : (
            <>
              {notificationTitle && <Title>{notificationTitle}</Title>}
              {notifications.map((notification, i) => (
                <NotificationComponent
                  key={`notification-${i}`}
                  onClick={onClose}
                  notification={notification}
                />
              ))}
            </>
          )}
        </List>
      )}
      {!hideLoadMore && (
        <>
          <Divider />
          <ExpandActions>
            <div>
              {!isLoadMoreDisabled && (
                <Typography variant="subtitle2" color="secondary">
                  {loading ? (
                    <Skeleton width={30} />
                  ) : (
                    t('notification.moreNotifications', { count: moreCount })
                  )}
                </Typography>
              )}
            </div>
            <LoadingButton
              color="primary"
              fullWidth={false}
              loading={loading}
              disabled={isLoadMoreDisabled}
              onClick={onLoadMore}
            >
              {t('notification.viewMore')}
            </LoadingButton>
          </ExpandActions>
        </>
      )}
    </>
  )
}

export default NotificationsList
