import { PropsWithChildren } from 'react'
import { Box, Alert } from '@mui/material'

interface DataTableErrorHandlerProps {
  visible?: boolean
}

const DataTableErrorHandler = ({
  children,
  visible = false,
}: PropsWithChildren<DataTableErrorHandlerProps>) =>
  visible && children ? (
    <Box paddingTop={2}>
      <Alert severity="error">{children}</Alert>
    </Box>
  ) : null

export default DataTableErrorHandler
