import { generatePath, useNavigate } from 'react-router-dom'
import { paths } from 'config'
import { useOrganizationId } from '../../../../hooks'

export const useRedirectToHelpPage = () => {
  const organizationId = useOrganizationId()
  const navigate = useNavigate()
  const helpPath = generatePath(paths.organizationHelp, {
    organizationId: organizationId.toString(),
  })

  return () => navigate(helpPath)
}
