import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../resource'
import { AvailabilityTableInfo } from '../../types'
import {
  createResourceAccessibilities,
  deleteResourceAccessibility,
  getSingleResourceAccessibilityTableInfo,
  updateResourceAccessibility,
} from './actions'

export interface State {
  updateResourceAccessibility: Resource
  deleteResourceAccessibility: Resource
  createResourceAccessibilities: Resource
  getSingleResourceAccessibilityTableInfo: Resource<AvailabilityTableInfo[]>
}

const initialState: State = {
  updateResourceAccessibility: resource.getInitial(),
  deleteResourceAccessibility: resource.getInitial(),
  createResourceAccessibilities: resource.getInitial(),
  getSingleResourceAccessibilityTableInfo: resource.getInitial<
    AvailabilityTableInfo[]
  >([]),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateResourceAccessibility.pending, state => {
      resource.setPending(state.updateResourceAccessibility)
    })
    .addCase(updateResourceAccessibility.fulfilled, state => {
      resource.setSucceeded(state.updateResourceAccessibility)
    })
    .addCase(updateResourceAccessibility.rejected, (state, action) => {
      resource.setFailed(
        state.updateResourceAccessibility,
        action.error.message
      )
    })
    .addCase(deleteResourceAccessibility.pending, state => {
      resource.setPending(state.deleteResourceAccessibility)
    })
    .addCase(deleteResourceAccessibility.fulfilled, state => {
      resource.setSucceeded(state.deleteResourceAccessibility)
    })
    .addCase(deleteResourceAccessibility.rejected, (state, action) => {
      resource.setFailed(
        state.deleteResourceAccessibility,
        action.error.message
      )
    })
    .addCase(createResourceAccessibilities.pending, state => {
      resource.setPending(state.createResourceAccessibilities)
    })
    .addCase(createResourceAccessibilities.fulfilled, state => {
      resource.setSucceeded(state.createResourceAccessibilities)
    })
    .addCase(createResourceAccessibilities.rejected, (state, action) => {
      resource.setFailed(
        state.createResourceAccessibilities,
        action.error.message
      )
    })
    .addCase(getSingleResourceAccessibilityTableInfo.pending, state => {
      resource.setPending(state.getSingleResourceAccessibilityTableInfo)
    })
    .addCase(
      getSingleResourceAccessibilityTableInfo.fulfilled,
      (state, action) => {
        resource.setSucceeded(
          state.getSingleResourceAccessibilityTableInfo,
          action.payload.data
        )
      }
    )
    .addCase(
      getSingleResourceAccessibilityTableInfo.rejected,
      (state, action) => {
        resource.setFailed(
          state.getSingleResourceAccessibilityTableInfo,
          action.error.message
        )
      }
    )
)
