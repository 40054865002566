import Api from '../api'
import {
  GetArchivesResponse,
  GetArchivesParams,
  ExportArchivesParams,
} from './Archives.types'

class Archives extends Api {
  getArchives = async ({ careProviderId, ...params }: GetArchivesParams) => {
    const response = await this.api.get<GetArchivesResponse>(
      `/care-providers/${careProviderId}/need-assignments/archives`,
      { params }
    )

    const { data, ...meta } = response.data

    return { data, meta }
  }

  exportArchives = async ({
    careProviderId,
    assignmentStatuses,
    roleId,
    unitId,
    ...params
  }: ExportArchivesParams) => {
    const { data } = await this.api.post<ArrayBuffer>(
      `/care-providers/${careProviderId}/need-assignments/archives/export`,
      { assignmentStatuses, roleId, unitId },
      { params, responseType: 'arraybuffer' }
    )

    return data
  }
}

export default Archives
