import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth, useSelector } from '../../hooks'
import { actions, selectors } from '../../store'

export const useSupportWidgetButton = () => {
  const supportWidget = document.getElementById(
    'jsd-widget'
  ) as HTMLIFrameElement
  // this state only represents if support widget dialog is open and is based on width, it doesn't affect the widget itself
  const [isSupportPopUpOpen, setIsSupportPopUpOpen] = useState(false)
  const isSupportWidgetVisible = useSelector(
    selectors.settings.isSupportWidgetVisible
  )
  const dispatch = useDispatch()

  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!supportWidget) return
    // timeout to wait for widget to load before checking its width & height
    setTimeout(() => {
      // this condition prevents widget and close button from rendering on login page when there is error in widget (its width is smaller)
      if (supportWidget.offsetWidth <= 11 || supportWidget.offsetWidth > 70) {
        dispatch(actions.settings.setSupportWidgetVisibility(false))
      }
    }, 2000)
    if (isSupportWidgetVisible) {
      supportWidget.style.visibility = 'visible'
    } else {
      supportWidget.style.visibility = 'hidden'
    }
  }, [dispatch, isSupportWidgetVisible, supportWidget])

  useEffect(() => {
    if (!supportWidget?.contentWindow) return
    supportWidget.contentWindow.document.addEventListener('click', () => {
      if (supportWidget.offsetWidth > 70) setIsSupportPopUpOpen(true)
      else setIsSupportPopUpOpen(value => !value)
    })
  }, [isSupportPopUpOpen, supportWidget])

  useEffect(() => {
    // this condition prevents from rendering widget and close button after authentication if there is an error in widget
    if (
      isAuthenticated &&
      supportWidget.offsetWidth > 11 &&
      supportWidget.offsetWidth <= 70
    )
      dispatch(actions.settings.setSupportWidgetVisibility(true))
  }, [dispatch, isAuthenticated, supportWidget.offsetWidth])

  const onClick = () => {
    dispatch(actions.settings.setSupportWidgetVisibility(false))
  }

  return {
    isSupportWidgetVisible,
    isSupportPopUpOpen,
    isAuthenticated,
    onClick,
  }
}
