import { styled, Typography } from '@mui/material'

interface RequestedMessageConstainerProps {
  rejected: boolean
}

export const RequestedMessageConstainer = styled(
  Typography
)<RequestedMessageConstainerProps>(({ theme, rejected }) => ({
  color: rejected ? theme.palette.error.main : theme.palette.grey[500],
  fontStyle: 'italic',
}))
