import { PropsWithChildren } from 'react'
import { DialogProps } from '@mui/material'
import { Dialog as BaseDialog, useBackdropStyle } from './Dialog.style'

const Dialog = ({ children, ...props }: PropsWithChildren<DialogProps>) => {
  const backdropStyle = useBackdropStyle()
  return (
    <BaseDialog
      BackdropProps={{
        style: backdropStyle,
      }}
      {...props}
    >
      {children}
    </BaseDialog>
  )
}

export default Dialog
