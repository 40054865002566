import { useEffect } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { AssignmentStatus } from '../../../types'
import { BookedAssignmentContentValues } from '../BookedAssignmentContent'
import { AssignmentStateSectionValues } from '../RequestAssignmentContent'
import Loader from './Loader'
import {
  ErrorWrapper,
  StyledCheckboxField,
  StyledSelectAllCheckboxField,
} from '../AssignmentModal.style'
import { ListContainer } from './ShiftsSection.style'
import { useShifts } from './ShiftsSection.utils'

type FormValues = BookedAssignmentContentValues & AssignmentStateSectionValues

interface ShiftsSectionProps {
  name: keyof FormValues
  assignmentId?: number
  assignmentStatus?: AssignmentStatus
  resourceId?: number
  disabled?: boolean
  preliminary?: boolean
}

const ShiftsSection = ({
  name,
  assignmentId,
  resourceId,
  assignmentStatus,
  disabled,
  preliminary,
}: ShiftsSectionProps) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()
  const { errors } = useFormState<FormValues>()
  const { isLoading, error, shifts } = useShifts({
    resourceId,
    preliminary,
    status: assignmentStatus,
  })
  const errorMessage =
    (errors?.[name]?.message as string) || (error && t('error.defaultMessage'))

  useEffect(() => {
    setValue(name, [assignmentId])
    return () => {
      setValue(name, [])
    }
  }, [assignmentId, name, setValue])

  return (
    <>
      <Grid container>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <StyledSelectAllCheckboxField
              label={t('assignmentModal.selectAll', { count: shifts.length })}
              selectAllFor={name}
              options={shifts}
              checked
              disabled={disabled}
              disableClickOnLabel
            />
          </Grid>
        </Grid>
        <ListContainer
          container
          direction="column"
          error={Boolean(errorMessage)}
        >
          {isLoading ? (
            <Loader />
          ) : (
            shifts.map(({ label, value }, index) => (
              <Grid item key={`shift-${index}`}>
                <StyledCheckboxField
                  name={name}
                  label={label}
                  value={value}
                  multiple
                  disabled={disabled}
                />
              </Grid>
            ))
          )}
        </ListContainer>
      </Grid>
      {errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}
    </>
  )
}

export default ShiftsSection
