import { Popover, PopoverProps } from '@mui/material'
import { PopoverContentWrapper } from './AssignmentModal.style'

export interface AssignmentModalWrapperProps extends PopoverProps {
  onClose?: () => void
}

export const AssignmentModalWrapper = ({
  onClose = () => {},
  children,
  ...props
}: AssignmentModalWrapperProps) => {
  return (
    <Popover
      {...props}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
    >
      <PopoverContentWrapper>{children}</PopoverContentWrapper>
    </Popover>
  )
}

export default AssignmentModalWrapper
