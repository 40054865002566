import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import Logs, { GetLogsParams } from 'shared/services/logs'
import { MODULE_NAME } from '../strings'

const logs = new Logs()

export const getActivityLogs = createAsyncThunk(
  `${MODULE_NAME}/getActivityLogs`,
  (payload: GetLogsParams) => logs.getLogs(payload)
)

export const resetGetActivityLogs = createAction(
  `${MODULE_NAME}/resetGetActivityLogs`
)
