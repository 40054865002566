import { createAction } from '@reduxjs/toolkit'
import { Api, Accounts } from '../../services'
import {
  ResetLoginInfoParams,
  SetCurrentUserLanguageParams,
} from '../../services/accounts'
import { actions } from '../../store'
import { NAMESPACE } from '../../strings'
import { AuthChallenge } from '../../types'
import { createAsyncThunkWithErrorHandling } from '../../utils'
import {
  SignInPayload,
  SetNewPasswordPayload,
  ConfirmForgotPasswordPayload,
  ResolveChallengePayload,
  ChallengeData,
  ChallengeHandlers,
} from './actions.types'

const api = new Api()
const accounts = new Accounts()

const handleChallenge = (
  { challenge, token }: ChallengeData,
  {
    onNewPasswordRequired = () => {},
    onMFARequired = () => {},
  }: ChallengeHandlers
) => {
  switch (challenge) {
    case AuthChallenge.NewPasswordRequired: {
      onNewPasswordRequired(token)
      return null
    }
    case AuthChallenge.MFARequired: {
      onMFARequired(token)
      return null
    }
    default:
      return accounts.getMe()
  }
}

export const getCurrentUser = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/getCurrentUser`,
  (params: {}) => accounts.getMe()
)

export const setCurrentUserLanguage = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/setCurrentUserLanguage`,
  ({ language }: SetCurrentUserLanguageParams) => {
    accounts.setCurrentUserLanguage({ language })
    return language
  }
)

export const signIn = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/signIn`,
  async (
    { email, password, ...challengeHandlers }: SignInPayload,
    thunkApi
  ) => {
    const response = await api.signIn(email, password)
    const challengeResult = await handleChallenge(response, challengeHandlers)

    thunkApi.dispatch(actions.settings.setupLanguage(challengeResult?.language))
    return challengeResult
  }
)

export const signOut = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/signOut`,
  async (payload: {}) => {
    try {
      await api.signOut()
    } finally {
      window.location.reload()
    }
  }
)

export const resetPassword = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/resetPassword`,
  ({ email }: { email: string }) => api.resetPassword(email)
)

export const confirmForgotPassword = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/confirmForgotPassword`,
  ({ password, token }: ConfirmForgotPasswordPayload) =>
    api.confirmForgotPassword(password, token)
)

export const setNewPassword = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/setNewPassword`,
  ({ password, token }: SetNewPasswordPayload) =>
    api.setNewPassword(password, token)
)

export const resolveChallenge = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/resolveChallenge`,
  async ({ code, token }: ResolveChallengePayload) => {
    await api.resolveChallenge(code, token)
    return accounts.getMe()
  }
)

export const resetSignIn = createAction(`${NAMESPACE}/resetSignIn`)

export const resetSignOut = createAction(`${NAMESPACE}/resetSignOut`)

export const resetResetPassword = createAction(
  `${NAMESPACE}/resetResetPassword`
)

export const resetSetNewPassword = createAction(
  `${NAMESPACE}/resetSetNewPassword`
)

export const resetConfirmForgotPassword = createAction(
  `${NAMESPACE}/resetConfirmForgotPassword`
)

export const resetResolveChallenge = createAction(
  `${NAMESPACE}/resetResolveChallenge`
)

export const changeLoginInfo = createAsyncThunkWithErrorHandling(
  `${NAMESPACE}/changeLoginInfo`,
  async (payload: ResetLoginInfoParams) => {
    const data = await accounts.resetLoginInfo(payload)
    return data
  }
)
export const resetChangeLoginInfo = createAction(
  `${NAMESPACE}/resetChangeLoginInfo`
)
