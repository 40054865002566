import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, generatePath } from 'react-router-dom'
import {
  AccessTime,
  ArrowBack,
  Assignment,
  CalendarToday,
  EventAvailable,
  InsertChartOutlinedOutlined,
  Message,
  People,
  Settings,
} from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { paths } from 'config'
import { useSelector } from '../../hooks'
import {
  useAuth,
  useIsInOrganizationList,
  useOrganizationId,
} from '../../hooks'
import { Organizations, Requests } from '../../icons'
import { selectors } from '../../store'
import { NotificationType, UserRole } from '../../types'
import { ModuleRoute, Location } from '../../types'
import { addIf, getCurrentPath } from '../../utils'
import { DrawerItemProps } from './Drawer'

export const useCurrentLayoutSettings = (routes: ModuleRoute[]) => {
  const location = useLocation()
  const pathname = getCurrentPath(location as Location)
  const currentRoute = routes.find(routeProps =>
    matchPath({ path: routeProps.path || '', end: false }, pathname)
  )

  return currentRoute?.layoutSettings || {}
}

export const useNavItems = (): DrawerItemProps[] => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { hasAllowedRole } = useAuth()
  const organizationId = useOrganizationId().toString()
  const unreadNotifications = useSelector(
    selectors.notifications.getUnreadCount
  )
  const chatNotificationsCount = useSelector(
    selectors.notifications.getUnreadChatNotificationsCount
  )
  const schedulesUnreadNotifications = useSelector(
    selectors.notifications.getSchedulesUnreadCount
  )
  const requestsCount = useSelector(
    selectors.notifications.getResourceRequestsCount
  )

  const isInOrganizationsList = useIsInOrganizationList()

  if (hasAllowedRole([UserRole.Resource])) {
    return [
      {
        label: t('navigation.schedules'),
        icon: CalendarToday,
        to: generatePath(paths.organizationSchedules, { organizationId }),
        badgeCount: schedulesUnreadNotifications,
      },
      {
        label: t('navigation.bookings'),
        icon: EventAvailable,
        to: generatePath(paths.organizationBookings, { organizationId }),
        badgeCount: unreadNotifications[NotificationType.NeedAssignmentBooked],
      },
      {
        label: t('navigation.requests'),
        icon: Requests,
        to: generatePath(paths.organizationRequests, { organizationId }),
        badgeCount: requestsCount,
      },
      {
        label: t('navigation.messages'),
        icon: Message,
        to: generatePath(paths.organizationMessages, { organizationId }),
        badgeCount: chatNotificationsCount,
      },
      //TODO: uncomment after fixes
      // {
      //   label: t('navigation.activityLogs'),
      //   icon: AccessTime,
      //   to: generatePath(paths.organizationActivityLogs, { organizationId }),
      // },
    ]
  }

  if (hasAllowedRole([UserRole.UnitAdmin])) {
    return [
      {
        label: t('navigation.schedules'),
        icon: CalendarToday,
        to: generatePath(paths.organizationSchedules, { organizationId }),
        badgeCount: schedulesUnreadNotifications,
      },
      {
        label: t('navigation.units'),
        icon: Assignment,
        to: generatePath(paths.organizationNeeds, { organizationId }),
        badgeCount: unreadNotifications[NotificationType.NeedCreated],
      },
      //TODO: uncomment when client changes mind
      // {
      //   label: t('navigation.statistics'),
      //   icon: InsertChartOutlinedOutlined,
      //   to: generatePath(paths.organizationStatistics, { organizationId }),
      // },
      //TODO: uncomment when client changes mind
      // {
      //   label: t('navigation.resources'),
      //   icon: People,
      //   to: generatePath(paths.organizationResources, { organizationId }),
      // },
      {
        label: t('navigation.messages'),
        icon: Message,
        to: generatePath(paths.organizationMessages, { organizationId }),
        badgeCount: chatNotificationsCount,
        style: { marginTop: theme.spacing(2.5) }, // TODO: remove when the archives view are ready
      },
      {
        label: t('navigation.activityLogs'),
        icon: AccessTime,
        to: generatePath(paths.organizationActivityLogs, { organizationId }),
      },
    ]
  }

  if (!hasAllowedRole([UserRole.SuperCareProvider]) || !isInOrganizationsList) {
    return [
      {
        label: t('navigation.units'),
        icon: Assignment,
        to: generatePath(paths.organizationUnits, { organizationId }),
        badgeCount: unreadNotifications[NotificationType.NeedCreated],
        end: true,
      },
      {
        label: t('navigation.schedules'),
        icon: CalendarToday,
        to: generatePath(paths.organizationSchedules, { organizationId }),
        badgeCount: schedulesUnreadNotifications,
      },
      {
        label: t('navigation.statistics'),
        icon: InsertChartOutlinedOutlined,
        to: generatePath(paths.organizationStatistics, { organizationId }),
      },
      {
        label: t('navigation.resources'),
        icon: People,
        to: generatePath(paths.organizationResources, { organizationId }),
      },
      {
        label: t('navigation.archives'),
        icon: CalendarToday,
        to: generatePath(paths.organizationArchives, { organizationId }),
        style: { marginTop: theme.spacing(2.5) },
      },
      {
        label: t('navigation.messages'),
        icon: Message,
        to: generatePath(paths.organizationMessages, { organizationId }),
        badgeCount: chatNotificationsCount,
      },
      {
        label: t('navigation.activityLogs'),
        icon: AccessTime,
        to: generatePath(paths.organizationActivityLogs, { organizationId }),
      },
    ]
  }

  return [
    {
      label: t('navigation.organizations'),
      icon: Organizations,
      to: paths.organizations,
      end: true,
    },
  ]
}

export const useFooterNavItems = (): DrawerItemProps[] => {
  const { t } = useTranslation()
  const { hasAllowedRole } = useAuth()
  const organizationId = useOrganizationId()
  const isInOrganizationsList = useIsInOrganizationList()
  if (isInOrganizationsList) return []
  return [
    ...addIf(
      !!organizationId &&
        hasAllowedRole([
          UserRole.SuperCareProvider,
          UserRole.CareProvider,
          UserRole.UnitAdmin,
        ]),
      {
        label: t('navigation.adminPanel'),
        icon: Settings,
        to: generatePath(paths.organizationAdministration, {
          organizationId: organizationId.toString(),
        }),
      }
    ),
    ...addIf(hasAllowedRole([UserRole.SuperCareProvider]) && !!organizationId, {
      label: t('navigation.organizationsView'),
      icon: ArrowBack,
      to: paths.organizations,
      match: false,
    }),
  ]
}
