import Api from '../api'
import {
  GetListingAssignmentParams,
  GetListingAssignmentResponse,
} from './ListingAssignments.types'

class ListingAssignments extends Api {
  public getListingAssignment = async ({
    careProviderId,
    assignmentId,
    ...params
  }: GetListingAssignmentParams) => {
    const { data } = await this.api.get<GetListingAssignmentResponse>(
      `/care-providers/${careProviderId}/listing-assignments/${assignmentId}`,
      { params }
    )
    return data
  }
}

export default ListingAssignments
