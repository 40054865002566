import {
  TableHead,
  TableHeadProps,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { SortingOrder, SortingParams } from '../../../types'
import { Column } from '../DataTableRow'
import { TableSortLabelIcon } from '../DataTable.style'
import { TableHeadVariant } from '../DataTable.types'
import { StyledTableCell } from './DataTableHead.style'

export type SortHandler = (sortBy: string, sortOrder?: SortingOrder) => void

interface DataTableHeadProps extends TableHeadProps {
  columns: Column[]
  sorting: SortingParams
  sort: SortHandler
  tableHeadVariant?: TableHeadVariant
  nested?: boolean
}

const DataTableHead = ({
  sorting: { sortBy, sortOrder },
  sort,
  columns,
  nested = false,
  tableHeadVariant = 'default',
  ...headProps
}: DataTableHeadProps) => (
  <TableHead {...headProps}>
    <TableRow>
      {columns.map(({ field, label, sortable = true, ...columnProps }, i) => {
        const isActive = sortBy === field
        const direction = isActive ? sortOrder : undefined
        return (
          <StyledTableCell
            data-test-id={`header-${field}`}
            key={`header-${field}-${i}`}
            tableHeadVariant={tableHeadVariant}
            nested={nested}
            {...columnProps}
          >
            {sortable ? (
              <TableSortLabel
                data-test-id={label}
                active={isActive}
                direction={direction}
                onClick={() => sort(field, direction)}
                IconComponent={props => (
                  <TableSortLabelIcon active={isActive} {...props} />
                )}
              >
                {label}
              </TableSortLabel>
            ) : (
              <span data-test-id={label}>{label}</span>
            )}
          </StyledTableCell>
        )
      })}
    </TableRow>
  </TableHead>
)

export default DataTableHead
