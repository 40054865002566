import { HTMLAttributes } from 'react'
import { Typography } from '@mui/material'
import { Container, StyledCircularProgress } from './Loader.style'

export interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
  label?: string
  size?: number
}

const Loader = ({ label, size = 100, ...props }: LoaderProps) => (
  <Container {...props}>
    <StyledCircularProgress size={size} />
    <Typography variant="subtitle1" color="textPrimary">
      {label}
    </Typography>
  </Container>
)

export default Loader
