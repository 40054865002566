import { PropsWithChildren } from 'react'
import { Dialog } from '@mui/material'
import { Transition } from './DailyAvailability.style'

interface DailyAvailabilityFormProps {
  resourceForm: boolean
  isDailyAvilabilityVisible: boolean
  isDailyAvailabilityOpen: boolean
  onClose: () => void
}

const DailyAvailabilityForm = ({
  children,
  resourceForm,
  isDailyAvilabilityVisible,
  isDailyAvailabilityOpen,
  onClose,
}: PropsWithChildren<DailyAvailabilityFormProps>) =>
  resourceForm ? (
    <Dialog
      fullScreen
      open={isDailyAvailabilityOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {children}
    </Dialog>
  ) : (
    <>{isDailyAvilabilityVisible ? children : null}</>
  )

export default DailyAvailabilityForm
