import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

type InViewProps = PropsWithChildren<{}>

const InView = ({ children }: InViewProps) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const intersectionCallback = useCallback(
    (
      [entry]: IntersectionObserverEntryInit[],
      observer: IntersectionObserver
    ) => {
      setIsVisible(entry?.isIntersecting)
      if (entry?.isIntersecting) {
        observer.unobserve(entry.target)
      }
    },
    []
  )

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: 0.2,
    })
    const currentRef = containerRef.current

    if (currentRef) observer.observe(currentRef)

    return () => {
      if (currentRef) observer.unobserve(currentRef)
    }
  }, [intersectionCallback])

  return <div ref={containerRef}>{isVisible && children}</div>
}

export default InView
