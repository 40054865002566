import { styled } from '@mui/material'

interface PillStylesProps {
  isTypeAdd: boolean
  isCounter: boolean
  isRemovePreviewActive: boolean
  color?: string
  monthlyView?: boolean
}

export const StyledPill = styled('div')<PillStylesProps>(
  ({
    theme,
    isTypeAdd,
    isCounter,
    isRemovePreviewActive,
    color,
    monthlyView,
  }) => ({
    fontSize: monthlyView ? '0.5rem' : '0.85rem',
    margin: theme.spacing(1, 'auto'),
    maxWidth: theme.spacing(8),
    height: theme.spacing(1.625),
    background: isTypeAdd
      ? theme.palette.common.white
      : color || theme.palette.pill.green.main,
    border:
      isTypeAdd || isCounter ? `1px solid ${theme.palette.grey[400]}` : 'none',
    color: isTypeAdd ? theme.palette.grey[400] : theme.palette.common.black,
    borderRadius: theme.spacing(2),
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '11px',
    '&:hover': {
      background:
        isTypeAdd || isCounter
          ? theme.palette.pill.green.main
          : theme.palette.pill.red.main,
      '&:after': {
        content: isTypeAdd || isCounter ? 'none' : '"x"',
        color: theme.palette.common.white,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
    '&:first-child': {
      background: isRemovePreviewActive ? theme.palette.pill.red.main : '',
    },
  })
)
