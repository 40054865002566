import resource from 'shared/resource'
import {
  AssignmentRequestStatus,
  AssignmentStatus,
  ResourceSchedule,
} from 'shared/types'
import {
  RequestWithActions,
  ResourceScheduleWithActions,
  State,
} from './reducer'

type Data = State['getResourceSchedules']['data'][number]

export const getDataIndexByRequest = (requestId: number, state: State) =>
  state.getResourceSchedules.data.findIndex(({ requests }) =>
    requests.some(({ id }) => id === requestId)
  )
export const getRequestIndex = (requestId: number, data: Data) =>
  data.requests.findIndex(({ id }) => id === requestId)

export const spliceRequest = (requestIndex: number, data: Data) => {
  const [request] = data?.requests.splice(requestIndex, 1)
  return request
}

export const bookAssignment = (requestId: number, state: State) => {
  const index = getDataIndexByRequest(requestId, state)
  const data = state.getResourceSchedules.data[index] || null
  const requestIndex = getRequestIndex(requestId, data)
  const request = spliceRequest(requestIndex, data)
  if (request)
    data?.assignments.push({
      ...request.assignment,
      status: AssignmentStatus.BookedInternally,
    })
}

export const rejectAssignmentRequest = (requestId: number, state: State) => {
  const index = getDataIndexByRequest(requestId, state)
  const data = state.getResourceSchedules.data[index] || null
  const requestIndex = getRequestIndex(requestId, data)
  const request = data?.requests[requestIndex] || null
  if (request) request.status = AssignmentRequestStatus.Rejected
}

export const getResourceScheduleWithActions = (
  data: ResourceSchedule[]
): ResourceScheduleWithActions[] =>
  data.map(data => ({
    ...data,
    requests: data.requests.map(request => ({
      ...request,
      acceptRequest: resource.getInitial(),
      rejectRequest: resource.getInitial(),
    })),
  }))

export const getRequest = (state: State, requestId: number) =>
  state.getResourceSchedules.data
    .reduce(
      (acc, { requests }) => [...acc, ...requests],
      [] as RequestWithActions[]
    )
    .find(({ id }) => id === requestId)
