import Api from '../api'
import { GetMatchingParams, GetMatchingResponse } from './Matching.types'

export class Matching extends Api {
  public getMatching = async ({
    careProviderId,
    unitId,
    ...params
  }: GetMatchingParams) => {
    const response = await this.api.get<GetMatchingResponse>(
      `/care-providers/${careProviderId}/matching/units/${unitId}/resources/details`,
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }
}

export default Matching
