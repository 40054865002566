import { differenceInMinutes, format, parse, parseISO } from 'date-fns'
import { useDateFns } from '../../../hooks'
import { parseDate } from '../../../utils'

const HOUR_FORMAT = 'HH.mm'
const DAY_MONTH_FORMAT = '(dd/MM)'
const COMBINED_FORMAT = `${HOUR_FORMAT} ${DAY_MONTH_FORMAT}`
const DAY_IN_MINUTES = 24 * 60

export const useDividedAvailabilityInfo = (
  timeFrom: string,
  timeTo: string,
  startDate: Date | string,
  endDate: Date | string
) => {
  const dateOptions = useDateFns()

  const from = parse(timeFrom, HOUR_FORMAT, parseDate(startDate))
  const to = parse(timeTo, HOUR_FORMAT, parseDate(endDate))

  const formatDate = (date: Date) => format(date, COMBINED_FORMAT, dateOptions)

  return {
    isMoreThan24Hours: differenceInMinutes(to, from) > DAY_IN_MINUTES,
    fromStartDate: formatDate(parseISO(startDate.toLocaleString())),
    fromEndDate: formatDate(parseISO(endDate.toLocaleString())),
    toStartDate: formatDate(from),
    toEndDate: formatDate(to),
  }
}
