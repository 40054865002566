import { createAsyncThunk } from '@reduxjs/toolkit'
import config from 'config'
import CareProviders from 'shared/services/care-providers'
import Departments, {
  GetDepartmentsStatisticsParams,
} from 'shared/services/departments'
import Units, {
  GetUnitsStatisticsParams,
  GetShiftsStatisticsParams,
} from 'shared/services/units'
import {
  createAsyncThunkWithErrorHandling,
  downloadFile,
  generateFileName,
  GenerateFileNameOptions,
  getFileUrl,
} from 'shared/utils'
import { MODULE_NAME } from '../strings'
import {
  ExportOrganizationStatisticsPayload,
  ExportUnitStatisticsPayload,
} from './actions.types'

const departments = new Departments()
const units = new Units()
const careProviders = new CareProviders()

export const getDepartmentsStatistics = createAsyncThunk(
  `${MODULE_NAME}/getDepartmentsStatistics`,
  (payload: GetDepartmentsStatisticsParams) =>
    departments.getDepartmentsStatistics(payload)
)

export const getUnitsStatistics = createAsyncThunk(
  `${MODULE_NAME}/getUnitsStatistics`,
  (payload: GetUnitsStatisticsParams) => units.getUnitsStatistics(payload)
)

export const getShiftsStatistics = createAsyncThunk(
  `${MODULE_NAME}/getShiftsStatistics`,
  (payload: GetShiftsStatisticsParams) => units.getShiftsStatistics(payload)
)

const download = (file: ArrayBuffer, options: GenerateFileNameOptions) => {
  const url = getFileUrl(file)
  const fileName = generateFileName(options)
  downloadFile(url, fileName)
}

export const exportOrganizationStatistics = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/exportOrganizationStatistics`,
  async ({
    careProvider,
    dateFrom,
    dateTo,
    timeInterval,
    role,
  }: ExportOrganizationStatisticsPayload) => {
    const file = await careProviders.exportStatistics({
      dateFrom,
      dateTo,
      timeInterval,
      careProviderId: careProvider.id,
      roleId: role?.value || null,
    })
    return download(file, {
      dateFrom,
      dateTo,
      prefix: careProvider.name || '',
      suffix: role?.label || '',
      extension: config.defaultExportFileExtension,
    })
  }
)

export const exportUnitStatistics = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/exportUnitStatistics`,
  async ({
    careProvider,
    dateFrom,
    dateTo,
    timeInterval,
    role,
    unitEntries,
  }: ExportUnitStatisticsPayload) => {
    const file = await units.exportStatistics({
      dateFrom,
      dateTo,
      timeInterval,
      careProviderId: careProvider.id,
      roleId: role?.value || null,
      unitIds: unitEntries.map(unit => unit?.value),
    })
    return download(file, {
      dateFrom,
      dateTo,
      prefix: unitEntries
        .map(unit => unit?.label.replace(/\s/g, '|'))
        .join(' '),
      suffix: role?.label || '',
      extension: config.defaultExportFileExtension,
    })
  }
)
