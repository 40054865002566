import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateNext } from '@mui/icons-material'
import { ListItemAvatar, ListItemText } from '@mui/material'
import { useSnackbar } from 'notistack'
import {
  useDispatch,
  useAssignmentNotification,
  useStatusPillLabel,
  useOrganizationId,
} from '../../../../hooks'
import { actions } from '../../../../store'
import { AssignmentStatus, ScheduleDaysAssignment } from '../../../../types'
import { filterRequests } from '../../../../utils'
import AssignmentPill from '../../../AssignmentPill'
import PillLabel from '../../../PillLabel'
import { useModalProps } from '../../AssignmentModal.utils'
import {
  StyledListItemButton,
  StyledBadge,
  PillWrapper,
} from './MoreAssignmentItem.style'
import { getResourceName } from './MoreAssignmentItem.utils'

interface MoreAssignmentItemProps {
  assignment: ScheduleDaysAssignment
  showNotifications?: boolean
}

const MoreAssignmentItem = ({
  assignment,
  showNotifications = false,
}: MoreAssignmentItemProps) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { closeModal } = useModalProps()
  const careProviderId = useOrganizationId()
  const { markAsRead } = useAssignmentNotification(assignment.id)
  const modifiedAssignment = filterRequests(assignment)
  const { status, hasAbsence } = modifiedAssignment
  const pillLabel = useStatusPillLabel({ status, hasAbsence })

  const isNotEmptyOrCanceled =
    assignment.status !== AssignmentStatus.Empty &&
    assignment.status !== AssignmentStatus.Canceled

  const resourceName = getResourceName(assignment)

  const onFailure = useCallback(
    (message?: string) => {
      enqueueSnackbar(message || t('error.defaultMessage'), {
        variant: 'error',
      })
      closeModal()
    },
    [closeModal, enqueueSnackbar, t]
  )

  const handleAssignmentClick = useCallback(
    (assignment: ScheduleDaysAssignment) => {
      markAsRead()
      if (assignment.listingAssignmentId) {
        dispatch(
          actions.schedules.getListingAssignmentData({
            onFailure,
            careProviderId,
            assignmentId: assignment.listingAssignmentId,
          })
        )
        dispatch(
          actions.schedules.setAssignmentModalRole(assignment.roleShortCode)
        )
        return
      }

      return dispatch(
        actions.schedules.getNeedAssignmentData({
          onFailure,
          careProviderId,
          assignmentId: assignment.id,
        })
      )
    },
    [careProviderId, dispatch, markAsRead, onFailure]
  )

  return (
    <StyledListItemButton
      key={`more-assignment-${assignment.id}`}
      alignItems="center"
      onClick={() => handleAssignmentClick(assignment)}
    >
      {showNotifications && <StyledBadge variant="dot" color="error" />}
      <ListItemAvatar>
        <PillWrapper>
          <AssignmentPill
            assignment={modifiedAssignment}
            showNotifications
            label={<PillLabel labelText={pillLabel} assignment={assignment} />}
          />
        </PillWrapper>
      </ListItemAvatar>
      <ListItemText
        primary={isNotEmptyOrCanceled && resourceName}
        primaryTypographyProps={{
          variant: 'body2',
        }}
      />
      <NavigateNext color="secondary" />
    </StyledListItemButton>
  )
}

export default MoreAssignmentItem
