import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../resource'
import { LanguageCode, NeedSettings } from '../../types'
import {
  getNeedSettings,
  patchNeedSettings,
  setLanguage,
  setSupportWidgetVisibility,
} from './actions'

export interface State {
  getNeedSettings: Resource<NeedSettings>
  patchNeedSettings: Resource<string>
  language: LanguageCode
  isSupportWidgetVisible: boolean
}

const initialState: State = {
  getNeedSettings: resource.getInitial<NeedSettings>(),
  patchNeedSettings: resource.getInitial<string>(''),
  language: LanguageCode.EN,
  isSupportWidgetVisible: true,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getNeedSettings.pending, state => {
      resource.setPending(state.getNeedSettings)
    })
    .addCase(getNeedSettings.fulfilled, (state, action) => {
      resource.setSucceeded(state.getNeedSettings, action.payload)
    })
    .addCase(getNeedSettings.rejected, (state, action) => {
      resource.setFailed(state.getNeedSettings, action.error.message)
    })
    .addCase(patchNeedSettings.pending, state => {
      resource.setPending(state.patchNeedSettings)
    })
    .addCase(patchNeedSettings.fulfilled, (state, action) => {
      resource.setSucceeded(state.patchNeedSettings, action.payload)
      if (action.meta.arg.params.maxDisplayedAssignmentsCount)
        state.getNeedSettings.data.maxDisplayedAssignmentsCount =
          action.meta.arg.params.maxDisplayedAssignmentsCount
    })
    .addCase(patchNeedSettings.rejected, (state, action) => {
      resource.setFailed(state.patchNeedSettings, action.error.message)
    })
    .addCase(setLanguage, (state, action) => {
      state.language = action.payload
    })
    .addCase(setSupportWidgetVisibility, (state, action) => {
      state.isSupportWidgetVisible = action.payload
    })
)
