import { PropsWithChildren } from 'react'
import { alpha, styled } from '@mui/material'

export const FOOTER_HEIGHT = 150

interface FooterProps extends PropsWithChildren<{}> {
  small?: boolean
}

export const Footer = styled('div')<FooterProps>(({ theme, small }) => ({
  ...(!small && { minHeight: FOOTER_HEIGHT }),
  padding: theme.spacing(3, 4),
  boxShadow: `0px -4px 4px ${alpha(theme.palette.common.black, 0.13)}`,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 2,
}))

const DefaultFooter = ({ children, small, ...props }: FooterProps) => (
  <Footer small={small} {...props}>
    {children}
  </Footer>
)

export default DefaultFooter
