import {
  GetChatMembersResponse,
  GetChatMessagesResponse,
  GetChatNotificationsResponse,
  GetChatResponse,
  GetChatsResponse,
  NewMessageResponse,
} from 'shared/services/chat'

export enum ChatMarking {
  Flagged = 'FLAGGED',
  Archived = 'ARCHIVED',
}

export enum ChatMessageType {
  System = 'SYSTEM',
  User = 'USER',
  Admin = 'ADMIN',
}

export enum ChatUserMode {
  Basic = 'BASIC',
  Admin = 'ADMIN',
  ReadOnly = 'READ_ONLY',
}

export type ChatMember = GetChatMembersResponse['data'][number]

export type ChatMessage = GetChatMessagesResponse['data'][number]

export type Chat = GetChatResponse

export type ChatListEntry = GetChatsResponse['data'][number]

export type ChatNotification = Omit<
  GetChatNotificationsResponse['data'][number],
  'type'
>

export type ReceivedMessage = NewMessageResponse
