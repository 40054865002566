import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from '../../resource'
import {
  AdminInfoMessage,
  AdminUpdateMessage,
  ChatNotification,
  Notification,
  NotificationType,
  SchedulesNotification,
} from '../../types'
import {
  getNotifications,
  getUnreadNotifications,
  getSchedulesUnreadNotifications,
  resetNotifications,
  setNotificationsCount,
  markAsRead,
  getResourceBookingsNotifications,
  getResourceRequestsNotifications,
  resetRequestsNotifications,
  resetBookingsNotifications,
  getNeedsCreatedNotifications,
  getRequestsCount,
  getLatestAdminInfoMessage,
  getLatestAdminUpdateMessage,
  openAdminUpdateMessageModal,
  closeAdminUpdateMessageModal,
  sendAdminMessageFeedback,
  getAdminUpdateMessage,
  readChatNotifications,
  getUnreadChatNotifications,
  addChatNotification,
} from './actions'

interface AdminUpdateMessageState
  extends Resource<AdminUpdateMessage | undefined> {
  isModalOpen: boolean
}

export interface State {
  notifications: Resource<Notification[]>
  unreadCount: Record<NotificationType, number>
  getSchedulesUnreadNotifications: Resource<SchedulesNotification[]>
  getNeedsCreatedNotifications: Resource<Notification[]>
  markAsRead: Resource
  resourceBookingsNotifications: Resource<Notification[]>
  resourceRequestsNotifications: Resource<Notification[]>
  requestsCount: Resource<number>
  latestAdminInfoMessage: Resource<AdminInfoMessage>
  latestAdminUpdateMessage: Resource<AdminUpdateMessage>
  adminUpdateMessage: AdminUpdateMessageState
  sendAdminMessageFeedback: Resource<number>
  getUnreadChatNotifications: Resource<ChatNotification[]>
  readChatNotifications: Resource
}

const initialState: State = {
  notifications: resource.getInitial<Notification[]>([]),
  unreadCount: {
    [NotificationType.Other]: 0,
    [NotificationType.NeedAssignmentRequestAccepted]: 0,
    [NotificationType.NeedAssignmentRequestCreated]: 0,
    [NotificationType.NeedAssignmentRequestRejected]: 0,
    [NotificationType.NeedAssignmentBooked]: 0,
    [NotificationType.NeedAssignmentCanceled]: 0,
    [NotificationType.NeedAssignmentNoteAdded]: 0,
    [NotificationType.NeedAssignmentResourceNoteAdded]: 0,
    [NotificationType.NeedCreated]: 0,
  },
  getSchedulesUnreadNotifications: resource.getInitial<SchedulesNotification[]>(
    []
  ),
  getNeedsCreatedNotifications: resource.getInitial<Notification[]>([]),
  markAsRead: resource.getInitial(),
  resourceBookingsNotifications: resource.getInitial<Notification[]>([]),
  resourceRequestsNotifications: resource.getInitial<Notification[]>([]),
  requestsCount: resource.getInitial(),
  latestAdminInfoMessage: resource.getInitial(),
  latestAdminUpdateMessage: resource.getInitial(),
  adminUpdateMessage: {
    isModalOpen: false,
    ...resource.getInitial(),
  },
  sendAdminMessageFeedback: resource.getInitial(),
  getUnreadChatNotifications: resource.getInitial(),
  readChatNotifications: resource.getInitial(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(setNotificationsCount, (state, action) => {
      state.unreadCount[action.payload.type] = action.payload.unreadCount
    })
    .addCase(getUnreadNotifications.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.unreadCount = initialState.unreadCount
        return
      }

      action.payload.forEach(notification => {
        state.unreadCount[notification.type] = notification.count || 0
      })
    })
    .addCase(getNotifications.pending, state => {
      resource.setPending(state.notifications)
    })
    .addCase(getNotifications.fulfilled, (state, action) => {
      const skip = action.meta.arg.skip || 0
      resource.setSucceeded(
        state.notifications,
        skip > (state.notifications.meta?.skip || 0)
          ? [...state.notifications.data, ...action.payload.data]
          : action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getNotifications.rejected, (state, action) => {
      resource.setFailed(state.notifications)
    })
    .addCase(getNeedsCreatedNotifications.pending, state => {
      resource.setPending(state.getNeedsCreatedNotifications)
    })
    .addCase(getNeedsCreatedNotifications.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getNeedsCreatedNotifications,
        action.payload.data
      )
    })
    .addCase(getNeedsCreatedNotifications.rejected, (state, action) => {
      resource.setFailed(state.getNeedsCreatedNotifications)
    })
    .addCase(resetNotifications, (state, action) => {
      resource.reset(state.notifications, initialState.notifications.data)
    })
    .addCase(getSchedulesUnreadNotifications.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getSchedulesUnreadNotifications,
        action.payload
      )
    })
    .addCase(markAsRead.pending, (state, action) => {
      resource.setPending(state.markAsRead)
    })
    .addCase(markAsRead.fulfilled, (state, action) => {
      resource.setSucceeded(state.markAsRead)
    })
    .addCase(markAsRead.rejected, (state, action) => {
      resource.setFailed(state.markAsRead)
    })
    .addCase(getResourceBookingsNotifications.pending, (state, action) => {
      resource.setPending(state.resourceBookingsNotifications)
    })
    .addCase(getResourceBookingsNotifications.fulfilled, (state, action) => {
      const skip = action.meta.arg.skip || 0
      resource.setSucceeded(
        state.resourceBookingsNotifications,
        skip > (state.resourceBookingsNotifications.meta?.skip || 0)
          ? [
              ...state.resourceBookingsNotifications.data,
              ...action.payload.data,
            ]
          : action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getResourceBookingsNotifications.rejected, (state, action) => {
      resource.setFailed(
        state.resourceBookingsNotifications,
        action.error.message
      )
    })
    .addCase(getResourceRequestsNotifications.pending, (state, action) => {
      resource.setPending(state.resourceRequestsNotifications)
    })
    .addCase(getResourceRequestsNotifications.fulfilled, (state, action) => {
      const skip = action.meta.arg.skip || 0
      resource.setSucceeded(
        state.resourceRequestsNotifications,
        skip > (state.resourceRequestsNotifications.meta?.skip || 0)
          ? [
              ...state.resourceRequestsNotifications.data,
              ...action.payload.data,
            ]
          : action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getResourceRequestsNotifications.rejected, (state, action) => {
      resource.setFailed(
        state.resourceRequestsNotifications,
        action.error.message
      )
    })
    .addCase(resetRequestsNotifications, state => {
      resource.reset(
        state.resourceRequestsNotifications,
        initialState.resourceRequestsNotifications.data
      )
    })
    .addCase(resetBookingsNotifications, state => {
      resource.reset(
        state.resourceBookingsNotifications,
        initialState.resourceBookingsNotifications.data
      )
    })
    .addCase(getRequestsCount.pending, (state, action) => {
      resource.setPending(state.requestsCount)
    })
    .addCase(getRequestsCount.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.requestsCount,
        action.payload.data.filter(({ notificationId }) => !!notificationId)
          .length
      )
    })
    .addCase(getRequestsCount.rejected, (state, action) => {
      resource.setFailed(state.requestsCount, action.error.message)
    })
    .addCase(getLatestAdminInfoMessage.fulfilled, (state, action) => {
      resource.setSucceeded(state.latestAdminInfoMessage, action.payload)
    })
    .addCase(getLatestAdminUpdateMessage.fulfilled, (state, action) => {
      resource.setSucceeded(state.latestAdminUpdateMessage, action.payload)
    })
    .addCase(getAdminUpdateMessage.fulfilled, (state, action) => {
      resource.setSucceeded(state.adminUpdateMessage, action.payload)
    })
    .addCase(openAdminUpdateMessageModal.pending, state => {
      resource.setPending(state.adminUpdateMessage)
      state.adminUpdateMessage.isModalOpen = true
    })
    .addCase(openAdminUpdateMessageModal.rejected, (state, action) => {
      resource.setFailed(state.adminUpdateMessage, action.error.message)
      state.adminUpdateMessage.isModalOpen = false
    })
    .addCase(openAdminUpdateMessageModal.fulfilled, (state, action) => {
      resource.setSucceeded(state.adminUpdateMessage, action.payload)
    })
    .addCase(closeAdminUpdateMessageModal, state => {
      state.adminUpdateMessage.isModalOpen = false
    })
    .addCase(sendAdminMessageFeedback.fulfilled, state => {
      resource.setSucceeded(state.adminUpdateMessage)
    })
    .addCase(getUnreadChatNotifications.pending, state => {
      resource.setPending(state.getUnreadChatNotifications)
    })
    .addCase(getUnreadChatNotifications.rejected, (state, action) => {
      resource.setFailed(state.getUnreadChatNotifications, action.error.message)
    })
    .addCase(getUnreadChatNotifications.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getUnreadChatNotifications,
        action.payload.map(({ id }) => ({ id }))
      )
    })
    .addCase(readChatNotifications.pending, state => {
      resource.setPending(state.readChatNotifications)
    })
    .addCase(readChatNotifications.rejected, (state, action) => {
      resource.setFailed(state.readChatNotifications, action.error.message)
    })
    .addCase(readChatNotifications.fulfilled, (state, action) => {
      resource.setSucceeded(state.readChatNotifications, action.payload)
      if (!state.getUnreadChatNotifications.data) return

      const chatNotificationIndex =
        state.getUnreadChatNotifications.data.findIndex(
          chatNotification =>
            chatNotification.id === action.meta.arg.params.chatId
        )
      if (chatNotificationIndex !== -1)
        state.getUnreadChatNotifications.data.splice(chatNotificationIndex, 1)
    })
    .addCase(addChatNotification, (state, action) => {
      if (
        !state.getUnreadChatNotifications.data.some(
          ({ id }) => id === action.payload.chatId
        )
      )
        state.getUnreadChatNotifications.data.push({
          id: action.payload.chatId,
        })
    })
)
