import Loader from '../../Loader'
import Content from './Components/Content'
import { StyledDialog } from './UpdateMessageModal.style'
import { useAdminUpdateMessageModal } from './UpdateMessageModal.utils'

const UpdateMessageModal = () => {
  const { isLoading, error, isOpen, onClose } = useAdminUpdateMessageModal()

  if (error) return null

  return (
    <StyledDialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      isLoading={isLoading}
    >
      {isLoading ? <Loader /> : <Content />}
    </StyledDialog>
  )
}

export default UpdateMessageModal
