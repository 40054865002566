import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { paths } from 'config'

export const useIsOrganizationSelected = () => {
  const location = useLocation()
  const pathname = location.pathname
  const state = location.state as { background?: Location } | null
  const path = state?.background?.pathname || pathname
  return useMemo(() => {
    const match = matchPath<'organizationId', string>(
      { path: paths.organization, end: false },
      path
    )
    const { organizationId } = match?.params || {}
    return !!organizationId
  }, [path])
}
