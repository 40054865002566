import Api from '../api'
import {
  GetCareProvidersParams,
  GetCareProvidersResponse,
  GetCareProviderResponse,
  UpdateCareProviderInformationParams,
  GetCareProviderInformationResponse,
  GetCareProviderInformationParams,
  ExportCareProviderStatisticsParams,
  ExportCareProviderStatisticsResponse,
} from './CareProviders.types'

class CareProviders extends Api {
  public exportStatistics = async ({
    careProviderId,
    ...params
  }: ExportCareProviderStatisticsParams) => {
    const { data } = await this.api.post<ExportCareProviderStatisticsResponse>(
      `/care-providers/${careProviderId}/export-statistics`,
      {},
      { params, responseType: 'arraybuffer' }
    )
    return data
  }

  public getCareProviders = async (params: GetCareProvidersParams = {}) => {
    const response = await this.api.get<GetCareProvidersResponse>(
      '/care-providers',
      { params }
    )
    const { data, ...meta } = response.data
    return { data, meta }
  }

  public getCareProviderById = async (id: number) => {
    const { data } = await this.api.get<GetCareProviderResponse>(
      `/care-providers/${id}`
    )
    return data.data
  }

  public getCareProviderInformation = async ({
    careProviderId,
  }: GetCareProviderInformationParams) => {
    const { data } = await this.api.get<GetCareProviderInformationResponse>(
      `/care-providers/${careProviderId}`
    )
    return data
  }

  public updateCareProviderInformation = async ({
    careProviderId,
    ...payload
  }: UpdateCareProviderInformationParams) => {
    const { data } = await this.api.put<string>(
      `/care-providers/${careProviderId}`,
      payload
    )
    return data
  }
}

export default CareProviders
