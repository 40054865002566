import { useState } from 'react'
import { SvgIconComponent } from '@mui/icons-material'
import { useAuth } from '../../hooks'
import { ModuleRoute } from '../../types'
import Routes from '../Routes'
import Drawer from './Drawer'
import Topbar from './Topbar'
import { Container, Content } from './Layout.style'
import {
  useCurrentLayoutSettings,
  useNavItems,
  useFooterNavItems,
} from './Layout.utils'

export interface LayoutNavItem {
  path: string
  label: string
  icon?: SvgIconComponent
}

export interface LayoutProps {
  routes: ModuleRoute[]
  modalRoutes: ModuleRoute[]
  hideDrawer?: boolean
  hideTopbar?: boolean
}

const Layout = ({ routes, modalRoutes }: LayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const navItems = useNavItems()
  const footerNavItems = useFooterNavItems()
  const { hasOrganization } = useAuth()
  const { drawerProps = {}, topbarProps = {} } =
    useCurrentLayoutSettings(routes)

  const handleDrawerToggle = () => setIsDrawerOpen(open => !open)
  const handleDrawerClose = () => setIsDrawerOpen(false)

  return (
    <Container>
      <Topbar onDrawerToggle={handleDrawerToggle} {...topbarProps} />
      {hasOrganization && (
        <Drawer
          navItems={navItems}
          footerNavItems={footerNavItems}
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          {...drawerProps}
        />
      )}
      <Content withTopbarFix={!topbarProps.hidden}>
        <Routes routes={routes} modalRoutes={modalRoutes} />
      </Content>
    </Container>
  )
}

export default Layout
