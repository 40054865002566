import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { StyledCheckboxField } from '../../AssignmentModal.style'
import { RequestedResourceStatus } from '../RequestAssignmentContent.utils'
import { RequestedMessageConstainer } from './RequestSection.style'

interface RequestCheckboxProps {
  name: string
  value: string | number | boolean
  label: string
  disabledBooking?: boolean
  status?: RequestedResourceStatus
}

const RequestCheckbox = ({
  name,
  label,
  value,
  disabledBooking = false,
  status = RequestedResourceStatus.Requestable,
}: RequestCheckboxProps) => {
  const { t } = useTranslation()
  const isRejected = status === RequestedResourceStatus.Rejected
  const isRequested = status === RequestedResourceStatus.Requested
  return (
    <Grid item container alignItems="center">
      <Grid item>
        <StyledCheckboxField
          disabled={disabledBooking || isRequested}
          name={name}
          label={label}
          multiple
          value={value}
          labelColorInherited={isRequested}
          {...(isRequested && { checked: isRequested })}
        />
      </Grid>
      <Grid item>
        <RequestedMessageConstainer rejected={isRejected} variant="body2">
          {isRejected && t('assignmentModal.rejectedMessage')}
          {isRequested && t('assignmentModal.requestedMessage')}
        </RequestedMessageConstainer>
      </Grid>
    </Grid>
  )
}

export default RequestCheckbox
