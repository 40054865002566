import { Sync } from '@mui/icons-material'
import { styled } from '@mui/material'

const SYNC_ICON_SIZE = 16

export const SyncIcon = styled(Sync)(({ theme }) => ({
  height: SYNC_ICON_SIZE,
  width: SYNC_ICON_SIZE,
  padding: theme.spacing(0.25),
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderRadius: '50%',
}))
