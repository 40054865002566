import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import Chat, {
  GetChatMessagesParams,
  GetChatsParams,
  GetChatsSortBy,
  SendChatMessageParams,
  ChatEvent,
  chatSocket,
  GetChatParams,
} from 'shared/services/chat'
import 'shared/services/chat'
import { actions as commonActions } from 'shared/store'
import { ChatListEntry, SortingOrder } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME } from '../strings'
import {
  AddUsersToChatPayload,
  ArchiveChatPayload,
  CreateChatPayload,
  ReceiveChatMessagePayload,
  ToggleIsFlaggedPayload,
  UnarchiveChatPayload,
} from './actions.types'

const chat = new Chat()

export const joinChatRoom = createAsyncThunk(
  `${MODULE_NAME}/joinChatRoom`,
  async (chatId: number) => {
    chatSocket.emit(ChatEvent.JoinRoom, { chatId })
  }
)

export const leaveChatRoom = createAsyncThunk(
  `${MODULE_NAME}/leaveChatRoom`,
  async (chatId: number) => {
    chatSocket.emit(ChatEvent.LeaveRoom, { chatId })
  }
)

export const createChat = createAsyncThunk(
  `${MODULE_NAME}/createChat`,
  async (
    { params, onFailure, onSuccess }: CreateChatPayload,
    { rejectWithValue }
  ) => {
    try {
      const response = await chat.createChat(params)
      onSuccess(response.id)
      chatSocket.emit(ChatEvent.JoinRoom, { chatId: response.id })
      return response
    } catch (err) {
      onFailure()
      return rejectWithValue(err)
    }
  }
)

export const sendChatMessage = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/sendChatMessage`,
  (payload: SendChatMessageParams) => chat.sendChatMessage(payload)
)

export const addUsersToChat = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addUsersToChat`,
  async (payload: AddUsersToChatPayload) => {
    const accountIds = payload.accounts.map(account => account?.value)
    return await chat.addUsersToChat({ ...payload, accountIds })
  }
)

export const archiveChat = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/archiveChat`,
  (payload: ArchiveChatPayload) => chat.editChat({ ...payload, archive: true })
)

export const unarchiveChat = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/unarchiveChat`,
  (payload: UnarchiveChatPayload) =>
    chat.editChat({ ...payload, archive: false })
)

export const toggleChatFlag = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/toggleChatFlag`,
  (payload: ToggleIsFlaggedPayload) =>
    chat.editChat({ ...payload, flag: payload.isFlagged })
)

export const setActiveChat = createAction<ChatListEntry>(
  `${MODULE_NAME}/setActiveChat`
)

export const receiveChatMessage = createAsyncThunk(
  `${MODULE_NAME}/receiveChatMessage`,
  (
    { selectedChatId, message, careProviderId }: ReceiveChatMessagePayload,
    { dispatch }
  ) => {
    if (selectedChatId === message.chatId) {
      dispatch(
        commonActions.notifications.readChatNotifications({
          params: { chatId: message.chatId, careProviderId },
        })
      )
    } else {
      dispatch(
        commonActions.notifications.addChatNotification({
          chatId: message.chatId,
          messageId: message.id,
        })
      )
    }
  }
)

export const getChats = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/getChats`,
  async (payload: GetChatsParams, { dispatch }) => {
    const data = await chat.getChats({
      ...payload,
      sortBy: GetChatsSortBy.LastMessageCreatedAt,
      sortOrder: SortingOrder.Descending,
    })

    data.data.forEach(({ id }) => {
      dispatch(joinChatRoom(id))
    })
    return data
  }
)

export const setChatsFilterParams = createAction<GetChatsParams>(
  `${MODULE_NAME}/setChatsFilterParams`
)

export const getSelectedChat = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/getSelectedChat`,
  (payload: GetChatParams) => chat.getChat(payload)
)

export const resetSelectedChat = createAction(
  `${MODULE_NAME}/resetSelectedChat`
)

export const getChatMessages = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/getChatMessages`,
  (payload: GetChatMessagesParams) => chat.getChatMessages(payload)
)

export const resetChatMessages = createAction(
  `${MODULE_NAME}/resetChatMessages`
)

export const markChatAsRead = createAction<number>(
  `${MODULE_NAME}/markChatAsRead`
)
