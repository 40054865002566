import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useDispatch } from '../../hooks'
import { useDialog, useOrganizationId } from '../../hooks'
import { actions } from '../../store'
import { MessageDeliveryType } from '../../types'

const useSuccessText = (messageDeliveryType: MessageDeliveryType | null) => {
  const { t } = useTranslation()

  if (!messageDeliveryType) return

  switch (messageDeliveryType) {
    case MessageDeliveryType.Email:
      return t('resetLoginInformation.successEmail')
    case MessageDeliveryType.Sms:
      return t('resetLoginInformation.successSms')
    default:
      return ''
  }
}

export const useLoginInfoReset = (accountId: number) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const careProviderId = useOrganizationId()
  const [isActivationModalOpen, , openActivationModal, closeActivationModal] =
    useDialog()

  const { formState } = useFormContext()
  const { isDirty } = formState

  const [hasBeenReset, setHasBeenReset] = useState(false)
  const [messageDeliveryType, setMessageDeliveryType] =
    useState<MessageDeliveryType | null>(null)

  const onResetViaSmsClick = useCallback(() => {
    openActivationModal()
    setMessageDeliveryType(MessageDeliveryType.Sms)
  }, [openActivationModal])

  const onResetViaEmailClick = useCallback(() => {
    openActivationModal()
    setMessageDeliveryType(MessageDeliveryType.Email)
  }, [openActivationModal])

  const resetLoginInfo = useCallback(() => {
    if (!messageDeliveryType) return

    const onFailure = (message?: string) =>
      enqueueSnackbar(message || t('resetLoginInformation.failure'), {
        variant: 'error',
      })

    const onSuccess = () => setHasBeenReset(true)

    dispatch(
      actions.auth.changeLoginInfo({
        params: {
          careProviderId,
          accountId,
          messageDeliveryType,
        },
        onFailure,
        onSuccess,
      })
    )
  }, [
    accountId,
    careProviderId,
    dispatch,
    enqueueSnackbar,
    messageDeliveryType,
    t,
  ])

  useEffect(() => {
    return () => {
      dispatch(actions.auth.resetChangeLoginInfo())
    }
  }, []) // eslint-disable-line

  return {
    isDirty,
    hasBeenReset,
    isActivationModalOpen,
    successText: useSuccessText(messageDeliveryType),
    onResetViaEmailClick,
    onResetViaSmsClick,
    resetLoginInfo,
    closeActivationModal,
  }
}
