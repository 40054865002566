import { memo } from 'react'
import { ChartData } from '../../../../types'
import { CircularMarkerTemplate, PillMarkerTemplate } from '../Templates'
import { StyledListItem, Text } from './LegendItem.style'

interface LegendItemProps {
  data: ChartData
  circular?: boolean
}

const LegendItem = memo(({ data, circular = false }: LegendItemProps) => {
  const { status, legendItem, color, borderColor, filledLegend } = data

  return (
    <StyledListItem>
      {circular ? (
        <CircularMarkerTemplate color={color} />
      ) : (
        <PillMarkerTemplate
          filledLegend={filledLegend}
          color={color}
          borderColor={borderColor}
        />
      )}

      <Text noWrap>{legendItem ?? status}</Text>
    </StyledListItem>
  )
})

export default LegendItem
