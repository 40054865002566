import { useFormContext, useController } from 'react-hook-form'
import { TextField } from '@mui/material'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker'

export interface TimeFieldProps extends Partial<TimePickerProps<Date, Date>> {
  name: string
  defaultValue?: string | number
}

const TimePickerField = ({
  name,
  defaultValue = '',
  ...props
}: TimeFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...formFieldProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })
  return (
    <TimePicker
      closeOnSelect={true}
      ampm={false}
      inputRef={ref}
      renderInput={renderInputProps => (
        <TextField
          {...renderInputProps}
          error={!!error}
          helperText={error?.message}
          inputProps={{
            ...renderInputProps.inputProps,
            sx: {
              textTransform: 'uppercase',
            },
          }}
        />
      )}
      {...formFieldProps}
      {...props}
    />
  )
}

export default TimePickerField
