import { Badge, styled } from '@mui/material'

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: '50%',
    left: theme.spacing(-2),
    boxShadow: `0 2px 7px ${theme.palette.shadow.dark}`,
  },
}))

export const FormContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))
