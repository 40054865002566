import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import {
  DetailedUnit,
  Need,
  Shift,
  MatchingResource,
  NeedAssignmentsStatistics,
  BookedResource,
  BookedAssignment,
  MatchingAssignment,
  UnitNeedAssignment,
  Role,
  UnitNeedSummaryDetails,
  AssignmentToAdd,
  EditingModeDialogData,
} from 'shared/types'
import { matchAssignmentToAddWithShift } from 'shared/utils'
import {
  getPublishedNeeds,
  getShifts,
  publishNeed,
  resetPublishNeed,
  getDetailedUnits,
  resetDetailedUnits,
  getMatchingResources,
  resetGetMatchingResources,
  bookAssignments,
  assignAssignmentToResources,
  changeAssignmentState,
  modifyBookedAssignment,
  setSelectedResource,
  setSelectedBookedResource,
  resetChangeAssignmentState,
  resetModifyBookedAssignment,
  getNeedAssignmentsStatistics,
  getBookedResources,
  resetGetBookedResources,
  requestAssignments,
  assignResourceToAssignments,
  getBookedAssignments,
  unbookAssignments,
  setSelectedAssignments,
  resetSelectedAssignments,
  getUpdatedSelectedResource,
  getUnitNeedAssignments,
  exportNeedAssignments,
  resetExportNeedAssignments,
  getRoles,
  getUnitNeedsSummaries,
  filterUnitNotifications,
  resetShifts,
  resetBookAssignments,
  updateNeed,
  removeNeedAssignment,
  addNeedAssignment,
  disableSchedulerEditingMode,
  enableSchedulerEditingMode,
  openUpdateNeedDialog,
  closeUpdateNeedDialog,
  exportNeedAssignmentsToViviumX,
} from './actions'
import { DEFAULT_SHIFTS, getNonRequestableResource } from './utils'

interface MatchingResourcesData {
  matchingResources: MatchingResource[]
  totalAssignmentsCount: number
  bookableResourcesCount: number
  totalResourcesCount: number
  requestableResourcesCount: number
}

interface BookedResourcesData {
  resources: BookedResource[]
  totalBookedResourcesCount: number
}

interface SchedulerEditingMode {
  isEnabled: boolean
  assignmentsToAdd: AssignmentToAdd[]
  assignmentIdsToDelete: number[]
  preventedClickTarget: EventTarget | null
  dialog: {
    isOpen: boolean
    data: EditingModeDialogData
  }
}

interface State {
  getPublishedNeeds: Resource<Need[]>
  publishNeed: Resource<string>
  getShifts: Resource<Shift[]>
  getDetailedUnits: Resource<DetailedUnit[]>
  getMatchingResources: Resource<MatchingResourcesData>
  bookAssignments: Resource<string>
  changeAssignmentState: Resource<string>
  modifyBookedAssignment: Resource<string>
  selectedBookedResource: BookedResource | null
  selectedResource: MatchingResource | null
  getNeedAssignmentsStatistics: Resource<NeedAssignmentsStatistics>
  getBookedResources: Resource<BookedResourcesData>
  requestAssignments: Resource<string>
  assignResourceToAssignments: Resource<string>
  assignAssignmentToResources: Resource<string>
  getBookedAssignments: Resource<BookedAssignment[]>
  unbookAssignments: Resource<string>
  selectedAssignments: MatchingAssignment[] | null
  getUpdatedSelectedResource: Resource<MatchingResource[]>
  getUnitNeedAssignments: Record<number, Resource<UnitNeedAssignment[]>>
  exportNeedAssignments: Resource<ArrayBuffer>
  exportNeedAssignmentsToViviumX: Resource<{
    createdListingDraftsCount: number
  }>
  getRoles: Resource<Role[]>
  roles: Role[] | []
  getUnitNeedsSummaries: Record<number, Resource<UnitNeedSummaryDetails[]>>
  updateNeed: Resource<string>
  schedulerEditingMode: SchedulerEditingMode
}

const initialEditingModeDialogData = {
  mainContent: '',
  confirmationButtonLabel: '',
  cancelButtonLabel: '',
  onConfirmation: () => {},
  onCancel: () => {},
  onClose: () => {},
}

const initialState: State = {
  getPublishedNeeds: resource.getInitial<Need[]>([]),
  publishNeed: resource.getInitial<string>(''),
  getShifts: resource.getInitial<Shift[]>([]),
  getDetailedUnits: resource.getInitial<DetailedUnit[]>([]),
  getMatchingResources: resource.getInitial<MatchingResourcesData>({
    matchingResources: [],
    totalAssignmentsCount: 0,
    bookableResourcesCount: 0,
    totalResourcesCount: 0,
    requestableResourcesCount: 0,
  }),
  bookAssignments: resource.getInitial<string>(''),
  changeAssignmentState: resource.getInitial<string>(''),
  modifyBookedAssignment: resource.getInitial<string>(''),
  selectedResource: null,
  selectedBookedResource: null,
  getNeedAssignmentsStatistics:
    resource.getInitial<NeedAssignmentsStatistics>(),
  getBookedResources: resource.getInitial<BookedResourcesData>({
    resources: [],
    totalBookedResourcesCount: 0,
  }),
  requestAssignments: resource.getInitial<string>(''),
  assignResourceToAssignments: resource.getInitial<string>(''),
  assignAssignmentToResources: resource.getInitial<string>(''),
  getBookedAssignments: resource.getInitial([]),
  unbookAssignments: resource.getInitial(''),
  selectedAssignments: null,
  getUpdatedSelectedResource: resource.getInitial<MatchingResource[]>([]),
  getUnitNeedAssignments: {},
  exportNeedAssignments: resource.getInitial<ArrayBuffer>(),
  exportNeedAssignmentsToViviumX: resource.getInitial<{
    createdListingDraftsCount: number
  }>({ createdListingDraftsCount: 0 }),
  getRoles: resource.getInitial<Role[]>(),
  roles: [],
  getUnitNeedsSummaries: {},
  updateNeed: resource.getInitial(''),
  schedulerEditingMode: {
    isEnabled: false,
    assignmentsToAdd: [],
    assignmentIdsToDelete: [],
    preventedClickTarget: null,
    dialog: {
      isOpen: false,
      data: initialEditingModeDialogData,
    },
  },
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getPublishedNeeds.pending, state => {
      resource.setPending(state.getPublishedNeeds)
    })
    .addCase(getPublishedNeeds.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getPublishedNeeds,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getPublishedNeeds.rejected, (state, action) => {
      resource.setFailed(state.getPublishedNeeds, action.error.message)
    })
    .addCase(publishNeed.pending, state => {
      resource.setPending(state.publishNeed)
    })
    .addCase(publishNeed.fulfilled, (state, action) => {
      resource.setSucceeded(state.publishNeed, action.payload)
    })
    .addCase(publishNeed.rejected, (state, action) => {
      resource.setFailed(state.publishNeed, action.error.message)
    })
    .addCase(resetPublishNeed, state => {
      resource.reset(state.publishNeed, initialState.publishNeed.data)
    })
    .addCase(getShifts.pending, state => {
      resource.setPending(state.getShifts)
      state.getShifts.data = DEFAULT_SHIFTS
    })
    .addCase(getShifts.fulfilled, (state, action) => {
      resource.setSucceeded(state.getShifts, action.payload)
    })
    .addCase(getShifts.rejected, (state, action) => {
      resource.setFailed(state.getShifts, action.error.message)
    })
    .addCase(getDetailedUnits.pending, state => {
      resource.setPending(state.getDetailedUnits)
    })
    .addCase(getDetailedUnits.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.getDetailedUnits,
        action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getDetailedUnits.rejected, (state, action) => {
      resource.setFailed(state.getDetailedUnits, action.error.message)
    })
    .addCase(resetDetailedUnits, state => {
      resource.reset(state.getDetailedUnits)
    })
    .addCase(getMatchingResources.pending, state => {
      resource.setPending(state.getMatchingResources)
    })
    .addCase(getMatchingResources.fulfilled, (state, action) => {
      const {
        bookableResourcesCount,
        totalAssignmentsCount,
        totalResourcesCount,
        requestableResourcesCount,
      } = action.payload.meta
      resource.setSucceeded(
        state.getMatchingResources,
        {
          matchingResources: action.payload.data,
          bookableResourcesCount,
          requestableResourcesCount,
          totalAssignmentsCount,
          totalResourcesCount,
        },
        action.payload.meta
      )
      if (state.selectedResource)
        state.selectedResource =
          action.payload.data.find(
            ({ id }) => id === state.selectedResource?.id
          ) || getNonRequestableResource(state.selectedResource)
    })
    .addCase(getMatchingResources.rejected, (state, action) => {
      resource.setFailed(state.getMatchingResources, action.error.message)
    })
    .addCase(resetGetMatchingResources, state => {
      resource.reset(
        state.getMatchingResources,
        initialState.getMatchingResources.data
      )
    })
    .addCase(bookAssignments.pending, state => {
      resource.setPending(state.bookAssignments)
    })
    .addCase(bookAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.bookAssignments, action.payload)
    })
    .addCase(bookAssignments.rejected, (state, action) => {
      resource.setFailed(state.bookAssignments, action.error.message)
    })
    .addCase(resetBookAssignments, state => {
      resource.reset(state.bookAssignments, '')
    })
    .addCase(assignAssignmentToResources.pending, state => {
      resource.setPending(state.assignAssignmentToResources)
    })
    .addCase(assignAssignmentToResources.fulfilled, (state, action) => {
      resource.setSucceeded(state.assignAssignmentToResources, action.payload)
    })
    .addCase(assignAssignmentToResources.rejected, (state, action) => {
      resource.setFailed(
        state.assignAssignmentToResources,
        action.error.message
      )
    })
    .addCase(changeAssignmentState.pending, state => {
      resource.setPending(state.changeAssignmentState)
    })
    .addCase(changeAssignmentState.fulfilled, (state, action) => {
      resource.setSucceeded(state.changeAssignmentState, action.payload)
    })
    .addCase(changeAssignmentState.rejected, (state, action) => {
      resource.setFailed(state.changeAssignmentState, action.error.message)
    })
    .addCase(resetChangeAssignmentState, state => {
      resource.reset(
        state.changeAssignmentState,
        initialState.changeAssignmentState.data
      )
    })
    .addCase(modifyBookedAssignment.pending, state => {
      resource.setPending(state.modifyBookedAssignment)
    })
    .addCase(modifyBookedAssignment.fulfilled, (state, action) => {
      resource.setSucceeded(state.modifyBookedAssignment, action.payload)
    })
    .addCase(modifyBookedAssignment.rejected, (state, action) => {
      resource.setFailed(state.modifyBookedAssignment, action.error.message)
    })
    .addCase(resetModifyBookedAssignment, state => {
      resource.reset(state.modifyBookedAssignment)
    })
    .addCase(setSelectedResource, (state, action) => {
      state.selectedBookedResource = null
      state.selectedResource = action.payload
    })
    .addCase(getUpdatedSelectedResource.pending, state => {
      resource.setPending(state.getUpdatedSelectedResource)
    })
    .addCase(getUpdatedSelectedResource.fulfilled, (state, action) => {
      resource.setSucceeded(state.getUpdatedSelectedResource)
      if (state.selectedResource)
        state.selectedResource =
          action.payload.data.find(
            ({ id }) => id === state.selectedResource?.id
          ) || getNonRequestableResource(state.selectedResource)
    })
    .addCase(getUpdatedSelectedResource.rejected, (state, action) => {
      resource.setFailed(state.modifyBookedAssignment, action.error.message)
    })
    .addCase(getNeedAssignmentsStatistics.pending, state => {
      resource.setPending(state.getNeedAssignmentsStatistics)
    })
    .addCase(getNeedAssignmentsStatistics.fulfilled, (state, action) => {
      resource.setSucceeded(state.getNeedAssignmentsStatistics, action.payload)
    })
    .addCase(getNeedAssignmentsStatistics.rejected, (state, action) => {
      resource.setFailed(
        state.getNeedAssignmentsStatistics,
        action.error.message
      )
    })
    .addCase(getBookedResources.pending, state => {
      resource.setPending(state.getBookedResources)
    })
    .addCase(getBookedResources.fulfilled, (state, action) => {
      resource.setSucceeded(state.getBookedResources, action.payload)
    })
    .addCase(getBookedResources.rejected, (state, action) => {
      resource.setFailed(state.getBookedResources, action.error.message)
    })
    .addCase(resetGetBookedResources, state => {
      resource.reset(
        state.getBookedResources,
        initialState.getBookedResources.data
      )
    })
    .addCase(setSelectedBookedResource, (state, action) => {
      state.selectedBookedResource = action.payload
      state.selectedResource = null
    })
    .addCase(requestAssignments.pending, state => {
      resource.setPending(state.requestAssignments)
    })
    .addCase(requestAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.requestAssignments, action.payload)
      state.selectedResource = null
    })
    .addCase(requestAssignments.rejected, (state, action) => {
      resource.setFailed(state.requestAssignments, action.error.message)
    })
    .addCase(assignResourceToAssignments.pending, state => {
      resource.setPending(state.assignResourceToAssignments)
    })
    .addCase(assignResourceToAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.assignResourceToAssignments, action.payload)
    })
    .addCase(assignResourceToAssignments.rejected, (state, action) => {
      resource.setFailed(
        state.assignResourceToAssignments,
        action.error.message
      )
    })
    .addCase(getBookedAssignments.pending, state => {
      resource.setPending(state.getBookedAssignments)
    })
    .addCase(getBookedAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.getBookedAssignments, action.payload)
    })
    .addCase(getBookedAssignments.rejected, (state, action) => {
      resource.setFailed(state.getBookedAssignments, action.error.message)
    })
    .addCase(unbookAssignments.pending, state => {
      resource.setPending(state.unbookAssignments)
    })
    .addCase(unbookAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.unbookAssignments, action.payload)
    })
    .addCase(unbookAssignments.rejected, (state, action) => {
      resource.setFailed(state.unbookAssignments, action.error.message)
    })
    .addCase(setSelectedAssignments, (state, action) => {
      state.selectedAssignments = action.payload
    })
    .addCase(resetSelectedAssignments, state => {
      state.selectedAssignments = null
    })
    .addCase(resetShifts, state => {
      resource.reset(state.getShifts, initialState.getShifts.data)
    })
    .addCase(getUnitNeedAssignments.pending, (state, action) => {
      const { unitId, needId } = action.meta.arg
      const detailsId = needId ?? unitId
      if (!state.getUnitNeedAssignments[detailsId]) {
        state.getUnitNeedAssignments[detailsId] = resource.getInitial([])
      }
      resource.setPending(state.getUnitNeedAssignments[detailsId])
    })
    .addCase(getUnitNeedAssignments.fulfilled, (state, action) => {
      const { unitId, needId } = action.meta.arg
      const detailsId = needId ?? unitId
      const skip = action.meta.arg.skip || 0
      const prevSkip = state.getUnitNeedAssignments[detailsId].meta?.skip || 0

      resource.setSucceeded(
        state.getUnitNeedAssignments[detailsId],
        skip > prevSkip
          ? [
              ...state.getUnitNeedAssignments[detailsId].data,
              ...action.payload.data,
            ]
          : action.payload.data,
        action.payload.meta
      )
    })
    .addCase(getUnitNeedAssignments.rejected, (state, action) => {
      const { unitId, needId } = action.meta.arg
      const detailsId = needId ?? unitId
      resource.setFailed(
        state.getUnitNeedAssignments[detailsId],
        action.error.message
      )
    })
    .addCase(exportNeedAssignments.pending, state => {
      resource.setPending(state.exportNeedAssignments)
    })
    .addCase(exportNeedAssignments.fulfilled, (state, action) => {
      resource.setSucceeded(state.exportNeedAssignments, action.payload)
    })
    .addCase(exportNeedAssignments.rejected, (state, action) => {
      resource.setFailed(state.exportNeedAssignments, action.error.message)
    })
    .addCase(resetExportNeedAssignments, state => {
      resource.reset(
        state.exportNeedAssignments,
        initialState.exportNeedAssignments.data
      )
    })
    .addCase(exportNeedAssignmentsToViviumX.pending, state => {
      resource.setPending(state.exportNeedAssignmentsToViviumX)
    })
    .addCase(exportNeedAssignmentsToViviumX.fulfilled, (state, action) => {
      resource.setSucceeded(
        state.exportNeedAssignmentsToViviumX,
        action.payload
      )
    })
    .addCase(exportNeedAssignmentsToViviumX.rejected, (state, action) => {
      resource.setFailed(
        state.exportNeedAssignmentsToViviumX,
        action.error.message
      )
    })
    .addCase(getRoles.pending, state => {
      resource.setPending(state.getRoles)
    })
    .addCase(getRoles.fulfilled, (state, action) => {
      resource.setSucceeded(state.getRoles, action.payload)
      state.roles = action.payload
    })
    .addCase(getRoles.rejected, (state, action) => {
      resource.setFailed(state.getRoles, action.error.message)
    })
    .addCase(getUnitNeedsSummaries.pending, (state, action) => {
      const { unitId } = action.meta.arg
      if (!state.getUnitNeedsSummaries[unitId]) {
        state.getUnitNeedsSummaries[unitId] = resource.getInitial([])
      }
      resource.setPending(state.getUnitNeedsSummaries[unitId])
    })
    .addCase(getUnitNeedsSummaries.fulfilled, (state, action) => {
      const { unitId } = action.meta.arg
      resource.setSucceeded(state.getUnitNeedsSummaries[unitId], action.payload)
    })
    .addCase(getUnitNeedsSummaries.rejected, (state, action) => {
      const { unitId } = action.meta.arg
      resource.setFailed(
        state.getUnitNeedsSummaries[unitId],
        action.error.message
      )
    })
    .addCase(filterUnitNotifications, (state, { payload }) => {
      state.getDetailedUnits.data = state.getDetailedUnits.data.map(
        ({ notifications, ...unit }) => ({
          ...unit,
          notifications: notifications.filter(
            ({ id }) => !payload.notificationIds.includes(id)
          ),
        })
      )
    })
    .addCase(updateNeed.pending, state => {
      resource.setPending(state.updateNeed)
    })
    .addCase(updateNeed.fulfilled, (state, action) => {
      resource.setSucceeded(state.updateNeed, action.payload)
    })
    .addCase(updateNeed.rejected, (state, action) => {
      resource.setFailed(state.updateNeed, action.error.message)
    })
    .addCase(enableSchedulerEditingMode, state => {
      state.schedulerEditingMode.isEnabled = true
      state.schedulerEditingMode.assignmentsToAdd = []
      state.schedulerEditingMode.assignmentIdsToDelete = []
      state.schedulerEditingMode.dialog.isOpen = false
    })
    .addCase(disableSchedulerEditingMode, state => {
      state.schedulerEditingMode.isEnabled = false
      state.schedulerEditingMode.assignmentsToAdd = []
      state.schedulerEditingMode.assignmentIdsToDelete = []
      state.schedulerEditingMode.dialog.isOpen = false
      if (state.schedulerEditingMode.preventedClickTarget) {
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
        })
        state.schedulerEditingMode.preventedClickTarget.dispatchEvent(
          clickEvent
        )
        state.schedulerEditingMode.preventedClickTarget = null
      }
    })
    .addCase(addNeedAssignment, (state, action) => {
      state.schedulerEditingMode.assignmentsToAdd.push(action.payload)
    })
    .addCase(removeNeedAssignment, (state, action) => {
      if (action.payload.id) {
        state.schedulerEditingMode.assignmentIdsToDelete.push(action.payload.id)
      } else {
        const foundIndex =
          state.schedulerEditingMode.assignmentsToAdd.findIndex(assignment =>
            matchAssignmentToAddWithShift(assignment, {
              id: action.payload.shiftId,
              ...action.payload,
            })
          )
        if (foundIndex > -1)
          state.schedulerEditingMode.assignmentsToAdd =
            state.schedulerEditingMode.assignmentsToAdd.filter((_, index) => {
              return index !== foundIndex
            })
      }
    })
    .addCase(openUpdateNeedDialog, (state, action) => {
      state.schedulerEditingMode.dialog.isOpen = true
      state.schedulerEditingMode.dialog.data = action.payload.dialogData
      state.schedulerEditingMode.preventedClickTarget =
        action.payload.preventedClickTarget
    })
    .addCase(closeUpdateNeedDialog, state => {
      state.schedulerEditingMode.dialog.isOpen = false
      state.schedulerEditingMode.preventedClickTarget = null
    })
)
