import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DialogActions } from '@mui/material'

interface DailyAvailabilityDialogActionsProps {
  onClose: () => void
  onSave: () => void
}

const DailyAvailabilityDialogActions = ({
  onSave,
  onClose,
}: DailyAvailabilityDialogActionsProps) => {
  const { t } = useTranslation()
  return (
    <DialogActions>
      <Button onClick={onClose} color="primary" fullWidth={false}>
        {t('availabilityModal.cancel')}
      </Button>
      <Button onClick={onSave} color="primary" fullWidth={false}>
        {t('availabilityModal.save')}
      </Button>
    </DialogActions>
  )
}

export default DailyAvailabilityDialogActions
