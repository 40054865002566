import { useCallback, useEffect, useMemo } from 'react'
import { Control, useForm, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfDay,
  endOfToday,
  isBefore,
  isEqual,
  parse,
  startOfDay,
  startOfToday,
} from 'date-fns'
import { useSnackbar } from 'notistack'
import { date, object, SchemaOf, string, boolean, mixed } from 'yup'
import config from 'config'
import {
  useDispatch,
  useSelector,
  useDateFns,
  useOrganizationId,
  useOrganizationResourceId,
  useStoreValue,
} from '../../hooks'
import { actions, selectors } from '../../store'
import {
  AutocompleteValue,
  AbsenceReason,
  ResourcePeriodType,
  LoadingStatus,
} from '../../types'
import { addIf } from '../../utils'
import { FormProps } from '../Form'
import {
  DailyAvailabilityItemFields,
  DailyAvailabilityItemValues,
  useValidationSchema as useDailyAvailabilityValidationSchema,
  TIME_FORMAT as DAILY_AVAILABILITY_TIME_FORMAT,
} from './DailyAvailability'

export const TIME_FORMAT = 'HH.mm'
const DEFAULT_START_TIME = '07.00'
const DEFAULT_END_TIME = '16.00'
const DEFAULT_ADD_DAY = 1

export enum AvailabilityFormFields {
  Type = 'availabilityType',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  TimeFrom = 'timeFrom',
  TimeTo = 'timeTo',
  IsOpenForOvertime = 'isOpenForOvertime',
  DailyAvailability = 'dailyAvailabilty',
  Reason = 'reason',
  FullDayAvailability = 'fullDayAvailability',
  Resource = 'resource',
}

export interface AvailabilityFormValues {
  [AvailabilityFormFields.Type]:
    | ResourcePeriodType.Availability
    | ResourcePeriodType.Absence
  [AvailabilityFormFields.DateFrom]: Date
  [AvailabilityFormFields.DateTo]: Date
  [AvailabilityFormFields.TimeFrom]: string
  [AvailabilityFormFields.TimeTo]: string
  [AvailabilityFormFields.DailyAvailability]: DailyAvailabilityItemValues[]
  [AvailabilityFormFields.IsOpenForOvertime]?: boolean
  [AvailabilityFormFields.Reason]?: AutocompleteValue
  [AvailabilityFormFields.FullDayAvailability]?: boolean
  [AvailabilityFormFields.Resource]: AutocompleteValue
}

const getStoredValues = (): Partial<AvailabilityFormValues> => ({
  [AvailabilityFormFields.TimeFrom]:
    localStorage.getItem(config.localStorage.resourceAvailabilityTimeFrom) ||
    DEFAULT_START_TIME,
  [AvailabilityFormFields.TimeTo]:
    localStorage.getItem(config.localStorage.resourceAvailabilityTimeTo) ||
    DEFAULT_END_TIME,
})

const getDefaultValues = (): AvailabilityFormValues => ({
  [AvailabilityFormFields.Type]: ResourcePeriodType.Availability,
  [AvailabilityFormFields.DateFrom]: startOfToday(),
  [AvailabilityFormFields.DateTo]: addWeeks(startOfToday(), 1),
  [AvailabilityFormFields.TimeFrom]: DEFAULT_START_TIME,
  [AvailabilityFormFields.TimeTo]: DEFAULT_END_TIME,
  [AvailabilityFormFields.IsOpenForOvertime]: false,
  [AvailabilityFormFields.DailyAvailability]: [],
  [AvailabilityFormFields.Reason]: null,
  ...getStoredValues(),
  [AvailabilityFormFields.FullDayAvailability]: false,
  [AvailabilityFormFields.Resource]: null,
})

export const useValidationSchema = (
  formForCp = false
): SchemaOf<AvailabilityFormValues> => {
  const { t } = useTranslation()
  const dailyAvailabilitySchema = useDailyAvailabilityValidationSchema()
  return object()
    .shape({
      [AvailabilityFormFields.DailyAvailability]: dailyAvailabilitySchema,
      [AvailabilityFormFields.Type]: string().required(
        t('validation.required')
      ),
      [AvailabilityFormFields.DateFrom]: date()
        .required(t('validation.required'))
        .typeError(t('validation.invalidDate')),
      [AvailabilityFormFields.DateTo]: date()
        .required(t('validation.required'))
        .typeError(t('validation.invalidDate')),
      [AvailabilityFormFields.TimeFrom]: string().required(
        t('validation.required')
      ),
      [AvailabilityFormFields.TimeTo]: string()
        .required(t('validation.required'))
        .test(
          'timeTo !== timeFrom',
          t('validation.differentThan', { value: t('time.from') }),
          function (timeTo) {
            return this.parent.timeFrom !== timeTo
          }
        ),
      [AvailabilityFormFields.IsOpenForOvertime]: boolean(),
      [AvailabilityFormFields.FullDayAvailability]: boolean(),
      [AvailabilityFormFields.Reason]: object().when(
        AvailabilityFormFields.Type,
        {
          is: ResourcePeriodType.Absence,
          then: object()
            .shape({ label: string(), value: string() })
            .nullable()
            .required(t('validation.required')),
          otherwise: object().nullable(),
        }
      ),
      ...(formForCp && {
        [AvailabilityFormFields.Resource]: mixed<AutocompleteValue>().required(
          t('validation.required')
        ),
      }),
    })
    .required()
}

const useParsePeriodDate = () => {
  const dateOptions = useDateFns()
  return (time: string, date: Date) =>
    parse(time, DAILY_AVAILABILITY_TIME_FORMAT, date, dateOptions)
}

const useParseEndDate = () => {
  const parsePeriodDate = useParsePeriodDate()
  return (timeFrom: string, timeTo: string, date: Date) => {
    const from = parsePeriodDate(timeFrom, date)
    const to = parsePeriodDate(timeTo, date)
    return isBefore(to, from) ? addDays(to, DEFAULT_ADD_DAY) : to
  }
}

interface Period {
  startDate: Date
  endDate: Date
  note: string
  reason?: AbsenceReason
  openForOvertime?: boolean
  fullDayAvailability?: boolean
}

const useMapPeriods = () => {
  const parsePeriodDate = useParsePeriodDate()
  const parseEndDate = useParseEndDate()
  return (
    dailyAvailability: AvailabilityFormValues[AvailabilityFormFields.DailyAvailability],
    isFullDayAvailability?: AvailabilityFormValues[AvailabilityFormFields.FullDayAvailability],
    reason?: AbsenceReason
  ) =>
    dailyAvailability.reduce(
      (acc, period) => [
        ...acc,
        ...addIf(!period[DailyAvailabilityItemFields.IsRemoved], {
          reason,
          startDate: isFullDayAvailability
            ? startOfDay(period[DailyAvailabilityItemFields.Date])
            : parsePeriodDate(
                period[DailyAvailabilityItemFields.TimeFrom],
                period[DailyAvailabilityItemFields.Date]
              ),
          endDate: isFullDayAvailability
            ? endOfDay(period[DailyAvailabilityItemFields.Date])
            : parseEndDate(
                period[DailyAvailabilityItemFields.TimeFrom],
                period[DailyAvailabilityItemFields.TimeTo],
                period[DailyAvailabilityItemFields.Date]
              ),
          openForOvertime:
            period[DailyAvailabilityItemFields.IsOpenForOvertime],
          note: '',
        }),
      ],
      [] as Period[]
    )
}

const messages: Partial<Record<ResourcePeriodType, string>> = {
  [ResourcePeriodType.Absence]: 'absenceCreated',
  [ResourcePeriodType.Availability]: 'availabilityCreated',
}

export const useOnSubmit = (onSuccess: () => void) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const careProviderId = useOrganizationId()
  const resourceAccountId = useOrganizationResourceId()
  const mapPeriods = useMapPeriods()
  const { loading } = useSelector(
    selectors.accessibilities.getCreateResourceAccessibilities
  )

  const onFailure = (error?: string) => {
    enqueueSnackbar(error || t('error.defaultMessage'), {
      variant: 'error',
    })
  }

  const onSubmit = (values: AvailabilityFormValues) => {
    const type = values[AvailabilityFormFields.Type]
    const selectedResourceId = values[AvailabilityFormFields.Resource]?.value
    const periods = mapPeriods(
      values[AvailabilityFormFields.DailyAvailability],
      values[AvailabilityFormFields.FullDayAvailability],
      values[AvailabilityFormFields.Reason]?.value
    )

    if (!careProviderId || (!selectedResourceId && !resourceAccountId)) {
      return onFailure(t('error.missingRequestData'))
    }

    const onSuccessActions = () => {
      enqueueSnackbar(t(`resourceAccessibility.${messages[type]}`))
      onSuccess()
    }

    return dispatch(
      actions.accessibilities.createResourceAccessibilities({
        onFailure,
        onSuccess: onSuccessActions,
        params: {
          type,
          periods,
          careProviderId,
          resourceId: selectedResourceId || resourceAccountId,
        },
      })
    )
  }

  return { isSubmitting: loading === LoadingStatus.Pending, onSubmit }
}

export const useFormProps = (): Omit<
  FormProps<AvailabilityFormValues>,
  'onSubmit'
> => {
  const schema = useValidationSchema()
  const defaultValues = useMemo(() => getDefaultValues(), [])
  const methods = useForm<AvailabilityFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })
  return methods
}

export const useFormValues = (
  control: Control<AvailabilityFormValues, object>
) => {
  const [
    type,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    isOpenForOvertime,
    dailyAvailability,
    fullDayAvailability,
    resource,
  ] = useWatch({
    control,
    name: [
      AvailabilityFormFields.Type,
      AvailabilityFormFields.DateFrom,
      AvailabilityFormFields.DateTo,
      AvailabilityFormFields.TimeFrom,
      AvailabilityFormFields.TimeTo,
      AvailabilityFormFields.IsOpenForOvertime,
      AvailabilityFormFields.DailyAvailability,
      AvailabilityFormFields.FullDayAvailability,
      AvailabilityFormFields.Resource,
    ],
  }) as [
    AvailabilityFormValues[AvailabilityFormFields.Type],
    AvailabilityFormValues[AvailabilityFormFields.DateFrom],
    AvailabilityFormValues[AvailabilityFormFields.DateTo],
    AvailabilityFormValues[AvailabilityFormFields.TimeFrom],
    AvailabilityFormValues[AvailabilityFormFields.TimeTo],
    AvailabilityFormValues[AvailabilityFormFields.IsOpenForOvertime],
    AvailabilityFormValues[AvailabilityFormFields.DailyAvailability],
    AvailabilityFormValues[AvailabilityFormFields.FullDayAvailability],
    AvailabilityFormValues[AvailabilityFormFields.Resource]
  ]

  useStoreValue(config.localStorage.resourceAvailabilityTimeFrom, timeFrom)
  useStoreValue(config.localStorage.resourceAvailabilityTimeTo, timeTo)

  return {
    type,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    isOpenForOvertime,
    dailyAvailability,
    fullDayAvailability,
    resource,
  }
}

interface UseGetDailyAvailabilityProps {
  timeFrom: string
  timeTo: string
  isOpenForOvertime?: boolean
  control: Control<AvailabilityFormValues, object>
}

const useGetDailyAvailability = ({
  timeFrom,
  timeTo,
  isOpenForOvertime,
  control,
}: UseGetDailyAvailabilityProps) => {
  const { dailyAvailability } = useFormValues(control)

  const dailyAvailabilityDefaultValues = useMemo(
    () => ({
      [DailyAvailabilityItemFields.TimeFrom]: timeFrom,
      [DailyAvailabilityItemFields.TimeTo]: timeTo,
      [DailyAvailabilityItemFields.IsOpenForOvertime]: isOpenForOvertime,
      [DailyAvailabilityItemFields.IsRemoved]: false,
    }),
    [isOpenForOvertime, timeFrom, timeTo]
  )

  const getDailyAvailability = useCallback(
    (dailyAvailabilityDate: Date) =>
      dailyAvailability.find(({ date }) =>
        isEqual(dailyAvailabilityDate, date)
      ) || dailyAvailabilityDefaultValues,
    [dailyAvailability, dailyAvailabilityDefaultValues]
  )

  return getDailyAvailability
}

interface UseFormResetOptions {
  dateFrom: Date
  dateTo: Date
  timeFrom: string
  timeTo: string
  isOpenForOvertime?: boolean
  setValue: UseFormSetValue<AvailabilityFormValues>
  control: Control<AvailabilityFormValues, object>
}

export const useFormReset = ({
  dateFrom,
  dateTo,
  setValue,
  timeFrom,
  timeTo,
  ...options
}: UseFormResetOptions) => {
  const getDailyAvailability = useGetDailyAvailability({
    timeFrom,
    timeTo,
    ...options,
  })

  const start = dateFrom ? startOfDay(dateFrom) : startOfToday()
  const end = dateTo ? endOfDay(dateTo) : endOfToday()

  useEffect(() => {
    if (!isBefore(start, end)) return
    const days: DailyAvailabilityItemValues[] = eachDayOfInterval({
      start,
      end,
    }).map(date => {
      const dailyAvailability = getDailyAvailability(date)
      return {
        ...dailyAvailability,
        [DailyAvailabilityItemFields.Date]: date,
      }
    })
    setValue(AvailabilityFormFields.DailyAvailability, days)
  }, [dateFrom, dateTo]) // eslint-disable-line

  useEffect(() => {
    if (!isBefore(start, end)) return

    const days: DailyAvailabilityItemValues[] = eachDayOfInterval({
      start,
      end,
    }).map(date => {
      const dailyAvailability = getDailyAvailability(date)
      return {
        ...dailyAvailability,
        [DailyAvailabilityItemFields.Date]: date,
        [DailyAvailabilityItemFields.TimeFrom]: timeFrom,
      }
    })
    setValue(AvailabilityFormFields.DailyAvailability, days)
  }, [timeFrom]) // eslint-disable-line

  useEffect(() => {
    if (!isBefore(start, end)) return

    const days: DailyAvailabilityItemValues[] = eachDayOfInterval({
      start,
      end,
    }).map(date => {
      const dailyAvailability = getDailyAvailability(date)
      return {
        ...dailyAvailability,
        [DailyAvailabilityItemFields.Date]: date,
        [DailyAvailabilityItemFields.TimeTo]: timeTo,
      }
    })
    setValue(AvailabilityFormFields.DailyAvailability, days)
  }, [timeTo]) // eslint-disable-line
}

interface UseChangedDaysNumberOptions {
  timeFrom: string
  timeTo: string
  dailyAvailability: DailyAvailabilityItemValues[]
}

export const getChangedDaysNumber = ({
  timeFrom,
  timeTo,
  dailyAvailability,
}: UseChangedDaysNumberOptions) =>
  dailyAvailability.reduce(
    (acc, day) =>
      acc +
      (day.isRemoved || day.timeFrom !== timeFrom || day.timeTo !== timeTo
        ? 1
        : 0),
    0
  )
