import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ExpandMore } from '@mui/icons-material'
import { Select, SelectChangeEvent, Typography } from '@mui/material'
import countryFlagIcons from '../../assets/flags'
import { useDispatch } from '../../hooks'
import { actions, selectors } from '../../store'
import { LanguageCode } from '../../types'
import {
  StyledFlagIcon,
  StyledInput,
  StyledMenuItem,
} from './LanguagePicker.styles'

const LANGUAGE_OPTIONS = [
  {
    value: LanguageCode.SV,
    label: 'SE',
    icon: countryFlagIcons[LanguageCode.SV],
  },
  {
    value: LanguageCode.EN,
    label: 'EN',
    icon: countryFlagIcons[LanguageCode.EN],
  },
  // uncomment once Norwegian json files are ready
  // {
  //   value: LanguageCode.NO,
  //   label: 'NO',
  //   icon: countryFlagIcons[LanguageCode.NO],
  // },
]

interface LanguagePickerProps {
  border?: boolean
}

const LanguagePicker = ({ border = false }: LanguagePickerProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(selectors.settings.getLanguage)
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    dispatch(actions.settings.setupLanguage(event.target.value as LanguageCode))
  }

  return (
    <Select
      data-test-id="select-language-picker"
      onChange={event => handleChangeLanguage(event)}
      IconComponent={ExpandMore}
      input={<StyledInput border={border} />}
      value={selectedLanguage}
    >
      {LANGUAGE_OPTIONS.map(opt => (
        <StyledMenuItem
          data-test-id={`language-${opt.value}`}
          disableGutters
          key={`language-picker-opt-${opt.value}`}
          value={opt.value}
          selected={opt.value === selectedLanguage}
        >
          <StyledFlagIcon
            src={opt.icon}
            alt={`${opt.label} ${t('locales.countryFlag')}`}
          />
          <Typography lineHeight={1.4} variant="caption">
            {opt.label}
          </Typography>
        </StyledMenuItem>
      ))}
    </Select>
  )
}

export default LanguagePicker
