import { createReducer } from '@reduxjs/toolkit'
import resource, { Resource } from 'shared/resource'
import { GetChatsParams } from 'shared/services/chat'
import {
  ChatMarking,
  ChatMessageType,
  ChatUserMode,
  Chat,
  ChatListEntry,
  ChatMessage,
} from 'shared/types'
import {
  archiveChat,
  createChat,
  getChatMessages,
  getChats,
  getSelectedChat,
  toggleChatFlag,
  addUsersToChat,
  sendChatMessage,
  markChatAsRead,
  unarchiveChat,
  receiveChatMessage,
  resetChatMessages,
  resetSelectedChat,
  setChatsFilterParams,
} from './actions'
import { getMessagesObject, sortChats } from './utils'

type ChatUser = Chat['users'][number]

interface State {
  getChats: Resource<ChatListEntry[]>
  getSelectedChat: Resource<Chat | null>
  createChat: Resource<{ createdChatId: number } | null>
  getChatMessages: Resource<Record<number, Omit<ChatMessage, 'id'>>>
  addUsersToChat: Resource
  sendChatMessage: Resource
  archiveChat: Resource
  unarchiveChat: Resource
  toggleChatFlag: Resource
  chatsFilterParams: Partial<GetChatsParams>
}

const initialState: State = {
  getChats: resource.getInitial([]),
  getSelectedChat: resource.getInitial(null),
  getChatMessages: resource.getInitial([]),
  createChat: resource.getInitial(null),
  addUsersToChat: resource.getInitial(),
  sendChatMessage: resource.getInitial(),
  archiveChat: resource.getInitial(),
  unarchiveChat: resource.getInitial(),
  toggleChatFlag: resource.getInitial(),
  chatsFilterParams: {},
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getChats.pending, state => {
      resource.setPending(state.getChats)
    })
    .addCase(getChats.fulfilled, (state, action) => {
      // if skip is provided, combine existing data with data incoming from API before sending it to state:
      const combinedData = action.payload.meta.skip
        ? [...state.getChats.data, ...action.payload.data]
        : action.payload.data

      resource.setSucceeded(
        state.getChats,
        combinedData.sort(sortChats),
        action.payload.meta
      )
    })
    .addCase(getChats.rejected, (state, action) => {
      resource.setFailed(state.getChats, action.error.message)
    })
    .addCase(setChatsFilterParams, (state, action) => {
      state.chatsFilterParams = action.payload
    })
    .addCase(getSelectedChat.pending, state => {
      resource.setPending(state.getSelectedChat)
    })
    .addCase(getSelectedChat.fulfilled, (state, action) => {
      resource.setSucceeded(state.getSelectedChat, action.payload)
    })
    .addCase(getSelectedChat.rejected, (state, action) => {
      resource.setFailed(state.getSelectedChat, action.error.message)
    })
    .addCase(resetSelectedChat, state => {
      resource.reset(state.getSelectedChat, initialState.getSelectedChat.data)
    })
    .addCase(addUsersToChat.pending, state => {
      resource.setPending(state.addUsersToChat)
    })
    .addCase(addUsersToChat.fulfilled, (state, action) => {
      resource.setSucceeded(state.addUsersToChat, action.payload)

      if (!state.getSelectedChat.data?.users) return

      const chatUsers: ChatUser[] = action.payload.data.map(chatUser => {
        const account = action.meta.arg.params.accounts.find(
          a => a?.value === chatUser.accountId
        )

        return {
          ...chatUser,
          title: account?.data?.title || '',
          name: account?.label || '',
          mode: ChatUserMode.Basic,
        }
      })

      state.getSelectedChat.data.users = [
        ...state.getSelectedChat.data.users,
        ...chatUsers,
      ]
    })
    .addCase(addUsersToChat.rejected, (state, action) => {
      resource.setFailed(state.addUsersToChat, action.error.message)
    })
    .addCase(sendChatMessage.pending, state => {
      resource.setPending(state.sendChatMessage)
    })
    .addCase(sendChatMessage.fulfilled, (state, action) => {
      if (!state.getSelectedChat.data) return
      resource.setSucceeded(state.sendChatMessage, action.payload)
      const { id: messageId, ...message } = action.payload
      const { user, id: chatId } = state.getSelectedChat.data
      const newMessage = {
        ...message,
        userId: user.id,
        authorAccountId: user.accountId,
        authorName: user.name,
        authorTitle: user.title,
        type: ChatMessageType.User,
      }

      Object.assign(state.getChatMessages.data, { [messageId]: newMessage })

      const chatListEntry = state.getChats.data.find(({ id }) => id === chatId)
      if (!chatListEntry) return

      chatListEntry.lastMessage = {
        ...action.payload,
        createdBy: { id: user.accountId, name: user.name },
      }

      state.getChats.data = state.getChats.data.sort(sortChats)
    })
    .addCase(sendChatMessage.rejected, (state, action) => {
      resource.setFailed(state.sendChatMessage, action.error.message)
    })
    .addCase(archiveChat.pending, state => {
      resource.setPending(state.archiveChat)
    })
    .addCase(archiveChat.fulfilled, (state, action) => {
      resource.setSucceeded(state.archiveChat, action.payload)

      state.getSelectedChat.data = null

      state.getChats.data = state.getChats.data.filter(
        ({ id }) => action.payload !== id
      )
    })
    .addCase(archiveChat.rejected, (state, action) => {
      resource.setFailed(state.archiveChat, action.error.message)
    })
    .addCase(unarchiveChat.pending, state => {
      resource.setPending(state.unarchiveChat)
    })
    .addCase(unarchiveChat.fulfilled, (state, action) => {
      resource.setSucceeded(state.unarchiveChat, action.payload)
      const selectedChat = state.getSelectedChat.data
      if (!selectedChat) return
      selectedChat.user.markings = selectedChat.user.markings.filter(
        marking => marking !== ChatMarking.Archived
      )

      const selectedListChat = state.getChats.data.find(
        chat => chat.id === selectedChat?.id
      )

      if (!selectedListChat?.user) return
      selectedListChat.user.markings = selectedListChat.user.markings.filter(
        marking => marking !== ChatMarking.Archived
      )
    })
    .addCase(unarchiveChat.rejected, (state, action) => {
      resource.setFailed(state.unarchiveChat, action.error.message)
    })
    .addCase(toggleChatFlag.pending, state => {
      resource.setPending(state.toggleChatFlag)
    })
    .addCase(toggleChatFlag.fulfilled, (state, action) => {
      resource.setSucceeded(state.toggleChatFlag, action.payload)
      const selectedChatUser = state.getSelectedChat.data?.user
      if (selectedChatUser?.markings) {
        if (selectedChatUser.markings.includes(ChatMarking.Flagged))
          selectedChatUser.markings = selectedChatUser?.markings.filter(
            marking => marking !== ChatMarking.Flagged
          )
        else selectedChatUser.markings.push(ChatMarking.Flagged)
      }

      const selectedListChat = state.getChats.data.find(
        ({ id }) => action.payload === id
      )

      if (selectedListChat?.user?.markings.includes(ChatMarking.Flagged)) {
        selectedListChat.user.markings = selectedListChat.user.markings.filter(
          marking => marking !== ChatMarking.Flagged
        )
      } else {
        selectedListChat?.user?.markings.push(ChatMarking.Flagged)
      }
    })
    .addCase(toggleChatFlag.rejected, (state, action) => {
      resource.setFailed(state.toggleChatFlag, action.error.message)
    })
    .addCase(getChatMessages.pending, state => {
      resource.setPending(state.getChatMessages)
    })
    .addCase(getChatMessages.fulfilled, (state, action) => {
      const { data: messages, meta } = action.payload

      resource.setSucceeded(
        state.getChatMessages,
        meta.skip > 0
          ? { ...state.getChatMessages.data, ...getMessagesObject(messages) }
          : getMessagesObject(messages),
        meta
      )
    })
    .addCase(getChatMessages.rejected, (state, action) => {
      resource.setFailed(state.getChatMessages, action.error.message)
    })
    .addCase(resetChatMessages, state => {
      resource.reset(state.getChatMessages, initialState.getChatMessages.data)
      resource.reset(state.sendChatMessage)
    })
    .addCase(createChat.pending, state => {
      resource.setPending(state.createChat)
    })
    .addCase(createChat.fulfilled, (state, action) => {
      resource.setSucceeded(state.createChat, {
        createdChatId: action.payload.id,
      })

      const chat: ChatListEntry = {
        ...action.payload,
        lastMessage: null,
        lastUnreadMessage: null,
      }

      state.getChats.data.unshift(chat)
      if (state.getChats.meta?.count) state.getChats.meta.count += 1
    })
    .addCase(createChat.rejected, (state, action) => {
      resource.setFailed(state.createChat, action.error.message)
    })
    .addCase(receiveChatMessage.fulfilled, (state, payload) => {
      const message = payload.meta.arg.message

      if (payload.meta.arg.authAccountId === message.createdBy.id) return

      const listChat = state.getChats.data.find(
        chat => chat.id === message.chatId
      )

      if (listChat) {
        listChat.lastMessage = message
      }

      if (state.getSelectedChat.data?.id === message.chatId) {
        const { id, body, createdBy, createdAt, userId } = message
        state.getChatMessages.data[id] = {
          body,
          createdAt,
          userId,
          authorName: createdBy.name,
          authorTitle: createdBy.title,
          authorAccountId: createdBy.id,
          type: ChatMessageType.User,
        }
      } else {
        if (!listChat || listChat.user?.markings.includes(ChatMarking.Archived))
          return
        listChat.lastUnreadMessage = message
      }

      state.getChats.data = state.getChats.data.sort(sortChats)
    })
    .addCase(markChatAsRead, (state, action) => {
      const chatToRead = state.getChats.data.find(
        ({ id }) => id === action.payload
      )

      if (!chatToRead) return

      chatToRead.lastUnreadMessage = null
    })
)
