import { Components } from '@mui/material'

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    input: { padding: '0 !important' },
    inputRoot: { padding: '8px 52px 12px 12px !important' },
  },
}

export default MuiAutocomplete
