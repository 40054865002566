import { styled, AppBar as BaseAppBar } from '@mui/material'
import { OPEN_DRAWER_WIDTH } from '../Drawer'

export const StyledTopbar = styled(BaseAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}))

export const ToolbarActions = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const LogoContainer = styled('div')({
  width: OPEN_DRAWER_WIDTH,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const LanguagePickerWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2),
}))
