import { SvgIcon, SvgIconProps } from '@mui/material'

const ViviumX = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path
        d="M21.2698 0H3.8964C1.83653 0 0.166016 1.67011 0.166016 3.72947V21.0986C0.166016 23.158 1.83653 24.8281 3.8964 24.8281H21.2698C23.3297 24.8281 25.0002 23.158 25.0002 21.0986V3.72947C24.9975 1.67011 23.3297 0 21.2698 0ZM19.4598 19.9624H17.2763C17.0948 19.9624 16.9212 19.8783 16.8081 19.7362L12.4647 14.2525L8.22397 19.6047C8.11084 19.7468 7.93722 19.8309 7.7557 19.8309H5.57219C5.07235 19.8309 4.79348 19.2549 5.10391 18.863L10.61 11.9143L5.89313 5.96243C5.5827 5.57054 5.86157 4.99455 6.36141 4.99455H8.54492C8.72644 4.99455 8.90007 5.07871 9.01319 5.22074L12.4647 9.57617L16.0188 5.08923C16.132 4.94721 16.3056 4.86304 16.4871 4.86304H18.6706C19.1705 4.86304 19.4493 5.43904 19.1389 5.83092L14.3194 11.9143L19.9281 18.9945C20.2385 19.3864 19.9597 19.9624 19.4598 19.9624Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ViviumX
