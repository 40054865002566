import { Entity, ResourcePeriodType, UserRole } from 'shared/types'

interface UseIsShowingModifiedByParams {
  createdBy?: Entity<{ role: UserRole }>
  modifiedBy?: Entity<{ role: UserRole }>
  type?: ResourcePeriodType
}

export const useResourceScheduleModificationIconLabel = ({
  createdBy,
  modifiedBy,
  type,
}: UseIsShowingModifiedByParams) => {
  const isAvailabilityOrAbsence =
    type === ResourcePeriodType.Availability ||
    type === ResourcePeriodType.Absence

  if (!isAvailabilityOrAbsence || !createdBy) return null

  if (modifiedBy && modifiedBy.role !== UserRole.Resource)
    return modifiedBy.name

  if (!modifiedBy && createdBy.role !== UserRole.Resource) return createdBy.name

  return null
}
