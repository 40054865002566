import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronRight, Search } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import {
  useAvailabilityTypes,
  useDialog,
  useEnumOptions,
  useGetCareProviderResourceOptions,
} from '../../hooks'
import { AbsenceReason, ResourcePeriodType } from '../../types'
import AutocompleteField from '../AutocompleteField'
import CheckboxField from '../CheckboxField'
import DateField from '../DateField'
import Form from '../Form'
import LoadingButton from '../LoadingButton'
import Prompt from '../Prompt'
import RadioField from '../RadioField'
import StartAdornment from '../StartAdornment'
import TimeField from '../TimeField'
import DailyAvailability, {
  DailyAvailabilityForm,
  DailyAvailabilityItemFields,
} from './DailyAvailability'
import OvertimeCheckboxField from './OvertimeCheckboxField'
import ResourceAvailabilityTable from './ResourceAvailabilityTable'
import { StyledBadge } from './AvailabilityForm.styles'
import {
  useFormProps,
  useFormValues,
  useOnSubmit,
  useFormReset,
  getChangedDaysNumber,
  AvailabilityFormFields,
  TIME_FORMAT,
} from './AvailabilityForm.utils'

const DATE_FORMAT = 'yyyy-MM-dd'

interface AvailabilityFormProps {
  onSuccess: () => void
  resourceForm?: boolean
  onClose?: () => void
}

const AvailabilityForm = ({
  resourceForm = false,
  onClose,
  onSuccess,
}: AvailabilityFormProps) => {
  const { t } = useTranslation()
  const formProps = useFormProps()
  const { setValue, getValues, control } = formProps
  const { onSubmit, isSubmitting } = useOnSubmit(onSuccess)
  const typeOptions = useAvailabilityTypes()
  const formValues = useFormValues(control)
  const reasonOptions = useEnumOptions(AbsenceReason, 'AbsenceReason')
  const getResourceOptions = useGetCareProviderResourceOptions()
  const [shouldPromptAppear, setShouldPromptAppear] = useState(true)
  const [isDailyAvilabilityVisible, setIsDailyAvilabilityVisible] =
    useState(false)
  const [
    isDailyAvailabilityOpen,
    ,
    openDailyAvailability,
    closeDailyAvailability,
  ] = useDialog()
  const {
    type,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    fullDayAvailability,
    dailyAvailability,
    resource,
  } = formValues

  useFormReset({ ...formValues, setValue, control })

  const isDailyAvailabilityDisabled =
    !dateFrom || !dateTo || !timeFrom || !timeTo || fullDayAvailability

  const changedDaysNumber = getChangedDaysNumber(formValues)

  const OpenForOvertime =
    type === ResourcePeriodType.Availability ? (
      <OvertimeCheckboxField
        name={AvailabilityFormFields.IsOpenForOvertime}
        selectAllFor={AvailabilityFormFields.DailyAvailability}
        targetFieldName={DailyAvailabilityItemFields.IsOpenForOvertime}
        disabled={fullDayAvailability}
      />
    ) : null

  useEffect(() => {
    if (fullDayAvailability)
      setValue(
        AvailabilityFormFields.DailyAvailability,
        dailyAvailability.map(availability => ({
          ...availability,
          isOpenForOvertime: false,
          timeFrom,
          timeTo,
          isRemoved: false,
        }))
      )
  }, [fullDayAvailability]) //eslint-disable-line

  const handleSubmit = () => {
    onSubmit(getValues())
    setShouldPromptAppear(false)
  }

  return (
    <>
      <Prompt
        message={t('dirtyFormPromptMessage')}
        when={!!changedDaysNumber && shouldPromptAppear && resourceForm}
      />
      <Form {...formProps} onSubmit={handleSubmit}>
        <DailyAvailabilityForm
          isDailyAvilabilityVisible={isDailyAvilabilityVisible}
          resourceForm={resourceForm}
          isDailyAvailabilityOpen={isDailyAvailabilityOpen}
          onClose={() => closeDailyAvailability()}
        >
          <DailyAvailability
            name={AvailabilityFormFields.DailyAvailability}
            openForOvertimeComponent={OpenForOvertime}
            resourceForm={resourceForm}
            onClose={
              resourceForm
                ? () => closeDailyAvailability()
                : () => setIsDailyAvilabilityVisible(false)
            }
          />
        </DailyAvailabilityForm>
        {!isDailyAvilabilityVisible && (
          <Grid container spacing={2}>
            {!resourceForm && (
              <Grid item xs={12}>
                <AutocompleteField
                  name={AvailabilityFormFields.Resource}
                  label={t('availabilityModal.selectResource')}
                  placeholder={t('form.resourcesPlaceholderShort')}
                  fetchOptions={getResourceOptions}
                  textFieldProps={{
                    InputProps: {
                      startAdornment: <StartAdornment icon={Search} />,
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <RadioField
                    row
                    name={AvailabilityFormFields.Type}
                    options={typeOptions}
                  />
                </Grid>
                {type === ResourcePeriodType.Absence && (
                  <Grid item xs={12}>
                    <AutocompleteField
                      name={AvailabilityFormFields.Reason}
                      placeholder={t('availabilityModal.reasonPlaceholder')}
                      options={reasonOptions}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <DateField
                    inputFormat={DATE_FORMAT}
                    name={AvailabilityFormFields.DateFrom}
                    label={t('availabilityModal.dateFrom')}
                    disablePast
                    data-test-id="availability-table-date-from"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateField
                    inputFormat={DATE_FORMAT}
                    name={AvailabilityFormFields.DateTo}
                    label={t('availabilityModal.dateTo')}
                    minRangeDate={dateFrom}
                    data-test-id="availability-table-date-to"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TimeField
                    name={AvailabilityFormFields.TimeFrom}
                    format={TIME_FORMAT}
                    variant="standard"
                    disabled={fullDayAvailability}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TimeField
                    name={AvailabilityFormFields.TimeTo}
                    format={TIME_FORMAT}
                    variant="standard"
                    disabled={fullDayAvailability}
                  />
                </Grid>
                <Grid item xs>
                  <CheckboxField
                    name={AvailabilityFormFields.FullDayAvailability}
                    label={t('availabilityModal.fullDayAvailability')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>{OpenForOvertime}</Grid>
                <Grid item>
                  <StyledBadge
                    badgeContent={changedDaysNumber}
                    color="error"
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <Button
                      type="button"
                      variant="text"
                      color="primary"
                      fullWidth={false}
                      endIcon={<ChevronRight />}
                      onClick={
                        resourceForm
                          ? () => openDailyAvailability()
                          : () => setIsDailyAvilabilityVisible(true)
                      }
                      disabled={isDailyAvailabilityDisabled}
                    >
                      {t('availabilityModal.adjustPerDay')}
                    </Button>
                  </StyledBadge>
                </Grid>
              </Grid>
            </Grid>
            {resource && !resourceForm && (
              <Grid item xs={12}>
                <ResourceAvailabilityTable
                  resource={resource}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {!resourceForm && onClose && (
                  <Grid item>
                    <Button onClick={onClose}>
                      {t('availabilityModal.cancel')}
                    </Button>
                  </Grid>
                )}
                <Grid item {...(resourceForm && { xs: 12 })}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                  >
                    {t('availabilityModal.submit')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Form>
    </>
  )
}

export default AvailabilityForm
