import { Grid, styled } from '@mui/material'
import TextField from '../../../TextField'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '& .MuiInputBase-root': {
    fontSize: '0.825rem',
  },
  '& MuiTextField-root': {
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiFormHelperText-root.MuiFormHelperText-error': {
    backgroundColor: theme.palette.neutral.main,
  },
  alignSelf: 'center',
}))

export const ExternalResourceFieldsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2, 1),
}))

export const ResourceNoteWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}))
