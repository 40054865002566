import { useMemo } from 'react'
import { paths } from 'config'
import { useOrganizationId } from '../../../../../hooks'
import {
  AutocompleteValue,
  CalendarView,
  SchedulerType,
} from '../../../../../types'
import { generatePathWithQuery } from '../../../../../utils'

interface UseBookingSchedulePathOptions {
  resource?: AutocompleteValue | null
  unit?: AutocompleteValue | null
  date?: string
  schedulerType?: SchedulerType
}

interface GeneratePathOptions extends UseBookingSchedulePathOptions {
  organizationId: number
}

export const genrateSchedulerPath = ({
  organizationId,
  unit,
  resource,
  date,
  schedulerType,
}: GeneratePathOptions) => {
  switch (schedulerType) {
    case SchedulerType.Booking:
      const unitId = unit?.value
      return generatePathWithQuery({
        path: paths.organizationUnitBookings,
        params: {
          organizationId: organizationId.toString(),
          unitId: typeof unitId === 'number' ? unitId.toString() : unitId || '',
        },
        qs: { date, resource: resource },
      })
    case SchedulerType.Scheme:
      return generatePathWithQuery({
        path: paths.organizationSchedules,
        params: { organizationId: organizationId.toString() },
        qs: {
          date,
          unit: unit,
          viewName: CalendarView.Weekly,
          ...(resource && { resource: resource }),
        },
      })
    default:
      return ''
  }
}

export const useBookingSchedulePath = ({
  unit,
  resource,
  date,
  schedulerType = SchedulerType.Booking,
}: UseBookingSchedulePathOptions) => {
  const organizationId = useOrganizationId()

  return useMemo(
    () =>
      organizationId && unit
        ? genrateSchedulerPath({
            organizationId,
            date,
            resource,
            unit,
            schedulerType,
          })
        : '',
    [organizationId, resource, unit, date, schedulerType]
  )
}

interface RedirectionParams {
  resourceId?: number
  resourceName?: string
  unitId?: number
  unitName?: string
}

export const useRedirectionParams = ({
  resourceId,
  resourceName,
  unitId,
  unitName,
}: RedirectionParams) => {
  const resource = useMemo(
    () =>
      resourceId && resourceName
        ? { value: resourceId, label: resourceName }
        : null,
    [resourceId, resourceName]
  )

  const unit = useMemo(
    () => (unitId && unitName ? { value: unitId, label: unitName } : null),
    [unitId, unitName]
  )

  return { resource, unit }
}
