import { ExitToApp as BaseExitToApp, Help } from '@mui/icons-material'
import { Avatar as BaseAvatar, styled } from '@mui/material'

const AVATAR_DIMENSION = 35

export const ExitToApp = styled(BaseExitToApp)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

export const InfoIcon = styled(Help)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

export const Avatar = styled(BaseAvatar)({
  width: AVATAR_DIMENSION,
  height: AVATAR_DIMENSION,
})
