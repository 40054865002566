import { useCallback, useMemo } from 'react'
import { startOfToday } from 'date-fns'
import { parseQueryDate } from '../utils'
import { useQuery, useUpdateQuery } from './useQuery'

export const useQueryDate = () => {
  const { date: queryDate } = useQuery<{ date?: string }>()

  return useMemo(
    () => parseQueryDate(queryDate) || startOfToday(),
    [queryDate] //eslint-disable-line
  )
}

export const useUpdateQueryDate = () => {
  const updateQuery = useUpdateQuery()
  return useCallback(
    (newDate: Date) => updateQuery({ date: newDate }),
    [updateQuery]
  )
}
