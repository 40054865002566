import { isValid, parseISO, formatISO } from 'date-fns'

export const parseQueryDate = (
  date: string | null | undefined = '',
  defaultValue: Date | null = null
) => {
  const parsedDate = parseISO(date || '')
  return isValid(parsedDate) ? parsedDate : defaultValue
}

export const formatQueryDate = (
  date: Date | null | undefined,
  defaultValue = ''
) => (date ? formatISO(date) : defaultValue)
