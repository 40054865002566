import {
  ApplicationType,
  ChatMarking,
  ChatMessageType,
  ChatUserMode,
  Entity,
  FilteringParams,
  PaginationData,
  PaginationParams,
  SortingParams,
  UserRole,
} from '../../types'

export interface BaseChatParams {
  chatId: number
  careProviderId: number
}

interface ChatUser {
  id: number
  chatTitle: string
  markings: ChatMarking[]
}

export interface CreateChatParams {
  name: string
  accountIds: number[]
}

export type CreateChatResponse = Entity<{ createdAt: string; user: ChatUser }>

export interface EditChatParams extends BaseChatParams {
  title?: string
  archive?: boolean
  flag?: boolean
}

export type EditChatResponse = number

export interface SendChatMessageParams extends BaseChatParams {
  userId: number
  body: string
}

export type SendChatMessageResponse = {
  id: number
  body: string
  createdAt: string
}

export interface AddUsersToChatParams extends BaseChatParams {
  accountIds: number[]
}

export type AddUsersToChatResponse = {
  data: {
    accountId: number
    createdAt: string
    id: number
  }[]
}

export enum GetChatsSortBy {
  LastMessageCreatedAt = 'lastMessageCreatedAt',
}

export interface GetChatsParams
  extends PaginationParams,
    SortingParams,
    FilteringParams {
  markings?: ChatMarking[]
  onlyUnread?: boolean
  accountId?: number
  sortBy?: GetChatsSortBy
  app?: ApplicationType
  careProviderId: number
}

export interface GetChatResponse {
  id: number
  user: Entity<{
    mode: ChatUserMode
    chatTitle: string
    markings: ChatMarking[]
    accountId: number
    title?: string
  }>
  users: Entity<{ title?: string; mode: ChatUserMode; accountId: number }>[]
}

interface LastMessage {
  id: number
  body: string
  createdAt: string
  createdBy: Entity
}

export interface GetChatsResponse extends PaginationData {
  data: Entity<{
    createdAt: string
    user: ChatUser | null
    lastMessage?: LastMessage | null
    lastUnreadMessage?: LastMessage | null
  }>[]
}

export interface GetChatMessagesParams
  extends PaginationParams,
    SortingParams,
    BaseChatParams {}

export interface GetChatMessagesResponse extends PaginationData {
  data: {
    id: number
    body: string
    userId: number
    type: ChatMessageType
    createdAt: string
    authorAccountId: number
    authorName: string
    authorTitle: string
  }[]
}

export type GetChatUserParams = BaseChatParams

export interface GetChatUserResponse {
  id: number
  mode: ChatUserMode
  chatTitle: string
  markings: ChatMarking[]
}

export type GetChatMembersParams = BaseChatParams

export interface GetChatMembersResponse {
  data: Entity<{
    accountId: number
    title?: string
    mode: ChatUserMode
  }>[]
}

export interface GetChatAccountsParams extends SortingParams, FilteringParams {
  careProviderId: number
  authRoles?: UserRole[]
  notInChatId?: number
  inChatId?: number
}

export interface GetChatAccountsResponse {
  data: Entity<{
    title?: string
    role: UserRole
  }>[]
}

export interface GetChatNotificationsResponse {
  data: { id: number; type: string }[]
}

export interface GetChatNotificationsParams {
  app?: ApplicationType
  careProviderId?: number
  staffProviderId?: number
}

export enum ChatEvent {
  JoinRoom = 'JOIN_ROOM',
  LeaveRoom = 'LEAVE_ROOM',
  NewMessage = 'NEW_MESSAGE',
}

export interface NewMessageResponse {
  body: string
  chatId: number
  createdAt: string
  createdBy: Entity<{ title: string }>
  id: number
  userId: number
  type: ChatMessageType
}

export type GetChatParams = BaseChatParams

export type ReadChatNotificationsParams = BaseChatParams
