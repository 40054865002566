import { styled, Typography } from '@mui/material'
import LoadingButton from '../LoadingButton'

export const Button = styled(LoadingButton)(({ theme }) => ({
  minHeight: theme.spacing(3),
  height: theme.spacing(3),
  maxWidth: theme.spacing(28),
  width: theme.spacing(28),
  borderColor: theme.palette.secondary.main,
  borderWidth: 1,
}))

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  fontWeight: 500,
  letterSpacing: 1,
  color: theme.palette.secondary.main,
  textTransform: 'uppercase',
}))
