import { memo } from 'react'
import { styled } from '@mui/material'

const RADIUS = 4
const SVG_DIMENSION = 2 * RADIUS

interface MarkerTemplateProps {
  borderColor?: string
  color?: string
  filledLegend?: boolean
}

export const PillMarkerTemplate = styled('div')<MarkerTemplateProps>(
  ({ theme, borderColor, color, filledLegend }) => ({
    width: 24,
    height: 6,
    borderRadius: 3,
    background: filledLegend ? color : theme.palette.common.white,
    border:
      filledLegend && !borderColor
        ? 'none'
        : `1px solid ${borderColor || color}`,
  })
)

export const CircularMarkerTemplate = memo(({ color }: MarkerTemplateProps) => {
  return (
    <svg width={SVG_DIMENSION} height={SVG_DIMENSION}>
      <circle cx={RADIUS} cy={RADIUS} r={RADIUS} fill={color} />
    </svg>
  )
})
