import {
  AsyncThunkPayloadCreator,
  AsyncThunkOptions,
  Dispatch,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ApiError } from '../types'
import { BasePayload } from '../types/store'

export const getErrorMessage = <P = void, T extends string = string, M = never>(
  action: PayloadAction<P, T, M, SerializedError>
) =>
  (action.payload as Partial<ApiError> | undefined)?.type ||
  action.error.message

interface AsyncThunkConfig {
  state?: unknown
  dispatch?: Dispatch
  extra?: unknown
  rejectValue?: any
  serializedErrorType?: unknown
  pendingMeta?: unknown
  fulfilledMeta?: unknown
  rejectedMeta?: {}
}

export const createAsyncThunkWithErrorHandling = <
  Returned,
  ThunkArg = void,
  ThunkApiConfig extends AsyncThunkConfig = {}
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<BasePayload<ThunkArg>, ThunkApiConfig>
) =>
  createAsyncThunk<Returned, BasePayload<ThunkArg>, ThunkApiConfig>(
    typePrefix,
    async ({ onFailure, onSuccess, params }, thunkAPI): Promise<any> => {
      try {
        const data = await payloadCreator(params, thunkAPI)
        if (onSuccess) onSuccess()
        return data
      } catch (err) {
        const error = (err as AxiosError<ApiError>)?.response?.data || err
        if (onFailure) onFailure()
        return thunkAPI.rejectWithValue(error as any, {} as any)
      }
    },
    options
  )
