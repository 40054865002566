import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { object, string, SchemaOf } from 'yup'
import { useDispatch, useSelector } from '../../hooks'
import { useOrganizationId } from '../../hooks'
import { actions, selectors } from '../../store'
import { LoadingStatus, Tag } from '../../types'
import { FormProps } from '../Form'

export enum CreateTagFields {
  Name = 'name',
}

export interface CreateTagValues {
  [CreateTagFields.Name]: string
}

export type OnSuccess = (tag: Tag) => void

export const createTagDefaultValues = {
  [CreateTagFields.Name]: '',
}

export const useCreateTagSchema = (): SchemaOf<CreateTagValues> => {
  const { t } = useTranslation()
  return object()
    .shape({
      [CreateTagFields.Name]: string().required(t('validation.required')),
    })
    .required()
}

export const useOnSubmit = (onSuccess?: OnSuccess) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const careProviderId = useOrganizationId()
  const { loading, error, data } = useSelector(selectors.tags.getCreateTag)
  const { data: tags } = useSelector(selectors.tags.getTags)
  const [name, setName] = useState<string | null>(null)

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      enqueueSnackbar(t('createTagModal.addedSuccessfully'), {
        variant: 'success',
      })
      if (onSuccess && name) onSuccess({ id: parseInt(data, 10), name })
    }
    if (loading === LoadingStatus.Failed) {
      enqueueSnackbar(error || t('error.defaultMessage'), {
        variant: 'error',
      })
    }
  }, [loading]) // eslint-disable-line

  useEffect(() => {
    dispatch(actions.tags.getTags({ careProviderId }))
    return () => {
      dispatch(actions.tags.resetCreateTag())
    }
  }, []) // eslint-disable-line

  return ({ name }: CreateTagValues) => {
    if (!tags.some(tag => tag.name === name)) {
      setName(name)
      dispatch(actions.tags.createTag({ careProviderId, name }))
    } else {
      enqueueSnackbar(t('createTagModal.alreadyExists'), {
        variant: 'error',
      })
    }
  }
}

export const useFormProps = (
  onSuccess?: OnSuccess
): FormProps<CreateTagValues> => {
  const schema = useCreateTagSchema()
  const onSubmit = useOnSubmit(onSuccess)
  const methods = useForm<CreateTagValues>({
    defaultValues: createTagDefaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  return { ...methods, onSubmit }
}
