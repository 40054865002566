import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app'
import { addHours, isBefore, isSameDay, parseISO } from 'date-fns'
import {
  getSchedulerDates,
  getSchedulesDaysSideEffects,
  getSchedulesShiftsData,
} from 'shared/store/schedules/selectors'
import {
  Availability,
  LoadingStatus,
  MonthlyViewAppointment,
} from 'shared/types'
import {
  mapDaysToShifts,
  mapResourceSchedulesToAssignments,
} from 'shared/utils'
import { filterSummaries, mapResourceScheduleToDays } from './utils'
import { getRequest } from './reducer.utils'

export const LOADING_STATUS = LoadingStatus.Pending

export const getSchedulesSummariesData = (state: RootState) =>
  state.schedules.getSchedulesSummaries.data

export const getSchedulesSummariesLoading = (state: RootState) =>
  state.schedules.getSchedulesSummaries.loading

export const getResourceSchedules = (state: RootState) =>
  state.schedules.getResourceSchedules

export const getResourceSchedulesData = (state: RootState) =>
  state.schedules.getResourceSchedules.data

export const getResourceMonthlySchedule = createSelector(
  getResourceSchedulesData,
  mapResourceSchedulesToAssignments
)

export const getResourceWeeklySchedule = createSelector(
  getResourceSchedulesData,
  getSchedulesShiftsData,
  (days, shifts) =>
    mapDaysToShifts({
      data: mapResourceScheduleToDays(days),
      shifts,
    })
)

export const getSchedulesSummaries = createSelector(
  getSchedulesSummariesData,
  getResourceWeeklySchedule,
  (data, schedules): MonthlyViewAppointment[] =>
    data.map(({ date, ...rest }) => {
      const startDate = parseISO(date)
      const { availabilities = [], absences = [] } =
        schedules.find(schedule => isSameDay(startDate, schedule.startDate)) ||
        {}
      return {
        ...rest,
        startDate,
        availabilities,
        absences,
        endDate: addHours(parseISO(date), 1),
        assignments: [],
      }
    })
)

export const getResourceSchedulesSideEffects = (state: RootState) => ({
  loading: state.schedules.getResourceSchedules.loading,
  error: state.schedules.getResourceSchedules.error,
})

export const getResourceSchedulesLoading = (state: RootState) =>
  state.schedules.getResourceSchedules.loading

export const getResourceStatistics = (state: RootState) =>
  state.schedules.getResourceStatistics

export const getIsSchedulerLoading = createSelector(
  [
    getSchedulesSummariesLoading,
    getSchedulesDaysSideEffects,
    getResourceSchedulesLoading,
  ],
  (summariesLoading, daysSideEffects, resourceSchedulesLoading) =>
    LOADING_STATUS.includes(summariesLoading) ||
    LOADING_STATUS.includes(daysSideEffects.loading) ||
    LOADING_STATUS.includes(resourceSchedulesLoading)
)

export const getNeedAssignmentsStatistics = (state: RootState) =>
  state.schedules.getNeedAssignmentsStatistics

export const isAcceptRequestLoading =
  (requestId: number) => (state: RootState) =>
    getRequest(state.schedules, requestId)?.acceptRequest.loading ===
    LoadingStatus.Pending

export const isRejectRequestLoading =
  (requestId: number) => (state: RootState) =>
    getRequest(state.schedules, requestId)?.rejectRequest.loading ===
    LoadingStatus.Pending

export const getModifyBookedAssignmentResource = (state: RootState) =>
  state.schedules.modifyBookedAssignment

export const getResourceSchedulesAssignments = (state: RootState) => {
  return state.schedules.getResourceSchedules.data
    .reduce(
      (acc, data) => [...acc, ...data.availabilities],
      [] as Availability[]
    )
    .filter(
      availability => !isBefore(new Date(availability.startDate), new Date())
    )
}

export const getDeleteAvailabilities = (state: RootState) =>
  state.schedules.deleteAvailabilities

export const getChangeAssignmentStateResource = (state: RootState) =>
  state.needs.changeAssignmentState

export const getModifyBookedAssignmentLoading = (state: RootState) =>
  state.needs.modifyBookedAssignment.loading

export const getChangeAssignmentStateLoading = (state: RootState) =>
  state.needs.changeAssignmentState.loading

export const getScheduleAssignments = (state: RootState) =>
  state.schedules.getResourceSchedules.data.flatMap(
    ({ assignments }) => assignments
  )

export const getNeedsSummary = (state: RootState) =>
  state.schedules.getNeedsSummaries.data

export const getFilteredNeedsSummary = createSelector(
  [getNeedsSummary, getSchedulerDates],
  (data, { dateFrom, dateTo }) => filterSummaries(data, dateFrom, dateTo)
)

export const getNeedsSummarySideEffects = (state: RootState) => ({
  loading: state.schedules.getNeedsSummaries.loading,
  error: state.schedules.getNeedsSummaries.error,
})

export const getUnitNeedsSummaries = (state: RootState) =>
  state.schedules.getUnitNeedsSummaries.data

export const getFilteredUnitNeedsSummary = createSelector(
  [getUnitNeedsSummaries, getSchedulerDates],
  (data, { dateFrom, dateTo }) => filterSummaries(data, dateFrom, dateTo)
)

export const getUnitNeedsSummarySideEffects = (state: RootState) => ({
  loading: state.schedules.getUnitNeedsSummaries.loading,
  error: state.schedules.getUnitNeedsSummaries.error,
})

export const getResourcesSummary = (state: RootState) =>
  state.schedules.getResourceSummaries

export const getShiftsSummariesData = (state: RootState) =>
  state.schedules.getShiftsSummaries.data

export const getShiftsSummariesSideEffects = (state: RootState) => ({
  loading: state.schedules.getShiftsSummaries.loading,
  error: state.schedules.getShiftsSummaries.error,
})

export const getFilteredShiftsSummaries = createSelector(
  [getShiftsSummariesData, getSchedulerDates],
  (data, { dateFrom, dateTo }) => filterSummaries(data, dateFrom, dateTo)
)

export const exportSchedulesSideEffects = (state: RootState) => ({
  loading: state.schedules.exportSchedules.loading,
  error: state.schedules.exportSchedules.error,
})

export const getAssignmentNotesHistoryResource = (state: RootState) =>
  state.schedules.getAssignmentNotesHistory
