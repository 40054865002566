import { useEffect, useCallback } from 'react'
import config from 'config'
import { actions } from '../store'
import { useAppDispatch as useDispatch } from './redux'
import { useAuth } from './useAuth'
import { useOrganizationId, useOrganizationResourceId } from './useOrganization'

export const useNotificationsListener = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()
  const careProviderId = useOrganizationId()
  const resourceId = useOrganizationResourceId()

  const getUnreadNotifications = useCallback(() => {
    dispatch(
      actions.notifications.getUnreadNotifications({
        careProviderId,
        ...(resourceId && { resourceId }),
      })
    )
    dispatch(
      actions.notifications.getUnreadChatNotifications({ careProviderId })
    )

    if (!resourceId) return
    dispatch(
      actions.notifications.getRequestsCount({ careProviderId, resourceId })
    )
  }, [dispatch, careProviderId, resourceId])

  useEffect(() => {
    if (isAuthenticated) {
      getUnreadNotifications()
    }
  }, [getUnreadNotifications, isAuthenticated])

  useEffect(() => {
    const interval =
      careProviderId && isAuthenticated
        ? setInterval(
            getUnreadNotifications,
            config.notificationsListenerInterval
          )
        : null

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [getUnreadNotifications, careProviderId, isAuthenticated, resourceId])
}
