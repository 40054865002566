import { Fragment } from 'react'
import { Box, ListItem } from '@mui/material'
import { Skeleton } from '@mui/material'

const LOADER_ITEMS = 1

const NotificationsLoader = () => (
  <>
    {Array.from({ length: LOADER_ITEMS }, (_, i) => (
      <Fragment key={`loader-${i}`}>
        <ListItem disabled>
          <Box width="100%">
            <Skeleton variant="text" />
          </Box>
        </ListItem>
        <ListItem disabled>
          <Box width="100%">
            <Skeleton variant="text" />
          </Box>
        </ListItem>
        <ListItem disabled>
          <Box width="100%">
            <Skeleton variant="text" />
          </Box>
        </ListItem>
      </Fragment>
    ))}
  </>
)

export default NotificationsLoader
