import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import NeedAssignments, {
  AssignAssignmentToResourcesParams,
  ChangeAssignmentStateParams,
  GetNeedAssignmentNotesHistoryParams,
  ModifyBookedAssignmentParams,
} from 'shared/services/need-assignments'
import Resources, {
  GetResourceSchedulesParams,
  GetResourceStatisticsParams,
  DeleteResourceAvailabilitiesParams,
} from 'shared/services/resources'
import Schedules, {
  GetSchedulesSummariesParams,
  GetNeedsSummariesParams,
  GetResourcesSummariesParams,
  GetShiftsSummariesParams,
} from 'shared/services/schedules'
import Units, {
  GetNeedAssignmentsStatisticsParams,
} from 'shared/services/units'
import { ResourceActions } from 'shared/types'
import { createAsyncThunkWithErrorHandling } from 'shared/utils'
import { MODULE_NAME } from '../strings'
import { State } from './reducer'
import {
  RequestActionPayload,
  ExportSchedulesPayload,
  AddResourceNotePayload,
} from './actions.types'

const schedules = new Schedules()
const units = new Units()
const resources = new Resources()
const needAssignments = new NeedAssignments()

export const getSchedulesSummaries = createAsyncThunk(
  `${MODULE_NAME}/getMonthlySchedules`,
  (payload: GetSchedulesSummariesParams) =>
    schedules.getSchedulesSummaries(payload)
)

export const getResourceSchedules = createAsyncThunk(
  `${MODULE_NAME}/getResourceSchedules`,
  (payload: GetResourceSchedulesParams) =>
    resources.getResourceSchedules(payload)
)

export const refreshGetResourceSchedules = createAsyncThunk(
  `${MODULE_NAME}/refreshGetResourceSchedules`,
  (_, { getState }) => {
    const { schedules } = getState() as { schedules: State }
    const payload = schedules.getResourceSchedules.args
    return resources.getResourceSchedules(payload)
  }
)

export const resetGetResourceSchedules = createAction(
  `${MODULE_NAME}/resetGetResourceSchedules`
)

export const assignAssignmentToResources = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/assignAssignmentToResources`,
  (payload: AssignAssignmentToResourcesParams) =>
    needAssignments.assignAssignmentToResources(payload)
)

export const changeAssignmentState = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/changeAssignmentState`,
  (payload: ChangeAssignmentStateParams) =>
    needAssignments.changeAssignmentState(payload)
)

export const modifyBookedAssignment = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/modifyBookedAssignment`,
  (payload: ModifyBookedAssignmentParams) =>
    needAssignments.modifyBookedAssignment(payload)
)

export const resetChangeAssignmentState = createAction(
  `${MODULE_NAME}/resetChangeAssignmentState`
)

export const resetModifyBookedAssignment = createAction(
  `${MODULE_NAME}/resetModifyBookedAssignment`
)

export const getResourceStatistics = createAsyncThunk(
  `${MODULE_NAME}/getResourceStatistics`,
  (payload: GetResourceStatisticsParams) =>
    resources.getResourceStatistics(payload)
)

export const getNeedAssignmentsStatistics = createAsyncThunk(
  `${MODULE_NAME}/getNeedAssignmentsStatistics`,
  (payload: GetNeedAssignmentsStatisticsParams) =>
    units.getNeedAssignmentsStatistics(payload)
)

export const acceptRequest = createAsyncThunk(
  `${MODULE_NAME}/acceptRequest`,
  async ({ onSuccess, onError, ...payload }: RequestActionPayload) => {
    try {
      const res = await resources.requestAction({
        ...payload,
        action: ResourceActions.Accept,
      })
      onSuccess()
      return res
    } catch (err) {
      const error = err as AxiosError
      onError(error?.response?.data?.message || '')
    }
  }
)

export const rejectRequest = createAsyncThunk(
  `${MODULE_NAME}/rejectRequest`,
  async ({ onSuccess, onError, ...payload }: RequestActionPayload) => {
    try {
      const res = await resources.requestAction({
        ...payload,
        action: ResourceActions.Reject,
      })
      onSuccess()
      return res
    } catch (err) {
      const error = err as AxiosError
      onError(error?.response?.data?.message || '')
    }
  }
)

export const deleteAvailabilities = createAsyncThunk(
  `${MODULE_NAME}/deleteAvailabilities`,
  (payload: DeleteResourceAvailabilitiesParams) =>
    resources.deleteResourceAvailabilities(payload)
)

export const resetDeleteAvailabilities = createAction(
  `${MODULE_NAME}/resetDeleteAvailabilities`
)

export const resetGetSchedulesSummaries = createAction(
  `${MODULE_NAME}/resetGetSchedulesSummaries`
)

export const addResourceNoteToAssignment = createAsyncThunkWithErrorHandling(
  `${MODULE_NAME}/addResourceNoteToAssignment`,
  async (payload: AddResourceNotePayload) =>
    needAssignments.addResourceNote(payload)
)

export const getUnitNeedsSummaries = createAsyncThunk(
  `${MODULE_NAME}/getUnitNeedsSummaries`,
  (payload: GetNeedsSummariesParams) => schedules.getUnitNeedsSummaries(payload)
)

export const getNeedsSummaries = createAsyncThunk(
  `${MODULE_NAME}/getNeedsSummaries`,
  (payload: GetNeedsSummariesParams) => schedules.getNeedsSummaries(payload)
)

export const resetGetNeedsSummaries = createAction(
  `${MODULE_NAME}/resetGetNeedsSummaries`
)

export const getResourceSummaries = createAsyncThunk(
  `${MODULE_NAME}/getResourceSummaries`,
  (payload: GetResourcesSummariesParams) =>
    schedules.getResourcesSummaries(payload)
)

export const resetGetResourceSummaries = createAction(
  `${MODULE_NAME}/resetGetResourceSummaries`
)

export const getShiftsSummaries = createAsyncThunk(
  `${MODULE_NAME}/getShiftSummaries`,
  (payload: GetShiftsSummariesParams) => schedules.getShiftsSummaries(payload)
)

export const resetGetShiftsSummaries = createAction(
  `${MODULE_NAME}/resetGetShiftsSummaries`
)

export const exportSchedules = createAsyncThunk(
  `${MODULE_NAME}/exportSchedules`,
  async ({ onSuccess, onFailure, ...payload }: ExportSchedulesPayload) => {
    try {
      const file = await schedules.exportSchedules(payload)
      onSuccess(file)
      return file
    } catch {
      onFailure()
    }
  }
)

export const resetExportSchedules = createAction(
  `${MODULE_NAME}/resetExportSchedules`
)

export const getAssignmentNotesHistory = createAsyncThunk(
  `${MODULE_NAME}/getAssignmentNotesHistory`,
  (payload: GetNeedAssignmentNotesHistoryParams) =>
    needAssignments.getAssignmentNotesHistory(payload)
)

export const resetAssignmentNotesHistory = createAction(
  `${MODULE_NAME}/resetAssignmentNotesHistory`
)
